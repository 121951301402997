import {
    createContext,
    CSSProperties,
    ReactElement,
    ReactNode,
    RefObject,
    useContext,
    useRef,
    useState,
} from 'react';
import type { Placement, UseFloatingReturn } from '@floating-ui/react';
import {
    arrow,
    autoUpdate,
    flip,
    offset,
    shift,
    useDismiss,
    useFloating,
    useFocus,
    useHover,
    useInteractions,
    useRole,
    useTransitionStyles,
} from '@floating-ui/react';
import { ARROW_OFFSET } from './config';

type Interactions = ReturnType<typeof useInteractions>;

export interface TooltipContextReturn extends Interactions, UseFloatingReturn {
    open: boolean;
    setOpen: (open: boolean) => void;
    arrowRef: RefObject<SVGSVGElement>;
    isMounted: boolean;
    transitionStyles: CSSProperties;
    withArrow: boolean;
}

const TooltipContext =
    createContext<TooltipContextReturn | undefined>(undefined);

export interface TooltipProps {
    children: ReactNode;
    initialOpen?: boolean;
    placement?: Placement;
    open?: boolean;
    onOpenChange?: (open: boolean) => void;
    edgeOffset?: number;
    triggerOffset?: number;
    disabled?: boolean;
    openDelay?: number;
    closeDelay?: number;
    withArrow?: boolean;
    doNotAutoUpdate?: boolean;
}

export function Tooltip(props: TooltipProps): ReactElement {
    const {
        children,
        initialOpen = false,
        placement = 'bottom-start',
        open: controlledOpen,
        onOpenChange: setControlledOpen,
        edgeOffset = 5,
        withArrow = false,
        triggerOffset = 5,
        disabled = false,
        openDelay,
        closeDelay,
    } = props;

    const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);

    const isControlled = typeof controlledOpen === 'boolean';

    const open = controlledOpen ?? uncontrolledOpen;
    const setOpen = setControlledOpen ?? setUncontrolledOpen;
    const arrowRef = useRef<SVGSVGElement>(null);

    const floatingConfig = useFloating({
        placement,
        open,
        onOpenChange: setOpen,
        whileElementsMounted: props.doNotAutoUpdate ? undefined : autoUpdate,
        middleware: [
            offset(withArrow ? ARROW_OFFSET : triggerOffset),
            flip({
                fallbackAxisSideDirection: 'start',
                padding: edgeOffset,
            }),
            shift({ padding: edgeOffset }),
            arrow({ element: arrowRef }),
        ],
    });

    const { context } = floatingConfig;

    const hover = useHover(context, {
        move: false,
        enabled: !disabled && !isControlled,
        delay: { open: openDelay, close: closeDelay },
    });
    const focus = useFocus(context, {
        enabled: !disabled && !isControlled,
    });
    const dismiss = useDismiss(context);
    const role = useRole(context, { role: 'tooltip' });

    const interactions = useInteractions([hover, focus, dismiss, role]);

    const { isMounted, styles } = useTransitionStyles(context);

    const tooltipContext: TooltipContextReturn = {
        open,
        setOpen,
        arrowRef,
        isMounted,
        transitionStyles: styles,
        ...interactions,
        ...floatingConfig,
        withArrow,
    };

    return (
        <TooltipContext.Provider value={tooltipContext}>
            {children}
        </TooltipContext.Provider>
    );
}

export const useTooltipContext = (): TooltipContextReturn => {
    const context = useContext(TooltipContext);

    if (!context) {
        throw new Error('Tooltip components must be wrapped in <Tooltip />');
    }

    return context;
};
