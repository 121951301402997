import { useQuery, UseQueryResult } from 'react-query';
import { TargetProfile } from '../../services/dataService';
import { targets } from '../../services/nestApiService';

interface TargetProfileQueryParams {
    caseId: string;
    targetId: string;
}

export const useTargetProfileQuery = (
    queryParams: TargetProfileQueryParams,
): UseQueryResult<TargetProfile> =>
    useQuery(
        [
            'target',
            queryParams.caseId,
            queryParams.targetId,
            'target',
            'profile',
        ],
        async () =>
            targets.getProfile(queryParams.caseId, queryParams.targetId),
        {
            refetchOnWindowFocus: false,
        },
    );
