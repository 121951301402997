import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { cases } from '../../services/nestApiService';

export const useUpdateCaseAgentsMutation = (): UseMutationResult<
    boolean,
    unknown,
    { caseId: string; userIds: string[] }
> => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ caseId, userIds }) => cases.updateCaseAgents(caseId, userIds),
        {
            onSuccess: () => queryClient.invalidateQueries('caseAgents'),
        },
    );
};
