import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { cases } from '../../services/nestApiService';

type DeleteCaseMutationConfig = {
    onSuccess: () => void;
    onError: () => void;
};

export const useDeleteCaseMutation = ({
    onSuccess,
    onError,
}: DeleteCaseMutationConfig): UseMutationResult<
    boolean,
    unknown,
    Record<string, string>
> => {
    const queryClient = useQueryClient();

    return useMutation(({ caseId }) => cases.delete(caseId), {
        onSuccess: () => {
            onSuccess();
            queryClient.invalidateQueries('cases');
        },
        onError,
    });
};
