import { FC, memo, useCallback, useState } from 'react';
import {
    cvDisplayOrderRight,
    DynamicKeyword,
    dynamicKeywordSectionKeys,
    FormSchema,
    FormType,
} from './form.interface';
import { formConfigMap } from './form-configs';
import {
    Paragraph,
    TooltipContentV2,
    TooltipTriggerV2,
    TooltipV2,
} from '_atoms';
import { useTranslation } from 'react-i18next';
import { BiBlock, BiCheckCircle } from 'react-icons/bi';
import classnames from 'classnames';
import { RiDeleteBin6Line, RiEdit2Line } from 'react-icons/ri';
import { ConfirmationModal } from '_molecules';

export interface DynamicFormFieldDetailsProps {
    sectionKey: FormType;
    data: FormSchema[FormType];
    onEditData: (key: FormType, index?: number) => void;
    onDeleteData?: (key: FormType, index?: number) => void;
}

const EditAndDeleteButtons: FC<
    Omit<DynamicFormFieldDetailsProps, 'data'> & {
        index?: number;
        useAltHoverColor?: boolean;
    }
> = ({ onEditData, onDeleteData, sectionKey, index, useAltHoverColor }) => {
    const { t } = useTranslation();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const sharedClassName =
        'w-5 h-5 text-gray-400 transition-colors p-1 inline-flex items-center justify-center';

    const handleDeleteConfirmationModalChoice = useCallback(
        (choice: 'confirm' | 'cancel') => {
            if (choice === 'confirm') {
                onDeleteData?.(sectionKey, index);
            }

            setIsModalOpen(false);
        },
        [index, onDeleteData, sectionKey],
    );

    return (
        <>
            <div className="flex gap-1 top-1 right-1">
                <button
                    type="button"
                    className={classnames(
                        sharedClassName,
                        useAltHoverColor
                            ? 'hover:text-neutral-50'
                            : 'hover:text-font-dark',
                        !onEditData
                            ? 'cursor-not-allowed opacity-50'
                            : 'cursor-pointer',
                    )}
                    disabled={!onEditData}
                    onClick={() => onEditData(sectionKey, index)}
                    title={t('targetWizard.editSectionBtn')}
                >
                    <RiEdit2Line />
                </button>
                {onDeleteData && (
                    <button
                        className={classnames(
                            sharedClassName,
                            'hover:text-red-600',
                            !onDeleteData
                                ? 'cursor-not-allowed opacity-50'
                                : 'cursor-pointer',
                        )}
                        onClick={() => setIsModalOpen(true)}
                        title={t('targetWizard.deleteSectionBtn')}
                    >
                        <RiDeleteBin6Line />
                    </button>
                )}
            </div>
            <ConfirmationModal
                isOpen={isModalOpen}
                handleButtonClick={handleDeleteConfirmationModalChoice}
                hideCloseButton={true}
                confirmButtonText={t('delete')}
            />
        </>
    );
};

const DYNAMIC_KEYWORD_ICON_SIZE = 14;

const ContentItem: FC<
    Omit<DynamicFormFieldDetailsProps, 'data'> & {
        sectionKey: FormType;
        dataItem: any;
        index?: number;
        onEditData: (key: FormType, index?: number) => void;
        onDeleteData?: (key: FormType, index?: number) => void;
    }
> = ({ sectionKey, dataItem, index, onEditData, onDeleteData }) => {
    const config = formConfigMap[sectionKey];
    const SectionContent = config.renderComponents.content;
    const useAltHoverColor = !cvDisplayOrderRight.includes(sectionKey);

    return (
        <>
            {/* This is just for jobs and education so a few "incomplete" entries after one another look better */}
            {index !== undefined && index !== 0 && !useAltHoverColor && (
                <hr className="border-neutral-300 my-2" />
            )}
            <div
                key={sectionKey + index}
                className="hover:bg-primary-4/15 bg-transparent rounded-md p-1 pr-0 transition-colors relative flex justify-between"
            >
                <div className="flex gap-2 items-center">
                    {dynamicKeywordSectionKeys.includes(sectionKey) &&
                        !!dataItem.included && (
                            <BiCheckCircle size={DYNAMIC_KEYWORD_ICON_SIZE} />
                        )}
                    {dynamicKeywordSectionKeys.includes(sectionKey) &&
                        !dataItem.included && (
                            <BiBlock size={DYNAMIC_KEYWORD_ICON_SIZE} />
                        )}
                    <SectionContent data={dataItem} />
                </div>
                <EditAndDeleteButtons
                    onEditData={onEditData}
                    onDeleteData={
                        sectionKey !== 'personalDetails'
                            ? onDeleteData
                            : undefined
                    }
                    sectionKey={sectionKey}
                    index={index}
                    useAltHoverColor={useAltHoverColor}
                />
            </div>
        </>
    );
};

export const DynamicFormFieldDetails: FC<DynamicFormFieldDetailsProps> = memo(
    ({ sectionKey, data, onEditData, onDeleteData }) => {
        const { t } = useTranslation();

        const config = formConfigMap[sectionKey];

        // const SectionContent = config.renderComponents.content;

        const SectionHeader =
            formConfigMap[sectionKey].renderComponents.header ?? null;

        const header = !!SectionHeader && (
            <SectionHeader sectionKey={sectionKey} icon={config.icon} />
        );

        const renderContent = (dataItem: any, index?: number) => (
            <ContentItem
                key={sectionKey + index}
                sectionKey={sectionKey}
                dataItem={dataItem}
                index={index}
                onEditData={onEditData}
                onDeleteData={onDeleteData}
            />
        );

        // Dynamic keywords get an "Extrawurst"
        if (
            dynamicKeywordSectionKeys.includes(sectionKey) &&
            Array.isArray(data)
        ) {
            const keywords = data as DynamicKeyword[];
            const keywordsWithIndices: [DynamicKeyword, number][] =
                keywords.map((keyword, index) => [keyword, index]);
            const included = keywordsWithIndices.filter(
                (item) => item[0].included,
            );
            const excluded = keywordsWithIndices.filter(
                (item) => !item[0].included,
            );
            return (
                <div className="flex flex-col">
                    {header}
                    {included.length > 0 && (
                        <>
                            <Paragraph
                                color="lighter"
                                size="sLabel"
                                className="px-1 pt-0.5"
                            >
                                {t('included')}
                            </Paragraph>
                            {included.map((item) =>
                                renderContent(item[0], item[1]),
                            )}
                        </>
                    )}
                    {excluded.length > 0 && (
                        <>
                            <Paragraph
                                color="lighter"
                                size="sLabel"
                                className="px-1 pt-0.5"
                            >
                                {t('excluded')}
                            </Paragraph>
                            {excluded.map((item) =>
                                renderContent(item[0], item[1]),
                            )}
                        </>
                    )}
                </div>
            );
        }

        const section = (
            <div className="flex flex-col">
                {header}
                {Array.isArray(data)
                    ? data.map(renderContent)
                    : renderContent(data)}
            </div>
        );

        if (cvDisplayOrderRight.includes(sectionKey) || SectionHeader) {
            return section;
        }

        return (
            <TooltipV2 openDelay={400} placement="left" withArrow={true}>
                <TooltipContentV2 className="max-w-150 z-40">
                    {t(`targetWizard.fields.${sectionKey}.title`)}
                </TooltipContentV2>
                <TooltipTriggerV2>{section}</TooltipTriggerV2>
            </TooltipV2>
        );
    },
);

DynamicFormFieldDetails.displayName = 'DynamicFormFieldDetails';
