import { useQuery, UseQueryResult } from 'react-query';
import { isNeitherNullNorUndefined } from '_utils';
import {
    CvValidation,
    CvValidationSchema,
} from '../../features/dashboard/CV/CVAnalysis/schemata';

const fetchCvValidation = async (
    caseId: string,
    targetId: string,
): Promise<CvValidation> => {
    try {
        const url = new URL('https://tartarus.stage.int.mov');

        url.pathname = [
            's3',
            'infra1',
            process.env.REACT_APP_STAGE === 'production' ? null : 'stage',
            'cv',
            caseId,
            targetId,
            'validations.json',
        ]
            .filter(isNeitherNullNorUndefined)
            .join('/');

        const response = await fetch(url.toString());
        const json = await response.json();

        return CvValidationSchema.readonly().parse(json);
    } catch (error) {
        console.error(error);

        return {
            userControlledComparison: {
                similarCvEntries: [],
                discrepancyCvEntries: [],
            },
            userUncontrolledComparison: {
                similarCvEntries: [],
                discrepancyCvEntries: [],
            },
        };
    }
};

export const useCvValidation = (
    caseId: string,
    targetId: string,
): UseQueryResult<CvValidation> =>
    useQuery(
        [caseId, targetId, 'cvValidation'],
        () => fetchCvValidation(caseId, targetId),
        {
            enabled: caseId.length !== 0 && targetId.length !== 0,
            refetchOnWindowFocus: false,
        },
    );
