import React, {
    FC,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import * as d3 from 'd3';
import classnames from 'classnames';
import type { TimelineFilters } from '../../types';
import { TableEmpty } from '_organisms/Table/TableEmpty/TableEmpty';
import { useTranslation } from 'react-i18next';
import { TooltipContentV2, TooltipTriggerV2, TooltipV2 } from '_atoms';
import type {
    PlainDateRange,
    TimeAwareCvGroup,
} from '../../CvStatistics/helpers';
import { toDate } from '../../../../../../utils/temporal';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi2';

type ChevronState = 'up' | 'down' | 'none';

type EntryProps = Readonly<{
    entry: TimeAwareCvGroup['cvEntries'][number];
    chevronNode: React.ReactNode;
}>;

const Entry = ({ entry, chevronNode }: EntryProps) => {
    const { t, i18n } = useTranslation();

    return (
        <div className="flex flex-col gap-2 p-2 text-sm text-wrap">
            <div className="flex items-center justify-between">
                <span className="font-bold">{entry.company}</span>
                {chevronNode}
            </div>
            <span>{entry.position}</span>
            <span>
                {entry.plainDateRange.start.toLocaleString(i18n.language, {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                })}
                &nbsp;-&nbsp;
                {entry.plainDateRange.end.toLocaleString(i18n.language, {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                })}
            </span>
            {entry.sources.map((s, i) => (
                <div key={i} className="text-neutral-450">
                    {t(`sourceName.${s}`)}
                </div>
            ))}
        </div>
    );
};

const Popup = (props: TimeAwareCvGroup) => {
    const [chevronState, setChevronState] = useState<ChevronState>(
        props.cvEntries.length < 2 ? 'none' : 'down',
    );

    const onClick = useCallback(() => {
        setChevronState((oldState) => (oldState === 'up' ? 'down' : 'up'));
    }, [setChevronState]);

    const stateElementRecord = useMemo(
        () => ({
            up: <HiChevronUp onClick={onClick} />,
            down: <HiChevronDown onClick={onClick} />,
            none: null,
        }),
        [onClick],
    );

    return (
        <>
            <Entry
                entry={props}
                chevronNode={stateElementRecord[chevronState]}
            />
            {chevronState === 'down' && (
                <div className="pl-4">
                    {props.cvEntries.map((entry) => (
                        <Entry
                            entry={entry}
                            key={entry.id}
                            chevronNode={null}
                        />
                    ))}
                </div>
            )}
        </>
    );
};

const chartMargin = { top: 0, right: 30, bottom: 0, left: 30 };

export const HorizontalTimeline: FC<
    Readonly<{
        filters: TimelineFilters;
        rows: ReadonlyArray<ReadonlyArray<TimeAwareCvGroup>>;
        range: PlainDateRange;
    }>
> = (props) => {
    const { t } = useTranslation();

    const [chartWidth, setChartWidth] = useState(0);

    const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null);

    useEffect(() => {
        setSelectedGroupId(null);
    }, [props.filters]);

    const containerRef = useRef<HTMLDivElement>(null);
    const svgRef = useRef<SVGSVGElement | null>(null);

    const fixedContainerHeight = 350;
    const rowHeight = 120;

    useEffect(() => {
        const updateWidth = () => {
            if (containerRef.current) {
                setChartWidth(
                    containerRef.current.offsetWidth -
                        chartMargin.left -
                        chartMargin.right,
                );
            }
        };

        updateWidth();
        window.addEventListener('resize', updateWidth);

        return () => {
            window.removeEventListener('resize', updateWidth);
        };
    });

    const totalHeight = props.rows.length * rowHeight;

    const xScale = d3
        .scaleTime()
        .domain([toDate(props.range.start), toDate(props.range.end)])
        .range([chartMargin.left, chartWidth - chartMargin.right]);

    const yScale = d3
        .scaleBand<number>()
        .domain(d3.range(props.rows.length))
        .range([50, totalHeight])
        .padding(0.5);

    const colorScale = d3
        .scaleOrdinal<string>()
        .domain(['education', 'occupation'])
        .range(['#8ED2C6', '#2CA4AB']); // contrast-3 and contrast-2

    const xAxisOutside = d3.axisBottom(xScale);

    const handleBarClick = (newGroupId: string) => {
        setSelectedGroupId((oldGroupId) => {
            if (oldGroupId === newGroupId) {
                return null;
            }

            return newGroupId;
        });
    };

    const maxBarWidth = 150;
    const rightEdge = xScale(xScale.domain()[1]);

    const hasEducation = props.rows.some((groups) =>
        groups.some((group) => group.kind === 'education'),
    );

    const hasWorkExperience = props.rows.some((groups) =>
        groups.some((group) => group.kind === 'occupation'),
    );

    const renderRangeTextAndLines = (type: 'start' | 'end') => {
        const rowIndex = props.rows.findIndex((row) =>
            row.some((group) => group.id === selectedGroupId),
        );

        const group =
            props.rows.at(rowIndex)?.find((g) => g.id === selectedGroupId) ??
            null;

        if (group === null) {
            return null;
        }

        const plainDate =
            type === 'start'
                ? group.plainDateRange.start
                : group.plainDateRange.end;

        const date = toDate(plainDate);

        return (
            <g>
                <line
                    x1={xScale(date) + (type === 'start' ? 0.5 : -0.5)}
                    y1={0}
                    x2={xScale(date) + (type === 'start' ? 0.5 : -0.5)}
                    y2={(yScale(rowIndex) as number) + 7}
                    stroke={colorScale('work') as string}
                    strokeWidth={0.5}
                />
            </g>
        );
    };

    if (props.rows.length === 0) {
        return (
            <TableEmpty
                className="p-4 pb-8 rounded-lg bg-neutral-50"
                imageHeight={132}
                message={t('cVAnalysis.noTimelineHeadline')}
            />
        );
    }

    return (
        <div className="w-full">
            <div
                className="relative rounded-lg bg-white p-2"
                ref={containerRef}
            >
                <div
                    className={classnames('relative rounded-t-lg')}
                    style={{
                        height: `${25}px`,
                        width: '100%',
                    }}
                >
                    {/* Sticky X-Axis */}
                    <svg
                        style={{
                            position: 'absolute',
                            top: 5,
                            left: 0,
                        }}
                        width={chartWidth}
                    >
                        <g
                            ref={(node) => {
                                if (node) {
                                    d3.select(node).call(xAxisOutside);
                                }
                            }}
                        />
                    </svg>
                </div>
                <div
                    className={classnames(
                        'relative overflow-auto rounded-b-lg',
                    )}
                    style={{
                        height: `${fixedContainerHeight}px`,
                        width: '100%',
                    }}
                >
                    <svg
                        ref={svgRef}
                        width={chartWidth}
                        height={Math.max(totalHeight, fixedContainerHeight)}
                        className="rounded-lg"
                    >
                        <g id={'gridLines'}>
                            {xScale.ticks().map((d) => (
                                <g
                                    key={d.toString()}
                                    opacity={selectedGroupId ? '0.5' : '1'}
                                >
                                    <line
                                        x1={xScale(d)}
                                        y1={0}
                                        x2={xScale(d)}
                                        y2={Math.max(
                                            totalHeight,
                                            fixedContainerHeight,
                                        )}
                                        stroke="#D5D5D5"
                                        strokeWidth={0.5}
                                    />
                                </g>
                            ))}
                        </g>
                        <g id={'bars'}>
                            {props.rows.map((row, rowIndex) =>
                                row.map((group, i) => {
                                    const nextExperience =
                                        i < row.length - 1 ? row[i + 1] : null;

                                    // NOTE: this seems to recalculate once and before this ends up being negative
                                    // which causes errors since we use this as width for <rect> tags below so this
                                    // removes those errors, but we should find out if we can prevent the recalculation
                                    const currBarWidth = Math.max(
                                        0,
                                        Math.floor(
                                            xScale(
                                                toDate(
                                                    group.plainDateRange.end,
                                                ),
                                            ) -
                                                xScale(
                                                    toDate(
                                                        group.plainDateRange
                                                            .start,
                                                    ),
                                                ),
                                        ),
                                    );
                                    const prevBarTooSmall =
                                        i !== 0 &&
                                        xScale(
                                            toDate(group.plainDateRange.start),
                                        ) -
                                            xScale(
                                                toDate(
                                                    row[i - 1].plainDateRange
                                                        .start,
                                                ),
                                            ) <
                                            maxBarWidth;

                                    const currTextBelow = prevBarTooSmall
                                        ? i % 2 !== 0
                                        : false;

                                    const nextTextBelow =
                                        currBarWidth < maxBarWidth &&
                                        (i + 1) % 2 !== 0;

                                    const availableSpace = !nextExperience
                                        ? Math.floor(
                                              rightEdge -
                                                  xScale(
                                                      toDate(
                                                          group.plainDateRange
                                                              .start,
                                                      ),
                                                  ),
                                          )
                                        : currTextBelow === nextTextBelow
                                        ? xScale(
                                              toDate(
                                                  nextExperience.plainDateRange
                                                      .start,
                                              ),
                                          ) -
                                          xScale(
                                              toDate(
                                                  group.plainDateRange.start,
                                              ),
                                          )
                                        : xScale(
                                              toDate(
                                                  nextExperience.plainDateRange
                                                      .end,
                                              ),
                                          ) -
                                          xScale(
                                              toDate(
                                                  group.plainDateRange.start,
                                              ),
                                          );

                                    const maxTextWidth = Math.max(
                                        currBarWidth,
                                        Math.floor(availableSpace),
                                    );

                                    const headerText = group.company;

                                    // TODO fix using Duration API

                                    const subHeaderText = `${
                                        group.position
                                    } (${Math.round(
                                        (toDate(
                                            group.plainDateRange.end,
                                        ).getTime() -
                                            toDate(
                                                group.plainDateRange.start,
                                            ).getTime()) /
                                            (1000 * 60 * 60 * 24 * 30),
                                    )} months)`;

                                    const prepareText = (
                                        text: string,
                                        isBold: boolean,
                                    ) => {
                                        // TODO: find a better way to calculate the width of the full text
                                        const averagePixelsPerLetter = isBold
                                            ? 8.5
                                            : 8;

                                        return text.length *
                                            averagePixelsPerLetter >
                                            maxTextWidth
                                            ? `${text.substring(
                                                  0,
                                                  Math.floor(
                                                      maxTextWidth /
                                                          averagePixelsPerLetter,
                                                  ),
                                              )}...`
                                            : text;
                                    };

                                    return (
                                        <TooltipV2
                                            disabled={
                                                selectedGroupId !== group.id
                                            }
                                            open={selectedGroupId === group.id}
                                            key={`${rowIndex}-${i}`}
                                            doNotAutoUpdate={true}
                                        >
                                            <TooltipTriggerV2 asChild={true}>
                                                <g
                                                    onClick={() =>
                                                        handleBarClick(group.id)
                                                    }
                                                >
                                                    <rect
                                                        x={xScale(
                                                            toDate(
                                                                group
                                                                    .plainDateRange
                                                                    .start,
                                                            ),
                                                        )}
                                                        y={
                                                            yScale(
                                                                rowIndex,
                                                            ) as number
                                                        }
                                                        width={currBarWidth}
                                                        height={14}
                                                        rx={8}
                                                        fill={
                                                            colorScale(
                                                                group.kind,
                                                            ) as string
                                                        }
                                                        opacity={
                                                            selectedGroupId &&
                                                            selectedGroupId !==
                                                                group.id
                                                                ? 0.2
                                                                : 1
                                                        }
                                                        className="cursor-pointer"
                                                    />
                                                    <g
                                                        opacity={
                                                            selectedGroupId &&
                                                            selectedGroupId !==
                                                                group.id
                                                                ? 0.2
                                                                : 1
                                                        }
                                                    >
                                                        <text
                                                            x={
                                                                xScale(
                                                                    toDate(
                                                                        group
                                                                            .plainDateRange
                                                                            .start,
                                                                    ),
                                                                ) + 3
                                                            }
                                                            y={
                                                                currTextBelow
                                                                    ? (yScale(
                                                                          rowIndex,
                                                                      ) as number) +
                                                                      30 // Position text below the bar
                                                                    : (yScale(
                                                                          rowIndex,
                                                                      ) as number) -
                                                                      24 // Default position above the bar
                                                            }
                                                            width={60}
                                                            className="text-white text-sm font-bold"
                                                        >
                                                            <title>
                                                                {headerText}
                                                            </title>
                                                            {prepareText(
                                                                headerText,
                                                                true,
                                                            )}
                                                        </text>
                                                        <text
                                                            x={
                                                                xScale(
                                                                    toDate(
                                                                        group
                                                                            .plainDateRange
                                                                            .start,
                                                                    ),
                                                                ) + 3
                                                            }
                                                            y={
                                                                currTextBelow
                                                                    ? (yScale(
                                                                          rowIndex,
                                                                      ) as number) +
                                                                      44 // Position text below
                                                                    : (yScale(
                                                                          rowIndex,
                                                                      ) as number) -
                                                                      10 // Default position above
                                                            }
                                                            width={60}
                                                            className="text-white text-sm"
                                                        >
                                                            <title>
                                                                {subHeaderText}
                                                            </title>
                                                            {prepareText(
                                                                subHeaderText,
                                                                false,
                                                            )}
                                                        </text>
                                                    </g>
                                                </g>
                                            </TooltipTriggerV2>
                                            <TooltipContentV2 className="w-80">
                                                <Popup {...group} />
                                            </TooltipContentV2>
                                        </TooltipV2>
                                    );
                                }),
                            )}
                        </g>
                        {selectedGroupId && (
                            <g>
                                {renderRangeTextAndLines('start')}
                                {renderRangeTextAndLines('end')}
                            </g>
                        )}
                    </svg>
                </div>
                <div
                    className={classnames(
                        'flex gap-6 items-center text-sm pt-2 pl-2 border-t',
                    )}
                >
                    <span className="font-semibold">
                        {t('cVAnalysis.legend')}
                    </span>
                    {hasEducation && (
                        <div className="flex flex-row gap-1">
                            <div className="rounded-full bg-contrast-3 w-6 h-4" />
                            <span>{t('cVAnalysis.education')} </span>
                        </div>
                    )}
                    {hasWorkExperience && (
                        <div className="flex flex-row gap-1">
                            <div className="rounded-full bg-contrast-2 w-6 h-4" />
                            <span>{t('cVAnalysis.work')}</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
