import { TargetReportSourceType } from '@indicium/common';

export const ALL_SOURCES: TargetReportSourceType[] = [
    'companyhouse',
    'orbis',
    'pipl',
    'digitalclues',
    'firmenabc',
    'indicium',
    'zefix',
    'complianceCatalyst',
    'icijLeaks',
    'google',
    'shadowdragon',
    'userInput',
];

export const handleSourceChange = (
    activeSources: ReadonlyArray<TargetReportSourceType>,
    source: 'userInput' | 'osint',
    isSelected: boolean,
    callback: (value: ReadonlyArray<TargetReportSourceType>) => void,
) => {
    const hasUserInput = activeSources.includes('userInput');
    const hasOsint = activeSources.includes('orbis');

    const osintSources = ALL_SOURCES.filter((s) => s !== 'userInput');
    const newSources: TargetReportSourceType[] = [];

    if (source === 'userInput') {
        if (isSelected) {
            newSources.push('userInput');
        }
        if (hasOsint) {
            newSources.push(...osintSources);
        }
    }

    if (source === 'osint') {
        if (isSelected) {
            newSources.push(...osintSources);
        }
        if (hasUserInput) {
            newSources.push('userInput');
        }
    }

    callback(newSources);
};
