// Keep in mind that this list also comes from nest api
// Be careful with changing it here because the nest api would need to be updated as well
export const educationLevelOptions = [
    'primary_school',
    'secondary_school',
    'high_school_diploma',
    'associate_degree',
    'online_course',
    'bachelors_degree',
    'masters_degree',
    'mba',
    'phd',
];
