import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import {
    useChecklistTemplates,
    useCreateChecklistTemplateMutation,
} from '_queries';
import {
    Button,
    LoadingSpinner,
    Modal,
    TooltipContentV2,
    TooltipTriggerV2,
    TooltipV2,
} from '_atoms';
import { ChecklistTemplate } from '../../../../types/ChecklistTemplate';
import { MdAdd } from 'react-icons/md';
import { WizardCard } from '../../../../components/_atoms/Card/WizardCard';
import { Reorder } from 'framer-motion';
import { ChecklistTemplateField } from '../../../../components/_atoms/ChecklistTemplateField';
import { Checklist } from '../../../../components/_molecules/Checklist';
import { useTranslation } from 'react-i18next';
import { useUpdateChecklistTemplateMutation } from '../../../../hooks/queries/useUpdateChecklistTemplateMutation';
import { useToast } from '../../../../context/Toast';
import {
    BiAlignLeft,
    BiCheck,
    BiDisc,
    BiEdit,
    BiHeading,
    BiText,
} from 'react-icons/bi';
import { ConfirmationModal } from '_molecules';
import { TwoColumnPageLayout } from '../../../../components/Layout/TwoColumnPageLayout';
import { FormProvider, useForm } from 'react-hook-form';

export const ChecklistTemplates: FC = () => {
    const { t } = useTranslation();
    const toast = useToast();
    const templateContainerRef = useRef<HTMLUListElement | null>(null);

    // TODO: remove this after refactoring the way we render the preview (next PR)
    const formMethods = useForm();

    // ----------------------------
    // STATE
    // ----------------------------
    const [checklist, setChecklist] = useState<ChecklistTemplate>();
    const [resetFormModalOpen, setResetFormModalOpen] = useState(false);
    const [addFieldModalOpen, setAddFieldModalOpen] = useState(false);
    const [layoutChanges, setLayoutChanges] = useState(0);

    // ----------------------------
    // MUTATIONS & QUERIES
    // ----------------------------
    const createChecklistTemplateMutation = useCreateChecklistTemplateMutation({
        onSuccess: useCallback((data: ChecklistTemplate) => {
            setChecklist(data);
        }, []),
        onError: () =>
            toast({
                style: 'error',
                text: t('checklistTemplate.errorCreating'),
            }),
    });

    const handleChecklistTemplatesSuccess = useCallback(
        (data: ChecklistTemplate[]) => {
            if (data.length > 0) {
                setChecklist(data[0]);
            } else if (createChecklistTemplateMutation.isIdle) {
                createChecklistTemplateMutation.mutate({
                    title: t('checklistTemplate.defaultTitle'),
                    desc: t('checklistTemplate.defaultDesc'),
                    data: { fields: [] },
                });
            }
        },
        [createChecklistTemplateMutation, t],
    );

    const checklists = useChecklistTemplates({
        onSuccess: handleChecklistTemplatesSuccess,
        onError: () =>
            toast({
                style: 'error',
                text: t('checklistTemplate.errorLoading'),
            }),
    });

    const handleUpdateChecklistSuccess = useCallback(() => {
        toast({
            style: 'confirmation',
            text: t('checklistTemplate.saved'),
        });
    }, [toast, t]);

    const updateChecklistTemplateMutation = useUpdateChecklistTemplateMutation({
        onSuccess: handleUpdateChecklistSuccess,
        onError: () =>
            toast({
                style: 'error',
                text: t('checklistTemplate.errorUpdating'),
            }),
    });

    // ----------------------------
    // HELPER: Update Checklist
    // ----------------------------
    const updateChecklist = useCallback(
        (updateFn: (prev: ChecklistTemplate) => ChecklistTemplate) => {
            setChecklist((prev) => (prev ? updateFn(prev) : prev));
        },
        [],
    );

    // ----------------------------
    // CALLBACKS
    // ----------------------------

    // Toggle/reset modal callbacks
    const handleResetModalToggle = useCallback(() => {
        setResetFormModalOpen((prev) => !prev);
    }, []);

    const handleResetModalClose = useCallback(() => {
        setResetFormModalOpen(false);
    }, []);

    const handleResetConfirm = useCallback(() => {
        updateChecklist((prev) => ({ ...prev, data: { fields: [] } }));
        setResetFormModalOpen(false);
    }, [updateChecklist]);

    const scrollToTemplateFormContainerBottom = useCallback(() => {
        setTimeout(() => {
            templateContainerRef?.current?.scrollTo({
                top: templateContainerRef.current.scrollHeight,
                behavior: 'smooth',
            });
        }, 0);
    }, []);

    // Add field callbacks
    const handleAddField = useCallback(
        (field: ChecklistTemplate['data']['fields'][number]['type']) => {
            updateChecklist((prev) => ({
                ...prev,
                data: {
                    ...prev.data,
                    fields: [
                        ...prev.data.fields,
                        {
                            type: field,
                            label: t(`checklistTemplate.new.${field}`),
                            name: `field_${prev.data.fields.length}`,
                            required: false,
                            options: [],
                        },
                    ],
                },
            }));
            scrollToTemplateFormContainerBottom();
            setAddFieldModalOpen(false);
        },
        [scrollToTemplateFormContainerBottom, t, updateChecklist],
    );

    // Reordering fields
    const handleReorder = useCallback(
        (values: ChecklistTemplate['data']['fields']) => {
            updateChecklist((prev) => ({
                ...prev,
                data: { ...prev.data, fields: values },
            }));
        },
        [updateChecklist],
    );

    // Remove or edit a field from the list
    const handleRemoveField = useCallback(
        (index: number) => {
            updateChecklist((prev) => ({
                ...prev,
                data: {
                    ...prev.data,
                    fields: prev.data.fields.filter((_, i) => i !== index),
                },
            }));
        },
        [updateChecklist],
    );

    const handleEditField = useCallback(
        (
            index: number,
            newField: ChecklistTemplate['data']['fields'][number],
        ) => {
            updateChecklist((prev) => ({
                ...prev,
                data: {
                    ...prev.data,
                    fields: prev.data.fields.map((f, i) =>
                        i === index ? newField : f,
                    ),
                },
            }));
        },
        [updateChecklist],
    );

    // Factories for per-field handlers
    const createHandleRemoveField = useCallback(
        (index: number) => () => handleRemoveField(index),
        [handleRemoveField],
    );

    const createHandleEditField = useCallback(
        (index: number) =>
            (newField: ChecklistTemplate['data']['fields'][number]) =>
                handleEditField(index, newField),
        [handleEditField],
    );

    // Save checklist
    const handleSave = useCallback(() => {
        if (checklist) {
            updateChecklistTemplateMutation.mutate(checklist);
        }
    }, [checklist, updateChecklistTemplateMutation]);

    // Checklist preview edit
    const handleChecklistPreviewEdit = useCallback(
        (fields: ChecklistTemplate['data']['fields']) => {
            updateChecklist((prev) => ({ ...prev, data: { fields } }));
        },
        [updateChecklist],
    );

    const handleLayoutChange = useCallback(() => {
        setLayoutChanges((prev) => prev + 1);
    }, []);

    // ----------------------------
    // VALIDATION (Memoized)
    // ----------------------------
    const errors = useMemo(() => {
        const result: string[] = [];
        if (!checklist) {
            return result;
        }
        if (checklist.data.fields.some((field) => field.label.length === 0)) {
            result.push(t('checklistTemplate.validationErrorLabelEmpty'));
        }
        if (
            checklist.data.fields.some(
                (field) =>
                    (field.type === 'radio' || field.type === 'checkbox') &&
                    (field.options?.length ?? 0) <= 1,
            )
        ) {
            result.push(t('checklistTemplate.validationErrorOptionsEmpty'));
        }
        return result;
    }, [checklist, t]);

    // CARDS
    const cards = [
        {
            name: 'title',
            icon: BiHeading,
            onClick: () => handleAddField('title'),
        },
        {
            name: 'description',
            icon: BiAlignLeft,
            onClick: () => handleAddField('description'),
        },
        {
            name: 'text',
            icon: BiText,
            onClick: () => handleAddField('text'),
        },
        {
            name: 'textarea',
            icon: BiEdit,
            onClick: () => handleAddField('textarea'),
        },
        {
            name: 'checkbox',
            icon: BiCheck,
            onClick: () => handleAddField('checkbox'),
        },
        {
            name: 'radio',
            icon: BiDisc,
            onClick: () => handleAddField('radio'),
        },
    ];

    // ----------------------------
    // RENDER
    // ----------------------------

    const leftContent = (
        <>
            <ConfirmationModal
                body={t('checklistTemplate.resetDescription')}
                cancelButtonText={t('cancel')}
                confirmButtonText={t('checklistTemplate.resetConfirm')}
                enableConfirm
                isOpen={resetFormModalOpen}
                title={t('checklistTemplate.reset')}
                handleButtonClick={(event) => {
                    if (event === 'cancel') {
                        handleResetModalClose();
                    }
                    if (event === 'confirm') {
                        handleResetConfirm();
                    }
                }}
            />
            {checklists.isLoading && <LoadingSpinner />}
            {checklist?.data && (
                <>
                    <Modal
                        title={t('checklistTemplate.addField')}
                        isOpen={addFieldModalOpen}
                        onClose={() => setAddFieldModalOpen(false)}
                        maxWidth="max-w-4xl"
                    >
                        <div>
                            <div className="grid grid-cols-3 gap-2">
                                {cards.map((card) => (
                                    <WizardCard
                                        key={card.name}
                                        icon={card.icon}
                                        onClick={card.onClick}
                                        title={t(
                                            `checklistTemplate.box.${card.name}`,
                                        )}
                                        description={t(
                                            `checklistTemplate.box.${card.name}Desc`,
                                        )}
                                    />
                                ))}
                            </div>
                        </div>
                    </Modal>
                    <div className="flex flex-wrap content-start w-full gap-4 h-full pb-4">
                        {!checklist?.data?.fields?.length && (
                            <p className="w-full">
                                {t('checklistTemplate.noFields')}
                            </p>
                        )}
                        <Reorder.Group
                            ref={templateContainerRef}
                            className="flex flex-col w-full gap-2 overflow-y-auto max-h-[90%]"
                            axis="y"
                            values={checklist.data.fields}
                            onReorder={handleReorder}
                            // dragSnapToOrigin
                        >
                            {checklist.data.fields.map((field, index) => (
                                <ChecklistTemplateField
                                    field={field}
                                    onRemove={createHandleRemoveField(index)}
                                    onEdit={createHandleEditField(index)}
                                    onLayoutChange={handleLayoutChange}
                                    // TODO! this is a hack to force re-rendering of the item, when the inside component changes it's height
                                    // framer motion gets confused and doesn't update the layout properly, and items overlap
                                    // this is fixed on a later framer-motion update but we can't use it due to our react version
                                    // in the future this needs to go away once we migrate
                                    key={field.name + layoutChanges}
                                />
                            ))}
                        </Reorder.Group>
                        <div className="w-2/3 max-h-[10%]">
                            {errors.map((error) => (
                                <div key={error} className="text-error-1">
                                    {error}
                                </div>
                            ))}
                        </div>
                        <div className="flex-grow max-h-[10%] text-right">
                            <Button
                                size="small"
                                className="text-sm w-32 self-end"
                                icon={MdAdd}
                                onClick={() => setAddFieldModalOpen(true)}
                            >
                                {t('checklistTemplate.newItem')}
                            </Button>
                        </div>
                    </div>
                </>
            )}
        </>
    );

    const rightContent = (
        <>
            {checklist?.data?.fields?.length ? (
                <FormProvider {...formMethods}>
                    <Checklist
                        checklistFields={checklist.data.fields}
                        onEdit={handleChecklistPreviewEdit}
                        isEditable={false}
                    />
                </FormProvider>
            ) : (
                <p className="text-center">{t('checklist.empty')}</p>
            )}
        </>
    );

    return (
        <TwoColumnPageLayout
            title={t('checklistTemplate.title')}
            headerActions={
                <div className="flex gap-2 justify-end">
                    <Button
                        level="primaryGhost"
                        onClick={handleResetModalToggle}
                    >
                        {t('checklistTemplate.reset')}
                    </Button>
                    <TooltipV2>
                        <TooltipContentV2>
                            {t('checklistTemplate.saveTooltip')}
                        </TooltipContentV2>
                        <TooltipTriggerV2>
                            <Button
                                disabled={
                                    errors.length > 0 ||
                                    updateChecklistTemplateMutation.isLoading
                                }
                                onClick={handleSave}
                            >
                                {t('checklistTemplate.save')}
                            </Button>
                        </TooltipTriggerV2>
                    </TooltipV2>
                </div>
            }
            leftContent={leftContent}
            rightContent={rightContent}
            rightBgClassName="bg-white"
            leftBgClassName="bg-neutral-300"
        />
    );
};
