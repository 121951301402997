import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ResultsProps } from '../Results';
import { Error, LoadingSpinner } from '_atoms';
import { Alert } from '_molecules';
import { nonProdDataTestId } from '_utils';
import { RoleType } from './RoleType';
import { DefaultPageLayout } from '../../../components/Layout/DefaultPageLayout';
import {
    toElasticQuery,
    useElasticSearchQuery,
} from '../../../hooks/useElasticSearchQuery';
import * as RawSearch from '_organisms/RawSearch';
import { SuspicionDetails } from '../RiskAnalysis/SuspicionDetails';

type SearchFilters = {
    companyStatus?: string[];
    sources?: string[];
    keywordsEnabled?: boolean;
    exactMatch?: boolean;
    search?: string;
};

export type RegisterdatenProps = ResultsProps & { accessToken: string };

export const Registerdaten: FC<RegisterdatenProps> = ({ targetProfile }) => {
    const { t } = useTranslation();
    const { caseId, targetId } =
        useParams<{
            caseId: string;
            targetId: string;
        }>();

    const { getFilter, onFilterChange } =
        RawSearch.useRawFilters<SearchFilters>();

    const {
        data,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
        isLoading,
        isFetching,
        isError,
    } = useElasticSearchQuery({
        params: {
            caseId,
            targetId,
            entityType: 'companies',
            exactMatch: getFilter('exactMatch'),
            query: getFilter('search'),
        },
        body: {
            sources: toElasticQuery(getFilter('sources')),
            companyStatus: toElasticQuery(getFilter('companyStatus')),
            'companyTargetRoles.roleType.keyword': toElasticQuery(
                getFilter('keywordsEnabled')
                    ? [RoleType.Manager, RoleType.Shareholder]
                    : undefined,
            ),
        },
    });

    const articles = useMemo(
        () =>
            (data?.pages.flatMap((page) => page.items) ?? []).map(
                (article) => ({
                    ...article,
                    indicators: [],
                }),
            ),
        [data],
    );

    const articleMeta = data?.pages.at(-1);

    const handlePageChange = useCallback(() => {
        if (hasNextPage) {
            fetchNextPage();
        }
    }, [hasNextPage, fetchNextPage]);

    const [activeArticleId, setActiveArticleId] =
        useState<string | undefined>();

    const firstArticleId = articles?.at(0)?.entity.value.id;

    useEffect(() => {
        if (firstArticleId) {
            setActiveArticleId(firstArticleId);
        }
    }, [firstArticleId]);

    const selectedArticle = articles?.find(
        (article) => article.entity.value.id === activeArticleId,
    );

    const pagination = articleMeta?.paging
        ? {
              ...articleMeta?.paging,
              handlePageChange,
          }
        : undefined;

    const statusOptions = useMemo(
        () => [
            { label: t('filters.active'), value: 'active' },
            { label: t('filters.inactive'), value: 'inactive' },
        ],
        [t],
    );

    const sourceOptions = useMemo(
        () => [
            { label: t('filters.Orbis'), value: 'orbis' },
            { label: t('filters.Pipl'), value: 'pipl' },
            { label: t('filters.CompanyHouse'), value: 'companyhouse' },
            { label: t('filters.userInput'), value: 'userInput' },
        ],
        [t],
    );

    return (
        <DefaultPageLayout title={t('rawData.registerData')}>
            <RawSearch.SearchPanel
                onSearchChange={(value) => onFilterChange('search', value)}
                searchValue={getFilter('search')}
            >
                <RawSearch.Checkbox
                    value={Boolean(getFilter('exactMatch'))}
                    onChange={(value) => onFilterChange('exactMatch', value)}
                    label={t('exactSearch')}
                />
                <RawSearch.OptionsList
                    options={statusOptions}
                    onChange={(value) => onFilterChange('companyStatus', value)}
                    value={getFilter('companyStatus') ?? []}
                    title={t('filters.status')}
                />
                <RawSearch.OptionsList
                    options={sourceOptions}
                    onChange={(value) => onFilterChange('sources', value)}
                    value={getFilter('sources') ?? []}
                    title={t('filters.source')}
                />
                <RawSearch.Checkbox
                    value={Boolean(getFilter('keywordsEnabled'))}
                    onChange={(value) =>
                        onFilterChange('keywordsEnabled', value)
                    }
                    label={t('commercialRegister.relevantCheckbox')}
                    title={t('commercialRegister.filterTitle')}
                    tooltipContent={t('commercialRegister.relevantTooltip')}
                />
            </RawSearch.SearchPanel>
            {targetProfile?.status === 'HasInitialProfile' && (
                <Alert
                    alertType="warning"
                    headline={t('profileDataNotFinal')}
                    className="mb-2"
                />
            )}
            <div
                className="relative mt-6 flex flex-col gap-4"
                data-testid={nonProdDataTestId('registerdaten')}
            >
                {isLoading ? (
                    <div className="absolute top-0 flex justify-center w-full z-0">
                        <LoadingSpinner message={t('profileLoading')} />
                    </div>
                ) : isError ? (
                    <Error
                        headline={t('profileErrorHeadline')}
                        message={t('profileErrorRetryMessage')}
                    />
                ) : null}
                {articles.length ? (
                    <SuspicionDetails
                        isLoading={isFetching || isFetchingNextPage}
                        articles={articles}
                        selectedArticle={selectedArticle}
                        selectArticle={setActiveArticleId}
                        pagination={pagination}
                        isNextPageLoading={isFetchingNextPage}
                    />
                ) : isLoading ? null : (
                    <Alert
                        alertType="info"
                        message={t('noData')}
                        className="mb-5"
                    />
                )}
            </div>
        </DefaultPageLayout>
    );
};
