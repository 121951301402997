import React, { FC, useMemo, useState } from 'react';
import { ResultsProps } from '../../../Results';
import { ProfileInfo } from '../../../RiskAnalysis/ProfileInfo';
import { DefaultPageLayout } from '../../../../../components/Layout/DefaultPageLayout';
import { useTranslation } from 'react-i18next';
import { Checkbox, Paragraph } from '_atoms';
import { buildChartConfig } from './helpers';
import { TargetReportSourceType } from '@indicium/common/src/types/Report/SharedTypes';
import { CvCard, CVCardSkeleton } from './Components/CvCard';
import { ALL_SOURCES, handleSourceChange } from '../helpers';
import { HeaderMessage } from './Components/HelperComponents';
import { StatisticConfig } from '../types';
import { useCvGroups } from '../../../../../hooks/queries/useCvGroups';

export const CvStatistics: FC<ResultsProps> = ({
    caseData,
    targetProfile,
    targetImages,
    targetLoading,
}) => {
    const { t } = useTranslation();

    const targetFullName = [
        targetProfile?.firstname,
        targetProfile?.middlename,
        targetProfile?.lastname,
    ]
        .filter(Boolean)
        .join(' ');

    const groupResult = useCvGroups(caseData.id ?? '', targetProfile?.id ?? '');

    const [activeSources, setActiveSources] =
        useState<ReadonlyArray<TargetReportSourceType>>(ALL_SOURCES);

    const chartConfig: StatisticConfig = useMemo(
        () =>
            buildChartConfig({
                activeSources,
                typos: targetProfile?.profile?.data?.typos ?? [],
                groups: groupResult.data ?? [],
            }),
        [activeSources, groupResult, targetProfile?.profile?.data?.typos],
    );

    const changeSource = (value: ReadonlyArray<TargetReportSourceType>) => {
        setActiveSources(value);
    };

    return (
        <DefaultPageLayout
            title={t('cVAnalysis.title')}
            headerActions={
                <ProfileInfo
                    name={targetFullName}
                    createdAt={targetProfile?.profile?.createdAt}
                    imageUrl={targetImages[0]}
                />
            }
        >
            <HeaderMessage />
            <div className="pb-2 pt-5">
                <div>
                    <Paragraph
                        color="light"
                        weight="bold"
                        size="small"
                        className="font-jost"
                    >
                        {t('cVAnalysis.filters.selectSourceGroup')}
                    </Paragraph>
                    <div className="flex gap-4 py-3.5">
                        <Checkbox
                            onChange={(isSelected) =>
                                handleSourceChange(
                                    activeSources,
                                    'userInput',
                                    isSelected,
                                    changeSource,
                                )
                            }
                            initialIsSelected={activeSources.includes(
                                'userInput',
                            )}
                            centered={true}
                            className="font-normal"
                            size={5}
                            label={t(
                                `cVAnalysis.filters.sourceGroupOptions.cv`,
                            )}
                            labelPosition={'append'}
                        />
                        <Checkbox
                            onChange={(isSelected) =>
                                handleSourceChange(
                                    activeSources,
                                    'osint',
                                    isSelected,
                                    changeSource,
                                )
                            }
                            initialIsSelected={activeSources.includes('orbis')}
                            centered={true}
                            className="font-normal"
                            size={5}
                            label={t(
                                `cVAnalysis.filters.sourceGroupOptions.osint`,
                            )}
                            labelPosition={'append'}
                        />
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-3 gap-4 overflow-visible">
                {chartConfig.map((statistic) => (
                    <CvCard key={statistic.labels.title} {...statistic} />
                ))}
                {targetLoading &&
                    Array(4)
                        .fill(null)
                        .map((_, index) => (
                            <CVCardSkeleton key={index} index={index} />
                        ))}
            </div>
        </DefaultPageLayout>
    );
};
