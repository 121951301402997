import { useQuery, UseQueryResult } from 'react-query';
import { TargetCompanies } from '../../services/dataService';
import { targets } from '../../services/nestApiService';

interface TargetCompaniesQueryParams {
    targetId: string;
    caseId: string;
}

export const useTargetCompaniesQuery = (
    queryParams: TargetCompaniesQueryParams,
): UseQueryResult<TargetCompanies> =>
    useQuery(
        ['target', queryParams.targetId, 'target', 'companies'],
        async () =>
            targets.getCompanies(queryParams.caseId, queryParams.targetId),
        {
            refetchOnWindowFocus: false,
        },
    );
