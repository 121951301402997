import { useQuery, UseQueryResult } from 'react-query';
import { MinimalTarget, TargetStatus } from '../../services/dataService';
import { targets } from '../../services/nestApiService';

export const useTargetQuery = (
    queryKey: string,
    caseId: string,
    targetId: string,
): UseQueryResult<MinimalTarget> =>
    useQuery(
        [queryKey, 'target', caseId, targetId],
        () => {
            return targets.getTarget(caseId, targetId);
        },
        {
            refetchInterval: (data) => {
                if (
                    data !== undefined &&
                    [
                        TargetStatus.Completed,
                        TargetStatus.CompletedWithIrregularities,
                        TargetStatus.CandidateSelectionPending,
                    ].indexOf(data.status) !== -1
                ) {
                    return false; // don't refetch if we're waiting on user input
                }

                if (
                    data !== undefined &&
                    data.status === TargetStatus.HasInitialProfile
                ) {
                    return 300000; // refetch every 5 minutes if we're waiting on report to finish
                }

                return 3000; // refetch every 3 seconds if we're actively waiting
            },
            enabled: !!targetId && !targetId.includes('new') && !!caseId,
            cacheTime: 0,
            refetchOnWindowFocus: false,
        },
    );
