import { FC, HTMLAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Flags, { FlagComponent } from 'country-flag-icons/react/1x1';
import { ProfileData as ProfileDataType } from '../../../services/dataService';
import {
    mapSourceToText,
    replaceUmlauts,
    transformBirthdayRangeToString,
    transformResidenceToString,
} from '_utils';
import {
    LocalizationService,
    TargetReportAttribute,
    TargetReportSourceType,
} from '@indicium/common';
import { InfoBlock, InfoItem } from './InfoBlock';
import { uniqBy } from 'lodash';
import { Locale } from '@indicium/common/src/types/Locale/Locale';
import { Button, Paragraph } from '_atoms';
import { Modal } from '_atoms';
import { CUT_OFF, WithShowMoreModal } from './ShowMoreModal';
import { ContentType } from '_molecules';

export interface ProfileDataProps extends HTMLAttributes<HTMLDivElement> {
    data?: ProfileDataType;
    isOverview?: boolean;
    jobs: ReadonlyArray<InfoItem>;
    skills?: TargetReportAttribute<string>[];
    hobbies?: TargetReportAttribute<string>[];
    description?: TargetReportAttribute<string>[];
}

interface MergedName {
    sources: TargetReportSourceType[];
    value: string;
}

const mergeNames = (
    nameData: ProfileDataType['name'],
    accessor: (nameData: {
        first: string;
        last: string;
        middle?: string;
    }) => string | undefined,
): MergedName[] => {
    const normalizedData = nameData
        .map((name) => ({
            value: replaceUmlauts(accessor(name.value) || ''),
            sources: name.sources,
        }))
        .filter((name) => Boolean(name.value));

    return uniqBy(normalizedData, 'value')
        .map((name, index) => {
            const duplicateSources = normalizedData
                .filter((n, i) => n.value === name.value && i !== index)
                .flatMap((n) => n.sources);

            return {
                ...name,
                sources: [...new Set([...name.sources, ...duplicateSources])],
            };
        })
        .sort((a, b) => b.sources.length - a.sources.length);
};

const transformNationalities = (
    nationalities: ProfileDataType['nationality'] = [],
    locale: string,
) =>
    nationalities.map(({ value, sources }) => {
        const country = LocalizationService.getCountry(value, locale as Locale);
        const Flag =
            country &&
            ((Flags as any)[country.iso2] as FlagComponent | undefined);

        return {
            value: country?.nationality ?? value,
            sources,
            icon: Flag ? <Flag className="w-5 h-5 rounded-full" /> : undefined,
        };
    });

export const ProfileData: FC<ProfileDataProps> = ({
    className,
    data,
    isOverview = false,
    jobs,
    skills = [],
    hobbies = [],
    description = [],
}: ProfileDataProps) => {
    const { t, i18n } = useTranslation();

    const [modalContent, setModalContent] =
        useState<{
            headline: string;
            content: ContentType[];
        } | null>(null);

    if (!data) {
        return null;
    }

    const { name, birthday, age, residence, birthPlace } = data;

    const ages = (age || []).map((age) => ({
        ...age,
        value: t('yearsOld', { age: age.value }),
    }));

    const names = mergeNames(
        name,
        (nd) => `${nd.first} ${nd.middle ? nd.middle + ' ' : ''}${nd.last}`,
    );

    const birthdays = transformBirthdayRangeToString(birthday);
    const residences = transformResidenceToString(residence);
    const nationalities = transformNationalities(
        data.nationality,
        i18n.resolvedLanguage,
    );

    const educations =
        data.educations?.map((education) => ({
            ...education,
            value: education.value.display,
        })) ?? [];

    const deduplicatedEducation: Record<string, TargetReportSourceType[]> = {};

    for (const education of educations) {
        if (!deduplicatedEducation[education.value]) {
            deduplicatedEducation[education.value] = [];
        }
        deduplicatedEducation[education.value].push(...education.sources);
    }

    const educationContent = Object.entries(deduplicatedEducation).map(
        ([value, sources]) => ({ value, sources }),
    );

    const jobsContent = jobs.map((j) => ({
        value: j.value,
        additionalValue: j.additionalValue,
        sources: j.sources?.map((s) => s as TargetReportSourceType),
    }));

    return isOverview ? (
        <div className={className}>
            <div className="row space-y-3 py-3">
                <InfoBlock
                    title={t('infoBlockItem.birthday')}
                    infoList={birthdays}
                />
                <InfoBlock
                    title={t('infoBlockItem.nationality')}
                    infoList={nationalities}
                />
                <InfoBlock
                    title={t('infoBlockItem.birthPlace')}
                    infoList={birthPlace ?? []}
                />
            </div>
        </div>
    ) : (
        <div className={className}>
            <Modal
                isOpen={modalContent !== null}
                title={modalContent?.headline ?? ''}
                onClose={() => setModalContent(null)}
                maxWidth="max-w-4xl"
                maxHeight="max-h-[95vh]"
                className="text-center"
            >
                <div className="text-left max-h-[75vh] overflow-y-auto">
                    {modalContent?.content.map((content, index) => {
                        const sources = content.sources
                            ?.map((s) => t(mapSourceToText(s)))
                            .join(', ');

                        return (
                            <Paragraph
                                key={index}
                                className="even:bg-neutral-200 p-2 rounded-md hyphens-auto break-words"
                            >
                                {content.value}
                                {sources ? ` - ${sources}` : ''}
                            </Paragraph>
                        );
                    })}
                </div>
                <div className="text-center">
                    <Button
                        level="primaryGhost"
                        onClick={() => setModalContent(null)}
                        className="min-w-48"
                    >
                        {t('closeButton')}
                    </Button>
                </div>
            </Modal>
            <div className="row">
                <div className="col print:hidden">
                    <InfoBlock title={t('name')} infoList={names} />
                    {birthdays?.length ? (
                        <InfoBlock
                            title={t('infoBlockItem.birthday')}
                            infoList={birthdays}
                            containerClassName="py-1"
                        />
                    ) : (
                        <InfoBlock
                            title={t('infoBlockItem.age')}
                            infoList={ages}
                        />
                    )}
                    <InfoBlock
                        title={t('infoBlockItem.nationality')}
                        infoList={nationalities}
                        containerClassName="py-1"
                    />
                    <InfoBlock
                        title={t('infoBlockItem.placeOfBirth')}
                        infoList={birthPlace ?? []}
                        containerClassName="py-1"
                    />
                    <InfoBlock
                        title={t('infoBlockItem.placesLived')}
                        infoList={residences}
                        containerClassName="py-1"
                    />
                </div>
                <div className="col">
                    <WithShowMoreModal
                        content={jobsContent}
                        headline={t('infoBlockItem.jobRoles')}
                        setModalContent={setModalContent}
                    >
                        <InfoBlock
                            title={t('infoBlockItem.jobRoles')}
                            infoList={jobsContent.slice(0, CUT_OFF)}
                        />
                    </WithShowMoreModal>
                </div>
                <div className="col">
                    <WithShowMoreModal
                        content={educationContent}
                        headline={t('infoBlockItem.education')}
                        setModalContent={setModalContent}
                    >
                        <InfoBlock
                            title={t('infoBlockItem.education')}
                            infoList={educationContent.slice(0, CUT_OFF)}
                        />
                    </WithShowMoreModal>
                </div>
                {(Boolean(description.length) ||
                    Boolean(hobbies.length) ||
                    Boolean(skills.length)) && (
                    <div className="row pt-4">
                        {Boolean(description.length) && (
                            <div className="col">
                                <InfoBlock
                                    title={t('infoBlockItem.description')}
                                    infoList={description}
                                />
                            </div>
                        )}
                        {Boolean(skills.length) && (
                            <div className="col">
                                <WithShowMoreModal
                                    content={skills}
                                    headline={t('infoBlockItem.skills')}
                                    setModalContent={setModalContent}
                                >
                                    <InfoBlock
                                        title={t('infoBlockItem.skills')}
                                        infoList={skills.slice(0, CUT_OFF)}
                                    />
                                </WithShowMoreModal>
                            </div>
                        )}
                        {Boolean(hobbies.length) && (
                            <div className="col">
                                <WithShowMoreModal
                                    content={hobbies}
                                    headline={t('infoBlockItem.hobbies')}
                                    setModalContent={setModalContent}
                                >
                                    <InfoBlock
                                        title={t('infoBlockItem.hobbies')}
                                        infoList={hobbies.slice(0, CUT_OFF)}
                                    />
                                </WithShowMoreModal>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
