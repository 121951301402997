import { StatisticConfig, Units } from '../types';
import {
    TargetReportAttribute,
    TargetReportSourceType,
} from '@indicium/common/src/types/Report/SharedTypes';
import { CVInfoEntry, EntrepreneurialActivities } from '_types';
import { TargetCompanies } from '../../../../../services/dataService';
import { t } from 'i18next';
import { GrBook } from 'react-icons/gr';
import { RiArtboardLine } from 'react-icons/ri';
import { GrCertificate } from 'react-icons/gr';
import { RiFilePaper2Line } from 'react-icons/ri';
import { MdLaptop } from 'react-icons/md';
import { FaPencilRuler } from 'react-icons/fa';
import { GoMortarBoard } from 'react-icons/go';
import { FiAward } from 'react-icons/fi';
import { FaMedal } from 'react-icons/fa';
import { IconType } from 'react-icons';
import { CvGroup, Industry } from '../schemata';
import { isNeitherNullNorUndefined } from '_utils';
import { Temporal } from 'temporal-polyfill';

const gapThresholdInMonths = 1;

export const parseExperienceDateRanges = (
    dateRanges: ReadonlyArray<PlainDateRange>,
): {
    gaps: PlainDateRange[];
    deduplicatedDateRanges: PlainDateRange[];
} => {
    const sortedEvents = dateRanges
        .flatMap(({ start, end }) => [
            { type: 'start' as const, date: start },
            { type: 'end' as const, date: end },
        ])
        .sort((a, b) => Temporal.PlainDate.compare(a.date, b.date));

    const gaps: PlainDateRange[] = [];
    const deduplicatedDateRanges: PlainDateRange[] = [];

    let currentActiveDateRangeCount = 0;
    let currentGapStart: Temporal.PlainDate | null = null;
    let currentDateRangeStart: Temporal.PlainDate | null = null;

    const addNewGap = (plainDateRange: PlainDateRange) => {
        const monthsDifference = calculateDurationInMonth(plainDateRange);

        if (monthsDifference >= gapThresholdInMonths) {
            gaps.push(plainDateRange);
        }

        currentGapStart = null;
    };

    const addNewDateRange = (plainDateRange: PlainDateRange) => {
        deduplicatedDateRanges.push(plainDateRange);

        currentDateRangeStart = null;
    };

    for (const event of sortedEvents) {
        if (event.type === 'start') {
            if (currentActiveDateRangeCount === 0) {
                if (currentDateRangeStart === null) {
                    currentDateRangeStart = event.date;
                }

                if (currentGapStart !== null) {
                    addNewGap({ start: currentGapStart, end: event.date });
                }
            }
            currentActiveDateRangeCount++;
            continue;
        }

        // event.type === 'end'
        currentActiveDateRangeCount--;
        if (currentActiveDateRangeCount === 0) {
            if (currentGapStart === null) {
                currentGapStart = event.date;
            }
            if (currentDateRangeStart !== null) {
                addNewDateRange({
                    start: currentDateRangeStart,
                    end: event.date,
                });
            }
        }
    }

    return {
        gaps,
        deduplicatedDateRanges,
    };
};

const calculateDurationInMonth = (plainDateRange: PlainDateRange): number => {
    const duration = plainDateRange.end.since(plainDateRange.start);

    if (duration.blank) {
        return 0;
    }

    return duration.total({
        unit: 'months',
        relativeTo: plainDateRange.start,
    });
};

export const calculateTimespanInMonths = (
    dateRanges: ReadonlyArray<PlainDateRange>,
): number =>
    Math.round(
        dateRanges.reduce(
            (total, range) => total + calculateDurationInMonth(range),
            0,
        ),
    );

type Timespan = {
    name: string;
    value: number;
};

export const calculateGroupedTotalTimespansInMonths = (
    groups: ReadonlyArray<TimeAwareCvGroup>,
    field: 'industry' | 'role',
): Timespan[] => {
    const map = new Map<string, ReadonlyArray<TimeAwareCvGroup>>();

    for (const group of groups) {
        if (field === 'role') {
            const groups = map.get(group.position) ?? [];

            map.set(group.position, [...groups, group]);

            continue;
        }

        for (const industry of group.industries) {
            const groups = map.get(industry) ?? [];

            map.set(industry, [...groups, group]);
        }
    }

    return Array.from(map.entries()).map(([fieldValue, groups]) => {
        const { deduplicatedDateRanges } = parseExperienceDateRanges(
            groups.map(({ plainDateRange }) => plainDateRange),
        );

        return {
            name: fieldValue,
            value: Math.round(
                calculateTimespanInMonths(deduplicatedDateRanges),
            ),
        };
    });
};

const getValueAndUnit = (value: number): [number, Units] =>
    value > 11
        ? [parseFloat((value / 12).toFixed(1)), 'years']
        : [value, 'months'];

const EDUCATION_LEVEL_ENTRIES = [
    ['online_course', 1],
    ['primary_school', 2],
    ['secondary_school', 3],
    ['high_school_diploma', 4],
    ['associate_degree', 5],
    ['bachelors_degree', 6],
    ['masters_degree', 7],
    ['mba', 8],
    ['phd', 9],
] as const;

export type EducationLevel = typeof EDUCATION_LEVEL_ENTRIES[number][0];
export type EducationLevelWeight = typeof EDUCATION_LEVEL_ENTRIES[number][1];

const EDUCATION_LEVEL_TO_WEIGHT = new Map(EDUCATION_LEVEL_ENTRIES);

const WEIGHT_TO_EDUCATION_LEVEL = new Map(
    EDUCATION_LEVEL_ENTRIES.map(([a, b]) => [b, a]),
);

const levelIcon = new Map<EducationLevel, IconType>([
    ['online_course', GrBook],
    ['primary_school', RiArtboardLine],
    ['secondary_school', GrCertificate],
    ['high_school_diploma', RiFilePaper2Line],
    ['associate_degree', MdLaptop],
    ['bachelors_degree', FaPencilRuler],
    ['masters_degree', GoMortarBoard],
    ['mba', FiAward],
    ['phd', FaMedal],
]);

type ChartConfigInput = {
    activeSources: ReadonlyArray<TargetReportSourceType>;
    typos: ReadonlyArray<TargetReportAttribute<string>>;
    groups: ReadonlyArray<CvGroup>;
};

export const buildChartConfig = (config: ChartConfigInput): StatisticConfig => {
    const { activeSources, typos, groups } = config;

    const typosBySource = activeSources.includes('userInput')
        ? typos.map(({ value }) => value)
        : [];

    const occupationsBySources = groups.filter(
        (group) =>
            group.kind === 'occupation' &&
            group.cvEntries.some((cvEntry) =>
                activeSources.some((source) =>
                    cvEntry.sources.includes(source),
                ),
            ),
    );

    const occupations = getTimeAwareGroups(occupationsBySources);

    const educationsBySources = groups.filter(
        (group) =>
            group.kind === 'education' &&
            group.cvEntries.some((cvEntry) =>
                activeSources.some((source) =>
                    cvEntry.sources.includes(source),
                ),
            ),
    );

    const educations = getTimeAwareGroups(educationsBySources);

    const missingWorkRanges =
        occupations.length !== occupationsBySources.length;

    const missingEducationRanges =
        educations.length !== educationsBySources.length;

    const uniqueCompanies = Array.from(
        new Set(occupationsBySources.flatMap(({ company }) => company)),
    );

    const timespanInMonthsOfPosition = calculateGroupedTotalTimespansInMonths(
        occupations,
        'role',
    );

    const industryTimespans = calculateGroupedTotalTimespansInMonths(
        occupations,
        'industry',
    );

    const { gaps } = parseExperienceDateRanges(
        occupations
            .concat(educations)
            .map(({ plainDateRange }) => plainDateRange),
    );

    const { deduplicatedDateRanges: uniqueWorkExperienceRanges } =
        parseExperienceDateRanges(
            occupations.map(({ plainDateRange }) => plainDateRange),
        );

    const { deduplicatedDateRanges: uniqueEducationRanges } =
        parseExperienceDateRanges(
            educations.map(({ plainDateRange }) => plainDateRange),
        );

    const workExperienceTimespan = calculateTimespanInMonths(
        uniqueWorkExperienceRanges,
    );

    const educationTimespan = calculateTimespanInMonths(uniqueEducationRanges);

    const [educationTimespanValue, educationTimespanUnit] =
        getValueAndUnit(educationTimespan);

    const [workExperienceTimespanValue, workExperienceTimespanUnit] =
        getValueAndUnit(workExperienceTimespan);

    const weight = educations
        .map(({ educationLevelWeight }) => educationLevelWeight)
        .reduce((a, b) => (a > b ? a : b), 0);

    const highestEducationLevel =
        weight !== 0 ? WEIGHT_TO_EDUCATION_LEVEL.get(weight) ?? null : null;

    return [
        {
            // educationTimespan
            kind: 'numerical',
            labels: {
                title: 'cVAnalysis.chartTitle.educationTimespan',
                warningMessage: 'cVAnalysis.chartWarning.educationTimespan',
                missingDataMessage:
                    educationTimespan === 0
                        ? 'cVAnalysis.chartEmpty.educationTimespan'
                        : 'cVAnalysis.chartNotFound.educationTimespan',
            },
            data: {
                showWarning: missingEducationRanges,
                isMissingData: educationTimespan === 0,
                content: educations.map(
                    ({ plainDateRange }) =>
                        `${calculateTimespanInMonths([plainDateRange])} ${t(
                            'cVAnalysis.chartUnit.months',
                        )}`,
                ),
                value: educationTimespanValue,
                unit: educationTimespanUnit,
            },
        },
        {
            // educationLevel
            kind: 'symbol',
            labels: {
                title: 'cVAnalysis.chartTitle.educationLevel',
                warningMessage: 'cVAnalysis.chartWarning.educationTimespan',
                missingDataMessage:
                    educations?.length === 0
                        ? 'cVAnalysis.chartEmpty.educationTimespan'
                        : 'cVAnalysis.chartNotFound.educationLevel',
            },
            data: {
                value: t(
                    `educationLevelOptions.${highestEducationLevel}`,
                    t('unknown'),
                ),
                icon:
                    highestEducationLevel !== null
                        ? levelIcon.get(highestEducationLevel)
                        : undefined,
                isMissingData: highestEducationLevel === null,
                showWarning: false,
            },
        },
        {
            // uniqueCompanies
            kind: 'numerical',
            labels: {
                title: 'cVAnalysis.chartTitle.uniqueCompanies',
                warningMessage: 'cVAnalysis.chartWarning.jobTimespan',
                missingDataMessage:
                    workExperienceTimespan === 0
                        ? 'cVAnalysis.chartEmpty.jobTimespan'
                        : 'cVAnalysis.chartNotFound.uniqueCompanies',
            },
            data: {
                showWarning: workExperienceTimespan === 0,
                isMissingData: uniqueCompanies.length === 0,
                content: uniqueCompanies,
                value: uniqueCompanies.length,
            },
        },
        {
            // workTimespan
            kind: 'numerical',
            labels: {
                title: 'cVAnalysis.chartTitle.jobTimespan',
                warningMessage: 'cVAnalysis.chartWarning.jobTimespan',
                missingDataMessage:
                    workExperienceTimespan === 0
                        ? 'cVAnalysis.chartEmpty.jobTimespan'
                        : 'cVAnalysis.chartNotFound.jobTimespan',
            },
            data: {
                showWarning: missingWorkRanges,
                isMissingData: workExperienceTimespan === 0,
                content: occupations.map(
                    ({ plainDateRange }) =>
                        `${calculateTimespanInMonths([plainDateRange])} ${t(
                            'cVAnalysis.chartUnit.months',
                        )}`,
                ),
                value: workExperienceTimespanValue,
                unit: workExperienceTimespanUnit,
            },
        },
        {
            // timespanOfPositions
            kind: 'donutChart',
            labels: {
                title: 'cVAnalysis.chartTitle.timespanOfPositions',
                warningMessage: 'cVAnalysis.chartWarning.jobTimespan',
                missingDataMessage:
                    workExperienceTimespan === 0
                        ? 'cVAnalysis.chartEmpty.jobTimespan'
                        : 'cVAnalysis.chartNotFound.timespanOfPositions',
            },
            data: {
                showWarning: missingWorkRanges,
                isMissingData: workExperienceTimespan === 0,
                data: timespanInMonthsOfPosition.map(({ name, value }) => ({
                    name,
                    value: value,
                })),
                unit: 'months',
                content: timespanInMonthsOfPosition.map(
                    (p) =>
                        `${p.name} (${p.value}${t(
                            'cVAnalysis.chartUnit.months',
                        ).charAt(0)})`,
                ),
            },
        },
        {
            // industriesTimespan
            kind: 'donutChart',
            labels: {
                title: 'cVAnalysis.chartTitle.industriesTimespan',
                warningMessage: 'cVAnalysis.chartWarning.jobTimespan',
                missingDataMessage:
                    workExperienceTimespan === 0
                        ? 'cVAnalysis.chartEmpty.jobTimespan'
                        : 'cVAnalysis.chartNotFound.industriesTimespan',
            },
            data: {
                showWarning: missingWorkRanges,
                isMissingData: workExperienceTimespan === 0,
                data: industryTimespans.map(({ name, value }) => ({
                    name,
                    value,
                })),
                unit: 'months',
                content: industryTimespans.map(
                    (p) =>
                        `${p.name} (${p.value}${t(
                            'cVAnalysis.chartUnit.months',
                        ).charAt(0)})`,
                ),
            },
        },
        {
            // gaps
            kind: 'numerical',
            labels: {
                title: 'cVAnalysis.chartTitle.gaps',
                warningMessage: 'cVAnalysis.chartWarning.gaps',
                missingDataMessage:
                    educationTimespan === 0 && workExperienceTimespan === 0
                        ? 'cVAnalysis.chartEmpty.gaps'
                        : 'cVAnalysis.chartNotFound.gaps',
            },
            data: {
                showWarning: missingEducationRanges || missingWorkRanges,
                isMissingData:
                    educationTimespan === 0 && workExperienceTimespan === 0,
                content: gaps.map(({ start, end }) => {
                    const [value, unit] = getValueAndUnit(
                        calculateTimespanInMonths([{ start, end }]),
                    );
                    return `${value} ${t(`cVAnalysis.chartUnit.${unit}`)}`;
                }),
                value: gaps.length,
            },
        },
        {
            // typos
            kind: 'numerical',
            labels: {
                title: 'cVAnalysis.chartTitle.typos',
                warningMessage: 'cVAnalysis.chartWarning.typos',
                missingDataMessage: 'cVAnalysis.chartNotFound.typos',
            },
            data: {
                showWarning: typosBySource.length !== typos.length,
                isMissingData: typos.length === 0,
                content: typosBySource,
                value: typosBySource.length,
                unit: typosBySource.length === 1 ? 'typo' : 'typos',
            },
        },
    ];
};

export const ENTITIES_THRESHOLD = 10;

// TODO: We should not be generating the colors separately here, but the color should be part of the data.
// Do not change any values until the data structure is updated.
export const generateColors = (length: number): string[] => {
    const baseHue = 160;
    const baseSaturation = 50;
    const baseLightness = 80;
    const lightnessStep =
        length < ENTITIES_THRESHOLD
            ? baseLightness / length
            : ENTITIES_THRESHOLD;

    let lightness = baseLightness;
    let saturation = baseSaturation;
    let hue = baseHue;

    const colors = [];

    for (let i = 0; i < length; i++) {
        colors.push(`hsl(${hue}, ${saturation}%, ${lightness}%)`);

        lightness -= lightnessStep;
        if (lightness < 21) {
            lightness = baseLightness;

            saturation = saturation + 30;
            hue = hue + 30;
        }
    }

    return colors;
};

export type PlainDateRange = Readonly<{
    start: Temporal.PlainDate;
    end: Temporal.PlainDate;
}>;

export type TimeAwareCvEntry = Readonly<{
    id: string;
    company: string;
    position: string;
    plainDateRange: PlainDateRange;
    sources: ReadonlyArray<TargetReportSourceType>;
}>;

export type TimeAwareCvGroup = Readonly<{
    id: string;
    plainDateRange: PlainDateRange;
    company: string;
    position: string;
    kind: CvGroup['kind'];
    sources: ReadonlyArray<TargetReportSourceType>;
    industries: ReadonlyArray<Industry>;
    educationLevelWeight: EducationLevelWeight | 0;
    cvEntries: ReadonlyArray<TimeAwareCvEntry>;
}>;

export const getTimeAwareGroups = (
    groups: ReadonlyArray<CvGroup>,
): ReadonlyArray<TimeAwareCvGroup> =>
    groups
        .map((group) => {
            if (group.dateRange === null) {
                return null;
            }

            const sources = group.cvEntries.flatMap(({ sources }) => sources);

            const industries = Array.from(
                new Set(
                    group.cvEntries.flatMap((entry) =>
                        entry.kind === 'occupation' ? entry.industries : [],
                    ),
                ),
            );

            const educationLevelWeight: EducationLevelWeight | 0 =
                group.cvEntries
                    .map((entry) => ('level' in entry ? entry.level : null))
                    .filter(isNeitherNullNorUndefined)
                    .map((level) => EDUCATION_LEVEL_TO_WEIGHT.get(level) ?? 0)
                    .reduce((a, b) => (a > b ? a : b), 0);

            const deduplicatedSources = Array.from(new Set(sources)).sort();

            const start = Temporal.PlainDate.from(group.dateRange.start);
            const end = Temporal.PlainDate.from(group.dateRange.end);

            const cvEntries = group.cvEntries
                .map((entry) => {
                    if (entry.dateRange === null) {
                        return null;
                    }

                    const start = Temporal.PlainDate.from(
                        entry.dateRange.start,
                    );
                    const end = Temporal.PlainDate.from(entry.dateRange.end);

                    return {
                        id: entry.id,
                        company: entry.company,
                        position: entry.position,
                        plainDateRange: {
                            start,
                            end,
                        },
                        sources: entry.sources,
                    };
                })
                .filter(isNeitherNullNorUndefined);

            return {
                id: group.id,
                plainDateRange: {
                    start,
                    end,
                },
                company: group.company,
                position: group.position,
                kind: group.kind,
                sources: deduplicatedSources,
                industries,
                educationLevelWeight,
                cvEntries,
            };
        })
        .filter(isNeitherNullNorUndefined);

export const sortCv = (a: CVInfoEntry, b: CVInfoEntry): number => {
    if (a.isCurrentPosition !== b.isCurrentPosition) {
        return a.isCurrentPosition ? -1 : 1;
    }

    if (a.to && b.to) {
        const toDateComparison =
            new Date(a.to).getTime() - new Date(b.to).getTime();
        return toDateComparison > 0 ? 1 : -1;
    }

    if (!a.to && b.to) {
        return 1;
    }

    if (a.to && !b.to) {
        return -1;
    }

    if (a.from && b.from) {
        const fromDateComparison =
            new Date(b.from).getTime() - new Date(a.from).getTime();
        return fromDateComparison > 0 ? 1 : -1;
    }

    if (!a.from && b.from) {
        return 1;
    }

    if (a.from && !b.from) {
        return -1;
    }

    return a.companyName.localeCompare(b.companyName);
};

const toUniqueCvEntries = (cvEntries: CVInfoEntry[]): CVInfoEntry[] => {
    const entriesMap = new Map<string, CVInfoEntry>();

    cvEntries.forEach((entry) => {
        const key = `${entry.companyName}-${entry.role}-${entry.from}-${entry.to}`;
        entriesMap.set(key, entry);
    });

    return Array.from(entriesMap.values());
};

export const toEntrepreneurialActivities = (
    companies: TargetCompanies['companies'],
): EntrepreneurialActivities => {
    const cvBySource: EntrepreneurialActivities = {};

    companies.forEach((company) => {
        (company.data?.role ?? []).forEach((role) => {
            role.sources.forEach((source) => {
                if (!cvBySource[source]) {
                    cvBySource[source] = [];
                }

                cvBySource[source].push({
                    companyName: company.data.name.value,
                    role: role.value.role,
                    isCurrentPosition:
                        role.value.currentPrevious === 'current' ||
                        (!role.value.currentPrevious && !role.value.to),
                    from: role.value.from,
                    to: role.value.to,
                    industry: (company.data.industry ?? []).at(0)?.value,
                });
            });
        });
    });

    Object.keys(cvBySource).forEach((source) => {
        cvBySource[source] = toUniqueCvEntries(cvBySource[source]).sort(sortCv);
    });

    return cvBySource;
};
