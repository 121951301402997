import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { cases } from '../../services/nestApiService';

export const useUpdateCaseMutation = (): UseMutationResult<
    boolean,
    unknown,
    { caseId: string; title: string }
> => {
    const queryClient = useQueryClient();

    return useMutation(({ caseId, title }) => cases.update(caseId, { title }), {
        onSuccess: () => queryClient.invalidateQueries('cases'),
    });
};
