import { z } from 'zod';

const dateRange = z
    .object({
        start: z.string(),
        end: z.string(),
    })
    .readonly();

const cvEntryKindSchema = z.enum(['education', 'occupation']);

const sourceSchema = z.enum([
    'companyhouse',
    'orbis',
    'pipl',
    'digitalclues',
    'userInput',
    'firmenabc',
    'indicium',
    'zefix',
    'complianceCatalyst',
    'icijLeaks',
    'google',
    'shadowdragon',
]);

export const industrySchema = z.enum([
    'agriculture_and_farming',
    'aerospace_and_defense',
    'artificial_intelligence_and_machine_learning',
    'architecture_and_urban_planning',
    'automotive',
    'biotechnology',
    'chemicals_and_petrochemicals',
    'consulting',
    'consumer_electronics',
    'construction',
    'cybersecurity',
    'digital_marketing_and_advertising',
    'e_commerce',
    'education',
    'electronics_and_semiconductors',
    'energy',
    'entertainment_and_media',
    'environmental_services',
    'event_planning_and_management',
    'fashion_and_apparel',
    'finance_and_banking',
    'fitness_and_wellness',
    'food_and_beverage',
    'forestry_and_timber',
    'gaming_and_esports',
    'healthcare',
    'hospitality',
    'human_resources_and_recruitment',
    'information_technology',
    'interior_design_and_home_improvement',
    'insurance',
    'legal_services',
    'logistics_and_supply_chain',
    'marine_and_maritime',
    'mining_and_metals',
    'music_and_arts',
    'nonprofit',
    'personal_care_and_beauty',
    'pharmaceuticals',
    'printing_and_publishing',
    'public_administration_and_government',
    'real_estate',
    'research_and_development',
    'retail',
    'space_exploration_and_technology',
    'sports_and_recreation',
    'telecommunications',
    'textile_manufacturing',
    'transportation',
    'travel_and_tourism',
    'venture_capital_and_private_equity',
]);

export type Industry = z.infer<typeof industrySchema>;

export const educationLevelSchema = z.enum([
    'primary_school',
    'secondary_school',
    'high_school_diploma',
    'associate_degree',
    'online_course',
    'bachelors_degree',
    'masters_degree',
    'mba',
    'phd',
]);

export type DateRange = z.infer<typeof dateRange>;
export type TargetReportSourceType = z.infer<typeof sourceSchema>;

// Define CV entry schemas
export const occupationCvEntrySchema = z.object({
    id: z.string(),
    kind: z.literal('occupation'),
    company: z.string(),
    position: z.string(),
    dateRange: dateRange.nullable(),
    sources: z.array(sourceSchema).readonly(),
    industries: z.array(industrySchema).readonly(),
});

export const educationCvEntrySchema = z.object({
    id: z.string(),
    kind: z.literal('education'),
    company: z.string(),
    position: z.string(),
    dateRange: dateRange.nullable(),
    sources: z.array(sourceSchema).readonly(),
    level: educationLevelSchema.nullable(),
});

export const cvEntrySchema = z
    .discriminatedUnion('kind', [
        occupationCvEntrySchema,
        educationCvEntrySchema,
    ])
    .readonly();

export const cvGroupSchema = z
    .object({
        id: z.string(),
        kind: cvEntryKindSchema,
        cvEntries: z.array(cvEntrySchema).readonly(),
        reason: z.string(),
        company: z.string(),
        position: z.string(),
        dateRange: dateRange.nullable(),
    })
    .readonly();

export type CvGroup = z.infer<typeof cvGroupSchema>;

const entrySchema = z.object({
    id: z.string(),
    kind: cvEntryKindSchema,
    companyName: z.string(),
    positionTitle: z.string(),
    startDate: z.string().nullish(),
    endDate: z.string().nullish(),
    sources: z.array(z.string()).readonly(),
});

const comparisonCvGroupSchema = z.object({
    entries: z.array(entrySchema),
    reason: z.string(),
});

const discrepancySchema = z.object({
    type: z.union([
        z.literal('company_name_mismatch'),
        z.literal('job_title_mismatch'),
        z.literal('date_range_mismatch'),
        z.literal('omitted_employment'),
        z.literal('education_mismatch'),
        z.literal('certification_mismatch'),
        z.literal('unrealistic_job_claims'),
        z.literal('verified_entries'),
    ]),
    description: z.string(),
    affectedEntries: z.array(entrySchema),
});

const comparisonCvSchema = z.object({
    similarCvEntries: z.array(comparisonCvGroupSchema).readonly(),
    discrepancyCvEntries: z.array(discrepancySchema).readonly(),
});

export const CvValidationSchema = z.object({
    userControlledComparison: comparisonCvSchema,
    userUncontrolledComparison: comparisonCvSchema,
});

export type CvValidation = z.infer<typeof CvValidationSchema>;
