import { StatisticType } from '../../types';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import classnames from 'classnames';
import { CvStatisticChart } from './CvStatisticChart';
import {
    CVChartModal,
    CvStatisticContent,
    CvStatisticHeader,
    CvStatisticNumeric,
    CvStatisticSymbol,
} from './HelperComponents';
import { Modal } from '_atoms';

export const CvCard: FC<StatisticType> = (props) => {
    const { data, labels, kind } = props;
    const { showWarning, isMissingData, unit } = data;

    const { title, warningMessage, missingDataMessage } = labels;

    const { t } = useTranslation();

    const [modalOpen, setModalOpen] = useState<boolean>(false);

    return (
        <>
            <div
                className={classnames(
                    'flex flex-row gap-3.5 rounded-2xl bg-neutral-100 p-3.5 drop-shadow-lg',
                    !isMissingData &&
                        kind === 'donutChart' &&
                        'cursor-pointer hover:drop-shadow-2xl transition-all duration-300',
                )}
                onClick={() => {
                    if (!isMissingData) {
                        setModalOpen(true);
                    }
                }}
            >
                <div className="flex flex-col w-16 h-16 items-center justify-center flex-shrink-0">
                    {kind === 'numerical' && (
                        <CvStatisticNumeric
                            value={data.value}
                            unit={
                                unit
                                    ? t(`cVAnalysis.chartUnit.${unit}`)
                                    : undefined
                            }
                            isMissingData={isMissingData}
                        />
                    )}
                    {kind === 'donutChart' && (
                        <CvStatisticChart data={data} labels={labels} />
                    )}
                    {kind === 'symbol' && (
                        <CvStatisticSymbol icon={data.icon} />
                    )}
                </div>
                <div className="flex flex-col gap-1.5 justify-start w-full">
                    <CvStatisticHeader
                        title={t(title)}
                        showWarning={showWarning}
                        warningMessage={t(warningMessage)}
                    />
                    <CvStatisticContent
                        chart={props}
                        isMissingData={isMissingData}
                        missingDataMessage={t(missingDataMessage)}
                    />
                </div>
            </div>
            {kind === 'donutChart' && (
                <Modal
                    isOpen={modalOpen}
                    title={t(title)}
                    onClose={() => setModalOpen(false)}
                    maxWidth="max-w-4xl"
                >
                    <CVChartModal data={data} labels={labels} />
                </Modal>
            )}
        </>
    );
};

export const CVCardSkeleton: FC<{ index: number }> = ({ index }) => {
    return (
        <div className="flex flex-grow overflow-y-auto items-center gap-3 rounded-2xl bg-neutral-100 p-5">
            <div className="flex flex-col animate-pulse bg-neutral-100 dark:bg-neutral-400 rounded-3xl w-12 h-full p-4" />
            <div className="flex flex-col gap-2 w-full text-font-dark border-red">
                <div
                    className={classNames(
                        'animate-pulse bg-neutral-100 dark:bg-neutral-400 h-3 rounded-full',
                        (index + 1) % 4 === 1 && 'w-2/5',
                        (index + 1) % 4 === 2 && 'w-4/5',
                        (index + 1) % 4 === 3 && 'w-2/5',
                        (index + 1) % 4 === 0 && 'w-3/5',
                    )}
                />
                <div className="animate-pulse bg-neutral-100 dark:bg-neutral-400 h-10 rounded-lg " />
            </div>
        </div>
    );
};
