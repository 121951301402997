import { ElementType, FC } from 'react';
import { Headline, Paragraph } from '_atoms';
import classnames from 'classnames';

interface WizardCardProps {
    title: string;
    icon: ElementType;
    description?: string;
    className?: string;
    onClick?: () => void;
}

const ICON_SIZE = 20;

export const WizardCard: FC<WizardCardProps> = ({
    title,
    icon: Icon,
    description,
    className,
    onClick,
}) => {
    return (
        <div
            className={classnames(
                className,
                'border-2 bg-neutral-300 rounded-lg p-4 cursor-pointer transition-colors duration-500 hover:bg-white border-neutral-300',
                'flex flex-col gap-2',
            )}
            onClick={onClick}
        >
            <div className="flex flex-shrink items-start gap-2 text-font-dark">
                <Icon
                    size={ICON_SIZE} // for react-icons
                    width={ICON_SIZE} // for regular svg icons
                    height={ICON_SIZE} // for regular svg icons
                    className="flex-shrink-0"
                />
                <Headline
                    Level="h5"
                    className="overflow-hidden text-ellipsis"
                    title={title}
                >
                    {title}
                </Headline>
            </div>
            {description && (
                <Paragraph color="light" size="medium">
                    {description}
                </Paragraph>
            )}
        </div>
    );
};
