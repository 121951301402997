import { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react';
import classnames from 'classnames';

export type SizeLevel =
    | 'default'
    | 'small'
    | 'medium'
    | 'label'
    | 'sLabel'
    | 'big'
    | 'xSmall';
export type WeightLevel = 'normal' | 'medium' | 'semibold' | 'bold';
export type LineHeightLevel = 'default' | 'small' | 'large' | 'none';
export type ColorLevel =
    | 'default'
    | 'light'
    | 'lighter'
    | 'mediumDark'
    | 'dark'
    | 'white'
    | 'blue'
    | 'green'
    | 'red';

export interface ParagraphProps
    extends DetailedHTMLProps<
        HTMLAttributes<HTMLHeadingElement>,
        HTMLHeadingElement
    > {
    size?: SizeLevel;
    weight?: WeightLevel;
    lineHeight?: LineHeightLevel;
    color?: ColorLevel;
    children: ReactNode;
    HtmlTag?: 'p' | 'span' | 'div';
}

export const textSize = {
    default: 'text-base',
    small: 'text-sm',
    medium: 'text-md',
    big: 'text-lg',
    xSmall: 'text-xs',
    label: 'text-label font-jost',
    sLabel: 'text-sm font-jost',
};

export const textWeight = {
    normal: 'font-normal',
    medium: 'font-medium',
    semibold: 'font-semibold',
    bold: 'font-bold',
};

export const textColor = {
    default: 'text-font-dark',
    light: 'text-neutral-500',
    lighter: 'text-neutral-400',
    mediumDark: 'text-gray-600',
    dark: 'text-font-dark',
    white: 'text-font-light',
    blue: 'text-primary-4',
    green: 'text-success-1',
    red: 'text-error-2',
};

export const textLineHeight = {
    default: 'leading-5',
    small: 'leading-4',
    large: 'leading-8',
    none: 'leading-none',
};

export const Paragraph: FC<ParagraphProps> = ({
    className,
    size = 'default',
    weight = 'normal',
    lineHeight = 'default',
    color = 'default',
    HtmlTag = 'p',
    children,
    ...rest
}: ParagraphProps) => {
    return (
        <HtmlTag
            className={classnames(
                className,
                textSize[size],
                textWeight[weight],
                textLineHeight[lineHeight],
                textColor[color],
            )}
            {...rest}
        >
            {children}
        </HtmlTag>
    );
};
