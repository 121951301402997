import {
    COMMON_COMPANY_LEGAL_POSTFIXES,
    COMMON_COMPANY_PHRASE_REGEXPS,
    LOWERCASE_COUNTRY_CODES,
    LOWERCASE_COUNTRY_NAMES,
} from './constants';

const NON_ALPHANUMERIC_REGEX = /[^a-zäöüß0-9&+]/g;
const MULTIPLE_SPACES_REGEX = / {2,}/g;

export const normalizeCompanyName = (name: string): string =>
    name
        .trim()
        .toLowerCase()
        .replace(NON_ALPHANUMERIC_REGEX, ' ')
        .replace(MULTIPLE_SPACES_REGEX, ' ');

const removeTrailingCompanyNameParts = (
    regExp: RegExp,
    name: string,
): string => {
    const lastSpaceIndex = name.lastIndexOf(' ');
    const lastWord = name.substring(lastSpaceIndex + 1);

    const matched = lastWord === '&' || regExp.test(lastWord);

    if (!matched) {
        return name;
    }

    if (lastSpaceIndex === -1) {
        return '';
    }

    return removeTrailingCompanyNameParts(
        regExp,
        normalizeCompanyName(name.substring(0, lastSpaceIndex)),
    );
};

const COUNTRY_CODE_REGEX = new RegExp(
    `\\b(${LOWERCASE_COUNTRY_CODES.join('|')})\\b`,
    'gi',
);
const COUNTRY_NAME_REGEX = new RegExp(
    `\\b(${LOWERCASE_COUNTRY_NAMES.join('|')})\\b`,
    'gi',
);

const removeCountryCodes = (companyName: string): string =>
    companyName.toLowerCase().trim().replace(COUNTRY_CODE_REGEX, '');

const removeCountryNames = (companyName: string): string =>
    companyName.toLowerCase().trim().replace(COUNTRY_NAME_REGEX, '');

const isCompanyMentionedInText = (text: string, company: string): boolean =>
    text.includes(company.toLowerCase());

export const scoreCompany = (text: string, companyName: string): number => {
    if (!companyName) {
        return 0;
    }

    const lowerCasedText = text.toLowerCase();

    if (isCompanyMentionedInText(lowerCasedText, companyName)) {
        return 15;
    }

    const companyNameNoLegalWords = removeTrailingCompanyNameParts(
        COMMON_COMPANY_LEGAL_POSTFIXES,
        normalizeCompanyName(companyName),
    );

    if (isCompanyMentionedInText(lowerCasedText, companyNameNoLegalWords)) {
        return 12;
    }

    const companyNameNoLegalNoPhraseWords = removeTrailingCompanyNameParts(
        COMMON_COMPANY_PHRASE_REGEXPS,
        companyNameNoLegalWords,
    );

    if (
        isCompanyMentionedInText(
            lowerCasedText,
            companyNameNoLegalNoPhraseWords,
        )
    ) {
        return 10;
    }

    const companyNameWithoutCode = removeCountryCodes(companyNameNoLegalWords);

    if (isCompanyMentionedInText(lowerCasedText, companyNameWithoutCode)) {
        return 8;
    }

    const companyNameWithoutCountryName = removeCountryNames(
        companyNameWithoutCode,
    );

    if (
        isCompanyMentionedInText(lowerCasedText, companyNameWithoutCountryName)
    ) {
        return 5;
    }

    return 0;
};
