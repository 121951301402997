import { FC, useEffect, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Button, LoadingSpinner } from '_atoms';
import { Alert, ConfirmationModal } from '_molecules';
import { PageNotFound } from '_organisms';
import { PersonInfo, Table } from '_organisms/Table/Table';
import { generatePath, Link, useLocation, useParams } from 'react-router-dom';
import { useUserState } from '../../../context/User';
import {
    useCaseQuery,
    useCustomerQuery,
    useDeleteTargetMutation,
    useTargetsQuery,
} from '_queries';
import { Target, TargetStatus } from '../../../services/dataService';
import { formatDateTime, isCustomerExpired, nonProdDataTestId } from '_utils';
import { useDeleteMutation } from '../../../hooks/useDeleteMutation';
import { useUserQuota } from '../../../hooks/useUserQuota';
import { USER_GROUP_ADMINISTRATORS } from '../../../services/authenticationService';
import {
    useExtractQueryParams,
    useUpdateSearchState,
} from '../../../hooks/useSearch';
import { SearchBar } from '../../../components/_molecules/SearchBar/SearchBar';
import { TableEmpty } from '_organisms/Table/TableEmpty/TableEmpty';
import { DefaultPageLayout } from '../../../components/Layout/DefaultPageLayout';
import { routes } from '../../../routes';
import { MdAddCircleOutline, MdOutlineGroupAdd } from 'react-icons/md';
import { CVUploader } from '../../../components/_molecules/CVUploader';
import { HiOutlineDocumentArrowUp } from 'react-icons/hi2';
import { useToast } from '../../../context/Toast';

const prepareCaseData = (
    data: Target[],
    t: TFunction<'translation'>,
    newFields: string[] = [],
): PersonInfo[] =>
    data.map(({ id, firstname, lastname, creator, createdAt, status }) => ({
        id,
        fullName: `${firstname} ${lastname}`,
        creator: creator ? `${creator.firstname} ${creator.lastname}` : '-',
        createdOn: formatDateTime(createdAt),
        status: t(`targetStatus.${TargetStatus[status]}`),
        shouldAnimate: `${newFields.includes(id)}`,
    }));

export const CaseShow: FC = () => {
    const [{ enableConfirm, showModal }, dispatch] = useDeleteMutation();

    const { t } = useTranslation();
    const toast = useToast();
    const { caseId } = useParams<{ caseId: string }>();
    const [newFields, setNewFields] = useState<string[]>([]);
    const [notificationStateAddCaseAgent, setNotificationStateAddCaseAgent] =
        useState<null | 'error' | 'success'>(null);
    const [notificationAddCaseAgent, setNotificationAddCaseAgent] =
        useState<string | null>(null);
    const location = useLocation<{ success?: string; error?: string }>();

    const [searchState, setSearchState] = useState({ query: '' });
    const [{ userRoles, customerId }] = useUserState();
    const { quota } = useUserQuota();
    const { data: customer } = useCustomerQuery(customerId);

    const getQueryParams = useExtractQueryParams('query', searchState);

    useEffect(() => {
        if (location.state) {
            setNotificationAddCaseAgent(Object.values(location.state)[0]);
            location.state.success &&
                setNotificationStateAddCaseAgent('success');
            location.state.error && setNotificationStateAddCaseAgent('error');
            window.history.replaceState({}, document.title);
        }
    }, [location]);

    const { data: caseData, ...caseQuery } = useCaseQuery(caseId);

    const { data: targetsResponse, ...targetsQuery } = useTargetsQuery(
        caseId,
        getQueryParams(),
    );
    const targetsData = targetsResponse?.data || [];

    const { mutate: deleteTargetMutation } = useDeleteTargetMutation({
        onSuccess: () => {
            toast({
                text: t('toasts.deleteTarget'),
                style: 'confirmation',
                ms: 5000,
            });
        },
        onError: () =>
            toast({
                text: t('toasts.deleteTargetFailed'),
                style: 'error',
                ms: 5000,
            }),
    });

    const handleModalEvent = (event: 'confirm' | 'cancel') => {
        switch (event) {
            case 'confirm':
                dispatch({
                    type: 'CONFIRM_DELETION',
                    mutation: deleteTargetMutation,
                });
                break;
            case 'cancel':
                dispatch({ type: 'RESET' });
                break;
        }
    };

    useUpdateSearchState('query', setSearchState);

    const tableRowActions = userRoles.includes(USER_GROUP_ADMINISTRATORS)
        ? [
              {
                  id: 'deleteTargetAction',
                  label: t('deleteTarget.action'),
                  callback: (targetId: string) => {
                      dispatch({
                          type: 'SET_DELETE_PARAMS',
                          params: { caseId, targetId },
                      });
                  },
              },
          ]
        : undefined;

    if (caseQuery.isLoading) {
        return (
            <div className="mt-20">
                <LoadingSpinner message={t('profileLoading')} />
            </div>
        );
    }

    if (caseQuery.isError) {
        return <PageNotFound />;
    }

    return (
        <div
            // drop target for cv upload
            className="w-full h-full"
        >
            <DefaultPageLayout
                title={caseData?.title ?? ''}
                headerActions={
                    <div className="flex items-center justify-end space-x-4">
                        {userRoles.includes(USER_GROUP_ADMINISTRATORS) && (
                            <Link
                                to={generatePath(routes.caseAgentsAssign.path, {
                                    caseId,
                                })}
                            >
                                <Button
                                    level="primaryGhost"
                                    icon={MdOutlineGroupAdd}
                                >
                                    {t('assignUserToCase')}
                                </Button>
                            </Link>
                        )}

                        <CVUploader
                            customView={{
                                openElement(open) {
                                    return (
                                        <Button
                                            level="primaryGhost"
                                            onClick={open}
                                            icon={HiOutlineDocumentArrowUp}
                                        >
                                            {t('uploadPdf')}
                                        </Button>
                                    );
                                },
                            }}
                            onError={(error) => {
                                alert(
                                    t('cvUploadFailed') +
                                        '\n' +
                                        (typeof error === 'object' &&
                                        error instanceof Error
                                            ? error.message
                                            : String(error)),
                                );
                            }}
                            multi
                            maxFileCount={5}
                            onCreate={(created) => {
                                targetsQuery.refetch();
                                setNewFields(created.map((c) => c.id));
                            }}
                        />

                        <Link
                            to={generatePath(routes.newTargetData.path, {
                                caseId,
                            })}
                        >
                            <Button
                                onClick={() =>
                                    localStorage.removeItem('oldTargetId')
                                }
                                disabled={
                                    !quota?.remaining ||
                                    isCustomerExpired(customer?.expiresAt)
                                }
                                data-testid={nonProdDataTestId(
                                    'create target button',
                                )}
                                icon={MdAddCircleOutline}
                            >
                                {t('createNewTarget')}
                            </Button>
                        </Link>
                    </div>
                }
            >
                {notificationStateAddCaseAgent && notificationAddCaseAgent && (
                    <div className="row m-0">
                        <Alert
                            message={notificationAddCaseAgent}
                            alertType={notificationStateAddCaseAgent}
                            className="mt-8"
                        />
                    </div>
                )}

                {quota && quota.remaining === 0 && (
                    <div className="row m-0">
                        <Alert
                            message={t('quotaExceeded')}
                            alertType="warning"
                            className="mt-8"
                        />
                    </div>
                )}

                <div className="mb-8">
                    <SearchBar
                        query={searchState.query}
                        onSearchChange={setSearchState}
                    />
                </div>

                {targetsQuery.isLoading && <LoadingSpinner />}

                <>
                    {targetsQuery.isSuccess ? (
                        <>
                            {targetsData.length ? (
                                <>
                                    <Table
                                        headlines={[
                                            t('targetPerson'),
                                            t('creator'),
                                            t('createdOn'),
                                            t('status'),
                                            '', // empty column bc of I think ID column 🤷
                                        ]}
                                        items={prepareCaseData(
                                            targetsData,
                                            t,
                                            newFields,
                                        )}
                                        linkOption={{
                                            name: t('open'),
                                            // TODO: Needs to be refactored, see IND-853 for reference
                                            href: `${generatePath(
                                                `/cases/:caseId/targets`,
                                                {
                                                    caseId,
                                                },
                                            )}/:targetId`,
                                            param: 'targetId',
                                        }}
                                        layoutOptions={{
                                            highlightColumns: {
                                                firstColBold: true,
                                            },
                                            squashLayout: true,
                                        }}
                                        tableRowActions={tableRowActions}
                                    />

                                    <ConfirmationModal
                                        body={t('deleteTarget.modalText')}
                                        cancelButtonText={t(
                                            'deleteTarget.cancelButton',
                                        )}
                                        confirmButtonText={t(
                                            'deleteTarget.confirmButton',
                                        )}
                                        enableConfirm={enableConfirm}
                                        isOpen={showModal}
                                        radioOptions={{
                                            options: t(
                                                'deleteTarget.radioOptions',
                                                {
                                                    returnObjects: true,
                                                },
                                            ),
                                            handleSelect: () =>
                                                dispatch({
                                                    type: 'ENABLE_CONFIRM_BUTTON',
                                                }),
                                        }}
                                        title={t('deleteTarget.modalTitle')}
                                        handleButtonClick={handleModalEvent}
                                    />
                                </>
                            ) : (
                                <TableEmpty
                                    headline={t('noResultHeadline')}
                                    message={t('noResultText')}
                                />
                            )}
                        </>
                    ) : null}
                </>
            </DefaultPageLayout>
        </div>
    );
};
