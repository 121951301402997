import React from 'react';
import classNames from 'classnames';

interface FormErrorProps extends React.LabelHTMLAttributes<HTMLDivElement> {
    errorMsg?: string | unknown;
}

export const FormError = React.forwardRef<HTMLDivElement, FormErrorProps>(
    ({ className, errorMsg, ...rest }, ref) => {
        return (
            <>
                {errorMsg && (
                    <div
                        ref={ref}
                        className={classNames(
                            'text-error-1 text-sm',
                            className,
                        )}
                        {...rest}
                    >
                        {errorMsg}
                    </div>
                )}
            </>
        );
    },
);

FormError.displayName = 'FormError';
