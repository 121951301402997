import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormError, Headline, Input, TextArea } from '_atoms';
import { ChecklistField } from '../../../types/ChecklistData';
import { Controller, useFormContext } from 'react-hook-form';
import CheckboxGroup from '../../_atoms/CheckboxGroup';
import { RadioGroupV2 } from '../../_atoms/RadioGroupV2';
import { ValidateResult } from 'react-hook-form/dist/types/validator';

const validateRequiredField = (
    isRequired: boolean,
    value: string[],
): boolean | ValidateResult => {
    if (isRequired && value.length === 0) {
        return 'schemaValidation.required';
    }
    return true;
};

export const Checklist: FC<{
    checklistFields: ChecklistField[];
    isEditable?: boolean;
    showSignButton?: boolean;
    // TODO: remove this in cleanup PR
    onEdit?: (fields: ChecklistField[]) => void;
}> = ({ checklistFields, isEditable = false, showSignButton = false }) => {
    const { t } = useTranslation();

    const {
        register,
        setValue,
        getValues,
        control,
        watch,
        formState: { errors },
    } = useFormContext<Record<string, string[]>>();

    return (
        <div className="flex flex-col gap-2 w-full h-full">
            {checklistFields.length === 0 && <p>{t('checklist.empty')}</p>}
            {checklistFields.map((field) => {
                const errMsg = errors[field.name]?.message ?? '';
                const errorMsgEl = errMsg ? (
                    <FormError errorMsg={t(errMsg)} />
                ) : null;

                if (field.type === 'title') {
                    return (
                        <Headline key={field.name} Level="h3">
                            {field.label}
                        </Headline>
                    );
                }
                if (field.type === 'description') {
                    return <p key={field.name}>{field.label}</p>;
                }

                if (field.type === 'text') {
                    return (
                        <Fragment key={field.name}>
                            <Input
                                label={`${field.label}${
                                    field.required ? ' *' : ''
                                }`}
                                value={getValues(field.name)}
                                {...register(field.name, {
                                    validate: (value) =>
                                        validateRequiredField(
                                            field.required,
                                            value,
                                        ),
                                })}
                                disabled={!isEditable}
                                onChange={(e) => {
                                    setValue(field.name, [e.target.value]);
                                }}
                            />
                            {errorMsgEl}
                        </Fragment>
                    );
                }

                if (field.type === 'textarea') {
                    return (
                        <Fragment key={field.name}>
                            <TextArea
                                label={`${field.label}${
                                    field.required ? ' *' : ''
                                }`}
                                value={getValues(field.name)}
                                {...register(field.name, {
                                    validate: (value) =>
                                        validateRequiredField(
                                            field.required,
                                            value,
                                        ),
                                })}
                                disabled={!isEditable}
                                onChange={(e) => {
                                    setValue(field.name, [e.target.value]);
                                }}
                            />
                            {errorMsgEl}
                        </Fragment>
                    );
                }

                if (field.type === 'checkbox') {
                    return (
                        <Fragment key={field.name}>
                            <Controller
                                name={field.name}
                                control={control}
                                rules={{
                                    validate: (value) =>
                                        validateRequiredField(
                                            field.required,
                                            value,
                                        ),
                                }}
                                render={({ field: { onBlur } }) => {
                                    const currentValues =
                                        watch(field.name) ?? [];
                                    return (
                                        <CheckboxGroup
                                            label={`${field.label}${
                                                field.required ? ' *' : ''
                                            }`}
                                            initialSelection={currentValues}
                                            onChange={(value) => {
                                                setValue(field.name, value);
                                                onBlur();
                                            }}
                                            isDisabled={!isEditable}
                                            options={field.options?.map(
                                                (option) => ({
                                                    value: option.value,
                                                    label: option.label,
                                                }),
                                            )}
                                        />
                                    );
                                }}
                            />
                            {errorMsgEl}
                        </Fragment>
                    );
                }

                if (field.type === 'radio') {
                    return (
                        <Fragment key={field.name}>
                            <Controller
                                name={field.name}
                                control={control}
                                rules={{
                                    validate: (value) =>
                                        validateRequiredField(
                                            field.required,
                                            value,
                                        ),
                                }}
                                render={({ field: { onBlur } }) => {
                                    const currentValues = watch(field.name) ?? [
                                        '',
                                    ];
                                    return (
                                        <RadioGroupV2
                                            label={`${field.label}${
                                                field.required ? ' *' : ''
                                            }`}
                                            initialSelection={currentValues[0]}
                                            options={field.options?.map(
                                                (option) => ({
                                                    value: option.value,
                                                    label: option.label,
                                                }),
                                            )}
                                            disabled={!isEditable}
                                            onChange={(value) => {
                                                setValue(field.name, [value]);
                                                onBlur();
                                            }}
                                        />
                                    );
                                }}
                            />
                            {errorMsgEl}
                        </Fragment>
                    );
                }

                return <div key={field.name}>Unknown field type</div>;
            })}
            {showSignButton && (
                // TODO: decide if to leave this here or have parent component handle signing in next PR
                <Button level="primary">{t('checklist.sign')}</Button>
            )}
        </div>
    );
};
