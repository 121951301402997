import { Checkbox, FormLabel } from '_atoms';
import React, { forwardRef, useCallback } from 'react';

type CheckboxGroupProps = {
    label?: string;
    initialSelection?: string[];
    onChange: (value: string[]) => void;
    className?: string;
    options?: { value: string; label: string }[];
    isDisabled?: boolean;
};

export const CheckboxGroup = forwardRef<HTMLDivElement, CheckboxGroupProps>(
    (
        {
            label,
            initialSelection = [],
            onChange,
            className,
            options = [],
            isDisabled,
        },
        ref,
    ) => {
        const handleClick = useCallback(
            (optionValue: string, isSelected: boolean) => {
                onChange(
                    isSelected
                        ? Array.from(
                              new Set([...initialSelection, optionValue]),
                          )
                        : initialSelection.filter((v) => v !== optionValue),
                );
            },
            [initialSelection, onChange],
        );

        return (
            <div ref={ref} className={className}>
                {label && <FormLabel>{label}</FormLabel>}
                <div className="flex flex-col gap-2 p-2">
                    {options?.map((option) => {
                        const initialIsSelected = initialSelection.includes(
                            option.value,
                        );
                        return (
                            <Checkbox
                                key={option.value}
                                label={option.label}
                                onChange={(isSelected) => {
                                    handleClick(option.value, isSelected);
                                }}
                                disabled={isDisabled}
                                centered={false}
                                labelPosition="append"
                                initialIsSelected={initialIsSelected}
                            />
                        );
                    })}
                </div>
            </div>
        );
    },
);

CheckboxGroup.displayName = 'CheckboxGroup';

export default CheckboxGroup;
