import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    proxifyDigitalcluesAssetFilepathIfNeeded,
    transformProfileFieldsToInfoBlockItem,
} from '_utils';
import { ResultsProps } from '../Results';
import {
    Button,
    Card,
    Error,
    Headline,
    LoadingSpinner,
    Modal,
    Paragraph,
    Tag,
    TextArea,
} from '_atoms';
import { Alert, ImageSlider, InfoBlockItem } from '_molecules';
import { SocialMediaAccount, SocialMediaContacts } from '_organisms';
import { ProfileData } from './ProfileData';
import classnames from 'classnames';
import { filterOutPosts, mapTargetResultsToJobInfoItems } from './helpers';
import { DefaultPageLayout } from '../../../components/Layout/DefaultPageLayout';

export const Profile: FC<ResultsProps & { accessToken: string }> = ({
    accessToken,
    targetProfile,
    targetCompanies,
    targetLoading,
    targetError,
    targetImages,
}) => {
    const { t } = useTranslation();

    const [
        friendsAndInteractantsModalOpen,
        setFriendsAndInteractantsModalOpen,
    ] = useState(false);

    const profileData = targetProfile?.profile?.data;

    const socialMediaAccounts = useMemo(
        () =>
            (
                profileData?.usernames?.map((usernameEntry) => ({
                    name: usernameEntry.value?.name,
                    imageUrl:
                        usernameEntry.value.profilePicturePath &&
                        proxifyDigitalcluesAssetFilepathIfNeeded(
                            usernameEntry.value.profilePicturePath,
                            accessToken,
                        ),
                    profileUrl: usernameEntry.value.profileUrl,
                    sources: usernameEntry.sources,
                })) ?? []
            ).filter(filterOutPosts),
        [accessToken, profileData?.usernames],
    );

    const socialAccounts = socialMediaAccounts.filter(
        ({ name, sources }) =>
            !/^\d+$/.test(name) || sources.includes('userInput'),
    );
    const potentialSocialAccounts = socialMediaAccounts.filter(
        ({ name, sources }) =>
            /^\d+$/.test(name) && !sources.includes('userInput'),
    );

    const jobs = mapTargetResultsToJobInfoItems(
        t,
        targetCompanies?.companies ?? [],
    );

    const profileCard = () => {
        return (
            <Card className={classnames('px-6 py-5', 'ml-3 print:ml-0')}>
                <div className="w-full p-2">
                    <ProfileData
                        data={profileData}
                        jobs={jobs}
                        skills={profileData?.skills}
                        hobbies={profileData?.hobbies}
                        description={profileData?.description}
                    />
                </div>
            </Card>
        );
    };

    return (
        <DefaultPageLayout title={t('personalData')}>
            {(targetLoading || profileData === undefined) && (
                <LoadingSpinner message={t('profileLoading')} />
            )}
            {!targetLoading && targetError && (
                <Error
                    headline={t('profileErrorHeadline')}
                    message={t('profileErrorMessage')}
                />
            )}
            {!!(!targetLoading && !targetError && profileData) && (
                <>
                    {targetProfile?.status === 'HasInitialProfile' && (
                        <Alert
                            alertType="warning"
                            headline={t('profileDataNotFinal')}
                            className="mt-8"
                        />
                    )}
                    <div className="space-y-4">
                        <div className="flex flex-row">
                            <div className="w-1/4 print:hidden">
                                <Card className="p-3">
                                    <ImageSlider images={targetImages} />
                                </Card>
                            </div>
                            <div className="w-3/4 print:w-full">
                                {profileCard()}
                            </div>
                        </div>
                        <Card className="px-6 py-5">
                            <div className="grid grid-cols-3 gap-4 py-4 w-full">
                                {transformProfileFieldsToInfoBlockItem(
                                    profileData,
                                    ['phones', 'emails', 'websites'],
                                ).map((info, index) => (
                                    <InfoBlockItem
                                        key={index}
                                        info={info}
                                        noDataComponent={
                                            <Tag>{t('noData')}</Tag>
                                        }
                                        className="print:overflow-hidden"
                                    />
                                ))}
                            </div>
                        </Card>
                        {socialMediaAccounts.length > 0 && (
                            <>
                                <div className="page-break"></div>
                                <Card className="px-6 py-5">
                                    <div className="row pb-4">
                                        <Headline Level="h4" className="mb-1">
                                            {t('socialMediaSection')}
                                        </Headline>
                                    </div>
                                    <div
                                        className={classnames(
                                            'py-4 w-full',
                                            socialAccounts.length &&
                                                potentialSocialAccounts.length &&
                                                'grid grid-cols-2 gap-4',
                                        )}
                                    >
                                        {socialAccounts.length > 0 && (
                                            <div>
                                                <Paragraph
                                                    size="label"
                                                    weight="bold"
                                                >
                                                    {t(
                                                        'filters.mainSocialMediaProfiles',
                                                    )}
                                                </Paragraph>
                                                {socialAccounts.map(
                                                    (
                                                        {
                                                            name,
                                                            imageUrl,
                                                            profileUrl,
                                                            sources,
                                                        },
                                                        index,
                                                    ) => (
                                                        <SocialMediaAccount
                                                            key={[
                                                                name,
                                                                index,
                                                            ].join('-')}
                                                            name={name}
                                                            imageUrl={imageUrl}
                                                            profileUrl={
                                                                profileUrl
                                                            }
                                                            sources={sources}
                                                        />
                                                    ),
                                                )}
                                            </div>
                                        )}
                                        {potentialSocialAccounts.length > 0 && (
                                            <div>
                                                <Paragraph
                                                    size="label"
                                                    weight="bold"
                                                >
                                                    {t(
                                                        'filters.potentialSocialMediaProfiles',
                                                    )}
                                                </Paragraph>
                                                {potentialSocialAccounts.map(
                                                    (
                                                        {
                                                            name,
                                                            imageUrl,
                                                            profileUrl,
                                                            sources,
                                                        },
                                                        index,
                                                    ) => (
                                                        <SocialMediaAccount
                                                            key={[
                                                                name,
                                                                index,
                                                            ].join('-')}
                                                            name={name}
                                                            imageUrl={imageUrl}
                                                            profileUrl={
                                                                profileUrl
                                                            }
                                                            sources={sources}
                                                        />
                                                    ),
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    {/* TODO: Make this a bit nicer with someone from UX */}
                                    <Button
                                        onClick={() =>
                                            setFriendsAndInteractantsModalOpen(
                                                true,
                                            )
                                        }
                                    >
                                        {t(
                                            'socialMedia.friendsAndInteractants',
                                        )}
                                    </Button>
                                    <Modal
                                        maxWidth="max-w-4xl"
                                        isOpen={friendsAndInteractantsModalOpen}
                                        title={t(
                                            'socialMedia.friendsAndInteractants',
                                        )}
                                        onClose={() =>
                                            setFriendsAndInteractantsModalOpen(
                                                false,
                                            )
                                        }
                                    >
                                        <SocialMediaContacts />
                                    </Modal>
                                </Card>
                            </>
                        )}
                        {(profileData.note?.value?.length ?? 0) > 0 && (
                            <Card className="px-6 py-5">
                                <div className="row pb-4">
                                    <Headline Level="h4" className="mb-1">
                                        {t('note')}
                                    </Headline>
                                </div>
                                <TextArea disabled>
                                    {profileData.note?.value}
                                </TextArea>
                            </Card>
                        )}
                    </div>
                </>
            )}
        </DefaultPageLayout>
    );
};
