import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Alert } from '_molecules';
import { ResultsProps } from '../Results';
import { Error, LoadingSpinner } from '_atoms';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { nonProdDataTestId } from '_utils';
import { DefaultPageLayout } from '../../../components/Layout/DefaultPageLayout';
import {
    toElasticFilter,
    toElasticQuery,
    useElasticSearchQuery,
} from '../../../hooks/useElasticSearchQuery';
import * as RawSearch from '_organisms/RawSearch';
import { buildIndicatorsMap } from '../AllDataSearch/enrichArticleWithIndicators';
import { buildHighlightChunks } from '../../../components/_atoms/Highlight/public-utils';
import { SuspicionDetails } from '../RiskAnalysis/SuspicionDetails';

export type SearchEnginesProps = ResultsProps & { accessToken: string };

type SearchFilters = {
    createdDate?: {
        from?: Date;
        to?: Date;
    };
    sources?: string[];
    exactMatch?: boolean;
    search?: string;
};

// This is the component rendering the contents of the Search Engines tab in the raw data section of a report
export const SearchEngines: FC<SearchEnginesProps> = ({
    targetCompanies,
    targetProfile,
    caseData,
    entityIndicators,
}: SearchEnginesProps) => {
    const { t } = useTranslation();
    const { caseId, targetId } =
        useParams<{
            caseId: string;
            targetId: string;
        }>();

    const { getFilter, onFilterChange } =
        RawSearch.useRawFilters<SearchFilters>();

    const createdDate = getFilter('createdDate');

    const {
        data,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
        isLoading,
        isFetching,
        isError,
    } = useElasticSearchQuery({
        params: {
            caseId,
            targetId,
            entityType: 'WebPage',
            exactMatch: getFilter('exactMatch'),
            query: getFilter('search'),
        },
        body: {
            sources: toElasticQuery(getFilter('sources')),
            createdDate: toElasticFilter({
                gte: createdDate?.from,
                lte: createdDate?.to,
            }),
        },
    });

    const indicatorsById = useMemo(
        () => buildIndicatorsMap(entityIndicators),
        [entityIndicators],
    );

    const articles = useMemo(
        () =>
            (data?.pages.flatMap((page) => page.items) ?? []).map(
                (article) => ({
                    ...article,
                    indicators:
                        indicatorsById.get(article.entity.value.id) ?? [],
                }),
            ),
        [data, indicatorsById],
    );

    const articleMeta = data?.pages.at(-1);

    const handlePageChange = useCallback(() => {
        if (hasNextPage) {
            fetchNextPage();
        }
    }, [hasNextPage, fetchNextPage]);

    const [activeArticleId, setActiveArticleId] =
        useState<string | undefined>();

    const firstArticleId = articles?.at(0)?.entity.value.id;

    useEffect(() => {
        if (firstArticleId) {
            setActiveArticleId(firstArticleId);
        }
    }, [firstArticleId]);

    const selectedArticle = articles?.find(
        (article) => article.entity.value.id === activeArticleId,
    );

    const pagination = articleMeta?.paging
        ? {
              ...articleMeta?.paging,
              handlePageChange,
          }
        : undefined;

    const highlightChunks = buildHighlightChunks({
        searchQuery: getFilter('search'),
        targetCompanies,
        targetProfile,
        caseKeywords: caseData?.keywords,
    });

    const sourceOptions = useMemo(
        () => [
            {
                label: t('filters.Google'),
                value: 'google',
            },
        ],
        [t],
    );

    return (
        <DefaultPageLayout title={t('rawData.searchEngines')}>
            <RawSearch.SearchPanel
                onSearchChange={(value) => onFilterChange('search', value)}
                searchValue={getFilter('search')}
            >
                <RawSearch.Checkbox
                    value={Boolean(getFilter('exactMatch'))}
                    onChange={(value) => onFilterChange('exactMatch', value)}
                    label={t('exactSearch')}
                />
                <RawSearch.DateRange
                    value={getFilter('createdDate') ?? {}}
                    onChange={(value) => onFilterChange('createdDate', value)}
                />
                <RawSearch.OptionsList
                    options={sourceOptions}
                    onChange={(value) => onFilterChange('sources', value)}
                    value={getFilter('sources') ?? []}
                    title={t('filters.type')}
                />
            </RawSearch.SearchPanel>
            {targetProfile?.status === 'HasInitialProfile' && (
                <Alert
                    alertType="warning"
                    headline={t('profileDataNotFinal')}
                    className="mb-2"
                />
            )}
            <div
                className="relative mt-6 flex flex-col gap-4"
                data-testid={nonProdDataTestId('search-engines')}
            >
                {isLoading ? (
                    <div className="absolute top-0 flex justify-center w-full py-20">
                        <LoadingSpinner message={t('profileLoading')} />
                    </div>
                ) : isError ? (
                    <Error
                        headline={t('profileErrorHeadline')}
                        message={t('profileErrorRetryMessage')}
                    />
                ) : null}
                {articles.length ? (
                    <SuspicionDetails
                        isLoading={isFetching || isFetchingNextPage}
                        articles={articles}
                        selectedArticle={selectedArticle}
                        selectArticle={setActiveArticleId}
                        pagination={pagination}
                        isNextPageLoading={isFetchingNextPage}
                        highlightChunks={highlightChunks}
                    />
                ) : isLoading ? null : (
                    <Alert
                        alertType="info"
                        message={t('noData')}
                        className="mb-5"
                    />
                )}
            </div>
        </DefaultPageLayout>
    );
};
