import { FC } from 'react';
import { ChecklistData } from 'src/types/ChecklistData';
import { FormLabel, Headline } from '_atoms';
import { parseISO, format } from 'date-fns';

export const SignedChecklistDetails: FC<{
    checklist: ChecklistData;
}> = ({ checklist }) => {
    const fullname = [
        checklist.finalizedBy?.firstname,
        checklist.finalizedBy?.lastname,
    ]
        .filter(Boolean)
        .join(' ');
    const formattedDateTime = checklist.updatedAt
        ? format(parseISO(checklist.updatedAt), "dd.MM.yyyy '@' HH:mm")
        : '';

    return (
        <>
            {checklist.data.fields.map(
                ({ type, name, label, value = [], options = [] }) => {
                    if (type === 'title') {
                        return (
                            <Headline key={name} Level="h3" className="mb-4">
                                {label}
                            </Headline>
                        );
                    }
                    if (type === 'description') {
                        return (
                            <p key={name} className="mb-4">
                                {label}
                            </p>
                        );
                    }
                    if (type === 'text') {
                        return (
                            <div key={name} className="mb-4">
                                <FormLabel>{label}</FormLabel>
                                <p className="font-jost text-neutral-500">
                                    {value}
                                </p>
                            </div>
                        );
                    }
                    if (type === 'textarea') {
                        return (
                            <div key={name} className="mb-4">
                                <FormLabel>{label}</FormLabel>
                                <p className="font-jost text-neutral-500">
                                    {value}
                                </p>
                            </div>
                        );
                    }
                    if (type === 'checkbox') {
                        return (
                            <div key={name} className="mb-4">
                                <FormLabel>{label}</FormLabel>
                                <p className="font-jost text-neutral-500">
                                    {options
                                        ?.filter((option) =>
                                            value?.includes(option.value),
                                        )
                                        ?.map((option) => option.label)
                                        .join(', ')}
                                </p>
                            </div>
                        );
                    }
                    if (type === 'radio') {
                        return (
                            <div key={name} className="mb-4">
                                <FormLabel>{label}</FormLabel>
                                <p className="font-jost text-neutral-500">
                                    {
                                        options?.find(
                                            (option) =>
                                                option.value === value?.at(0),
                                        )?.label
                                    }
                                </p>
                            </div>
                        );
                    }
                    return <div key={name}>Unknown field type</div>;
                },
            )}
            <div className={'bg-neutral-400 w-full h-[1px] mb-4'} />
            <p className="font-bold font-jost text-neutral-500">
                Finalized by:
            </p>
            <span>
                {fullname} - {formattedDateTime}
            </span>
        </>
    );
};
