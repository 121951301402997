import { FC } from 'react';
import { TooltipContentV2, TooltipTriggerV2, TooltipV2 } from '_atoms';
import { ReactComponent as WarningIcon } from '_assets/icons/exclamation.svg';
import { DonutPieChart } from '_organisms/Charts/DonutPieChart';
import { ENTITIES_THRESHOLD, generateColors } from '../helpers';
import { useTranslation } from 'react-i18next';
import { DonutChartStatisticProps } from './CvStatisticChart';
import { StatisticType } from '../../types';
import { IconType } from 'react-icons';

export const HeaderMessage: FC = () => {
    const { t } = useTranslation();

    return (
        <div className="flex text-warning-2 text-sm border border-warning-1 bg-warning-1 bg-opacity-10 rounded-md p-3">
            <WarningIcon className="w-4 h-4 mr-2.5" />
            {t('cVAnalysis.infoMessage')}
        </div>
    );
};

export const CvStatisticHeader: FC<{
    title: string;
    showWarning: boolean;
    warningMessage: string;
}> = ({ title, showWarning, warningMessage }) => {
    return (
        <div className="flex justify-between">
            <span
                className="font-jost text-lg text-primary-2 text-ellipsis line-clamp-1"
                title={title}
            >
                {title}
            </span>

            {showWarning && (
                <TooltipV2 placement="right" withArrow={true}>
                    <TooltipTriggerV2 className="text-warning-2">
                        <WarningIcon className="w-4 h-4 m-0.5" />
                    </TooltipTriggerV2>
                    <TooltipContentV2 className="max-w-60">
                        {warningMessage}
                    </TooltipContentV2>
                </TooltipV2>
            )}
        </div>
    );
};

export const CvStatisticNumeric: FC<{
    value: number;
    unit?: string;
    isMissingData: boolean;
}> = ({ isMissingData, value, unit }) => {
    return (
        <>
            {isMissingData ? (
                <CvStatisticQuestionMark />
            ) : (
                <>
                    <span className="motion-safe:animate-drop font-jost text-h3 font-bold text-contrast-3">
                        {value}
                    </span>
                    {unit && (
                        <span className="motion-safe:animate-drop font-jost text-h5 font-bold text-contrast-3">
                            {unit}
                        </span>
                    )}
                </>
            )}
        </>
    );
};

export const CvStatisticSymbol: FC<{
    icon?: IconType;
}> = ({ icon: Icon }) => {
    if (!Icon) {
        return <CvStatisticQuestionMark />;
    }

    return (
        <span className="motion-safe:animate-drop font-jost font-bold text-contrast-3">
            <Icon className="h-10 w-10" />
        </span>
    );
};

export const CvStatisticQuestionMark: FC = () => {
    return (
        <span className="motion-safe:animate-drop font-jost text-h3 font-bold text-contrast-3">
            {'?'}
        </span>
    );
};

export const CvStatisticContent: FC<{
    isMissingData?: boolean;
    missingDataMessage: string;
    chart: StatisticType;
}> = ({ isMissingData, missingDataMessage, chart }) => {
    const content =
        chart.kind === 'donutChart' || chart.kind === 'numerical'
            ? chart.data.content.slice(0, 4)
            : [];

    return (
        <>
            {(isMissingData ||
                ((chart.kind === 'donutChart' || chart.kind === 'numerical') &&
                    content.length === 0)) && (
                <span className="text-neutral-450 text-sm text-ellipsis line-clamp-2">
                    {missingDataMessage}
                </span>
            )}
            {!isMissingData &&
                (chart.kind === 'donutChart' || chart.kind === 'numerical') && (
                    <div
                        className={`grid gap-2 ${
                            content.length ? 'grid-cols-2' : ''
                        }`}
                    >
                        {content.map((entry, index) => (
                            <div
                                key={index}
                                className="flex items-center gap-1"
                            >
                                {(index !== content.length - 1 ||
                                    content.length === 1) && (
                                    <div className="w-1.5 h-1.5 rounded-full flex-shrink-0 bg-contrast-3" />
                                )}
                                <span
                                    className="text-neutral-450 leading-none text-sm text-ellipsis line-clamp-1"
                                    title={
                                        index === content.length - 1 &&
                                        content.length !== 1
                                            ? '...'
                                            : entry
                                    }
                                >
                                    {/*TODO: on the last element show the text 'More' that has different color than the rest
                         is clickable, and on click opens a modal that will display the full list*/}
                                    {index === content.length - 1 &&
                                    content.length !== 1
                                        ? '...'
                                        : entry}
                                </span>
                            </div>
                        ))}
                    </div>
                )}
            {!isMissingData && chart.kind === 'symbol' && (
                <div className="font-jost text-xl text-primary-2 text-ellipsis line-clamp-1">
                    {chart.data.value}
                </div>
            )}
        </>
    );
};

export const CvStatisticChartElement: FC<{
    index: number;
    name: string;
    unit?: string;
    value: number;
    colors: string[];
}> = ({ index, colors, value, unit, name }) => {
    return (
        <div key={`${name}${value}`} className="flex gap-2 items-start">
            <span
                className="w-2 h-2 rounded-full flex-shrink-0 mt-1.5"
                style={{
                    backgroundColor: colors[index],
                }}
            />
            <div className="flex flex-wrap items-start gap-1">
                <span className="text-gray-600 text-base">{name}</span>
                <span className="text-neutral-500 text-sm">
                    {value}
                    {unit ? ` ${unit}` : ''}
                </span>
            </div>
        </div>
    );
};

const firstHalf = <T,>(data: T[]): T[] =>
    data.slice(0, Math.ceil(data.length / 2));

const secondHalf = <T,>(data: T[]): T[] =>
    data.slice(Math.ceil(data.length / 2));

export const CVChartModal: FC<DonutChartStatisticProps> = ({
    data,
    labels,
}) => {
    const { showWarning, unit, data: _data } = data;
    const { warningMessage } = labels;

    const { t } = useTranslation();
    const colors = generateColors(_data.length);

    return (
        <div className="flex flex-col gap-3">
            {showWarning && (
                <div className="flex flex-row gap-3 items-center justify-start pb-1 text-error-2">
                    <WarningIcon className="w-4 h-4" />
                    <span className="font-normal text-sm text-neutral-500">
                        {t(warningMessage)}
                    </span>
                </div>
            )}
            <div className="flex flex-col gap-6 items-center bg-neutral-200 rounded-lg p-6">
                <DonutPieChart
                    data={_data}
                    colors={colors}
                    radius={100}
                    compactView={false}
                />
                <div className="flex w-full bg-neutral-100 rounded-lg p-6">
                    {/*TODO: fix this content here*/}
                    {_data.length <= ENTITIES_THRESHOLD ? (
                        <div className="flex w-full justify-center flex-col gap-1">
                            {_data.map((entry, index) => (
                                <CvStatisticChartElement
                                    key={index}
                                    index={index}
                                    name={entry.name}
                                    unit={
                                        unit
                                            ? t(`cVAnalysis.chartUnit.${unit}`)
                                            : ''
                                    }
                                    value={entry.value}
                                    colors={colors}
                                />
                            ))}
                        </div>
                    ) : (
                        <div className="flex gap-6 justify-between">
                            <div className="flex flex-col gap-1">
                                {firstHalf(_data).map((entry, index) => (
                                    <CvStatisticChartElement
                                        key={index}
                                        index={index}
                                        name={entry.name}
                                        unit={
                                            unit
                                                ? t(
                                                      `cVAnalysis.chartUnit.${unit}`,
                                                  )
                                                : ''
                                        }
                                        value={entry.value}
                                        colors={firstHalf(colors)}
                                    />
                                ))}
                            </div>
                            <div className="flex flex-col gap-1">
                                {secondHalf(_data).map((entry, index) => (
                                    <CvStatisticChartElement
                                        key={index}
                                        index={index}
                                        name={entry.name}
                                        unit={
                                            unit
                                                ? t(
                                                      `cVAnalysis.chartUnit.${unit}`,
                                                  )
                                                : ''
                                        }
                                        value={entry.value}
                                        colors={secondHalf(colors)}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
