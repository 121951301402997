import { Temporal } from 'temporal-polyfill';

export const toDate = (plainDate: Temporal.PlainDate): Date =>
    new Date(plainDate.year, plainDate.month - 1, plainDate.day);

export const fromDate = (date: Date): Temporal.PlainDate =>
    Temporal.PlainDate.from({
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
    });
