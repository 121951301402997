/* istanbul ignore file */
export const en = {
    translation: {
        requestSupportAgent: {
            emailSubject: `Expert assistance requested`,
            emailBody: `Dear Indicium,\nI would like to request expert assistance on the following report: {{link}}.\nPlease contact me with additional details.`,
            button: `Request expert assistance`,
            buttonPopover: `You can request additional assistance from an expert OSINT analyst. Additional charges apply.`,
        },
        checklists: {
            title: 'Checklist',
            resetForm: 'Reset form',
            sendForm: 'Send form',
            errorLoading: 'Error loading checklist templates',
            errorUpdatingChecklist: 'Error updating checklist',
            saved: 'The checklist has been saved',
            noChecklistTemplate:
                'No checklist template found, you can create a new checklist template at: ',
            finalize: 'Are you sure all your answers are correct?',
            finalizeDescription:
                'Please confirm only if you are 100% sure. This action cannot be undone.',
            finalizeConfirm: 'Yes, confirm',
            finalizeCancel: 'No, return to the form',
        },
        checklistTemplate: {
            saveTooltip:
                'Save this checklist template, existing reports will not be affected',
            errorLoading: 'Error loading checklist templates',
            errorUpdating: 'Error updating checklist template',
            errorCreating: 'Error creating checklist template',
            title: 'Checklist Template',
            reset: 'Reset',
            resetDescription:
                'This will reset the checklist template to its default state. You will lose all unsaved changes.',
            resetConfirm: 'Reset the template',
            save: 'Save',
            saved: 'The checklist template has been saved',
            newItem: 'New item',
            noFields:
                'The checklist template has no fields, please add some using the button below',
            addField: 'Select the type of field you want to add',
            validationErrorOptionsEmpty:
                'A multi-choice field is missing options',
            validationErrorLabelEmpty: 'Labels cannot be empty',
            box: {
                title: 'Title',
                titleDesc: 'A read-only header',
                description: 'Description',
                descriptionDesc: 'A read-only paragraph',
                text: 'Text',
                textDesc: 'An input field for text',
                textarea: 'Textarea',
                textareaDesc: 'A textarea field for longer text',
                checkbox: 'Multiple choice',
                checkboxDesc: 'Multiple choice field',
                radio: 'Single choice',
                radioDesc: 'Single choice field',
            },
            new: {
                title: 'New Title',
                description: 'New Description',
                text: 'New Text',
                textarea: 'New Textarea',
                checkbox: 'New Multiple Choice',
                radio: 'New Single Choice',
            },
        },
        checklistTemplateField: {
            title: {
                title: 'Title',
            },
            description: {
                title: 'Description',
            },
            text: {
                title: 'Text',
            },
            textarea: {
                title: 'Textarea',
            },
            checkbox: {
                title: 'Multiple choice',
                label: 'Label',
                options: 'Options',
                newOptionLabel: 'New option',
                addOption: 'Add option',
            },
            radio: {
                title: 'Single choice',
                label: 'Label',
                options: 'Options',
                newOptionLabel: 'New option',
                addOption: 'Add option',
            },
            required: 'Required',
            emptyOptions: 'This field type must have at least two options',
            empty: 'This field cannot be empty',
        },
        checklist: {
            empty: 'The checklist is empty, new fields you add will appear here',
        },
        note: 'Note',
        lexisNexis: 'LexisNexis',
        serpNews: 'Search Engine News',
        subscriptionAboutToExpire:
            'Dear Indicium customer, your subscription to the Indicium Digital Analyst expires in less than one week ({{expiration_date}}). We would be delighted if you would like to extend your subscription. Please get in touch with your contact to discuss the next steps.',
        subscriptionExpired:
            'Dear Indicium customer, your subscription to the Indicium Digital Analyst expired {{expiration_date}}. We would be delighted if you would like to extend your subscription. Please get in touch with your contact to discuss the next steps.',
        reportStatusOverview: {
            inProgress: {
                header: 'Data and suspicions are currently being fetched.',
                subHeader: 'The visible results are possibly incomplete.',
            },
            inProgressGenerationOnly: {
                header: 'Data fetching complete. The complete report is now being generated.',
                subHeader: 'The visible results are possibly still incomplete.',
            },
            completedWithoutSuspicions: {
                header: 'The report was completed successfully. No suspicions were found.',
                subHeader:
                    'Despite an extensive search of all selected data sources, we were unable to find any suspicions for this report.',
            },
            completedWithSuspicions: {
                header: 'The report was completed successfully. Suspicions were found.',
                subHeader:
                    'Potentially problematic content found - please check.',
            },
            withIrregularitiesWithoutSuspicions: {
                header: 'The report was completed with errors. No suspicions were found.',
                subHeader:
                    'This report may be incomplete due to an error while processing one or more sections. We were not able to find any suspicions in the sections that completed successfully.',
            },
            withIrregularitiesWithSuspicions: {
                header: 'The report was completed with errors. Suspicions were found.',
                subHeader:
                    'This report may be incomplete due to an error while processing one or more sections. However, we found potentially problematic content in the successfully completed sections - please check.',
            },
            steps: {
                ANALYZING_COMPANIES: 'Analyzing companies',
                ANALYZING_SOCIAL_MEDIA: 'Analyzing social media',
                ANALYZING_PRESS_WEBSITES: 'Analyzing press and websites',
                GENERATING_NETWORK_GRAPH: 'Generating the network graph',
                GENERATING_REPORT: 'Generating report',
            },
            stepsComplete: 'Steps completed',
            stepsFailed: 'Steps failed',
        },
        toasts: {
            createdTargets: `Created {{count}} new targets`,
            userUpdated: `User updated`,
            userUpdateFailed: 'User update failed',
            deleteUser: `User deleted`,
            deleteUserFailed: `User deletion failed`,
            userCreated: `User created`,
            caseCreated: `Case created`,
            deleteCase: `Case deleted`,
            deleteCaseFailed: `Case deletion failed`,
            createdTarget: `Target created`,
            deleteTarget: `Target deleted`,
            deleteTargetFailed: `Target deleted failed`,
            suspicionStatusUpdated: `Suspicion edited`,
            suspicionItemLinked: `Suspicion edited`,
            mfaDisabled: `MFA disabled`,
        },
        confirmationModal: {
            defaultTitle: 'Are you sure you want to delete?',
            defaultBody: 'This action cannot be undone.',
        },
        confirmAndAddNew: 'Confirm and add new',
        confirm: 'Confirm',
        cancel: 'Cancel',
        current: 'Current',
        in: 'in',
        targetWizard: {
            headline: 'Create applicant',
            targetCreationInstructionMsg:
                'You can either upload a PDF (resume) here or enter the data manually in the left pane.',
            formInstructionMsg: `To add more data, click the type of data you want to add in the cards below.`,
            formDropDownCopyMsg: `Add New detail about candidate from dropdown above.`,
            fillFormManually: 'Enter manually',
            fields: {
                personalDetails: {
                    title: 'Personal details',
                    description: 'Name, title & gender',
                    helpText: 'Add the known personal details of the applicant',
                },
                birthInfo: {
                    title: 'Birth data',
                    description: 'Date & place of Birth',
                    helpText:
                        "Add all known information regarding the applicant's birth",
                },
                jobs: {
                    title: 'Professional experience',
                    description: 'Companies applicant is associated with',
                    helpText: 'Add professional experience for the applicant',
                },
                residentialInfo: {
                    title: 'Place of residence',
                    description: 'Current or recent place of residence',
                    helpText:
                        'Add the known places of residence of the applicant',
                },
                nationalities: {
                    title: 'Nationalities',
                    description: 'Countries of citizenship',
                    helpText: 'Add the known nationalities of the applicant',
                },
                contactEmails: {
                    title: 'Emails',
                    description: '...form of contact to the applicant',
                    helpText: 'Add the known email addresses of the applicant',
                },
                contactPhones: {
                    title: 'Phones',
                    description: '...form of contact to the applicant',
                    helpText: 'Add the known phone numbers of the applicant',
                },
                contactWebsites: {
                    title: 'Websites',
                    description: '...form of contact to the applicant',
                    helpText:
                        'Add the known websites of the applicant and their companies',
                },
                educationInfo: {
                    title: 'Education',
                    description: 'Educational institutions attended',
                    helpText:
                        "Add the known information about the applicant's education",
                },
                linkedinLinks: {
                    title: 'LinkedIn',
                    description:
                        'Professional social media profiles of the applicant',
                    helpText:
                        'Add the known LinkedIn profiles of the applicant',
                },
                xingLinks: {
                    title: 'Xing',
                    description:
                        'Professional social media profiles of the applicant',
                    helpText: 'Add the known Xing profiles of the applicant',
                },
                facebookLinks: {
                    title: 'Facebook',
                    description: 'Social media profiles of the applicant',
                    helpText:
                        'Add the known Facebook profiles of the applicant',
                },
                twitterLinks: {
                    title: 'X (Twitter)',
                    description: 'Social media profiles of the applicant',
                    helpText:
                        'Add the known X (Twitter) profiles of the applicant',
                },
                instagramLinks: {
                    title: 'Instagram',
                    description: 'Social media profiles of the applicant',
                    helpText:
                        'Add the known Instagram profiles of the applicant',
                },
                images: {
                    title: 'Images',
                    description: 'images information to be added',
                    helpText: 'Add any images you might have of the applicant',
                },
                keywords: {
                    title: 'Keywords',
                    description:
                        '...to be included or excluded from the search results',
                    helpText:
                        'Add any keywords that should specifically be included or excluded when searching for the applicant',
                },
                note: {
                    title: 'Note',
                    description: 'Additional information',
                    helpText:
                        'Add any additional information about the applicant, it will not impact the report',
                },
                skills: {
                    title: 'Skills',
                    description: 'Skills applicant is associated with',
                    helpText: 'Add skill for the applicant',
                },
                hobbies: {
                    title: 'Hobbies',
                    description: 'Hobbies target is associated with',
                    helpText: 'Add hobby for the applicant',
                },
                description: {
                    title: 'Self description',
                    description:
                        'Self description applicant is associated with',
                    helpText: 'Add self description for the applicant',
                },
            },
            editSectionBtn: 'Edit section',
            deleteSectionBtn: 'Delete section',
            createTargetBtn: 'Create candidate report',
            resetForm: 'Reset Form',
            unsavedChangesTitle:
                'You have unsaved changes. Are you sure you want to cancel?',
            resetFormConfirmation:
                'Are you sure you want to delete all entered data?',
        },
        industryOptions: {
            agriculture_and_farming: 'Agriculture and Farming',
            aerospace_and_defense: 'Aerospace and Defense',
            artificial_intelligence_and_machine_learning:
                'Artificial Intelligence and Machine Learning',
            architecture_and_urban_planning: 'Architecture and Urban Planning',
            automotive: 'Automotive',
            biotechnology: 'Biotechnology',
            chemicals_and_petrochemicals: 'Chemicals and Petrochemicals',
            consumer_electronics: 'Consumer Electronics',
            consulting: 'Consulting',
            construction: 'Construction',
            cybersecurity: 'Cybersecurity',
            digital_marketing_and_advertising:
                'Digital Marketing and Advertising',
            e_commerce: 'E-commerce',
            education: 'Education',
            electronics_and_semiconductors: 'Electronics and Semiconductors',
            energy: 'Energy (Oil, Gas, Renewable)',
            entertainment_and_media: 'Entertainment and Media',
            environmental_services:
                'Environmental Services (Waste Management, Recycling)',
            event_planning_and_management: 'Event Planning and Management',
            fashion_and_apparel: 'Fashion and Apparel',
            finance_and_banking: 'Finance and Banking',
            fitness_and_wellness: 'Fitness and Wellness',
            food_and_beverage: 'Food and Beverage',
            forestry_and_timber: 'Forestry and Timber',
            gaming_and_esports: 'Gaming and Esports',
            healthcare: 'Healthcare',
            hospitality: 'Hospitality',
            human_resources_and_recruitment: 'Human Resources and Recruitment',
            information_technology: 'Information Technology',
            interior_design_and_home_improvement:
                'Interior Design and Home Improvement',
            insurance: 'Insurance',
            legal_services: 'Legal Services',
            logistics_and_supply_chain: 'Logistics and Supply Chain',
            marine_and_maritime: 'Marine and Maritime',
            mining_and_metals: 'Mining and Metals',
            music_and_arts: 'Music and Arts',
            nonprofit: 'Nonprofit and Charitable Organizations',
            personal_care_and_beauty: 'Personal Care and Beauty',
            pharmaceuticals: 'Pharmaceuticals',
            printing_and_publishing: 'Printing and Publishing',
            public_administration_and_government:
                'Public Administration and Government',
            real_estate: 'Real Estate',
            research_and_development: 'Research and Development (R&D)',
            retail: 'Retail',
            space_exploration_and_technology:
                'Space Exploration and Technology',
            sports_and_recreation: 'Sports and Recreation',
            telecommunications: 'Telecommunications',
            textile_manufacturing: 'Textile Manufacturing',
            transportation: 'Transportation (Aviation, Rail, Maritime)',
            travel_and_tourism: 'Travel and Tourism',
            venture_capital_and_private_equity:
                'Venture Capital and Private Equity',
        },
        educationLevelOptions: {
            primary_school: 'Primary School',
            secondary_school: 'Secondary School',
            high_school_diploma: 'High School Diploma',
            associate_degree: 'Associate Degree',
            online_course: 'Online Course',
            bachelors_degree: "Bachelor's Degree",
            masters_degree: "Master's Degree",
            mba: 'MBA',
            phd: 'PhD',
        },
        bulkUpload: {
            title: 'CV Upload',
            subtitle: `You can upload a maximum of {{max}} CV's at the same time, you have {{count}} remaining`,
            error: 'An error occurred',
            uploading: 'Uploading file...',
            submitting: 'Processing file...',
            processed: 'File processed',
            success: 'File uploaded',
            noFilesLeft: `You can't upload more files`,
            dragAndDrop1: 'Drag and drop one or more PDF files here',
            dragAndDrop2: 'or click here to browse',
            cancel: 'Cancel',
            create: 'Create',
            fileAlreadyUploaded: 'A file with the same name already exists',
            fileTooLarge: 'File is larger than 25MB',
        },
        noSuspicionsDescription: `Despite an extensive search of all selected data sources, we were unable to find any suspicions for this report.`,
        ourDataSources: 'Our data sources',
        search: 'Search...',
        startTypingToSearch: 'Start typing to search',
        stats: {
            over: 'Over',
            billion: 'B',
            million: 'M',
            identities: 'Identities',
            phoneNumbers: 'Phone numbers',
            globalEmails: 'Email addresses',
            uniqueNewsArticlesAndSocials:
                'News articles and social media posts',
            onlineNewsSources: 'Online news sources',
            socialMediaFeeds: 'Social media feeds',
            globalCompanyEntries: 'Global companies',
        },
        printHiddenElements:
            'Some elements are not shown on this print report, please check the online version for more details.',
        preparingPrint:
            'We are preparing the print version of your report. Please hang tight.',
        printVersionNotice: {
            first: 'This is a print version of an Indicium report. By using this version you agree to the terms of use and privacy policy of Indicium. Unauthorized distribution or reproduction of this report is prohibited.',
            second: 'Report print generated on $$printversion by $$customerId.',
        },
        printTooltip:
            'Print this report or export it as a pdf (you can also use Control+P)',
        dismiss: 'Dismiss',
        spotlight: {
            startTutorial: 'Start tutorial',
            onboardingLeftMenuLogoHeading: 'Welcome to Indicium',
            onboardingLeftMenuLogoDescription:
                'You can return to the home screen at any point by clicking on the Indicium logo.',
            onboardingTopMenuBreadcrumsHeading: 'Navigation',
            onboardingTopMenuBreadcrumsDescription:
                'This navigation lets you know exactly where you always are. You can navigate back at any point.',
            onboardingTopMenuLanguageHeading: 'Language',
            onboardingTopMenuLanguageDescription:
                'Select your preferred language here.',
            onboardingTopMenuProfileHeading: 'Profile',
            onboardingTopMenuProfileDescription:
                'Click here to navigate to your profile settings. You can enable or disable 2FA and change your password.',
            onboardingLandingPageSearchHeading: 'Global Search',
            onboardingLandingPageSearchDescription:
                'You can search all exisiting positions and applicants using this search bar. By clicking on one of the results, you can directly navigate to the selected entry.',
            onboardingLeftMenuCasesHeading: 'Position Overview',
            onboardingLeftMenuCasesDescription:
                'This is where you can find all positions you have access to. You can create new positions, edit existing ones, and delete them.',
            onboardingCaseListCreatePositionHeading: 'Create a new position',
            onboardingCaseListCreatePositionDescription:
                'A position needs to be created first before an applicant report can be created. A position groups all applicants for it. All applicants belonging to the same position will be based on the same sources and analysis.',
            onboardingCaseNewOptionsHeading: 'Report Selection',
            onboardingCaseNewOptionsDescription:
                'Indicium provides 3 options for reports with different analysis and sources. From left to right the reports contain more data and use more sources. Please make sure you select the correct report type based on the position you are hiring for, as well as the consent you are having.',
            onboardingCaseNewContinueHeading: 'Continue',
            onboardingCaseNewContinueDescription:
                'Click on the continue button to proceed to the next step.',
            onboardingCaseNewTitleHeading: 'Position title',
            onboardingCaseNewTitleDescription:
                'Enter a title for the position. This title will be displayed in the overview.',
            onboardingTargetFormUploadPdfHeading: 'Upload PDF',
            onboardingTargetFormUploadPdfDescription:
                'You can upload a cv as a pdf document here. The pdf will be automatically parsed and all relevant data will be extracted into the form. Dragging and dropping a file works as well.',
            onboardingTargetFormBasicDataHeading: 'Basic data',
            onboardingTargetFormBasicDataDescription:
                'You need to provide the basic data of the applicant here.',
            onboardingTargetFormCompanyDataHeading: 'Company data',
            onboardingTargetFormCompanyDataDescription:
                'You also need to provide the company data of the applicant here.',
            onboardingTargetFormNextHeading: 'Request candidates',
            onboardingTargetFormNextDescription:
                'Click on the button to request any applicants that match the provided data. Remember, the more information you provide, the better the results will be.',
            onboardingTargetCandidatesunselectedCandidatesHeading: 'Unselected',
            onboardingTargetCandidatesunselectedCandidatesDescription:
                'In this tab you can see all the results that have been found but could not be automatically matched or discarded. You can manually include or exclude them.',
            onboardingTargetCandidatesdecidedCandidatesHeading: 'Decided',
            onboardingTargetCandidatesdecidedCandidatesDescription:
                'In this tab you can see all the results that have been matched or discarded, an automatic selection was already made for you based on the provided data but you can choose to manually edit the list.',
            onboardingTargetCandidatesExcludeHeading: 'Exclude',
            onboardingTargetCandidatesExcludeDescription:
                'Click on the exclude button to exclude the current candidate from the list.',
            onboardingTargetCandidatesIncludeHeading: 'Include',
            onboardingTargetCandidatesIncludeDescription:
                'Click on the include button to include the current candidate from the list.',
            onboardingTargetCandidatesRequestHeading: 'Request Report',
            onboardingTargetCandidatesRequestDescription:
                'Finally, click on the request report button to generate the report using the candidates you selected.',
        },
        doYouNeedHelp: 'Do you need help?',
        unknown: 'unknown',
        unknownPosition: 'Unknown Position',
        unknownCompany: 'Unknown Company',
        schemaValidation: {
            invalidDate: 'Invalid date',
            invalidDateStart: 'Invalid start date',
            invalidDateEnd: 'Invalid end date',
            invalidDateRange: 'Invalid date range',
            invalidDateRangeEndWithoutStart:
                'End date requires start date to be set',
            invalidStartDateGraterThanCurrentDate:
                'Start date cannot be later than current date',
            invalidEndDateGraterThanCurrentDate:
                'End date cannot be later than current date',
            invalidEmail: 'Invalid email address',
            invalidPhone: 'Invalid phone number',
            invalidUrl: 'Invalid URL',
            invalidSocialMediaUrl: {
                facebook: 'Invalid Facebook URL',
                instagram: 'Invalid Instagram URL',
                linkedin: 'Invalid Linkedin URL',
                xing: 'Invalid Xing URL',
                twitter: 'Invalid X URL',
            },
            invalidFacebookUrl: 'Invalid Facebook URL',
            invalidInstagramUrl: 'Invalid Instagram URL',
            invalidLinkedinUrl: 'Invalid Linkedin URL',
            invalidXingUrl: 'Invalid Xing URL',
            invalidTwitterUrl: 'Invalid X URL',
            tooFewCharacters: 'Too few characters',
            invalidNumber: 'Invalid number',
            invalidInteger: 'Invalid integer',
            invalidPositiveNumber: 'Number must be greater than 0',
            invalidCharacter: 'Invalid character',
            required: 'This field is required',
            toLessCharacters: 'Not enough characters',
            minimumCharacters: 'Needs to be at least {{minLength}} characters',
            maxLength:
                'Max length of the field exceeded ({{maxLength}} characters)',
        },
        passwordValidation: {
            min: 'Minimum {{minLength}} characters',
            oneNumber: 'Contains at least one number',
            oneSpecialChar: 'Contains at least one special character',
            oneUppercase: 'Contains at least one capital letter',
            oneLowercase: 'Contains at least one lowercase letter',
            passwordMatch: 'Passwords match',
        },
        targetNewForm: {
            targetCreationInProgress: 'The applicant is being created',
            targetDeletionNotAllowed:
                'The deletion of the applicant not allowed',
            dateOfBirthLabel: 'Date of birth or birth year',
            dateOfBirthPlaceholder: 'DD.MM.YYYY or YYYY',
        },
        dropPdfToUpload: 'Drop to upload PDF and generate new target',
        uploadPdf: 'Upload PDF',
        educationHeadline: 'Education',
        educationTitle: 'Title',
        educationTitlePlaceholder:
            'e.g. Master in Business Administration (MBA)',
        isCurrentInstitution: 'Current institution',
        educationType: 'Type',
        educationDate: 'Date (date, year, date range, year range)',
        educationInstitutionName: 'Institution',
        educationInstitutionLocation: 'Location',
        removeEntry: 'Remove entry',
        addEntry: 'Add entry',
        professionalExperienceHeadline: 'Professional experience',
        jobTitle: 'Job Title',
        jobDate: 'Date (date, year, date range, year range)',
        dateOrYear: 'Full date (DD.MM.YYYY) or year (YYYY)',
        dateRange: 'Date (date range, year range)',
        dateRangeStart: 'Start',
        dateRangeEnd: 'End',
        jobCompanyName: 'Company name',
        jobCompanyLocation: 'Company location',
        jobCompanyIndustry: 'Industry',
        educationLevel: 'Level',
        jobCompanyCity: 'Company city',
        selfEmployed: 'Self-employed',
        isCurrentPosition: 'Current position',
        jobCompanyWebsite: 'Company website',
        jobCompanyVatNumber: 'Company VAT number',
        jobCompanyCommercialRegisterNumber:
            'Company commercial register number',
        others: 'Others',
        required: 'Required field',
        requiredNameError: 'First and last name must be provided',
        requiredCompanyError: 'Company must be provided',
        emailAlreadyExists: 'The provided email address is already taken.',
        cvParsedWarning:
            'Some fields were parsed from a CV, please review them carefully',
        cvUploadFailed: 'The CV could not be uploaded, you may try again',
        autofilled: 'Autofilled',
        preselection: 'Sort remaining candidates',
        preselectionTitle: 'Selection',
        preselectionRunning: 'Automated selection of candidates in progress',
        preselectionFailed: 'Re-sorting failed',
        preselectionFinished: 'Re-sorting finished ',
        preselectionFinished2: ' new candidates were added',
        recommended: 'Recommended',
        back: 'Back',
        continue: 'Continue',
        getStarted: 'Get started',
        save: 'Save',
        show: 'Show',
        hide: 'Hide',
        showMore: 'Show more',
        showLess: 'Show less',
        download: 'Download',
        archive: 'Archive',
        delete: 'Delete',
        open: 'Open',
        edit: 'Edit',
        duplicate: 'Duplicate',
        admin: 'Admin',
        cases: 'Positions',
        user: 'User',
        users: 'Users',
        profileImage: 'Profile image',
        editProfile: 'Edit profile',
        editUserData: 'Edit your data',
        changePassword: 'Change password',
        emailPreferences: {
            groupLabel: 'GDPR-email preferences',
            groupLabelTooltip:
                'To remind you of the upcoming deletion according to GDPR, a reminder email will be sent for each report one week and again one day before. You can choose for which reports you want to receive these reminders.',
            allInOrg: 'Reminders for all reports',
            allInOrgTooltip:
                'All reports in the organizations in which I have admin rights + all reports I have created myself',
            ownReports: 'Reminders for only my reports',
            ownReportsTooltip: 'Only reports I have created myself',
            none: 'No reminders',
            noneTooltip:
                'I am aware of the deletion of raw data according to GDPR for reports 30 days after creation and do not want to receive email reminders.',
        },
        fallbackProfileImage: 'Fallback profile image',
        fallbackImageLabel: 'No image available',
        noResult: 'No result',
        loading: {
            searchingCandidates:
                'Searching data sources and compiling potential profiles.\nThis can take several minutes',
            collectingData:
                'Selection successfully submitted.\nChecking all sources and compiling final report.\nThis usually takes about an hour, you will be notified by email once the report is ready.',
        },
        moreInfo: 'More information',

        riskAnalysis: {
            title: 'Risk analysis',
            profileInfo: {
                createdOn: 'Created on',
            },
            suspicionRisk: {
                total: 'Total',
                totalRedFlags: 'Red Flags',
                totalConfirmed: 'confirmed',
                totalPending: 'pending',
                totalRemoved: 'removed',
                loadingMessage: 'Calculating total numbers of results',
                high: {
                    title: 'High severity',
                    tooltip:
                        'Content with high risk indicates that there is a concrete connection to one or more crimes, or that the person was very likely directly involved or even convicted. Additionally, all red flags found through the Compliance Catalyst are considered high risk.',
                },
                medium: {
                    title: 'Medium severity',
                    tooltip:
                        'Content with medium risk indicates that the person is suspected of having committed a crime. Additionally, any offensive behavior on social media channels is considered medium risk.',
                },
                low: {
                    title: 'Low severity',
                    tooltip:
                        'Content with low risk indicates that the person has been a victim of a crime. Additionally, personal keywords and general irregularities appear here.',
                },
                none: {
                    title: 'Undetermined severity',
                    tooltip:
                        'The system was unable to determine the severity of the content.',
                },
            },
            filters: {
                suspicion: 'Suspicion',
                participation: 'Participation',
                suspicionSource: 'Suspicion source',
                contentType: 'Content type',
                reviewStatus: 'Review status',
                resetAll: 'Reset All',
            },
            notFound: {
                headline: 'No Suspicions Found',
                message:
                    'Try to change your filter selection to find suspicions.',
            },
            articleMeta: {
                suspicions: 'Suspicions',
                suspicion: 'Suspicion',
                relatesTo: 'Content relates to:',
                systemSuspicions: 'Suspicions attributed by the system',
                userCreatedSuspicions: 'Suspicions attributed by users',
                editSuspicions: 'Edit Suspicions',
                suspicionStatus: 'Suspicion status',
                notConfirmed: 'Suspicion not confirmed',
            },
            employmentRole: {
                manager: 'Manager',
                employee: 'Employee',
            },
            articleDetails: {
                company: 'Company',
                role: 'Role',
                websites: 'Websites',
                phone: 'Phone',
                prevNames: 'Previous names',
                address: 'Address',
                companyActivity: 'Company activity',
                source: 'Source',
                pubDate: 'Publication Date',
                author: 'Author',
                retrievalDate: 'Retrieval Date',
                likes: 'likes',
            },
            editSuspicions: {
                title: 'Edit suspicions list',
                confirm: 'Confirm',
                reset: 'Reset',
                addToList: 'Add to list',
                selectSuspicion: 'Select one or more suspicions to edit',
                suspicionStatus: 'Suspicion status',
                suspicionDeleted: 'suspicion deleted',
                suspicionPending: 'suspicion not confirmed',
                suspicionConfirmed: 'suspicion confirmed',
                selectAll: 'Select all',
                unselectAll: 'Unselect all',
                successMessage: {
                    plural: 'Successfully changed {{count}} suspicions status',
                    singular: 'Successfully changed {{count}} suspicion status',
                },
                searchSuspicion: 'Search by suspicion name',
                addSuspicion: 'Add suspicions to the list',
            },
        },

        // Network Graph
        networkGraph: {
            searchPlaceholder: 'Search by name...',
            title: 'Network graph',
            graphDepth: 'Network depth',
            visibleDepth: 'Maximum visible graph depth:',
            onlyRedFlags: 'Hide nodes without red flags:',
            graphLevels: 'Level',
            unknownRole: 'Unknown Role',
            candidateRole: 'Candidate Role',
            executiveRole: 'Executive',
            employeeRole: 'Employee',
            companyStatus: 'Company Status',
            companyStatusActive: 'Active',
            companyStatusInactive: 'Inactive',
            companyStatusUnknown: 'Unknown',
            companyStatusLiquidation: 'Liquidation',
            companyStatusDissolved: 'Dissolved',
            companyStatusAll: 'All company statuses',
            hideControls: 'Automatically hide this controls',
            allFlags: 'All company flags',
            noRedFlags: 'Hide companies with red flags',
            redFlags: 'Only show companies with red flags',
            emptySidebar: 'Select a node to view details',
            showInGraph: 'Show in graph (if currently visible)',
            entityDetailLabels: {
                place_of_birth: 'Place of Birth',
                home_city: 'Home City',
                home_country: 'Home Country',
                home_email: 'Home Email',
                multiple_nationalities_label: 'Nationalities',
                birth_date: 'Date of birth',
                gender: 'Gender',
            },
            entityDetailValues: {
                birth_date: '{{formattedDate}} ({{age}} years old)',
                gender_male: 'Male',
                gender_female: 'Female',
                gender_other: 'Other (X)',
            },
            notFound: {
                headline: 'No graph possible',
                message:
                    'The target is very old and you need to recreate it to be able to generate the new graph.',
            },
        },

        networkGraphLegend: {
            title: 'Legend',
            showLegend: 'Show Legend',
            closeButton: 'Close',
            candidate: 'Candidate',
            company: 'Company',
            companyFlagged: 'Company Flagged (SOE, Sanctioned, etc.)',
            employeePosition: 'Employee Position',
            person: 'Person',
            personFlagged: 'Person Flagged (PEP, Sanctioned, etc.)',
        },

        cvData: {
            title: 'CV data',
        },

        cVAnalysis: {
            title: 'CV statistics',
            timeline: 'Timeline',
            noTimelineHeadline:
                'No entries with valid timeframes were found for the provided filters',
            noTimeframe: 'No timeframe',
            noTimeframeTooltip:
                'These entries did not have a valid timeframe or no timeframe was found at all.',
            legend: 'Legend:',
            education: 'Education',
            work: 'Work experience',
            infoMessage:
                'All ongoing positions (those without an end date) are treated as having ended the day this report was generated.\n' +
                'This approach ensures consistency across the report,  but may affect the accuracy of the results.',
            filters: {
                selectSourceGroup: 'Source',
                sourceGroupOptions: {
                    osint: 'Indicium analysis',
                    cv: 'Applicant CV',
                },
                search: 'Search',
                searchPlaceholder: 'Search by company name or university...',
                startDate: 'Start date',
                endDate: 'End date',
            },
            otherEllipses: 'Other...',
            chartUnit: {
                months: 'month(s)',
                years: 'year(s)',
                typos: 'typos',
                typo: 'typo',
            },
            chartTitle: {
                educationTimespan: 'Education Timespan',
                educationLevel: 'Level of education',
                jobTimespan: 'Job Timespan',
                uniqueCompanies: 'Unique Companies',
                gaps: 'Number of Gaps',
                typos: 'Grammar & Spelling',
                timespanOfPositions: 'Time spent per role',
                industriesTimespan: 'Time spent per industry',
            },
            chartWarning: {
                educationTimespan:
                    'Some education entries are not included because of missing start and end dates',
                jobTimespan:
                    'Some work experience entries are not included because of missing start and end dates',
                gaps: 'Some work and education entries are not included because of missing start and end dates',
                typos: 'Some typo entries are not included because typos are coming only from CV',
            },
            chartEmpty: {
                educationTimespan:
                    'There is not enough education data for the selected source.',
                educationLevel: 'No education level found.',
                jobTimespan:
                    'There is not enough work experience data for the selected source.',
                gaps: 'There is not enough work and education experience data for the selected source.',
            },
            chartNotFound: {
                educationTimespan: 'No education experience found.',
                educationLevel: 'No education level found.',
                jobTimespan: 'No work experience found.',
                uniqueCompanies: 'No companies found.',
                gaps: 'No gaps found.',
                typos: 'No typos found.',
                timespanOfPositions: 'No positions data found.',
                industriesTimespan: 'No industries data found.',
            },
        },

        cVTimeline: {
            title: 'CV timeline',
        },

        geoLocations: {
            title: 'Notes on locations',
        },

        persons: {
            title: 'Persons',
        },

        landingPage: {
            welcome: 'Welcome to the Indicium Insights Beta, {{name}}',
            title: 'Welcome {{name}}!',
            recentCases: 'My Recent Positions',
            recentTargets: 'My Recent Applicants',
            noCasesFound: 'You have not created any positions yet',
            noTargetsFound: 'You have not created any applicants yet',
        },

        // Navigation
        overview: 'Overview',
        personalData: 'Personal Data',
        socialMediaSection: 'Social Media',
        socialMediaConnectionSection: 'Social Media Connections',
        socialMediaPostSection: 'Social Media Posts',
        pressSection: 'Press',
        companySection: 'Affiliated companies',
        searchEngineSection: 'Search engines',
        pictureSection: 'Visuals',
        noData: 'No data',
        noDataFound: 'No data available',
        network: 'Network',
        downloads: 'Download',
        indicium: 'Indicium',
        signOut: 'Logout',
        searchAndFilter: 'Search & Filter',
        inputDataAndSources: 'Input data & sources',
        inputData: 'Input data',
        targetPersonSelection: 'The applicant selection',
        sourceOverview: 'Source overview',
        caseData: 'Position data',
        backToOverview: 'Back to the overview',

        rawData: {
            title: 'Raw Data',
            search: 'Free text search',
            socialMedia: 'Social Media',
            press: 'Press',
            registerData: 'Affiliated companies',
            searchEngines: 'Search engines',
            imageSlashVideos: 'Images/videos',
            dataNotAvailableTooltip:
                'This section is still being generated and thus not yet available.',
            disabledTooltip:
                'The raw data was deleted in accordance with the GDPR',
        },

        // newTarget: data input
        targetPersonDataHeadline: 'The applicant data',
        targetPersonDataSubHeadline: 'What do we know about the applicant?',
        basisData: 'Basic data',
        title: 'Title',
        titles: {
            dr: 'Dr.',
            prof: 'Prof.',
            profDr: 'Prof. Dr',
        },
        gender: 'Gender',
        genders: {
            male: 'male',
            female: 'female',
            // TODO add support for other
            // other: 'other',
        },
        name: 'Name',
        firstname: 'First name',
        middlename: 'Middle name',
        lastname: 'Last name',
        nickname: 'Nickname',
        nicknameAlternative: 'Nickname / stage name',
        dateOfBirth: 'Birthday',
        nationality: 'Nationality',
        countryOfResidence: 'Country of residence',
        placeOfBirth: 'Place of birth',
        countryOfBirth: 'Country of birth',
        occupation: 'Job role',
        placesLived: 'Places of residence',
        contactData: 'Contact details',
        phone: 'Phone',
        phonePlaceholder: 'e.g. +49 1234 567890',
        mobile: 'Mobile phone',
        email: 'Email',
        emailPlaceholder:
            'e.g. john.doe@example.com (person specific address (no info@, contact@, etc.)',
        address: 'Address',
        companyWebsite: 'Company website',
        websites: 'Websites',
        roles: 'Roles',
        shareholders: 'Shareholders',
        bankDetails: 'Bank details',
        company: 'Company',
        companyRole: {
            label: 'Role within the company',
            optionLabels: {
                Unknown: 'Unknown',
                Employee: 'Employee',
                ManagingDirector: 'Managing Director',
                Shareholder: 'Shareholder',
                AuthorisedSignatory: 'Authorized signatory',
                Other: 'Other',
            },
        },
        unused: 'This field will be available in future releases',
        employee: 'Employee',
        companyEducation: 'Company / Education',
        companyOwner: "Applicant's company",
        companyName: 'Company name',
        companyLocation: 'Headquarters',
        commercialRegisterNumber: 'Commercial Register Number',
        commercialRegisterNumberPlaceholder:
            'The commercial register number must consist of at least 5 characters',
        companyVatNumber: 'VAT number',
        companyVatNumberPlaceholder:
            'The sales tax number must consist of at least 5 characters',
        website: 'Website',
        websitePlaceholder: 'e.g. https://www.example.com',
        socialMediaProfiles: 'Social media profiles',
        socialMediaPrivacyHint:
            'Privacy notice: social media profiles may only be included in the analysis if there is a legitimate interest',
        socialMediaDisabledHint:
            'Private social media is disabled in this report type',
        socialMediaFormatHint: 'Link + Handle / Handle',
        targetPersonDetails: 'Narrow down the applicant',
        targetPersonDetailsHint:
            'You can improve the quality of the results by entering keywords for the applicant',
        targetPersonDetailsKeywords: 'Keywords for identifying the applicant',
        targetPersonAmbiguityKeywords:
            'Keywords to exclude persons with the same name',
        targetPersonAmbiguityKeywordsHint:
            "Are there any prominent people with the applicant's name, or people in the same industry?",
        invalidNationalityMessage:
            'The entry could not be assigned to a nationality',
        invalidCountryMessage: 'The input could not be assigned to a country',
        invalidServerResponse:
            'An error has occurred. Please check your input and try again.',

        //newTarget: resolve ambiguity
        age: 'Age',
        // TODO: IND-460 Check usage of duplicate keys on global level
        // Some of the keys inside `candidateInfo` like `age` already existed at the
        // root level before the `candidateInfo` nesting group was added. I haven't
        // removed them from the root level as it is currently unclear whether some
        // other component of the application has been reusing them.
        // Once IND-460 has been completed and other translation usage has been
        // migrated to use context / feature specific nesting, they keys from
        // candidateInfo that also exist at the root level should be checked for
        // obsolescence.
        candidateInfo: {
            placeOfResidency: 'Residence',
            age: 'Age',
            company: 'Company',
            nationality: 'Nationality',
            phone: 'Telephone',
            email: 'Email',
            website: 'Website',
            url: 'URLs',
            placeOfBirth: 'Place of birth',
            dateOfBirth: 'Date of birth',
            username: 'Username',
            gender: 'Gender',
            language: 'Language',
            originCountry: 'Country of origin',
            education: 'Education',
            city: 'City',
            state: 'State',
            country: 'Country',
            scientificTitle: 'Profession',
        },
        seeXMore: 'See {{ x }} more',
        placeOfResidency: 'Residence',
        resolveTargetCandidatesHeadline: 'Candidate selection',
        // TODO 'resolveTargetCandidatesSubHeadline' needs German approval
        resolveTargetCandidatesSubHeadline:
            'Please include all candidates that match your applicant, please exclude all that do not. If you are unsure, leave it blank.',
        candidateSelectionHelp:
            'In this section you choose which possible candidates match the target and which do not. Please <bold>decide for each possible match</bold> whether it matches your applicant or not, or <bold>skip it if you are unsure</bold>.' +
            '<p><span>1</span>You will be shown the most likely matches first.</p>' +
            '<p><span>2</span>Each time you make a selection, the system will try to automatically include and exclude other possible matches based on your selection and update the list of candidates to show you the next most likely matches.</p>' +
            '<p><span>3</span>The more matches you include or exclude, the more accurate the results will usually be.</p>',
        targetCandidatesIntro: `In the following section you can see all the results that have been found by searching all available data sources.\n
            We now need to determine which of these results are a match for the target person and which are not.\n
            We need at least one candidate selected to generate a report but having more generally leads to better results.\n
            The system has determined that <bold>of the <highlight>{{ totalCount }}</highlight> potential candidates found, <highlight>{{ includedCount }}</highlight> are a match for the target person</bold>.\n
            You can now either review/edit the selection or directly move on to report generation.`,
        reachedEndOfPendingCandidatesHelp:
            'You have reached the end of the list. You can now:' +
            '<p><span>1</span>Review your selection by clicking on "<bold>Decision made</bold>" above,</p>' +
            '<p><span>2</span>Request the generation of the report by clicking "<bold>Request Report</bold>" or</p>' +
            '<p><span>3</span><loop>Go back to the start</loop><space></space> to go through the "<bold>To be decided</bold>" list again.</p>',
        selectCandidate: 'Include candidate',
        excludeCandidate: 'Exclude candidate',
        selectAllCandidates: 'Include all candidates',
        excludeAllCandidates: 'Exclude all candidates',
        skipCandidate: 'Skip',
        addMoreCandidates: 'Add more candidates',
        reviewCandidates: 'Review selection',
        startAnalysis: 'Request Report',
        likelyCandidates: 'Likely correct',
        possibleCandidates: 'Possible match',
        wrongCandidates: 'Likely wrong',
        includedCandidates: 'Included candidates',
        excludedCandidates: 'Excluded candidates',
        unselectedCandidates: 'To be decided',
        skippedCandidates: 'Undecided/mixed candidates',
        totalCandidates: 'Total candidates',
        decidedCandidates: 'Decision made',
        finishedCandidateSelection:
            'All done! You can now review your selection or click on "Request Report" to continue.',

        // Dashboard: Overview
        overviewAnalysis: 'Analysis',
        globalSearch: {
            title: 'Search positions and applicants',
            hint: 'find positions and applicants based on their name or title',
            noResults: 'No results found',
            tooltip: {
                type: 'Type',
                status: 'Status',
                reportType: 'Report type',
                creator: 'Created by',
            },
            type: {
                case: 'Position',
                target: 'Applicant',
            },
            resultsCount: 'Found {{count}} results',
        },
        // Red Flags
        redFlags: {
            heading: 'Examination required',
            cardHeadline: 'Critical issues',
            cardSubHeadline: 'Results found',
            headers: {
                name: 'Entity name',
                role: 'Company role(s)',
                detail: 'Red Flag Details',
                country: 'Country',
                source: 'Source',
                description: 'Description',
            },
            types: {
                pep: {
                    detail: 'Politically exposed person',
                    description:
                        'The applicant finds himself on a list for high-ranking public active persons (Politically Exposed Persons)',
                },
                sanction: {
                    detail: 'Sanction list',
                    description:
                        'The applicant was found on a global sanctions list',
                },
                enforcement: {
                    detail: 'Law enforcement',
                    description:
                        'The applicant was found on a global law enforcement list',
                },
                soe: {
                    detail: 'SOE',
                    description:
                        'The applicant has links to state-owned property or governments and through their employees (State Owned Enterprises)',
                },
                government_link: {
                    detail: 'Government Linked',
                    description:
                        'The applicant has links to government property as well as their employees',
                },
                adverse_media: {
                    detail: 'Adverse Media',
                    description:
                        'The applicant is possibly linked to illegal money laundering and terrorist financing activities, based on news published by credible media',
                },
                associated_entity: {
                    detail: 'Associated Entity',
                    description:
                        'The applicant is associated with at least one sanctioned entity',
                },
                registrations: {
                    detail: 'Registration',
                    description:
                        'The applicant is associated with surveillance material published by official government agencies, international organisations, industry regulators and event disciplinary committees that meet qualified standards and definitions.',
                },
                generic_red_flag: {
                    detail: 'General conspicuousness',
                    description:
                        'The applicant is on a global law enforcement list',
                    sources: {
                        orbis: 'Global sanctions lists, watchlist and politically exposed person lists (via "BvD Compliance Catalyst")',
                    },
                },
            },
            rawTypes: {
                'PEP/Former PEP':
                    'Current or former Politically Exposed Person (PEP)',
            },
            reportsHeading: 'Detailed Report',
            reportsItem: 'Report',
            roleNotAvailable: 'N/A',
            positionIsCurrent: 'Present',
            unknownTimeframe: 'Unknown timeframe',
            unknownRole: 'Unknown role',
        },

        highlight: {
            source: 'Source:',
            inferedFromTextAs: 'Inferred from text as:',
        },

        // Profile: Overview
        source: 'Source',
        sources: 'Sources',
        profileHeadline: 'Profile',
        downloadPDF: 'PDF',
        profileLoading: 'Data being loaded',
        profileDataNotFinal:
            'It may take up to 1 hour for the displayed data to be complete',
        profileErrorHeadline: 'An error has occurred',
        profileErrorMessage: 'Your request could not be processed',
        profileErrorRetryHeadline: 'No data is available yet',
        profileErrorRetryMessage: 'Please try again in a few minutes',
        workflowRunningMessage:
            'The process is still running, please come back later. (This may take up to half an hour)',
        complianceIndication: 'Indications of compliance violations',
        searchTerms: 'Own search terms',
        profileSize: 'Profile size',
        networkSize: 'Network size',
        from: 'From',
        to: 'To',
        role: 'Activity',
        cv: 'Resume',

        // profiles: Raw data
        data: 'Data',
        include: 'Include',
        included: 'Included',
        exclude: 'Exclude',
        cancelSelection: 'Cancel selection',
        excluded: 'Excluded',
        includeCandidate: 'Include candidate',
        backToDataInput: 'To data input',
        cancelCreateTarget: 'End analysis',
        noPersonHeadline: 'No persons found',
        noPersonText:
            'Unfortunately, we have not identified a possible applicant based on your input. Please check your entry.',
        summaryBetaTooltip: `This is a beta feature using Large Language Models (LLMs) for summarization. \nFor any questions, please reach out to your account manager.`,

        // Admin: User Admin
        overviewOfAllUsers: 'Overview of all users',
        createNewUser: 'Create new user',
        addButton: 'Add',
        closeButton: 'Close',
        addMoreUsers: 'Create another user',
        assignedCases: 'Active positions',
        activeReports: 'Active reports',
        totalReports: 'Total reports',
        includingDeleted: 'including deleted',
        userTableFooter: 'Total',
        createdOn: 'Created on',
        createdAt: 'Created on',
        changedOn: 'Changed on',
        updatedAt: 'Changed on',
        notAvailable: 'N/A',

        // Admin: Customer Admin
        overviewOfAllCustomers: 'Overview of all customers',
        createNewCustomer: 'Create new customer',
        customerName: 'Customer name',
        numberOfReports: 'Reports',
        limitOfReports: 'Report limit',
        expiresAt: 'Contract ends on',
        updateCustomer: {
            action: 'Edit customers',
            formHeadline: 'Available fields',
        },
        lastReportCreatedAt: 'Last report created',

        // Admin: Cases Admin
        overviewOfAllCases: 'Overview of all positions',
        createNewCase: 'Create new position',
        createNewTarget: 'Create new applicant',
        openCases: 'Open positions',
        archivedCases: 'Archived positions',
        caseName: 'Position name',
        caseType: 'Report type',
        caseTypes: {
            option1: 'Insight Essential',
            option2: 'Insight Plus',
            option3: 'Insight Complete',
        },
        mustBeUnique: 'Must be unique',
        caseNameMustBeUnique: 'Position name must be unique',

        subject: 'Subject',
        targetPerson: 'Applicant',
        targetPersonPress: '{{name}} (applicant)',
        targetNetworkPress: '{{name}} (network level {{networkLevel}})',
        editor: 'Editor',
        creator: 'Creator',
        dateChanged: 'Changed',
        dateCreated: 'Created',
        titleOfCase: 'Title of the position',
        exampleTitle: 'Example title',
        assignUserToCase: 'Assign user to position',
        assignUserToCaseSubtext:
            'Select here which staff members you want to give access rights to work on this position. Administrators in your organisation have access to all positions',
        currentResults: 'Current results',
        archivedResults: 'Archived results',
        noCaseFoundHeadline: 'No position found',
        noCaseFoundText:
            'Unfortunately, we have not identified a possible position based on your input. Please check your entry',
        deleteCase: {
            action: 'Delete position',
            modalTitle:
                'Are you sure you want to delete the selected position?',
            modalText:
                'Are you sure you want to delete the selected position? Please make sure that you have exported and saved all reports relevant to you beforehand, as these can no longer be made available for download after deletion.',
            radioOptions: [
                'Yes, I have downloaded the relevant applicant reports in advance and would like to delete the selected position',
                'Yes, I want to delete the position and all data irrevocably, but have not downloaded the reports as I do not need them',
            ],
            cancelButton: 'Cancel deletion',
            confirmButton: 'Confirm deletion',
        },
        editCaseAction: 'Edit position',
        editCaseFormHeadline: 'Available fields',
        editCase: {
            titleHasNotChanged: 'The title has not changed',
        },
        addAdminPermissionsAction: 'Add admin rights',
        removeAdminPermissionsAction: 'Revoke admin rights',
        deleteTarget: {
            action: 'Delete applicant',
            modalTitle:
                'Are you sure you want to delete this applicant irrevocably?',
            modalText:
                'Are you sure you want to delete the selected applicant irrevocably? Please make sure that you have exported and saved the report of the applicant beforehand, as it can no longer be made available for download after the deletion.',
            radioOptions: [
                "Yes, I have downloaded the applicant's report in advance and would like to delete the selected applicant",
                'Yes, I want to delete the applicant and their data irrevocably, but have not downloaded their report as I do not need it',
            ],
            cancelButton: 'Cancel deletion',
            confirmButton: 'Confirm deletion',
        },
        targetStatus: {
            Created: 'Created',
            Completed: 'Completed',
            FetchingCandidates: 'Fetching Candidates',
            CandidateSelectionPending: 'Candidate Selection Pending',
            CandidateSelectionCompleted: 'Candidate Selection Completed',
            FetchingDetails: 'Fetching Details',
            HasInitialProfile: 'Has Initial Profile',
            CompletedWithIrregularities: 'Completed With Irregularities',
        },
        deleteUser: {
            action: 'Delete user',
            modalTitle: 'Are you sure you want to delete this user?',
            modalText: 'Are you sure you want to delete this user irrevocably?',
            radioOptions: [
                'Yes, I would like to irrevocably delete the selected user',
            ],
            cancelButton: 'Cancel deletion',
            confirmButton: 'Confirm deletion',
        },
        deleteCustomer: {
            action: 'Delete customer',
            modalTitle:
                'Are you sure you want to delete this customer account?',
            modalText:
                'Are you really sure you want to delete the selected customer account? Please make sure that all relevant applicant reports have been exported and saved beforehand, as these will be deleted together with the customer account and can no longer be made available for download after deletion.',
            radioOptions: [
                'Yes, I have pre-downloaded the relevant applicant reports and would like to delete the selected client',
                'Yes, I would like to irrevocably delete the client and all associated data, but have not downloaded the reports as I do not need them',
            ],
            cancelButton: 'Cancel deletion',
            confirmButton: 'Confirm deletion',
        },
        editCustomerAction: 'Edit customer',
        editCustomerFormHeadline: 'Available fields',
        quotaExceeded: 'Your allowed volume of reports has been reached',
        quota: 'Quota',

        // Press
        press: 'Press',
        position: 'Position',
        date: 'Date',
        publicationDate: 'Publication date',
        retrievalDate: 'Retrieval date',
        preview: 'Preview',
        author: 'Author',
        copyright: 'Copyright ©',
        previous: 'Previous',
        next: 'Next',
        managers: 'Manager',

        // SocialMedia
        socialMedia: {
            contactsHeadline: 'Social media contacts',
            contactsNoAccounts: 'No social media accounts were found',
            postsHeadline: 'Social media posts',
            media: 'Media',
            url: 'Source',
            itemType: 'Item type',
            commentCount: 'Comments',
            likeCount: 'Likes',
            date: 'Date',
            datePublished: 'Date',
            tags: 'Tags',
            tagged: 'Tagged',
            links: 'Links',
            mentions: 'Mentions',
            profileName: 'Profile Name',
            profileId: 'Profile ID',
            profileImageUrl: 'Profile Image URL',
            profileImageWebAddress: 'Profile Image Web Address',
            profileLocation: 'Profile Location',
            inLanguage: 'Language',
            postingPlatform: 'Posted via',
            profileFollowers: 'Profile Subscribers',
            profileFollowing: 'Profile subscribed',
            source: 'Platform',
            favoriteCount: 'Favoured',
            retweetCount: 'Retweeted',
            userName: 'Username',
            contentId: 'Content ID',
            dateRecorded: 'Date of Entry',
            fullName: 'Full Name',
            mediaWebAddress: 'Media URL',
            isSharedContent: 'Shared Content',
            connectionType: 'Connection Type',
            username: 'User Name',
            mediaType: 'Media Type',
            body: 'Content',
            inReplyToUsername: 'In reply to username',
            inReplyToContentId: 'In reply to Content ID',
            inReplyToProfileId: 'In reply to Profile ID',
            friend: 'Friends',
            noFriends: 'No friends',
            follower: 'Followers',
            noFollowers: 'Does not follow any profile',
            followed: 'Following',
            nofollowed: 'No followers',
            noContacts: 'No contacts',
            connections: 'Connections',
            profileUrl: 'Profile URL',
            posts: 'Posts',
            profile: 'Profile',
            friendsAndInteractants: 'Friends & interactants',
            attribution: 'powered by',
        },

        // Socials
        facebook: 'Facebook',
        facebookPlaceholder: 'e.g. https://facebook.com/johndoe',
        instagram: 'Instagram',
        instagramPlaceholder: 'e.g. https://instagram.com/johndoe',
        linkedin: 'LinkedIn',
        linkedinPlaceholder: 'e.g. https://linkedin.com/in/johndoe',
        xing: 'Xing',
        xingPlaceholder: 'e.g. https://xing.com/profile/johndoe',
        twitter: 'X',
        twitterPlaceholder: 'e.g. https://x.com/johndoe',
        vkontakte: 'VKontakte',
        pinterest: 'Pinterest',
        flickr: 'Flickr',
        skype: 'Skype',
        whatsapp: 'WhatsApp',

        // commercial register
        commercialRegister: {
            filterTitle: 'Role',
            relevantCheckbox:
                'Only show companies with high responsibility role',
            relevantTooltip:
                'Companies for which the applicant held a leadership position of decision-making authority – e.g. CEO, Board member, Managing Director, etc. – which would indicate direct involvement in position of the company being connected to a crime',
            relevantLabel: 'Executive',
            nonRelevantLabel: 'Employee',
            nonRelevantTooltip:
                'Companies with which the applicant was involved but did not hold a position with a certain level of executive power',
        },

        // signIn
        signIn: {
            headline: 'Sign in',
            passwordForgotHeadline: 'Reset password',
            passwordResetRequiredHeadline: 'Reset password',
            emailAddressLabel: 'Email address',
            emailAddressInputPlaceholder: 'Email address',
            passwordLabel: 'Password',
            passwordInputPlaceholder: 'Password',
            signInButton: 'Sign in',
            rememberMe: 'Stay logged in',
            forgotPassword: 'Forgot your password?',
            imprint: 'Imprint',
        },
        authenticationSection: {
            headline: 'Authentication',
            enableMfa: 'Enable multi-factor authentication',
            disableMfa: 'Disable multi-factor authentication',
            enableMfaTooltop:
                'Enabling multi-factor authentication (MFA) will log you out of your current session',
            disableMfaTooltip:
                'Disabling multi-factor authentication (MFA) will log you out of your current session',
        },
        authentication: {
            headline: 'Multi-factor Authentication',
            description:
                'Please enter the code from your authentication app to complete the login process.',
            instructions: `
        1. Install an authentication app on your mobile device: If you haven't already, download and install an authentication app on your mobile device. Some popular options are Google Authenticator (available for Android and iOS) and Authy (available for Android and iOS).\n
        2. Scan the QR code: Open the authentication app and scan the QR code below. If you can't scan the QR code, you can manually enter the code and key below the QR code.\n
        3. Enter the authentication code: Enter the authentication code generated by the authentication app and click "Submit".\n
      `,
            codeInputPlaceholder: 'Authentication code',
            submitButton: 'Submit',
            oneTimePassword: 'One-time password',
        },

        passwordReset: {
            password: 'Password',
            oldPassword: 'Old password',
            newPassword: 'New password',
            newPasswordRepeat: 'Repeat password',
            newPasswordConfirm: 'Confirm new password',
            verifyAccount: 'Confirm email address',
            updatePassword: 'Update password',
        },

        // Error
        NotAuthorizedException:
            'You are not logged in. Email address or password are incorrect',
        UserNotFoundException:
            'You are not logged in. Email address or password are not correct',
        PasswordEmptyOrMismatchException:
            'The passwords entered are blank or do not match.',
        InvalidPasswordException:
            'The selected password must meet the following requirements:',
        passwordRequirements: [
            'Min. 8 characters',
            'Upper and lower position letters',
            'Numbers and special characters',
        ],
        PasswordResetRequiredException:
            'This is the first time you are logging in. Please set a new password',
        PasswordResetExpiredCodeException:
            'The code you have entered has expired.',
        InvalidParameterException: 'The entered code is not correct.',
        CodeMismatchException: 'The entered code is not correct.',
        LimitExceededException:
            'You have tried to change your password too many times. Wait a few minutes before trying again.',
        PasswordChallengeException:
            'Currently your password cannot be reset. Please use the password you received by email.',
        PasswordExpiredException:
            'Your temporary password is no longer valid. Please contact support to get a new password.',
        WrongPasswordException: 'The entered password is not correct.',
        uhOh: 'Uh-oh!',
        noPageFound: 'We can not find the page you are looking for',
        goBack: 'Please go back',
        noUsers: 'No users',
        noResultHeadline: 'No results found',
        noResultText:
            'Unfortunately, we did not find a possible result based on your input. Please check your entry',
        noTradeRegisterDataText:
            'We have checked over 400 million companies globally: a person with the specified name could not be identified with register-relevant, entrepreneurial activity',
        caseAgentsUpdated:
            'The user(s) have been successfully assigned to the position',
        caseAgentsAssignError:
            'The user(s) could not be assigned to the position',
        firstPage: '<< first page',
        previousPage: '< previous page',
        nextPage: 'next page >',
        lastPage: 'last page >>',
        pageNotFound: {
            title: 'Something went wrong here',
            subtitle:
                'This page does not exist or you lack permission to access this page',
            description:
                'Please check the URL for correctness or try again at a later time. If the problem persists, contact support.',
            button: 'Back to the overview',
        },
        organization: 'Organisation',
        privilegeLevel: 'Role',
        CustomerOwner: 'Owner',
        CustomerAdmin: 'Administrator',
        CustomerUser: 'Employee',
        administrators: 'Administrators',

        companyList: {
            roleStatus: {
                current: 'Current',
                previous: 'Former',
            },
        },
        caseNew: {
            caseTitle: 'Create new position',
            stepOne: 'Create position',
            stepTwo: 'Set position parameters',
            topicsTitle: 'Topics of the analysis',
            topicsParagraph:
                'The following analyses are supported by the system',
            redFlagsTitle: 'Potential critical issues',
            redFlagsParagraph:
                'Matching of the name of the applicant, its related companies and the persons related to these companies with national and international politically exposed person and sanctions lists',
            complianceTitle:
                'Notes in connection with compliance or criminal issues',
            complianceParagraph:
                'Keyword-based analysis and identification of anomalies in text material relating to the applicant. We examine terms from the area of classical crime (organised crime, terrorism, extremism) as well as, in particular, from the area of white-collar crime',
            keywordsLink: 'List of keywords and topics fields',
            businessRegistryTitle:
                'Flagging conspicuous characteristics in business registry data',
            sources: {
                title: 'Sources',
                searchEngines: 'Search engines',
                commercialRegisters: 'Commercial Register',
                sanctionsList: 'Politically exposed person/sanctions lists',
                webSites: 'Websites',
                pressArchive: 'Press Archives',
                businessSocialMedia: 'Business Social Media',
                privatePublicSocialMedia: 'PRIVATE, PUBLIC Social Media',
            },
            selectOption: 'Please choose the report type',
            title: {
                option1: 'Insight Essential',
                option2: 'Insight Plus',
                option3: 'Insight Complete',
            },
            stepSubtitle: {
                step1: 'Please select the report type',
                step2: 'Please provide additional details for the selected report',
                step3: 'Please select a name and configure access',
            },
            optionsInfo: {
                option1: [
                    'Possible without consent',
                    'Recommended for every applicant',
                    'Exclusion of purely private social media portals',
                ],
                option2: [
                    'Possible without consent based on selected sources',
                    'Recommended for special positions',
                    'Individual selection of sources',
                    'Individual option for keyword search',
                ],
                option3: [
                    'Requires consent',
                    'Recommended for executive positions',
                    'All online publicly available sources (trade register, press, social media and search engines)',
                    'Keywords related to the position should be provided',
                ],
            },
            sourceUsageInfo: {
                option1: {
                    included: [
                        'Professional social media (LinkedIn, Xing)',
                        'Press',
                        'Search engines',
                    ],
                    excluded: [
                        'Private social media',
                        'Sanction lists',
                        'Company networks',
                        'Offshore leak database',
                    ],
                },
                option2: {
                    included: [
                        'Professional social media (LinkedIn, Xing)',
                        'Press',
                        'Search engines',
                        'Private social media',
                    ],
                    excluded: [
                        'Sanction lists',
                        'Company networks',
                        'Offshore leak database',
                    ],
                },
                option3: {
                    included: [
                        'Professional social media (LinkedIn, Xing)',
                        'Press',
                        'Search engines',
                        'Private social media',
                        'Sanction lists',
                        'Company networks',
                        'Offshore leak database',
                    ],
                    excluded: [],
                },
            },
            backgroundCheckTitle:
                'Background check - business initiation without concrete suspicion',
            backgroundCheckDescription1:
                'Compliance searches are carried out out of economic interest based on planned business initiation, but without concrete suspicion. Indicium only processes information that is publicly available online.',
            targetPersonInformationTitle: 'Information of the data subject',
            targetPersonInformation: {
                yes: 'The data subjects have been informed about the planned processing.',
                no: 'The data subjects have not been informed about the intended processing because this would jeopardise the purpose of the processing',
            },
            osintCheckWithConsent:
                'OSINT check with consent of all targeted individuals',
            publicInfoDesc:
                'All information publicly available on the internet (text, image)',
            privateSocialMedia:
                'Private social media (e.g. Facebook, Instagram, X) - requires consent',
            customSearchTerms: 'Specific search terms',
            icijLeaks: 'Publicly available leak databases may be searched',
            option3IcijLeaks:
                'Include publicly available leak databases into search',
            suspCompInvestigation: {
                optionDesc: 'Suspicion-related compliance research',
                section2MainTitle: 'Add position-specific keyword to analysis',
                section3Title: 'Individual search terms',
                section2Description1:
                    'With your own position-related keywords you can give the analysis an additional depth. We will identify your or similar keywords in the found data.',
            },
            addNewKeyword: 'Add more keywords',
            dataPrivacy: {
                title: 'Data privacy details',
                bullets: [
                    'The client must ensure that the data protection basis is guaranteed (initiation of business relationship Art. 6 Para. 1 lit. b or legitimate interest Art. 6 Para. 1 lit. f)',
                    'Indicium generally only processes permitted data (example: search engines are queried in accordance with the valid questioning law)',
                    'Please contact support@indicium.ag for the prepared data protection report',
                ],
            },
            leftFooter: {
                title: 'Comprehensive analysis on your dataset',
                bullet0:
                    'Check for Critical Issues: politically exposed person check, sanctions list check and other watch lists of affiliates and contractors up to 2nd degree',
                bullet1:
                    'Matching of all found texts associated with the person with critical keywords or negative content (Based on artificial intelligence analysis)',
                bullet2:
                    'Automatic checking of suspicious factual connections from the perspective of a professional OSINT analyst',
                bullet3:
                    'For position-related keywords: analysis on occurrence of keywords and keyword extensions (via machine learning).',
            },
            rightFooter: {
                title: ' Included sources for your analysis',
                bullet0:
                    'Access to data from over 400 million companies globally across > 270 different providers',
                bullet1:
                    'Special resolution with a further ~ 10 million datasets in Germany, Austria and Switzerland through connection of specialist providers',
                bullet2:
                    'Daily updated, real-time global information via use of LexisNexis, access to more than 100,000 sources of national and international news and company information from over 100 countries in 90 languages.',
                bullet3:
                    'Machine access to search engine results on your applicant (texts; articles; documents, images)',
                bullet4:
                    'Coverage of the public parts of the main social media channels such as LinkedIn, Xing, Facebook, X, Instagram.',
            },
            reasonForTheAnalysisPopup: {
                title: 'Set up position parameters',
                content:
                    'For the GDPR-compliant selection of the analysis tools and sources, the framework conditions of the present position must be defined in the following. The respective consequences of the settings are explained again in detail in each point. Indicium is obliged to check the accuracy of the information on a random basis. If you have any further questions, please feel free to contact the Indicium team at ',
                contentEmail: 'fallanlage@indicium.ag.',
            },
            keywordsTitle: 'Enter a keyword',
            keywordsTitleEnglish: 'English',
            noTranslationsFound: 'No translation found',
            suggestSynonyms: 'Suggest synonyms',
            clearSynonyms: 'Clear synonyms',
            noSynonymsFound: 'No synonym found',
            keywordType: {
                topic: 'Topic',
            },
            gdprPopover: {
                title: '100% GDPR compliant',
                infoText1: 'All analyses are compliant with the GDPR',
                infoText2: 'Secure connection via SSL server',
                infoText3: 'Encrypted data',
            },
        },
        yes: 'Yes',
        no: 'No',

        consolidatedView: 'Consolidated View',
        present: 'Today',
        seeMore: 'Show more',
        seeLess: 'Show less',
        moreDetails: 'More details',

        image: 'Image',
        imageUpload: 'Upload image',
        uploadTheImage: 'Upload the image',
        browse: 'Browse',
        documentType: 'Document type',
        mdCeo: 'Managing Director/Executive Board',
        country: 'Country',
        zipCode: 'Postcode',
        street: 'Street',

        registrationDate: 'Registration date',
        registrationNumber: 'Registration number',

        keyword: 'Keyword',
        keywords: 'Keywords',
        /**
         * @todo: Clean-up and organize the keys better
         * Some of these translations exist on root level
         * Also there are duplicated because of naming inconsistencies between candidate tiles and target profile
         */
        infoBlockItem: {
            placeOfResidency: 'Places of residence',
            workPlaceOfResidency: 'Work places of residence',
            age: 'Age',
            company: 'Companies',
            companies: 'Companies',
            nationality: 'Nationalities',
            phone: 'Phones',
            phones: 'Telephones',
            email: 'Email',
            emails: 'Email',
            website: 'Websites',
            websites: 'Websites',
            url: 'URLs',
            placeOfBirth: 'Place of birth',
            birthPlace: 'Place of birth',
            dateRangeOfBirth: 'Birthday',
            dateOfBirth: 'Birthday',
            birthday: 'Birthday',
            username: 'User names',
            gender: 'Gender',
            language: 'Languages',
            originCountry: 'Country of origin',
            education: 'Education',
            city: 'City',
            state: 'State',
            country: 'Country',
            countries: 'Countries',
            scientificTitle: 'Professions',
            firstname: 'Firstname',
            lastname: 'Surname',
            placesLived: 'Places of residence',
            jobRoles: 'Job roles',
            jobTitles: 'Job titles',
            more: 'more',
            addresses: 'Addresses',
            leakSource: 'Leak',
            description: 'Personal description',
            skills: 'Skills',
            hobbies: 'Hobbies',
        },
        validUntil: 'Valid until: {{validUntil}}',
        icijLeakSource: {
            'Panama Papers': 'Panama Papers',
            'Offshore Leaks': 'Offshore Leaks',
            'Bahamas Leaks': 'Bahamas Leaks',
            'Paradise Papers - Malta corporate registry':
                'Paradise Papers - Malta corporate registry',
            'Paradise Papers - Barbados corporate registry':
                'Paradise Papers - Barbados corporate registry',
            'Paradise Papers - Appleby': 'Paradise Papers - Appleby',
            'Paradise Papers - Bahamas corporate registry':
                'Paradise Papers - Bahamas corporate registry',
            'Paradise Papers - Lebanon corporate registry':
                'Paradise Papers - Lebanon corporate registry',
            'Paradise Papers - Aruba corporate registry':
                'Paradise Papers - Aruba corporate registry',
            'Paradise Papers - Nevis corporate registry':
                'Paradise Papers - Nevis corporate registry',
            'Pandora Papers - Fidelity Corporate Services':
                'Pandora Papers - Fidelity Corporate Services',
            'Pandora Papers - Alemán, Cordero, Galindo & Lee (Alcogal)':
                'Pandora Papers - Alemán, Cordero, Galindo & Lee (Alcogal)',
            'Paradise Papers - Cook Islands corporate registry':
                'Paradise Papers - Cook Islands corporate registry',
            'Paradise Papers - Samoa corporate registry':
                'Paradise Papers - Samoa corporate registry',
            'Pandora Papers - Alpha Consulting':
                'Pandora Papers - Alpha Consulting',
            'Pandora Papers - Trident Trust': 'Pandora Papers - Trident Trust',
            'Pandora Papers - CILTrust International':
                'Pandora Papers - CILTrust International',
            'Pandora Papers - Asiaciti Trust':
                'Pandora Papers - Asiaciti Trust',
            'Pandora Papers - Commence Overseas':
                'Pandora Papers - Commence Overseas',
            'Pandora Papers - SFM Corporate Services':
                'Pandora Papers - SFM Corporate Services',
            'Pandora Papers - Il Shin Corporate Consulting Limited':
                'Pandora Papers - Il Shin Corporate Consulting Limited',
            'Pandora Papers - Overseas Management Company (OMC)':
                'Pandora Papers - Overseas Management Company (OMC)',
        },
        yearsOld: '{{ age }} years old',
        pagination: {
            previous: 'Previous',
            next: 'Next',
        },
        filters: {
            dateFrom: 'Date from',
            dateTo: 'Date to',
            neutral: 'Neutral',
            positive: 'Positive',
            mixed: 'Mixed',
            negative: 'Negative',
            source: 'Source',
            involvement: 'Participation',
            type: 'Type',
            socialMedia: 'Social media',
            mainSocialMediaProfiles: 'Profiles',
            potentialSocialMediaProfiles: 'Potential Profiles',
            connectionType: 'Connection Type',
            status: 'Status',
            post: 'Post',
            connection: 'Connection',
            press: 'Press',
            registerData: 'Affiliated companies',
            searchEngines: 'Search engines',
            image: 'Image',
            sources: 'Sources',
            active: 'Active',
            inactive: 'Inactive',
            Orbis: 'Trade Register (via BvD Orbis)',
            Pipl: 'Internet research (via Pipl)',
            CompanyHouse: 'Commercial Register (via Companyhouse)',
            userInput: 'User input',
            Instagram: 'Instagram',
            Facebook: 'Facebook',
            Twitter: 'X',
            LinkedIn: 'LinkedIn',
            Xing: 'Xing',
            Flickr: 'Flickr',
            Google: 'Google',
            Bing: 'Bing',
            confidence: 'Data confidence level',
            highConfidence: 'The applicant with high confidence',
            indiferrent: 'Potentially an applicant, but unclear',
            highNegativeConfidence: 'Different person with high confidence',
            selected: 'Selected',
            search: 'Search',
            clearFilters: 'Clear filters',
            selectAll: 'Select all',
        },
        exactSearch: 'Exact search',
        hitsCount: '{{hits}} hits',
        sortBy: 'Sort by',
        suspicionCount: '{{hits}} Found',
        confirmedSuspicionCount: '{{hits}} Confirmed',
        reports: {
            redFlags: 'Red Flags: Sanction lists',
            anomaliesCount: '{{count}} anomalies',
            sourcesCount: '{{count}} Sources checked',
            resultsCount: '{{count}} Total',
            sections: {
                compliance_violations: 'Notices of compliance violations',
                general_crime: 'Indications of crime',
                own_keywords: 'Own keywords',
                general_anomalies: 'Abnormalities',
                problematic_content_detected: 'Content to be checked',
                extreme_opinions: 'Extreme opinions',
                moderation: 'Offensive Content (Social Media)',
            },
            showAll: 'Show all',
            showLess: 'Show less',
        },
        relevanceScore: 'Relevance score',
        suspicion: 'Suspicion',
        relatedSuspicion: 'Content relates to',
        entity: 'Entity',
        suspicionItemSource: {
            User: 'User input',
            ML: 'Machine Learning Classifier',
            Cortical: 'AI',
            LogosLLM: 'AI',
            OwnKeyword: 'Position keywords',
            KeywordList: 'Rules / heuristics',
        },
        suspicionItemInvolvement: {
            perpetrator: 'Perpetrator',
            victim: 'Victim',
            suspect: 'Suspect',
        },
        suspicionItemInvolvementDecision: {
            inferred: 'Inferred', // Involvement inferred from context
            explicit: 'Explicit', // Explicitly stated involvement
            assumed: 'Assumed', // Assumed involvement based on available information
            unknown: 'Unknown', // Involvement is unknown
        },
        googleCandidates: {
            groupTitle: 'Advanced search engine search',
            selectGroup: 'Selection',
            excludeGroup: 'Exclude',
            results: 'Results',
            showGroupTopics: 'Show group topics',
            hideGroupTopics: 'Hide group topics',
            groupTopics: 'Group topics',
            moreDetails: 'More details',
            lessDetails: 'Less details',
            imagesSelected: 'Selected {{ selected }}/{{ total }} images',
            editImageSelection: 'Edit image selection',
            selectProfileImages: 'Select images to use as profile images',
            noCandidatesNoImages:
                'Select candidates with images to be able to select profile images',
        },
        saveSelection: 'Save selection',
        addToReport: 'Add to Report',
        changeSuspicion: 'Change to other suspicion',
        changeSuspicionButton: 'Change',
        selectSuspicion: 'Select Suspicion',
        linkedSuspicions: 'Linked Suspicion(s)',
        suspicionItemEntityType: {
            SocialAccountConnection: 'Public connections on social media',
            SocialAccountPost: 'Public posts on social media',
            press: 'Press',
            TargetCompany: 'Registry data',
            WebPage: 'Search engines',
            Image: 'Visuals',
        },
        suspicionItemStatus: {
            problem: 'Problem',
            no_problem: 'Unobjectionable',
            pending: 'Pending',
            wrong_target: 'Wrong person',
        },
        setAsProfilePhoto: 'Use as profile picture',
        apiErrors: {
            403: 'Agreed volume of reports reached',
            default:
                'An error has occurred. Please check your input and try again.',
        },
        companyStatus: {
            active: 'Active',
            'active (default of payment)': 'Active (default of payment)',
            'active (insolvency proceedings)':
                'Active (insolvency proceedings in progress)',
            'active (dormant)': 'Active (dormant)',
            insolvency: 'Insolvent',
            dissolved: 'Dissolved',
            'status unknown': 'Status unknown',
            inactive: 'Inactive',
            'in liquidation': 'In liquidation',
            ak: 'Active',
            ia: 'Inactive',
            'active (reorganization)': 'Active (reorganization)',
            bankruptcy: 'Bankruptcy',
            'dissolved (bankruptcy)': 'Dissolved (bankruptcy)',
            'dissolved (liquidation)': 'Dissolved (liquidation)',
            'dissolved (merger or take-over)': 'Dissolved (after take-over)',
            'inactive (no precision)': 'Inactive',
            'administratively suspended': 'Administratively suspended',
            default: 'Status unknown',
        },
        companyCardTabs: {
            basicData: 'Basic Data',
            subsidiaryCompany: 'Subsidiary Company',
            ownersAndManagers: 'Owners and Managers',
            financialInformation: 'Financial Information',
            publications: 'Publications',
        },
        companyMutations: {
            kapitalaenderung: 'Capital change',
            kapitalherabsetzung: 'Capital reduction',
            kapitalerhoehung: 'Capital increase',
            aenderungorgane: 'Change of organs',
            adressaenderung: 'Address change',
            status: 'Status',
            firmenaenderung: 'Company change',
        },
        sourceName: {
            socialMedia: 'Social media (via Pipl / ShadowDragon)',
            orbis: 'Commercial Register (via BvD Orbis)',
            pipl: 'Internet research (via Pipl)',
            companyhouse: 'Commercial Register (via Companyhouse)',
            complianceCatalyst:
                'Global sanctions lists, watchlist and politically exposed person lists (via "BvD Compliance Catalyst")',
            firmenabc: 'Commercial Register (via FirmenABC)',
            lexisnexis: 'Press archives (via Lexis Nexis)',
            google: 'Internet research (via Google)',
            facebook: 'Social Media (via ShadowDragon)',
            twitter: 'Social Media (via ShadowDragon)',
            instagram: 'Social Media (via ShadowDragon)',
            linkedin: 'Social Media (via ShadowDragon)',
            xing: 'Social Media (via ShadowDragon)',
            flickr: 'Social media (via ShadowDragon)',
            digitalclues: 'Social media (via ShadowDragon)',
            zefix: 'Commercial Register (via Zefix)',
            userinput: 'User input',
            userInput: 'User input',
            shadowDragon: 'Social media (via ShadowDragon)', // this is deprecated, but just here for backwards compatibility
            shadowdragon: 'Social media (via ShadowDragon)',
            icijleaks: 'Offshore leaks archives (via ICIJ)',
        },
        inputDataDisclamer:
            'Here you can view your original input data. You also have the option to change the data and thus create another report (which will be subject to charge).',
        somethingWentWrong: {
            title: 'Oops! Something went wrong here.',
            subtitle:
                'The current page cannot be displayed at the moment. We apologize for any inconvenience.',
            description:
                'If the problem persists or you would like some additional help, please contact the support.',
            sendEmail: 'Send us an Email',
            startChat: 'Start Live Chat',
            email: {
                subject: 'IDA - something went wrong',
                body: 'Dear Indicium Support, I just encountered an error on this page: {{currentUrl}}.',
            },
            backHomeButton: 'Back to home',
            tooltip: 'Contact support',
        },
        maintenance: {
            title: 'We are currently under maintenance',
            subtitle: 'Please try again later',
            reload: 'Reload',
        },
        companyCard: {
            dateOfIncorporation: 'Date of incorporation',
            companyActivity: 'Company activity',
            previousNames: 'Previous names',
            identifiers: 'Identifiers',
            tradeRegisterNumber: 'Trade register number',
            vatTaxNumber: 'USt-IdNr. (VAT/Tax number)',
            leiCompanyId: 'LEI(Company ID)',
            europeanVatNumber: 'European VAT number',
            subjectText: 'Subject text',
            subsidiaryName: 'Subsidiary Name',
            informationDate: 'Since',
            country: 'Country',
            shareholder: 'Shareholder',
            manager: 'Manager',
            actuality: 'Actuality',
            dob: 'DOB',
            pob: 'POB',
            directlyOwned: 'Directly Owned %',
            ultimatelyOwned: 'Ultimately Owned %',
            address: 'Address',
            financials: 'Financials / Key data',
            turnover: 'Turnover',
            profit: 'Profit',
            employees: 'Employees',
            previous: 'Previous',
            current: 'Current',
        },
        dynamicForm: {
            title: 'More keywords',
            selectField: 'Field',
            fields: {
                occupations: 'Job role',
                relatedPersons: 'Person',
                websites: 'Website',
                organizations: 'Organisation',
                topics: 'Topic',
                locations: 'Location',
                nicknames: 'Nicknames',
                default: 'Word',
            },
            buttons: {
                include: 'Include',
                exclude: 'Exclude',
            },
            description:
                'Please enter further keywords that describe the applicant (in German and English): places (country or city - in reference to place of birth / work / residence / ...), occupations (job title, position, ...), organizations (other than companies), affiliated websites, people (first and last name) or topics (hobbies, events, etc.) that are known to be associated with the applicant',
            totalKeywords: 'Total keywords:',
            maxKeywords: '(Max: {{maxLength}})',
        },
        confidenceScore: {
            score10:
                'high confidence that it is NOT the applicant but someone else’s data',
            score20:
                'indifferent whether it belongs to the applicant or another person with the same name',
            score30: 'high confidence that it is the applicant',
            matchesLabel: 'Score explanation',
            matches: {
                birthPlace: 'Place of birth',
                company: {
                    name: 'Company name',
                    addresses: 'Company address',
                    countries: 'Country',
                    managers: 'Manager',
                    shareholders: 'Shareholder',
                },
                educations: 'Education Institution',
                emails: 'Email address',
                locations: 'Location',
                name: 'Name',
                nationality: 'Nationality',
                nicknames: 'Nickname',
                occupations: 'Job Company',
                organizations: 'Organisation',
                phones: 'Phone number',
                relatedPersons: 'Related Person',
                residence: {
                    street: 'Street',
                    city: 'City',
                    country: 'Country',
                },
                topics: 'Topic',
                usernames: 'Username',
                websites: 'Website',
            },
        },
        suspicionKeywords: {
            arson: 'Arson',
            assault: 'Assault',
            asset_misuse_misdemeanors: 'Misuse of assets',
            assets_misuse_offenses: 'Data breach',
            banking_payment_offenses:
                'Potential offenses in the area of Banking & Payment Services Law',
            black_market: 'Black market',
            bmf_fiu_risk_country: 'BMF/FIU risk country',
            capital_market_offenses: 'Violation of capital market law',
            capital_offenses: 'Capital offenses',
            child_pornography: 'Child pornography',
            clans: 'Clans',
            climate_change_denier: 'Climate change denier',
            company_blacklist: 'Company blacklist',
            conspiracy_theorist: 'Conspiracy theorist',
            copyright_infringement: 'Copyright infringement',
            corona_denier: 'Corona denier',
            corporate_governance_code: 'Corporate governance code infringement',
            corporate_insurance_offenses: 'Insurance fraud',
            corporate_law_violations: 'Corporate law violations',
            corporate_logistics_offenses: 'Issues at operational logistics',
            corporate_obligations: 'Violation of corporate obligations',
            corporate_operations: 'Issues related to corporate operations ',
            corruption: 'Corruption',
            cybercrime: 'Cybercrime',
            data_breach: 'Data privacy breach',
            data_security_breaches: 'Lack of Data Protection',
            debt_law_offenses: 'Violation of debt law',
            digital_black_markets: 'Digital black markets',
            employment_relationships:
                'Problems in the design of employment relationships',
            eu_third_country_with_increased_:
                'EU third country with increased risk',
            exhibitionism: 'Exhibitionism',
            exploiting_leading_position: 'Exploiting leading position',
            explosives: 'Explosives',
            extortion: 'Extortion',
            extremism_terror: 'Extremism / Terror',
            fair_trading_offenses: 'Violation of fair trading law',
            fatf_increased_monitoring: 'FATF increased monitoring',
            financial_reporting: 'Fraudulent financial reporting',
            firearms: 'Firearms',
            foreign_trade_law: 'Violations of foreign trade law',
            fraud_consumer: 'Fraud / Consumer',
            hacking_attacks: 'Hacking attacks',
            harassment: 'Harassment',
            harassment_threatening: 'Harassment / Threatening',
            hate: 'Hate',
            hate_speech: 'Hate speech',
            hate_threatening: 'Hate / Threatening',
            health_safety_offenses:
                'Violation of occupational safety / health protection',
            human_rights_violations: 'Potential human rights offenses',
            identity_theft: 'Identity theft',
            illicit: 'Illicit',
            illicit_violent: 'Illicit / Violent',
            indications_for_crime: 'Indications for crime',
            information_protection: 'Lack of information protection',
            insolvency_law_offenses: 'Insolvency law violations',
            intolerance_bigotry_discrimination:
                'Intolerance / Bigotry / Discrimination',
            islamist_terror: 'Islamist terror',
            labor_law_offenses: 'Labour law offences',
            left_wing_extremism: 'Left-Wing extremism',
            malware: 'Malicious programs',
            money_laundering: 'Money laundering offenses',
            money_laundering_country_list: 'Money laundering country list',
            murder_manslaughter: 'Murder / Manslaughter',
            negative_content: 'Negative Content',
            negative_sentiment: 'Negative sentiment',
            no_internet_presence: 'No internet presence',
            offenses: 'Offenses',
            business_operations_and_risk_management_offenses:
                'Offenses in business operations and risk management',
            financial_and_corporate_compliance_offenses:
                'Offenses in financial and corporate compliance',
            offshore_leak: 'Offshore leak',
            organized_crime: 'Organized crime',
            other_criminal_activities: 'Other criminal activities',
            potential_compliance_violation: 'Potential compliance violation',
            procurement_law_offenses: 'Violation of public procurement law',
            product_safety: 'Violations against product safety and liability',
            property_damage: 'Property damage',
            property_offenses: 'Violation of company land and real estate laws',
            racism: 'Racism',
            ransomware: 'Ransomware',
            rape: 'Rape',
            red_light_milieu: 'Red light district',
            right_wing_extremism: 'Right-Wing extremism',
            robbery_kidnapping: 'Robbery / Kidnapping / Human trafficking',
            rockers: 'Rockers / MCs',
            russian_war_supporter: 'Russia-war supporter',
            scam_fraud: 'Scam / Fraud',
            self_harm: 'Self-harm',
            self_harm_instructions: 'Self-harm / Instructions',
            self_harm_intent: 'Self-harm / Intent',
            sexual: 'Sexual',
            sex_offenses: 'Sex offenses',
            sexual_assault: 'Sexual assault',
            sexual_minors: 'Sexual in connection with minors',
            social_security_fraud: 'Social security fraud',
            substance_abuse: 'Substance abuse',
            sunsidies_funding_offenses: 'Subsidy fraud',
            sustainability_environment:
                'Violations against sustainability and environment',
            tax_offenses: 'Tax law violations',
            theft: 'Theft / Embezzlement / Stolen property',
            trade_secrets: 'Trade secrets',
            transactions: 'Issues in connection with transactions',
            violent_crimes: 'Violent crimes',
            violence: 'Violence',
            violence_graphic: 'Graphic violence',
            weapon_offenses: 'Weapon offenses',
            white_collar_crime: 'White-collar crime',
            wrong_notices: 'Wrong notices',
        },
        errorEntityNames: {
            title: 'We encountered issues with external services.',
            subTitle: 'The following data might be missing or incomplete: ',
            entity: {
                company: 'companies',
                facebookFollower: 'Facebook followers',
                facebookFollowing: 'Facebook followings',
                facebookFriend: 'Facebook friends',
                facebookPhoto: 'Facebook photos',
                facebookPost: 'Facebook posts',
                facebookUser: 'Facebook users',
                facebookVideo: 'Facebook videos',
                googleNews: 'Google news',
                image: 'images',
                instagramFollower: 'Instagram followers',
                instagramFollowing: 'Instagram followings',
                instagramPost: 'Instagram posts',
                instagramReel: 'Instagram reels',
                instagramStory: 'Instagram stories',
                instagramUser: 'Instagram users',
                linkedInEducation: 'LinkedIn educations',
                linkedInPosition: 'LinkedIn positions',
                linkedInPost: 'LinkedIn posts',
                linkedInUser: 'LinkedIn users',
                person: 'people',
                pressArticle: 'press articles',
                searchEngineImage: 'search engine images',
                searchEnginePage: 'search engine pages',
                xFollower: 'X followers',
                xFollowing: 'X followings',
                xTweet: 'X tweets',
                xUser: 'X users',
                xingConnections: 'Xing connections',
                xingUser: 'Xing users',
            },
        },
        socialMediaErrors: {
            externalError:
                'Our service provider is experiencing an issue and data is missing for:',
            warning:
                'Our service provider is experiencing fluctuating stability and data might be incomplete for:',
            internalError:
                'Please contact support@indicium.ag because data is missing due to an internal error for:',
            path: {
                user: 'User',
                posts: 'Posts',
                friends: 'Friends',
                following: 'Following',
                followers: 'Followers',
                tweets: 'Tweets',
                post: 'Post',
                video: 'Video',
                photo: 'Photo',
                reel: 'Reel',
                story: 'Story',
                tweet: 'Tweet',
                contacts: 'Contacts',
                educations: 'Educations',
                positions: 'Positions',
            },
        },
    },
};
