import axios from 'axios';
import {
    AllSuspicionLabels,
    ArticleSuspicion,
    SupportedSuspicion,
    SuspicionArticle,
    SuspicionItem,
    SuspicionItemEntity,
    SuspicionsByCategory,
} from '_types';
import {
    Customer,
    UserInputForCreateCustomer,
    UserInputForUpdateCustomer,
    UserInputTarget,
} from '@indicium/common';
import { SuspicionItemStatus } from '_enums';
import config from '../backendConfig.json';
import { getAccessToken } from './authenticationService';
import {
    CustomerUsageResponse,
    Paginated,
    ResourceDeleteResponse,
    Response,
} from 'src/types/NestAPI';
import {
    CaseData,
    MinimalTarget,
    Paging,
    ReportState,
    SocialMediaError,
    Target,
    TargetCandidates,
    TargetCompanies,
    TargetProfile,
} from './dataService';
import { generateAllCandidatesArray } from '_utils';
import { EntityIndicator } from '../hooks/queries/useTargetIndicatorsQuery';
import {
    CreateSuspicionItemBody,
    SuspicionItemSource,
    SuspicionItemInvolvement,
} from '../types/SuspicionItem';
import { CasesQueryParams } from '../hooks/queries/useCasesQuery';
import { SuspicionFilters } from '../features/dashboard/RiskAnalysis/useFilters';
import { SuspicionArticleFilters } from '../hooks/queries/useSuspicionArticlesQuery';
import { CaseType } from '../features/cases/CaseNew';
import { UpdateUserData, UserData } from '../types/UserData';
import { ArticleIndicator } from '../types/SuspicionsByCategory';
import { SuspicionItemEntityType } from '_enums';

export const baseURL =
    process.env.REACT_APP_LOCAL === 'true'
        ? 'http://localhost:3080/api'
        : 'https://' + config.backendBaseUrl + '/api';

const client = axios.create({
    baseURL,
});

client.interceptors.request.use(async (config) => {
    try {
        const accessToken = await getAccessToken();
        if (accessToken) {
            config.headers['Authorization'] = 'Bearer ' + accessToken;
        }
    } catch (e) {
        console.error('[nestApiService] error while fetching access token:', e);
    }
    return config;
}, console.error);

client.interceptors.response.use(
    (response) => response,
    (error) => {
        console.error('[nestApiService] error:', error);
        return Promise.reject(error);
    },
);

const paramsSerializer = (params: Record<string, unknown>): string => {
    return Object.entries(params)
        .map(([key, value]) => {
            if (Array.isArray(value)) {
                return value
                    .map((item) => `${key}=${encodeURIComponent(item)}`)
                    .join('&');
            }
            return `${key}=${encodeURIComponent(`${value}`)}`;
        })
        .join('&');
};

export interface ReportFilters {
    suspicionItemId?: string;
    entityType?: SuspicionItemEntityType;
    status?: SuspicionItemStatus;
    involvement?: SuspicionItemInvolvement;
    minConfidenceScore?: number;
    page?: number;
    source?: SuspicionItemSource;
}

const targets = {
    addSuspicion: async (
        targetId: string,
        suspicionId: string,
        entity: SuspicionItemEntity,
    ): Promise<void> =>
        client.post(`targets/${targetId}/suspicions`, {
            entity,
            items: [{ suspicionId, score: 0 }],
        }),
    getSuspicionsByCategory: async (
        targetId: string,
        filters: SuspicionFilters,
    ): Promise<SuspicionsByCategory> => {
        return client
            .get(`targets/${targetId}/suspicions-by-category`, {
                params: filters,
                paramsSerializer,
            })
            .then((r) => r.data.data);
    },
    getSuspicionArticles: async (
        { targetId, ...filters }: SuspicionArticleFilters,
        pagination?: Record<'page' | 'pageSize', number>,
    ): Promise<{ data: SuspicionArticle[]; paging: Paging }> =>
        client
            .get(`targets/${targetId}/suspicion-articles`, {
                params: {
                    ...filters,
                    ...pagination,
                },
                paramsSerializer,
            })
            .then((r) => r.data.data),
    getAllSuspicionLabels: async (
        targetId: string,
    ): Promise<AllSuspicionLabels> =>
        client
            .get(`targets/${targetId}/all-suspicions-labels`)
            .then((r) => r.data.data),
    getIndicators: async (
        targetId: string,
        entityId?: string,
    ): Promise<Response<EntityIndicator[]>> =>
        client
            .get(
                `targets/${targetId}/indicators${
                    entityId ? `?entityId=${entityId}` : ''
                }`,
            )
            .then((r) => r.data),
    getInputData: async (
        caseId: string,
        targetId: string,
    ): Promise<Response<UserInputTarget>> =>
        client
            .get(`cases/${caseId}/targets/${targetId}/input`)
            .then((r) => r.data),
    getSelectedCandidates: async (
        caseId: string,
        targetId: string,
        caseType: CaseType,
    ): Promise<TargetCandidates> =>
        client
            .get(`cases/${caseId}/targets/${targetId}/candidates/selected`)
            .then((r) => generateAllCandidatesArray(r.data.data, caseType)),

    getReportState: async (
        caseId: string,
        targetId: string,
    ): Promise<ReportState> =>
        client
            .get(`targets/${targetId}/cases/${caseId}/report-state`)
            .then((r) => r.data.data),

    getCompanies: async (
        caseId: string,
        targetId: string,
    ): Promise<TargetCompanies> =>
        client
            .get(`targets/${targetId}/cases/${caseId}/companies`)
            .then((r) => r.data.data),

    getProfile: async (
        caseId: string,
        targetId: string,
    ): Promise<TargetProfile> =>
        client
            .get(`targets/${targetId}/cases/${caseId}/profile`)
            .then((r) => r.data.data),

    getSocialMediaErrors: async (
        caseId: string,
        targetId: string,
    ): Promise<SocialMediaError[]> =>
        client
            .get(`targets/${targetId}/cases/${caseId}/social-media-errors`)
            .then((r) => r.data.data),
    getStepGroups: async (caseId: string, targetId: string): Promise<unknown> =>
        client
            .get(`cases/${caseId}/targets/${targetId}/stepGroups`)
            .then((r) => r.data.data),

    getArticleSuspicions: async (
        targetId: string,
        entityIds: string[],
    ): Promise<
        Record<string, ArticleSuspicion & { indicators: ArticleIndicator[] }>
    > =>
        client
            .post(`targets/${targetId}/article-suspicions`, { entityIds })
            .then((r) => r.data.data),

    delete: async (caseId: string, targetId: string): Promise<boolean> =>
        client
            .delete(`cases/${caseId}/targets/${targetId}`)
            .then((r) => r.data.data),
    getTarget: async (
        caseId: string,
        targetId: string,
    ): Promise<MinimalTarget> =>
        client
            .get(`cases/${caseId}/targets/${targetId}/minimal`)
            .then((r) => r.data.data),
};

const suspicions = {
    listSupportedSuspicions: async (
        caseId: string,
    ): Promise<SupportedSuspicion[]> => {
        return client
            .get(`cases/${caseId}/supported-suspicions`)
            .then((r) => r.data.data);
    },
    items: {
        create: async (
            payload: CreateSuspicionItemBody,
        ): Promise<SuspicionItem> =>
            client
                .patch(`suspicions/entities/${payload.entity.id}`, payload)
                .then((r) => r.data.data),
        updateMany: async (
            ids: string[],
            status: SuspicionItemStatus,
        ): Promise<void> => client.patch(`suspicions/items`, { ids, status }),
    },
};

const customers = {
    findAll: async (): Promise<Paginated<Customer>> =>
        client.get('customers').then((r) => r.data),

    findOne: async (customerId: string): Promise<Response<Customer>> =>
        client.get(`customers/${customerId}`).then((r) => r.data),

    create: async (
        payload: UserInputForCreateCustomer,
    ): Promise<Response<Customer>> =>
        client.post('customers', payload).then((r) => r.data),

    update: async (
        customerId: string,
        payload: UserInputForUpdateCustomer,
    ): Promise<Response<Customer>> =>
        client.patch(`customers/${customerId}`, payload).then((r) => r.data),

    delete: async (
        customerId: string,
    ): Promise<Response<ResourceDeleteResponse>> =>
        client.delete(`customers/${customerId}`).then((r) => r.data),

    usage: async (
        customerId: string,
    ): Promise<Response<CustomerUsageResponse>> =>
        client.get(`customers/${customerId}/usage`).then((r) => r.data),
};

const cases = {
    findOne: (caseId?: string): Promise<Response<CaseData>> =>
        client.get(`cases/${caseId}`).then((r) => r.data.data),

    getKeywords: (caseId: string): Promise<Response<any>> =>
        client.get(`cases/${caseId}/keywords`).then((r) => r.data),

    delete: (caseId: string): Promise<boolean> =>
        client.delete(`cases/${caseId}`).then((r) => r.data.data),

    getAll: (queryParams?: CasesQueryParams): Promise<Paginated<CaseData>> =>
        client
            .get('cases', {
                params: queryParams,
            })
            .then((r) => r.data),
    getTargets: (
        caseId: string,
        queryParams?: { query?: string },
    ): Promise<Paginated<Target>> =>
        client
            .get(`cases/${caseId}/targets`, {
                params: queryParams,
            })
            .then((r) => r.data),
    getLatestTargetList: (queryParams?: {
        page?: string;
        pageSize?: number;
        query?: string;
        creatorId?: string;
    }): Promise<Paginated<Target>> =>
        client
            .get(`targets`, {
                params: queryParams,
            })
            .then((r) => r.data),
    getCandidates: (
        caseId: string,
        targetId: string,
        caseType: CaseType | null,
    ): Promise<TargetCandidates> =>
        client
            .get(`cases/${caseId}/targets/${targetId}/candidatesV2`)
            .then((r) => generateAllCandidatesArray(r.data.data, caseType)),
    getPreselectedCandidates: (
        caseId: string,
        targetId: string,
    ): Promise<TargetCandidates['preSelectedCandidates']> =>
        client
            .get(`cases/${caseId}/targets/${targetId}/preselection`)
            .then((r) => r.data.data),
    search: (queryParams: { query: string }): Promise<TargetCandidates> =>
        client.post(`cases/search`, { queryParams }).then((r) => r.data.data),
    update: (caseId: string, body: { title: string }): Promise<boolean> =>
        client.put(`cases/${caseId}`, body).then((r) => r.data.data),
    getCaseAgents: (caseId: string): Promise<string[]> =>
        client.get(`cases/${caseId}/agents`).then((r) => r.data.data),
    updateCaseAgents: async (
        caseId: string,
        userIds: string[],
    ): Promise<boolean> =>
        client
            .put(`cases/${caseId}/agents`, { userIds })
            .then((r) => r.data.data),
};

const app = {
    version: async (): Promise<any> =>
        client.get('version').then((r) => r.data),
};

const users = {
    getAll: async (customerId?: string): Promise<UserData[]> =>
        client
            .get(`users${customerId ? `?customerId=${customerId}` : ''}`)
            .then((r) => r.data.data),
    updateCurrentUser: async (
        userId: string,
        body: UpdateUserData,
    ): Promise<UserData> =>
        client.put(`users/${userId}`, body).then((r) => r.data.data),
    getUser: async (userId: string): Promise<UserData> =>
        client.get(`users/${userId}`).then((r) => r.data.data),
};

export { app, targets, suspicions, customers, cases, users };
