import React, { useEffect, useMemo, useState } from 'react';
import { ResultsProps } from '../Results';
import { ReactComponent as Logo } from '../../../images/logo.svg';
import { useUserState } from '../../../context/User';
import { t } from 'i18next';
import { Profile } from '../Profile';
import { NetworkGraphSection } from '../NetworkGraph';
import { useQueryClient } from 'react-query';
import { CvTable } from '../CV/CVPage/CvTable';
import { RiskAnalysis } from '../RiskAnalysis';
import { Overview } from '../Overview';
import { toEntrepreneurialActivities } from '../CV/CVAnalysis/CvStatistics/helpers';
import { Checklists } from '../Checklists/Checklists';

export const Print: React.FC<ResultsProps> = (props) => {
    const [{ customerId }] = useUserState();
    const [loadState, setLoadState] = useState<Record<string, boolean>>({});
    const queryClient = useQueryClient();
    const { targetCompanies, targetProfile } = props;

    useEffect(() => {
        // you may ask, why is this here? Well, we need to make sure the printable version is always up-to date
        // the user could have possibly loaded the page ages ago and after taking a good look at it decided to print it
        // in that case we want to make sure all the components always have the freshest data
        queryClient.invalidateQueries();
        const listener = () => {
            history.back();
        };
        addEventListener('afterprint', listener);
        return () => {
            removeEventListener('afterprint', listener);
        };
    }, [queryClient]);

    useEffect(() => {
        let cancel = false;
        if (
            loadState.overview === true &&
            loadState.network === true &&
            loadState.risk === true &&
            !cancel
        ) {
            cancel = true;
            setTimeout(() => window.print(), 1000);
        }
        console.log(loadState);
    }, [loadState]);

    const entrepreneurialActivities = useMemo(
        () => toEntrepreneurialActivities(targetCompanies?.companies ?? []),
        [targetCompanies?.companies],
    );

    const work = Object.values(entrepreneurialActivities).flat();

    const education = Object.values(
        targetProfile?.profile?.data?.educations ?? {},
    )
        .flat()
        .map(({ value }) => value);

    return (
        <div className="px-8 overflow-hidden max-h-screen print:max-h-[unset]">
            <div className="flex print:hidden h-screen justify-center items-center">
                {t('preparingPrint')}
            </div>
            <div className="opacity-0 print:opacity-100 pointer-events-none">
                <div className="hidden print:flex flex-row justify-between items-center only-print-version gap-8 px-8">
                    <Logo className="text-primary-4 fill-current w-28" />
                    <p className="font-bold text-sm text-justify">
                        {t('printVersionNotice.first')}{' '}
                        <span className="font-normal">
                            {t('printVersionNotice.second')
                                .replaceAll(
                                    '$$printversion',
                                    new Date().toLocaleDateString(),
                                )
                                .replaceAll('$$customerId', customerId)}
                        </span>{' '}
                        <a
                            href="https://www.indicium.ag/en/data-protection"
                            className="font-normal underline text-primary-4"
                        >
                            https://www.indicium.ag/en/data-protection
                        </a>
                    </p>
                </div>
                <Overview
                    {...props}
                    onFinishLoading={() =>
                        setLoadState((prev) => ({ ...prev, overview: true }))
                    }
                />
                <div className="page-break" />
                <Profile {...props} />
                <div className="page-break" />
                <NetworkGraphSection
                    {...props}
                    onFinishLoading={() =>
                        setLoadState((prev) => ({ ...prev, network: true }))
                    }
                />
                <div className="page-break" />
                <RiskAnalysis
                    {...props}
                    onFinishLoading={() =>
                        setLoadState((prev) => ({ ...prev, risk: true }))
                    }
                />
                {Boolean(work.length || education.length) && (
                    <>
                        <div className="page-break" />
                        <h1 className="font-jost font-bold text-3xl">
                            {t('cvData.title')}
                        </h1>
                        <CvTable
                            companies={work}
                            educations={education}
                            isForPrint={true}
                        />
                    </>
                )}
                <div className="page-break" />
                <Checklists {...props} />
            </div>
        </div>
    );
};
