import React, { FC, useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { FormProvider, useForm } from 'react-hook-form';

import { ResultsProps } from '../Results';
import { useTranslation } from 'react-i18next';
import { DefaultPageLayout } from '../../../components/Layout/DefaultPageLayout';
import { useChecklistTemplates } from '_queries';
import { ChecklistTemplate } from '../../../types/ChecklistTemplate';
import { useToast } from '../../../context/Toast';
import { Checklist } from '../../../components/_molecules/Checklist';
import { Button, Card, LoadingSpinner, Paragraph } from '_atoms';
import { useCreateChecklistMutation } from '../../../hooks/queries/useCreateChecklistMutation';
import { useChecklistsQuery } from '../../../hooks/queries/useChecklistsQuery';
import { ChecklistData, ChecklistField } from '../../../types/ChecklistData';
import { useUpdateChecklistMutation } from '../../../hooks/queries/useUpdateChecklistMutation';
import { Link } from 'react-router-dom';
import { routes } from '../../../routes';
import { ConfirmationModal, SignedChecklistDetails } from '_molecules';

export const Checklists: FC<ResultsProps> = ({ targetProfile }) => {
    const { t } = useTranslation();
    const toast = useToast();

    const [finalizeFormModalOpen, setFinalizeFormModalOpen] = useState(false);
    const [checklistTemplate, setChecklistTemplate] =
        useState<ChecklistTemplate>();
    const [checklist, setChecklist] = useState<ChecklistData>();

    const formMethods = useForm<Partial<Record<string, string[]>>>({
        mode: 'onBlur',
        defaultValues: {},
    });

    const {
        getValues,
        reset,
        handleSubmit,
        formState: { isValid, touchedFields },
    } = formMethods;

    const createDefaultValues = (
        fields: ChecklistField[],
    ): Record<string, string[]> => {
        return fields.reduce((acc, field) => {
            if (field.type !== 'title' && field.type !== 'description') {
                acc[field.name] = field.value || [];
            }
            return acc;
        }, {} as Record<string, string[]>);
    };

    const updateChecklistSuccess = useCallback(
        (data: ChecklistData) => {
            reset(getValues(), {
                keepDirtyValues: true,
                keepDirty: true,
            });
            setChecklist(data);
            toast({
                style: 'confirmation',
                text: t('checklists.saved'),
                ms: 5000,
            });
        },
        [getValues, reset, t, toast],
    );

    const {
        mutate: createChecklistMutate,
        isLoading: createChecklistIsLoading,
    } = useCreateChecklistMutation({
        onSuccess: updateChecklistSuccess,
        onError: () =>
            toast({
                style: 'error',
                text: t('checklists.errorUpdatingChecklist'),
                ms: 5000,
            }),
    });

    const {
        mutate: updateChecklistMutate,
        isLoading: updateChecklistIsLoading,
    } = useUpdateChecklistMutation({
        onSuccess: updateChecklistSuccess,
        onError: () =>
            toast({
                style: 'error',
                text: t('checklists.errorUpdatingChecklist'),
                ms: 5000,
            }),
    });

    const handleChecklistTemplatesSuccess = useCallback(
        (data: ChecklistTemplate[]) => {
            if (data.length > 0) {
                setChecklistTemplate(data[0]);
            }
        },
        [],
    );

    const { isLoading: templatesIsLoading } = useChecklistTemplates({
        onSuccess: handleChecklistTemplatesSuccess,
        onError: () =>
            toast({
                style: 'error',
                text: t('checklists.errorLoading'),
                ms: 5000,
            }),
    });

    const { isLoading: existingChecklistsIsLoading } = useChecklistsQuery({
        targetId: targetProfile?.id,
        isEnabled: !!checklistTemplate && !!targetProfile?.id,
        onSuccess: (data: ChecklistData[]) => {
            if (data.length > 0) {
                setChecklist(data[0]);
                reset(createDefaultValues(data[0].data.fields));
            } else if (checklistTemplate) {
                const newChecklist = {
                    id: '',
                    targetId: targetProfile.id ?? '',
                    data: checklistTemplate.data,
                    templateId: checklistTemplate.id,
                };
                setChecklist(newChecklist);
                reset(createDefaultValues(newChecklist.data.fields));
            }
        },
        onError: () =>
            toast({
                style: 'error',
                text: t('checklists.errorLoading'),
            }),
    });

    const resetForm = () => {
        /*
         * TODO: improve this by changing the way initial values of the form are
         *   set when checklist is loaded in next PR
         */
        const currentValues = getValues();
        const resetValues: Record<string, string[]> = {};

        Object.keys(currentValues).forEach((fieldName) => {
            resetValues[fieldName] = [];
        });
        reset(resetValues);
    };

    const saveFormData = useCallback(
        (data: Partial<Record<string, string[]>>, isFinalized = false) => {
            if (checklist && targetProfile.id) {
                const updatedFields = checklist.data.fields.map((field) => {
                    if (
                        field.type === 'title' ||
                        field.type === 'description'
                    ) {
                        return field;
                    }
                    return {
                        ...field,
                        value: data[field.name] || [],
                    };
                });

                const updatedChecklist: ChecklistData = {
                    ...checklist,
                    data: {
                        ...checklist.data,
                        fields: updatedFields,
                    },
                    isFinalized,
                };

                if (checklist.id) {
                    updateChecklistMutate({
                        checklistId: checklist.id,
                        data: updatedChecklist,
                    });
                } else {
                    createChecklistMutate({
                        targetId: targetProfile.id,
                        data: updatedChecklist,
                    });
                }
            }
        },
        [
            checklist,
            targetProfile.id,
            updateChecklistMutate,
            createChecklistMutate,
        ],
    );

    const hasUserTouchedFields = !!Object.keys(touchedFields).length;

    useEffect(() => {
        const debouncedSave = debounce(saveFormData, 1000);
        if (hasUserTouchedFields && isValid) {
            debouncedSave(getValues());
        }
        return () => {
            debouncedSave.cancel();
        };
    }, [isValid, hasUserTouchedFields, getValues, saveFormData]);

    const handleFinalizeModalToggle = useCallback(() => {
        setFinalizeFormModalOpen((prev) => !prev);
    }, []);

    const handleFinalizeModalClose = useCallback(() => {
        setFinalizeFormModalOpen(false);
    }, []);

    const handleFinalizeConfirm = useCallback(() => {
        saveFormData(getValues(), true);
        setFinalizeFormModalOpen(false);
    }, [getValues, saveFormData]);

    return (
        <DefaultPageLayout
            title={t('checklists.title')}
            headerActions={
                checklistTemplate && (
                    <div className="flex gap-2">
                        <Button
                            level="primaryGhost"
                            size="small"
                            type="button"
                            onClick={resetForm}
                            className="print:hidden"
                        >
                            {t('checklists.resetForm')}
                        </Button>
                        <Button
                            disabled={
                                !!checklist?.isFinalized ||
                                !isValid ||
                                updateChecklistIsLoading ||
                                createChecklistIsLoading
                            }
                            type="submit"
                            onClick={handleFinalizeModalToggle}
                            className="print:hidden"
                        >
                            {t('checklists.sendForm')}
                        </Button>
                    </div>
                )
            }
        >
            <Card backgroundColor="bg-white flex flex-col" className="p-8">
                {(templatesIsLoading || existingChecklistsIsLoading) && (
                    <LoadingSpinner />
                )}
                {!checklistTemplate && (
                    <Paragraph className="self-center">
                        {t('checklists.noChecklistTemplate')}
                        <Link
                            className={'text-blue-500 hover:text-primary-3'}
                            to={routes.checklistTemplates.path}
                        >
                            {t('checklistTemplate.title')}
                        </Link>
                    </Paragraph>
                )}
                {checklist?.data?.fields && (
                    <>
                        <ConfirmationModal
                            title={t('checklists.finalize')}
                            body={t('checklists.finalizeDescription')}
                            cancelButtonText={t('checklists.finalizeCancel')}
                            confirmButtonText={t('checklists.finalizeConfirm')}
                            enableConfirm
                            isOpen={finalizeFormModalOpen}
                            handleButtonClick={(event) => {
                                if (event === 'cancel') {
                                    handleFinalizeModalClose();
                                }
                                if (event === 'confirm') {
                                    handleFinalizeConfirm();
                                }
                            }}
                        />
                        {checklist?.isFinalized ? (
                            <SignedChecklistDetails checklist={checklist} />
                        ) : (
                            <FormProvider {...formMethods}>
                                <form
                                    className="space-y-4 min-h-0"
                                    onSubmit={handleSubmit((data) =>
                                        saveFormData(data),
                                    )}
                                >
                                    <Checklist
                                        checklistFields={checklist.data.fields}
                                        isEditable
                                    />
                                </form>
                            </FormProvider>
                        )}
                    </>
                )}
            </Card>
        </DefaultPageLayout>
    );
};
