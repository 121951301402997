import React, { forwardRef, useEffect, useState } from 'react';
import classNames from 'classnames';
import { FormLabel } from '_atoms';

export type CheckboxProps = {
    label?: string;
    name?: string;
    initialSelection?: string;
    onChange?: (value: string) => void;
    disabled?: boolean;
    className?: string;
    options?: { value: string; label: string }[];
};

/*
 * TODO: create better version of this that uses html inputs for radio groups so we can get semantic html benefits.
 *  Right now, we cannot do stuff like disable components
 */
export const RadioGroupV2 = forwardRef<HTMLDivElement, CheckboxProps>(
    (
        {
            label,
            initialSelection,
            onChange = () => {
                /* nop */
            },
            className,
            options = [],
        },
        ref,
    ) => {
        const [value, setValue] = useState(initialSelection);

        useEffect(() => {
            setValue(initialSelection);
        }, [initialSelection]);

        const handleClick = (optionValue: string) => {
            if (value !== optionValue) {
                setValue(optionValue);
                onChange(optionValue);
            }
        };

        return (
            <div ref={ref} className={className}>
                {label && <FormLabel>{label}</FormLabel>}
                <div className={classNames('flex flex-col gap-1 py-2 px-2')}>
                    {options.map((option) => (
                        <div
                            key={option.value}
                            className="flex gap-1 items-center"
                        >
                            <svg
                                role="radio"
                                onClick={() => handleClick(option.value)}
                                className="hover:text-primary-4 text-neutral-400 transition-all cursor-pointer"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect
                                    x="0.5"
                                    y="0.5"
                                    width="23"
                                    height="23"
                                    rx="11.5"
                                    fill="white"
                                />
                                <rect
                                    x="0.5"
                                    y="0.5"
                                    width="23"
                                    height="23"
                                    rx="11.5"
                                    stroke="currentColor"
                                />
                                {value === option.value && (
                                    <circle
                                        cx="12"
                                        cy="12"
                                        r="8"
                                        fill="#2AA3DC"
                                    />
                                )}
                            </svg>
                            <label
                                htmlFor={option.value}
                                className="text-sm font-bold text-neutral-500"
                            >
                                {option.label}
                            </label>
                        </div>
                    ))}
                </div>
            </div>
        );
    },
);

RadioGroupV2.displayName = 'RadioGroupV2';
