import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { targets } from '../../services/nestApiService';

type DeleteTargetMutationConfig = {
    onSuccess?: () => void;
    onError?: () => void;
};
export const useDeleteTargetMutation = ({
    onSuccess,
    onError,
}: DeleteTargetMutationConfig = {}): UseMutationResult<
    boolean,
    unknown,
    Record<string, string>
> => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ caseId, targetId }) => targets.delete(caseId, targetId),
        {
            onSuccess: () => {
                onSuccess?.();
                queryClient.invalidateQueries('targets');
            },
            onError,
        },
    );
};
