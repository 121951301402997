import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
    CaseData,
    CompanyData,
    ReportState,
    ReportStep,
    TargetCompanies,
    TargetProfile,
    TargetStatus,
} from '../../../services/dataService';
import { getAccessToken } from '../../../services/authenticationService';
import {
    SubNavigationBar,
    SubNavigationBarProps,
} from '../../../components/Layout/SubNavigationBar';
import {
    generatePath,
    Redirect,
    Switch,
    useHistory,
    useLocation,
    useRouteMatch,
} from 'react-router-dom';
import { ProtectedRoute } from '../../../Router';
import {
    CountrySelectOption,
    getCountrySelectionOptions,
} from '../../targets/TargetNew/countryCodeOptions';
import { resultsRoutes } from '../../../routes';
import { backendBaseUrl } from '../../../backendConfig.json';
import {
    useCaseKeywordsQuery,
    useCaseQuery,
    useCustomerQuery,
    useTargetCompaniesQuery,
    useTargetProfileQuery,
    useTargetReportStateQuery,
} from '_queries';
import {
    Button,
    LoadingSpinner,
    TooltipContentV2,
    TooltipTriggerV2,
    TooltipV2,
} from '_atoms';
import { PageNotFound } from '_organisms';
import { getCdnUrl, isCustomerExpired, withinSpanOfDays } from '_utils';
import { RedFlagItem } from 'src/types';
import { useUserState } from '../../../context/User';
import { map, zipObject } from 'lodash';
import { TargetReportAttribute, WocoEntity } from '@indicium/common';
import {
    EntityIndicator,
    useTargetIndicatorsQuery,
} from '../../../hooks/queries/useTargetIndicatorsQuery';
import classNames from 'classnames';
import { useMenuContext } from '../../../context/Menu';
import { FaPrint } from 'react-icons/fa6';

export const getRedFlags = (
    companies: TargetCompanies['companies'],
    profile: TargetProfile['profile'],
    countrySelectionOptions: CountrySelectOption[],
    {
        caseId,
        targetId,
        accessToken,
    }: Record<'caseId' | 'targetId' | 'accessToken', string>,
): RedFlagItem[] => {
    const countriesMap = zipObject(
        map(countrySelectionOptions, 'code'),
        map(countrySelectionOptions, 'name'),
    );

    const transformWocoEntityToRedFlagItem = (
        {
            value: {
                country: countryCode,
                positionOrCas,
                category,
                subCategory,
                name,
                externalId,
            },
            sources,
        }: TargetReportAttribute<WocoEntity>,
        apiIds: TargetReportAttribute<string>[],
        roles?: CompanyData['data']['role'],
    ): RedFlagItem => {
        const country = countryCode ? countriesMap[countryCode] : undefined;
        const type = `${category}/${subCategory}`;
        const reportUrl = `https://${backendBaseUrl}/redflagpdfproxy/${caseId}/${targetId}/${externalId}?token=${accessToken}`;

        return {
            apiId:
                apiIds.find((id) => id.sources.includes('orbis'))?.value || '',
            description: positionOrCas || '',
            name,
            type,
            sources,
            country,
            reportUrl,
            roles,
        };
    };

    const redFlagsFromCompanies = (companies || []).flatMap(
        ({ data: { wocoEntities, apiIds, role } }) => {
            return (wocoEntities || []).map((entity) =>
                transformWocoEntityToRedFlagItem(entity, apiIds, role),
            );
        },
    );

    const redFlagsFromProfiles = (profile?.data?.wocoEntities || []).map(
        (entity) =>
            transformWocoEntityToRedFlagItem(
                entity,
                profile?.data?.apiIds || [],
            ),
    );

    return [...redFlagsFromProfiles, ...redFlagsFromCompanies];
};

export type ResultsProps = {
    accessToken: string;
    caseData: CaseData;
    redFlags: RedFlagItem[];
    entityIndicators: EntityIndicator[];
    reportState: ReportState;
    targetCompanies: TargetCompanies;
    targetProfile: TargetProfile;
    targetLoading: boolean;
    targetError: boolean;
    targetImages: string[];
};

const getTestIdFromName = (name: string) => {
    switch (name) {
        case 'summary':
            return 'analysis menu summary';
        case 'overview':
            return 'analysis menu overview';
        case 'personalData':
            return 'analysis menu personal data';
        case 'overviewAnalysis':
            return 'analysis menu analysis';
        case 'riskAnalysis.title':
            return 'analysis menu risk analysis';
        case 'networkGraph.title':
            return 'analysis menu network graph';
        case 'cvData.title':
            return 'analysis menu resume data';
        case 'cVAnalysis.title':
            return 'analysis menu CV statistics';
        case 'cVTimeline.title':
            return 'analysis menu CV timeline';
        case 'geoLocations.title':
            return 'analysis menu notes on locations';
        case 'persons.title':
            return 'analysis menu persons';
        case 'rawData.title':
            return 'analysis menu raw data';
        case 'rawData.search':
            return 'analysis menu free text search';
        case 'rawData.socialMedia':
            return 'analysis menu social media';
        case 'rawData.press':
            return 'analysis menu press';
        case 'rawData.registerData':
            return 'analysis menu register data';
        case 'rawData.searchEngines':
            return 'analysis menu search engines';
        case 'rawData.imageSlashVideos':
            return 'analysis menu images videos';
        case 'checklists.title':
            return 'checklists view';

        default:
            console.warn('Unknown dataTestId name: ', name);
            return undefined;
    }
};

const areStepsFetchingDetails = (
    steps: ReadonlyArray<ReportStep>,
    stepMetadata: ReadonlyArray<{
        step: ReportStep;
        status: TargetStatus;
    }>,
): boolean => {
    const entireWorkflowStatus =
        stepMetadata.find(({ step }) => step === 'entire_workflow')?.status ??
        null;

    if (
        entireWorkflowStatus === TargetStatus.Completed ||
        TargetStatus.CompletedWithIrregularities
    ) {
        return false;
    }

    return steps
        .map(
            (step) =>
                stepMetadata.find((sm) => sm.step === step)?.status ?? null,
        )
        .some((status) => status === TargetStatus.FetchingDetails);
};

const LoadingIcon = (
    <span>
        <LoadingSpinner height={18} width={18} className="py-0" />
    </span>
);

export const Results: FC = () => {
    const { t, i18n } = useTranslation();
    const { caseId, targetId } =
        useParams<{
            caseId: string;
            targetId: string;
        }>();
    const history = useHistory();
    const location = useLocation();

    const handlePrint = useCallback(() => {
        history.push(
            generatePath(resultsRoutes.printVersion.path, {
                targetId,
                caseId,
            }),
        );
    }, [history, targetId, caseId]);

    const [{ customerId }] = useUserState();
    const { data: customer } = useCustomerQuery(customerId, {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    });
    const { collapsed } = useMenuContext();

    const [accessToken, setAccessToken] = useState<string>();

    const { data: caseData, ...caseQuery } = useCaseQuery(caseId);
    const { data: caseKeywords, ...caseKeywordsQuery } =
        useCaseKeywordsQuery(caseId);
    const { data: targetProfile, ...targetProfileQuery } =
        useTargetProfileQuery({
            caseId,
            targetId,
        });
    const { data: targetCompanies, ...targetCompaniesQuery } =
        useTargetCompaniesQuery({
            targetId,
            caseId,
        });

    const { data: reportState, ...reportStateQuery } =
        useTargetReportStateQuery(caseId, targetId);
    const targetIndicatorsQuery = useTargetIndicatorsQuery(targetId);

    const entityIndicators = targetIndicatorsQuery.isSuccess
        ? targetIndicatorsQuery.data
        : [];

    useEffect(() => {
        (async () => {
            setAccessToken(await getAccessToken());
        })();
    }, []);

    useEffect(() => {
        const listener = (e: KeyboardEvent) => {
            // detect control + p
            if (e.ctrlKey && e.key === 'p') {
                e.preventDefault();
                e.stopPropagation();
                handlePrint();
            }
        };
        document.addEventListener('keydown', listener);
        return () => {
            document.removeEventListener('keydown', listener);
        };
    }, [handlePrint]);

    const isPrint = useRouteMatch(resultsRoutes.printVersion.path);

    const countrySelectionOptions = getCountrySelectionOptions(i18n.language);

    const redFlags = useMemo(
        () =>
            accessToken && targetCompanies?.companies && targetProfile?.profile
                ? getRedFlags(
                      targetCompanies.companies,
                      targetProfile.profile,
                      countrySelectionOptions,
                      {
                          caseId,
                          targetId,
                          accessToken,
                      },
                  )
                : [],
        [
            targetCompanies?.companies,
            targetProfile?.profile,
            countrySelectionOptions,
            caseId,
            targetId,
            accessToken,
        ],
    );

    const isRawDataDisabled =
        process.env.REACT_APP_STAGE === 'production' &&
        ((!!targetProfile?.updatedAt &&
            !withinSpanOfDays(targetProfile.updatedAt)) ||
            isCustomerExpired(customer?.expiresAt));

    const isReportInProgress =
        targetProfile?.status &&
        ![
            TargetStatus.Completed,
            TargetStatus.CompletedWithIrregularities,
        ].includes(targetProfile.status);

    useEffect(() => {
        const alwaysEnabledRoutes = [
            resultsRoutes.dashboardOverview.path,
            resultsRoutes.dashboardCaseData.path,
            resultsRoutes.dashboardInput.path,
            resultsRoutes.dashboardCandidates.path,
        ];

        const disabledRoutes = Object.values(resultsRoutes)
            .map((route) => route.path)
            .filter((route) => !alwaysEnabledRoutes.includes(route));

        const isDisabledRoute = disabledRoutes.some((route) =>
            location.pathname.includes(
                generatePath(route, { caseId, targetId }),
            ),
        );

        if (isReportInProgress && isDisabledRoute) {
            history.replace(
                generatePath(resultsRoutes.dashboardOverview.path, {
                    caseId,
                    targetId,
                }),
            );
        }
    }, [location, isReportInProgress, history, caseId, targetId]);

    const getNavigationItemIcon = useCallback(
        (stepFetchingDetails: boolean): JSX.Element | null => {
            if (!stepFetchingDetails) {
                return null;
            }

            return LoadingIcon;
        },
        [],
    );

    const getMenuItemProps = useCallback(
        (
            key: ReadonlyArray<ReportStep>,
            statuses: ReadonlyArray<{
                step: ReportStep;
                status: TargetStatus;
            }>,
        ): {
            disabled: boolean;
            disabledTooltip: string;
            icon: JSX.Element | null;
        } => {
            const stepsFetchingDetails = areStepsFetchingDetails(key, statuses);

            return {
                disabled: isRawDataDisabled || stepsFetchingDetails,
                disabledTooltip: t('rawData.dataNotAvailableTooltip'),
                icon: getNavigationItemIcon(stepsFetchingDetails),
            };
        },
        [isRawDataDisabled, t, getNavigationItemIcon],
    );

    const rawDataGroupFetchingDetails = [
        'google_profile' as const,
        'press' as const,
        'network' as const,
        'socialmedia' as const,
    ]
        .map((step) =>
            areStepsFetchingDetails([step], reportState?.stepStatuses ?? []),
        )
        .every((stepFetchingDetails) => stepFetchingDetails);

    const handleSubNavigationItems = useMemo((): SubNavigationBarProps => {
        const subNavigationItems: SubNavigationBarProps = {
            navigationItems: [
                {
                    name: t('overview'),
                    dataTestId: getTestIdFromName('overview'),
                    href: generatePath(resultsRoutes.dashboardOverview.path, {
                        caseId,
                        targetId,
                    }),
                },
                {
                    name: t('personalData'),
                    dataTestId: getTestIdFromName('personalData'),
                    disabled: isReportInProgress,
                    disabledTooltip: isReportInProgress
                        ? t('rawData.dataNotAvailableTooltip')
                        : undefined,
                    icon: isReportInProgress ? LoadingIcon : undefined,
                    href: generatePath(resultsRoutes.dashboardProfile.path, {
                        caseId,
                        targetId,
                    }),
                },
                {
                    name: t('overviewAnalysis'),
                    dataTestId: getTestIdFromName('overviewAnalysis'),
                    disabled: isReportInProgress,
                    disabledTooltip: isReportInProgress
                        ? t('rawData.dataNotAvailableTooltip')
                        : undefined,
                    icon: isReportInProgress ? LoadingIcon : undefined,
                    children: [
                        {
                            name: t('riskAnalysis.title'),
                            dataTestId: getTestIdFromName('riskAnalysis.title'),
                            href: generatePath(
                                resultsRoutes.riskAnalysis.path,
                                {
                                    caseId,
                                    targetId,
                                },
                            ),
                        },
                        {
                            name: t('networkGraph.title'),
                            dataTestId: getTestIdFromName('networkGraph.title'),
                            href: generatePath(
                                resultsRoutes.networkGraphV2.path,
                                {
                                    caseId,
                                    targetId,
                                },
                            ),
                            icon: getNavigationItemIcon(
                                areStepsFetchingDetails(
                                    ['network'],
                                    reportState?.stepStatuses ?? [],
                                ),
                            ),
                        },
                        {
                            name: t('cVAnalysis.title'),
                            dataTestId: getTestIdFromName('cVAnalysis.title'),
                            href: generatePath(resultsRoutes.cVAnalysis.path, {
                                caseId,
                                targetId,
                            }),
                            icon: getNavigationItemIcon(
                                areStepsFetchingDetails(
                                    ['network', 'socialmedia'],
                                    reportState?.stepStatuses ?? [],
                                ),
                            ),
                        },
                        {
                            name: t('cVTimeline.title'),
                            dataTestId: getTestIdFromName('cVTimeline.title'),
                            href: generatePath(resultsRoutes.cVTimeline.path, {
                                caseId,
                                targetId,
                            }),
                            icon: getNavigationItemIcon(
                                areStepsFetchingDetails(
                                    ['network', 'socialmedia'],
                                    reportState?.stepStatuses ?? [],
                                ),
                            ),
                        },
                        {
                            name: t('checklists.title'),
                            dataTestId: getTestIdFromName('checklists.title'),
                            href: generatePath(resultsRoutes.checklists.path, {
                                caseId,
                                targetId,
                            }),
                        },
                        // {
                        //   name: t('geoLocations.title'),
                        //   dataTestId: getTestIdFromName('geoLocations.title'),
                        //   href: '#',
                        // },
                        // {
                        //   name: t('persons.title'),
                        //   dataTestId: getTestIdFromName('persons.title'),
                        //   href: '#',
                        // },
                    ],
                },
                {
                    name: t('rawData.title'),
                    dataTestId: getTestIdFromName('rawData.title'),
                    disabled:
                        isReportInProgress ||
                        isRawDataDisabled ||
                        rawDataGroupFetchingDetails,
                    disabledTooltip:
                        isReportInProgress && !isRawDataDisabled
                            ? t('rawData.dataNotAvailableTooltip')
                            : t('rawData.disabledTooltip'),
                    icon: isReportInProgress
                        ? LoadingIcon
                        : rawDataGroupFetchingDetails && !isRawDataDisabled
                        ? getNavigationItemIcon(rawDataGroupFetchingDetails)
                        : undefined,
                    children: [
                        {
                            name: t('rawData.search'),
                            dataTestId: getTestIdFromName('rawData.search'),
                            href: generatePath(
                                resultsRoutes.dashboardAllDataSearch.path,
                                {
                                    caseId,
                                    targetId,
                                },
                            ),
                            ...getMenuItemProps(
                                ['google_profile', 'socialmedia'],
                                reportState?.stepStatuses ?? [],
                            ),
                        },
                        {
                            name: t('rawData.socialMedia'),
                            dataTestId: getTestIdFromName(
                                'rawData.socialMedia',
                            ),
                            href: generatePath(
                                resultsRoutes.dashboardSocialMedia.path,
                                {
                                    caseId,
                                    targetId,
                                },
                            ),
                            ...getMenuItemProps(
                                ['socialmedia'],
                                reportState?.stepStatuses ?? [],
                            ),
                        },
                        {
                            name: t('rawData.press'),
                            dataTestId: getTestIdFromName('rawData.press'),
                            href: generatePath(
                                resultsRoutes.dashboardPress.path,
                                {
                                    caseId,
                                    targetId,
                                },
                            ),
                            ...getMenuItemProps(
                                ['press'],
                                reportState?.stepStatuses ?? [],
                            ),
                        },
                        {
                            name: t('rawData.registerData'),
                            dataTestId: getTestIdFromName(
                                'rawData.registerData',
                            ),
                            href: generatePath(
                                resultsRoutes.registerData.path,
                                {
                                    caseId,
                                    targetId,
                                },
                            ),
                            ...getMenuItemProps(
                                ['network'],
                                reportState?.stepStatuses ?? [],
                            ),
                        },
                        {
                            name: t('rawData.searchEngines'),
                            dataTestId: getTestIdFromName(
                                'rawData.searchEngines',
                            ),
                            href: generatePath(
                                resultsRoutes.searchEngines.path,
                                {
                                    caseId,
                                    targetId,
                                },
                            ),
                            ...getMenuItemProps(
                                ['google_profile'],
                                reportState?.stepStatuses ?? [],
                            ),
                        },
                        {
                            name: t('rawData.imageSlashVideos'),
                            dataTestId: getTestIdFromName(
                                'rawData.imageSlashVideos',
                            ),
                            href: generatePath(resultsRoutes.images.path, {
                                caseId,
                                targetId,
                            }),
                            ...getMenuItemProps(
                                ['google_profile', 'socialmedia'],
                                reportState?.stepStatuses ?? [],
                            ),
                        },
                    ],
                },
                {
                    name: t('inputDataAndSources'),
                    children: [
                        {
                            name: t('caseData'),
                            href: generatePath(
                                resultsRoutes.dashboardCaseData.path,
                                {
                                    caseId,
                                    targetId,
                                },
                            ),
                        },
                        {
                            name: t('inputData'),
                            href: generatePath(
                                resultsRoutes.dashboardInput.path,
                                {
                                    caseId,
                                    targetId,
                                },
                            ),
                        },
                        {
                            name: t('targetPersonSelection'),
                            href: generatePath(
                                resultsRoutes.dashboardCandidates.path,
                                {
                                    caseId,
                                    targetId,
                                },
                            ),
                        },
                        // {
                        //   name: t('sourceOverview'),
                        //   disabled: true,
                        // },
                    ],
                },
            ],
            downloadItems: [
                // {
                //   name: 'PDF',
                //   href: '#',
                // },
                // {
                //   name: 'XLS',
                //   href: '#',
                // },
            ],
        };

        return subNavigationItems;
    }, [
        caseId,
        reportState,
        t,
        targetId,
        isReportInProgress,
        getNavigationItemIcon,
        isRawDataDisabled,
        rawDataGroupFetchingDetails,
        getMenuItemProps,
    ]);

    if (
        caseQuery.isError ||
        caseKeywordsQuery.isError ||
        reportStateQuery.isError ||
        targetProfileQuery.isError ||
        targetCompaniesQuery.isError
    ) {
        return <PageNotFound />;
    }

    if (
        caseQuery.isLoading ||
        caseKeywordsQuery.isLoading ||
        reportStateQuery.isLoading ||
        targetProfileQuery.isLoading ||
        targetCompaniesQuery.isLoading
    ) {
        return (
            <div className="mt-20">
                <LoadingSpinner message={t('profileLoading')} />
            </div>
        );
    }

    const profileImages = (targetProfile?.profileImages || [])
        .sort(
            (a, b) =>
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime(),
        )
        .map((image) => getCdnUrl(image.url));

    return (
        <>
            <SubNavigationBar {...handleSubNavigationItems} />
            <div
                className={classNames(
                    // we can get rid of the max-width when we axe the current lib used for the CV page
                    'relative',
                    !collapsed
                        ? 'max-w-result-page ml-50'
                        : 'max-w-result-page-collapsed-sidebar print:max-w-full',
                )}
            >
                {!isPrint && (
                    <div className="print:hidden absolute top-2 right-4 z-5">
                        <TooltipV2>
                            <TooltipContentV2>
                                {t('printTooltip')}
                            </TooltipContentV2>
                            <TooltipTriggerV2>
                                <Button
                                    icon={FaPrint}
                                    className="print:hidden"
                                    onClick={handlePrint}
                                />
                            </TooltipTriggerV2>
                        </TooltipV2>
                    </div>
                )}
                <Switch>
                    <Redirect
                        to={resultsRoutes.dashboardOverview.path}
                        path="/cases/:caseId/targets/:targetId/results"
                        exact={true}
                    />
                    {Object.values(resultsRoutes)
                        .sort((a, b) => b.weight - a.weight)
                        .map((route, index) => (
                            <ProtectedRoute
                                key={index}
                                {...route}
                                props={{
                                    accessToken,
                                    reportState,
                                    targetCompanies,
                                    targetProfile,
                                    targetImages: profileImages,
                                    caseData: {
                                        ...caseData,
                                        keywords: caseKeywords,
                                    },
                                    redFlags,
                                    entityIndicators,
                                    disabled: isRawDataDisabled,
                                }}
                            />
                        ))}
                </Switch>
            </div>
        </>
    );
};
