import axios from 'axios';
import { useMutation, UseMutationResult } from 'react-query';
import { baseURL } from '../../services/nestApiService';
import { ChecklistData } from '../../types/ChecklistData';

export const createChecklist = async ({
    targetId,
    data,
}: {
    targetId: string;
    data: ChecklistData;
}): Promise<ChecklistData> => {
    const { templateId, ...dataValues } = data;
    return (
        await axios.post(`${baseURL}/checklists/targets/${targetId}`, {
            data: dataValues.data,
            templateId: templateId,
        })
    ).data.data;
};

export const useCreateChecklistMutation = ({
    onSuccess,
    onError,
}: {
    onSuccess?: (data: ChecklistData) => void;
    onError?: () => void;
}): UseMutationResult<
    ChecklistData,
    string,
    { targetId: string; data: ChecklistData }
> => {
    return useMutation(createChecklist, {
        onSuccess,
        onError,
    });
};
