import {
    SocialMediaType,
    TargetProfileUsername,
    TargetReportAttribute,
} from '@indicium/common';
import { InfoItem } from './InfoBlock';
import { SimpleEntry, TargetCompanies } from '../../../services/dataService';
import { TFunction } from 'react-i18next';
import { formatDate } from '_utils';

export const socialMediaPostPatterns: Record<string, RegExp> = {
    [SocialMediaType.facebook]:
        /\/((posts|videos|photos)\/)|(photo.php\?)|(story.php)/,
    [SocialMediaType.instagram]: /\/(p|stories|reel)\//,
    [SocialMediaType.linkedin]: /\/(feed|posts)\//,
    [SocialMediaType.twitter]: /\/status\//,
};

export const socialMediaUsernamePatterns: Record<SocialMediaType, RegExp> = {
    [SocialMediaType.facebook]:
        /(?:facebook\.[a-z]{2,}\/(?:profile\.php\?id=)?)([a-zA-Z0-9\-._~:!$&'()*+,;]+)\S*$|^@?([a-zA-Z0-9\-._~:!$&'()*+,;]+)$/,
    [SocialMediaType.instagram]:
        /(?:instagram\.com\/)([a-zA-Z0-9\-._~:!$&'()*+,;]+)\S*$|^@?([a-zA-Z0-9\-._~:!$&'()*+,;]+)$/,
    [SocialMediaType.linkedin]:
        /(?:linkedin\.[a-z]{2,}(?:\.[a-z]{2,})?\/in\/?)([a-zA-Z0-9\-._~:!$&'()*+,;À-ÖØ-öø-ÿА-Яа-яÀ-ÖØ-öø-ÿĀ-ſ]+)\S*$|^@?([a-zA-Z0-9\-._~:!$&'()*+,;À-ÖØ-öø-ÿА-Яа-яÀ-ÖØ-öø-ÿĀ-ſ]+)$/,
    [SocialMediaType.twitter]:
        /(?:(twitter|x)\.[a-z]{2,}\/(?:\/user\/)?)([a-zA-Z0-9\-._~:!$&'()*+,;]+)\S*$|^@?([a-zA-Z0-9\-._~:!$&'()*+,;]+)$/,
    [SocialMediaType.xing]:
        /(?:xing\.[a-z]{2,}\/profile\/)([a-zA-Z0-9\-._~:!$&'()*+,;]+)\S*$/,
};

export const parseSocialMediaProfile = (
    profileUrl: string,
): {
    socialMediaType: SocialMediaType;
    username: string;
} | null => {
    let decodedUrl: string;

    try {
        decodedUrl = decodeURIComponent(profileUrl);
    } catch (err) {
        return null;
    }

    const socialMediaType = detectPlatformFromPostUrl(decodedUrl);

    if (!socialMediaType) {
        return null;
    }

    const username = runUsernameExtractionRegex(
        decodedUrl,
        socialMediaUsernamePatterns[socialMediaType],
    );

    if (!username) {
        return null;
    }

    return {
        username,
        socialMediaType,
    };
};

export const runUsernameExtractionRegex = (
    url: string,
    expression: RegExp,
): string | null => {
    const match = url.match(expression);
    if (!match) {
        return null;
    }

    // If the regex matches, we expect it to contain two capture groups. The first one should match
    // a username within a url, the second one should match a user name provided directly on its own.
    return match?.[2] ?? match?.[1] ?? match?.[0] ?? null;
};

export const detectPlatformFromPostUrl = (
    decodedUrl: string,
): SocialMediaType | null => {
    for (const [platform, pattern] of Object.entries(
        socialMediaUsernamePatterns,
    )) {
        if (pattern.test(decodedUrl)) {
            return platform as SocialMediaType;
        }
    }

    return null;
};

export const filterOutPosts = ({
    profileUrl,
    site,
    sources,
}: TargetProfileUsername & {
    sources: TargetReportAttribute<TargetProfileUsername>['sources'];
}): boolean => {
    if (sources.includes('userInput') || !profileUrl) {
        return true;
    }

    const detectedPlatform = site ?? detectPlatformFromPostUrl(profileUrl);

    // we are currently opting to not filter out entries of which we can't determine the origin
    if (!detectedPlatform) {
        return true;
    }

    // filter out if profile is actually not a profile (e.g. a post or video)
    const pattern: RegExp | undefined =
        socialMediaPostPatterns[detectedPlatform];

    return pattern && !pattern.test(profileUrl);
};

const mapCompanyToSimpleEntries = (
    t: TFunction<'translation'>,
    company: TargetCompanies['companies'][number]['data'],
): SimpleEntry[] => {
    return company.role.map((role) => {
        const positionName = role.value.role || t('unknownPosition');
        const companyName = company.name.value || t('unknownCompany');

        const dateRange = role.value.from
            ? `(${formatDate(role.value.from)} - ${
                  role.value.to ? formatDate(role.value.to) : t('present')
              })`
            : '';
        const value = `${positionName} at ${companyName} ${dateRange}`;

        return {
            value,
            sources: company.name.sources,
        };
    });
};

export const mapTargetResultsToJobInfoItems = (
    t: TFunction<'translation'>,
    companies: TargetCompanies['companies'],
): ReadonlyArray<InfoItem> => {
    const entries = companies
        ?.map((company) => mapCompanyToSimpleEntries(t, company.data))
        .flat()
        .map((entry) => [entry.value, entry.sources] as const);

    const map = new Map(entries);

    return Array.from(map.entries()).map(([value, sources]) => ({
        value,
        sources: Array.from(new Set(sources)),
    }));
};
