import axios from 'axios';
import { useMutation, UseMutationResult } from 'react-query';
import { baseURL } from '../../services/nestApiService';
import { ChecklistData } from '../../types/ChecklistData';

export const updateChecklistTemplate = async ({
    checklistId,
    data,
}: {
    checklistId: string;
    data: ChecklistData;
}): Promise<ChecklistData> => {
    const { isFinalized, ...dataValues } = data;
    return (
        await axios.patch(`${baseURL}/checklists/${checklistId}`, {
            data: dataValues.data,
            isFinalized,
        })
    ).data.data;
};

export const useUpdateChecklistMutation = ({
    onSuccess,
    onError,
}: {
    onSuccess?: (data: ChecklistData) => void;
    onError?: () => void;
}): UseMutationResult<
    ChecklistData,
    string,
    { checklistId: string; data: ChecklistData }
> => {
    return useMutation(updateChecklistTemplate, {
        onSuccess,
        onError,
    });
};
