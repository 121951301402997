import { FC, HTMLAttributes, ReactNode } from 'react';
import classnames from 'classnames';

export type SizeLevel = 'default' | 'xSmall' | 'small' | 'medium';
export type WeightLevel = 'normal' | 'medium' | 'semibold' | 'bold';
export type ColorLevel =
    | 'default'
    | 'red'
    | 'darkRed'
    | 'green'
    | 'yellow'
    | 'blue'
    | 'light'
    | 'natural'
    | 'darkGray';

export const textSize = {
    default: 'text-base',
    small: 'text-sm',
    xSmall: 'text-xs',
    medium: 'text-md',
    label: 'text-sm tracking-wider uppercase',
};

export const textWeight: Record<WeightLevel, string> = {
    normal: 'font-normal',
    medium: 'font-medium',
    semibold: 'font-semibold',
    bold: 'font-bold',
};

export const backgroundColor: Record<ColorLevel, string> = {
    default: 'bg-gray-200',
    darkGray: 'bg-gray-500',
    green: 'bg-green-200',
    red: 'bg-error-2',
    darkRed: 'bg-error-1',
    yellow: 'bg-warning-1',
    blue: 'bg-primary-4',
    light: 'bg-neutral-100',
    natural: 'bg-neutral-300',
};

export const textColor: Record<ColorLevel, string> = {
    default: 'text-neutral-500',
    darkGray: 'text-gray-500',
    red: 'text-error-1',
    darkRed: 'text-font-light',
    green: 'text-green-600',
    yellow: 'text-yellow-900',
    blue: 'text-font-light',
    light: 'text-primary-4',
    natural: 'text-font-dark',
};

interface BadgeProps extends HTMLAttributes<HTMLSpanElement> {
    children: ReactNode;
    size?: SizeLevel;
    weight?: WeightLevel;
    background?: ColorLevel;
    paddingClassName?: string;
    marginClassName?: string;
    className?: string;
}

export const Badge: FC<BadgeProps> = ({
    children,
    size = 'default',
    weight = 'bold',
    background = 'default',
    paddingClassName = 'px-3 py-2',
    marginClassName = 'my-auto',
    className,
    ...props
}: BadgeProps) => {
    return (
        <span
            className={classnames(
                'inline-flex items-center justify-center leading-none rounded-full whitespace-nowrap font-jost',
                textSize[size],
                textWeight[weight],
                backgroundColor[background],
                textColor[background],
                paddingClassName,
                marginClassName,
                className,
            )}
            {...props}
        >
            {children}
        </span>
    );
};
