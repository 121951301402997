/* istanbul ignore file */
export const de = {
    translation: {
        requestSupportAgent: {
            emailSubject: `Anfrage zu Experten-Analyse`,
            emailBody: `Liebes Indicium Team,\nich möchte gerne die Experten-Analyse für den folgenden Fall in Anspruch nehmen: {{link}}.\nBitte kontaktieren Sie mich mit weiteren Details.`,
            button: `Experten-Analyse anfragen`,
            buttonPopover: `Sie können weitere Analysen eines OSINT-Experten anfragen. Es fallen extra Gebühren an.`,
        },
        checklists: {
            title: 'Checklisten',
            resetForm: 'Formular zurücksetzen',
            sendForm: 'Formular abschicken',
            errorLoading: 'Fehler beim Laden der Checklist-Vorlagen',
            errorUpdatingChecklist: 'Fehler beim Aktualisieren der Checkliste',
            saved: 'Die Checkliste wurde gespeichert',
            noChecklistTemplate:
                'Keine Checklisten-Vorlage gefunden, Sie können hier eine neue Checklisten-Vorlage erstellen: ',
            finalize:
                'Sind Sie sich sicher, dass alle Ihre Antworten korrekt sind?',
            finalizeDescription:
                'Bitte bestätigen Sie nur, wenn Sie sich zu 100% sicher sind. Diese Aktion kann nicht rückgängig gemacht werden.',
            finalizeConfirm: 'Ja, bestätigen',
            finalizeCancel: 'Nein, zurück zu dem Formular',
        },
        checklistTemplate: {
            saveTooltip:
                'Speichern Sie diese Checklisten-Vorlage, bestehende Berichte werden nicht beeinflusst',
            errorLoading: 'Fehler beim Laden der Checklisten-Vorlagen',
            errorUpdating: 'Fehler beim Aktualisieren der Checklisten-Vorlage',
            errorCreating: 'Fehler beim Erstellen der Checklisten-Vorlage',
            title: 'Checklisten-Vorlage',
            reset: 'Zurücksetzen',
            resetDescription:
                'Dies setzt die Checklisten-Vorlage auf ihren Standardzustand zurück. Sie verlieren alle nicht gespeicherten Änderungen.',
            resetConfirm: 'Vorlage zurücksetzen',
            save: 'Speichern',
            saved: 'Die Checklisten-Vorlage wurde gespeichert',
            newItem: 'Neues Element',
            noFields:
                'Die Checklisten-Vorlage hat keine Felder. Bitte fügen Sie einige mit der Schaltfläche unten hinzu.',
            addField: 'Wählen Sie den Feldtyp aus, den Sie hinzufügen möchten',
            validationErrorOptionsEmpty:
                'Ein Mehrfachauswahlfeld hat keine Optionen',
            validationErrorLabelEmpty: 'Bezeichnungen dürfen nicht leer sein',
            box: {
                title: 'Titel',
                titleDesc: 'Ein schreibgeschützter Kopfbereich',
                description: 'Beschreibung',
                descriptionDesc: 'Ein schreibgeschützter Absatz',
                text: 'Text',
                textDesc: 'Ein Eingabefeld für Text',
                textarea: 'Textbereich',
                textareaDesc: 'Ein Textbereich für längeren Text',
                checkbox: 'Mehrfachauswahl',
                checkboxDesc: 'Feld für Mehrfachauswahl',
                radio: 'Einzelauswahl',
                radioDesc: 'Feld für Einzelauswahl',
            },
            new: {
                title: 'Neuer Titel',
                description: 'Neue Beschreibung',
                text: 'Neuer Text',
                textarea: 'Neuer Textbereich',
                checkbox: 'Neue Mehrfachauswahl',
                radio: 'Neue Einzelauswahl',
            },
        },
        checklistTemplateField: {
            title: {
                title: 'Titel',
            },
            description: {
                title: 'Beschreibung',
            },
            text: {
                title: 'Text',
            },
            textarea: {
                title: 'Textbereich',
            },
            checkbox: {
                title: 'Mehrfachauswahl',
                label: 'Bezeichnung',
                options: 'Optionen',
                newOptionLabel: 'Neue Option',
                addOption: 'Option hinzufügen',
            },
            radio: {
                title: 'Einzelauswahl',
                label: 'Bezeichnung',
                options: 'Optionen',
                newOptionLabel: 'Neue Option',
                addOption: 'Option hinzufügen',
            },
            required: 'Erforderlich',
            emptyOptions: 'Dieses Feld muss mindestens zwei Optionen haben',
            empty: 'Dieses Feld darf nicht leer sein',
        },
        checklist: {
            empty: 'Die Checkliste ist leer. Neue Felder, die Sie hinzufügen, werden hier angezeigt.',
        },
        note: 'Notiz',
        lexisNexis: 'LexisNexis',
        serpNews: 'Search Engine News',
        subscriptionAboutToExpire:
            'Sehr geehrter Indicium Kunde, Ihr Abonnement des Indicium Digital Analyst läuft in weniger als einer Woche ab ({{expiration_date}}). Wir freuen uns, wenn Sie Ihr Abonnement verlängern möchten. Setzen Sie sich gerne mit uns in Verbindung, um das weitere Vorgehen zu besprechen.',
        subscriptionExpired:
            'Sehr geehrter Indicium Kunde, Ihr Abonnement des Indicium Digital Analyst ist seit {{expiration_date}} abgelaufen. Wir freuen uns, wenn Sie Ihr Abonnement verlängern möchten. Setzen Sie sich gerne mit uns in Verbindung, um das weitere Vorgehen zu besprechen.',
        reportStatusOverview: {
            inProgress: {
                header: 'Daten für den Bericht werden abgerufen und verarbeitet.',
                subHeader:
                    'Die sichtbaren Ergebnisse sind möglicherweise unvollständig.',
            },
            inProgressGenerationOnly: {
                header: 'Datenabruf abgeschlossen. Der vollständige Bericht wird jetzt erstellt.',
                subHeader:
                    'Die sichtbaren Ergebnisse sind möglicherweise noch unvollständig.',
            },
            completedWithoutSuspicions: {
                header: 'Der Bericht wurde erfolgreich fertiggestellt - es wurden keine verdächtigen Informationen gefunden.',
                subHeader:
                    'Trotz einer umfangreichen Suche in allen ausgewählten Datenquellen konnten wir keine verdächtigen Inhalte für diesen Bericht finden.',
            },
            completedWithSuspicions: {
                header: 'Der Bericht wurde erfolgreich fertiggestellt. Es wurden verdächtige Informationen gefunden.',
                subHeader:
                    'Potenziell problematischer Inhalt gefunden - bitte prüfen.',
            },
            withIrregularitiesWithoutSuspicions: {
                header: 'Der Bericht wurde mit Fehlern fertiggestellt. Es wurden keine verdächtigen Informationen gefunden.',
                subHeader:
                    'Dieser Bericht kann aufgrund eines Fehlers bei der Verarbeitung eines oder mehrerer Abschnitte unvollständig sein. In den Abschnitten, die erfolgreich fertiggestellt wurden, konnten wir keine verdächtigen Inhalte finden.',
            },
            withIrregularitiesWithSuspicions: {
                header: 'Der Bericht wurde mit Fehlern fertiggestellt. Es wurden verdächtige Informationen gefunden.',
                subHeader:
                    'Dieser Bericht kann aufgrund eines Fehlers bei der Verarbeitung eines oder mehrerer Abschnitte unvollständig sein. In den erfolgreich abgeschlossenen Abschnitten haben wir jedoch potenziell problematische Inhalte gefunden - bitte überprüfen Sie diese.',
            },
            steps: {
                ANALYZING_COMPANIES: 'Analysiere Firmen',
                ANALYZING_SOCIAL_MEDIA: 'Analysiere Soziale Medien',
                ANALYZING_PRESS_WEBSITES: 'Analysiere Presse und Websiten',
                GENERATING_NETWORK_GRAPH: 'Erstelle Netzwerk-Graph',
                GENERATING_REPORT: 'Erstelle Report',
            },
            stepsComplete: 'Schritte fertiggestellt',
            stepsFailed: 'Schritte fehlerhaft',
        },
        toasts: {
            createdTargets: '{{count }} neue Bewerber:innen erstellt',
            userUpdated: 'Nutzer aktualisiert',
            userUpdateFailed: 'Nutzer konnt nicht aktualisiert werden',
            deleteUser: 'Nutzer gelöscht',
            deleteUserFailed: 'Nutzer konnte nicht gelöscht werden',
            userCreated: 'Nutzer erstellt',
            caseCreated: 'Position erstellt',
            deleteCase: 'Position gelöscht',
            deleteCaseFailed: 'Position konnte nicht gelöscht werden',
            createdTarget: 'Bewerber:in erstellt',
            deleteTarget: 'Bewerber:in gelöscht',
            deleteTargetFailed: `Bewerber:in konnte nicht gelöscht werden`,
            suspicionStatusUpdated: 'Verdacht bearbeitet',
            suspicionItemLinked: 'Verdacht bearbeitet',
            mfaDisabled: 'MFA deaktiviert',
        },
        confirmationModal: {
            defaultTitle: 'Sicher, dass Sie mt dem Lǒschen fortfahren wollen?',
            defaultBody: 'Diese Aktion kann nicht rückgängig gemacht werden.',
        },
        confirmAndAddNew: 'Bestätigen und neu hinzufügen',
        confirm: 'Bestätigen',
        cancel: 'Abbrechen',
        current: 'Aktuell',
        in: 'in',
        targetWizard: {
            headline: 'Neue Zielperson erstellen',
            targetCreationInstructionMsg: `Sie können entweder einen Lebenslauf (PDF-Datei) hochladen oder
                                            die Daten manuell im linken Bereich eingeben.`,
            formInstructionMsg: `Klicken Sie in der Auswahl auf eine der Karten, um weitere Daten hinzuzufügen.`,
            formDropDownCopyMsg: `Fügen Sie neue Details über die Person aus dem Drop-Down Menü hinzu oder klicken Sie
                                    den unteren Knopf, um den Bericht zu erstellen.`,
            fields: {
                personalDetails: {
                    title: 'Persönliche Details',
                    description: 'Name, Titel und Geschlecht',
                    helpText:
                        'Fügen Sie die persönlichen Daten der Zielperson hinzu',
                },
                birthInfo: {
                    title: 'Geburtsinformationen',
                    description: 'Geburtsdatum und -ort',
                    helpText:
                        'Fügen Sie die Geburtsinformationen der Zielperson hinzu',
                },
                jobs: {
                    title: 'Professionelle Erfahrung',
                    description: 'Berufliche Informationen',
                    helpText:
                        'Fügen Sie die berufliche Erfahrung der Zielperson hinzu',
                },
                residentialInfo: {
                    title: 'Informationen zum Wohnort',
                    description: 'Aktueller oder früherer Wohnort',
                    helpText:
                        'Fügen Sie die Wohnortinformationen der Zielperson hinzu',
                },
                nationalities: {
                    title: 'Nationalitäten',
                    description: 'Angabe der Staatsangehörigkeit',
                    helpText:
                        'Fügen Sie die Nationalitäten der Zielperson hinzu',
                },
                contactEmails: {
                    title: 'Emails',
                    description: '...als Kontaktmöglichkeit zur Zielperson',
                    helpText:
                        'Fügen Sie die Email-Adressen der Zielperson hinzu',
                },
                contactPhones: {
                    title: 'Telefonnummern',
                    description: '...als Kontaktmöglichkeit zur Zielperson',
                    helpText:
                        'Fügen Sie die Telefonnummern der Zielperson hinzu',
                },
                contactWebsites: {
                    title: 'Webseiten',
                    description: '...als Kontaktmöglichkeit zur Zielperson',
                    helpText: 'Fügen Sie die Webseiten der Zielperson hinzu',
                },
                educationInfo: {
                    title: 'Bildung',
                    description: 'Besuchte Bildungseinrichtungen',
                    helpText:
                        'Fügen Sie die Bildungsinformationen der Zielperson hinzu',
                },
                linkedinLinks: {
                    title: 'LinkedIn',
                    description:
                        'Berufsbezogene Social-Media Profile der Zielperson',
                    helpText:
                        'Fügen Sie die LinkedIn-Profile der Zielperson hinzu',
                },
                xingLinks: {
                    title: 'Xing',
                    description:
                        'Berufsbezogene Social-Media Profile der Zielperson',
                    helpText: 'Fügen Sie die Xing-Profile der Zielperson hinzu',
                },
                facebookLinks: {
                    title: 'Facebook',
                    description: 'Social-Media Profile der Zielperson',
                    helpText:
                        'Fügen Sie die Facebook-Profile der Zielperson hinzu',
                },
                twitterLinks: {
                    title: 'X (Twitter)',
                    description: 'Social-Media Profile der Zielperson',
                    helpText:
                        'Fügen Sie die Twitter-Profile der Zielperson hinzu',
                },
                instagramLinks: {
                    title: 'Instagram',
                    description: 'Social-Media Profile der Zielperson',
                    helpText:
                        'Fügen Sie die Instagram-Profile der Zielperson hinzu',
                },
                images: {
                    title: 'Bilder',
                    description: 'images information to be added',
                    helpText: 'Fügen Sie die Bilder der Zielperson hinzu',
                },
                keywords: {
                    title: 'Themen',
                    description:
                        '...die in der Suche gezielt (nicht) berücksichtigt werden sollen',
                    helpText:
                        'Fügen Sie die Themen hinzu, die bei der Suche nach der Zielperson berücksichtigt bzw. gezielt ausgelassen werden sollen',
                },
                note: {
                    title: 'Notiz',
                    description: 'Zusätzliche Informationen',
                    helpText:
                        'Fügen Sie zusätzliche Informationen über die Zielperson hinzu, die sich nicht auf den Bericht auswirken',
                },
                skills: {
                    title: 'Skills',
                    description: 'Skills des Bewerbenden',
                    helpText: 'Skills hinzufügen',
                },
                hobbies: {
                    title: 'Hobbies',
                    description: 'Hobbies des Bewerbenden',
                    helpText: 'Hobby hinzufügen',
                },
                description: {
                    title: 'Persönliches Profil',
                    description: 'Persönliches Profil des Bewerbenden',
                    helpText: 'Persönliches Profil hinzufügen',
                },
            },
            editSectionBtn: 'Abschnitt bearbeiten',
            deleteSectionBtn: 'Abschnitt löschen',
            createTargetBtn: 'Personenbericht erstellen',
            resetForm: 'Formular zurücksetzen',
            unsavedChangesTitle:
                'Sie haben ungespeicherte Änderungen. Möchten Sie wirklich abbrechen?',
            resetFormConfirmation:
                'Sind Sie sicher, dass Sie alle eingegebenen Daten löschen möchten?',
        },
        industryOptions: {
            agriculture_and_farming: 'Landwirtschaft und Agrarwesen',
            aerospace_and_defense: 'Luft- und Raumfahrt sowie Verteidigung',
            artificial_intelligence_and_machine_learning:
                'Künstliche Intelligenz und Maschinelles Lernen',
            architecture_and_urban_planning: 'Architektur und Stadtplanung',
            automotive: 'Automobilindustrie',
            biotechnology: 'Biotechnologie',
            chemicals_and_petrochemicals: 'Chemie und Petrochemie',
            consumer_electronics: 'Unterhaltungselektronik',
            consulting: 'Beratung',
            construction: 'Bauwesen',
            cybersecurity: 'Cybersicherheit',
            digital_marketing_and_advertising:
                'Digitales Marketing und Werbung',
            e_commerce: 'E-commerce',
            education: 'Bildung',
            electronics_and_semiconductors: 'Elektronik und Halbleiter',
            energy: 'Energie (Öl, Gas, Erneuerbare)',
            entertainment_and_media: 'Unterhaltung und Medien',
            environmental_services:
                'Umweltdienstleistungen (Abfallwirtschaft, Recycling)',
            event_planning_and_management:
                'Veranstaltungsplanung und -management',
            fashion_and_apparel: 'Mode und Bekleidung',
            finance_and_banking: 'Finanzen und Banken',
            fitness_and_wellness: 'Fitness und Wellness',
            food_and_beverage: 'Lebensmittel und Getränke',
            forestry_and_timber: 'Forstwirtschaft und Holzindustrie',
            gaming_and_esports: 'Gaming und E-Sport',
            healthcare: 'Gesundheitswesen',
            hospitality: 'Hotellerie und Gastronomie',
            human_resources_and_recruitment: 'Personalwesen und Recruiting',
            information_technology: 'Informationstechnologie',
            interior_design_and_home_improvement:
                'Innenarchitektur und Heimverbesserung',
            insurance: 'Versicherungen',
            legal_services: 'Rechtsdienstleistungen',
            logistics_and_supply_chain: 'Logistik und Lieferkette',
            marine_and_maritime: 'Schifffahrt und maritime Industrie',
            mining_and_metals: 'Bergbau und Metallindustrie',
            music_and_arts: 'Musik und Kunst',
            nonprofit: 'Gemeinnützige und wohltätige Organisationen',
            personal_care_and_beauty: 'Körperpflege und Schönheit',
            pharmaceuticals: 'Pharmazeutika',
            printing_and_publishing: 'Druck und Verlagswesen',
            public_administration_and_government:
                'Öffentliche Verwaltung und Regierung',
            real_estate: 'Immobilien',
            research_and_development: 'Forschung und Entwicklung (F&E)',
            retail: 'Einzelhandel',
            space_exploration_and_technology: 'Raumfahrt und Technologie',
            sports_and_recreation: 'Sport und Freizeit',
            telecommunications: 'Telekommunikation',
            textile_manufacturing: 'Textilherstellung',
            transportation: 'Transport (Luftfahrt, Bahn, Schifffahrt)',
            travel_and_tourism: 'Reisen und Tourismus',
            venture_capital_and_private_equity:
                'Venture Capital und Private Equity',
        },
        educationLevelOptions: {
            primary_school: 'Primary School',
            secondary_school: 'Secondary School',
            high_school_diploma: 'High School Diploma',
            associate_degree: 'Associate Degree',
            online_course: 'Online Course',
            bachelors_degree: "Bachelor's Degree",
            masters_degree: "Master's Degree",
            mba: 'MBA',
            phd: 'PhD',
        },
        bulkUpload: {
            title: 'CV-Upload',
            subtitle:
                'Sie können maximal {{max}} CV gleichzeitig hochladen, Sie haben noch {{count}} übrig',
            error: 'Ein Fehler ist aufgetreten',
            uploading: 'Datei wird hochgeladen...',
            submitting: 'Datei wird verarbeitet...',
            processed: 'Datei verarbeitet',
            success: 'Datei hochgeladen',
            noFilesLeft: 'Sie können keine weiteren Dateien hochladen',
            dragAndDrop1: 'Ziehen Sie eine oder mehrere PDF-Dateien hierher',
            dragAndDrop2: 'oder klicken Sie hier zum Durchsuchen',
            cancel: 'Abbrechen',
            create: 'Erstellen',
            fileAlreadyUploaded:
                'Eine Datei mit demselben Namen existiert bereits',
            fileTooLarge: 'Die Datei ist größer als 25MB',
        },
        noSuspicionsDescription:
            'Nach einer ausführlichen Prüfung aller gewählten Datenquellen, konnten wir für diesen Bericht keine Auffälligkeiten feststellen.',
        ourDataSources: 'Unsere Datenquellen',
        search: 'Suchen...',
        startTypingToSearch: 'Suche mit Tippen beginnen',
        stats: {
            over: 'Über',
            billion: 'Mrd.',
            million: 'Mio.',
            identities: 'Identitäten',
            phoneNumbers: 'Telefonnummern',
            globalEmails: 'Email-Adressen',
            uniqueNewsArticlesAndSocials:
                'Einzigartige Nachrichtenartikel und Social-Media-Beiträge',
            onlineNewsSources: 'Online-Nachrichtenquellen',
            socialMediaFeeds: 'Social-Media-Feeds',
            globalCompanyEntries: 'Internationale Handelsregistereinträge',
        },
        printHiddenElements:
            'Einige Elemente werden in diesem Druckbericht nicht angezeigt, bitte prüfen Sie die Online-Version für weitere Details.',
        preparingPrint:
            'Wir bereiten die Druckversion Ihres Berichts vor. Bitte haben Sie einen Moment Geduld.',
        printVersionNotice: {
            first: 'Dies ist eine Druckversion eines Indicium-Berichts. Mit der Nutzung dieser Version stimmen Sie den Nutzungsbedingungen und der Datenschutzrichtlinie von Indicium zu. Die unautorisierte Verteilung oder Reproduktion dieses Berichts ist verboten.',
            second: 'Berichtsdruck erstellt am $$printversion durch $$customerId.',
        },
        printTooltip:
            'Drucken Sie diesen Bericht aus oder exportieren Sie ihn als PDF (Sie können auch Strg+P verwenden).',
        dismiss: 'Entlassen',
        spotlight: {
            startTutorial: 'Tutorial starten',
            onboardingLeftMenuLogoHeading: 'Willkommen bei Indicium',
            onboardingLeftMenuLogoDescription:
                'Sie können jederzeit auf den Home-Screen zurückkehren, indem Sie auf das Indicium Logo klicken.',
            onboardingTopMenuBreadcrumsHeading: 'Navigation',
            onboardingTopMenuBreadcrumsDescription:
                'Die Navigation zeigt Ihnen immer an, wo Sie sich befinden. Sie können jederzeit über diese Navigation zu den vorherigen Seiten zurückkehren.',
            onboardingTopMenuLanguageHeading: 'Einstellung der Sprache',
            onboardingTopMenuLanguageDescription:
                'Bitte wählen Sie hier Ihre präferierte Sprache aus.',
            onboardingTopMenuProfileHeading: 'Profil',
            onboardingTopMenuProfileDescription:
                'Klicken Sie hier um zu Ihren Profileinstellungen zu gelangen. Sie können Multifaktor-Authentifizierung aktivieren und Ihr Passwort ändern.',
            onboardingLandingPageSearchHeading: 'Globale Suche',
            onboardingLandingPageSearchDescription:
                'Sie können alle Positionen und Bewerber:innen mit dieser Suchleiste durchsuchen. Wenn Sie auf einen Eintrag klicken, werden Sie direkt dorthin geleitet.',
            onboardingLeftMenuCasesHeading: 'Übersicht der Positionen',
            onboardingLeftMenuCasesDescription:
                'Hier finden Sie alle erstellten Positionen, auf die Sie Zugriff haben.',
            onboardingCaseListCreatePositionHeading: 'Neue Position anlegen',
            onboardingCaseListCreatePositionDescription:
                'Als erstes muss eine Position angelegt werden, bevor ein Bewerber:innen-Report angelegt werden kann. Eine Position ist eine Gruppierung von Bewerber:innen. Für alle Bewerber:innen derselben Position werden dieselben Analysen und Quellen zugrunde gelegt.',
            onboardingCaseNewOptionsHeading: 'Auswahl des Reports',
            onboardingCaseNewOptionsDescription:
                'Indicium bietet Ihnen 3 Optionen für Reports an, die unterschiedliche Analysen durchführen und Quellen nutzen. Von links nach rechts erhalten die Reports mehr Daten und Quellen. Bitte stellen Sie sicher, dass Sie auf Basis der Position für die Sie einstellen, als auch der Einwilligung die Sie haben, den korrekten Typ auswählen.',
            onboardingCaseNewContinueHeading: 'Fortfahren',
            onboardingCaseNewContinueDescription:
                'Klicken Sie auf die Schaltfläche Weiter, um zum nächsten Schritt zu gelangen.',
            onboardingCaseNewTitleHeading: 'Positionstitel',
            onboardingCaseNewTitleDescription:
                'Geben Sie einen Titel für die Position ein. Dieser Titel wird in der Übersicht angezeigt.',
            onboardingTargetFormUploadPdfHeading: 'PDF hochladen',
            onboardingTargetFormUploadPdfDescription:
                'Hier können Sie einen CV in Form eines PDF-Dokuments hochladen. Das Dokument wird automatisch verarbeitet und alle notwendigen und relevanten Informationen werden extrahiert. Sie können auch direkt eine PDF-Datei per Drag&Drop hierhin ziehen.',
            onboardingTargetFormBasicDataHeading: 'Basisdaten',
            onboardingTargetFormBasicDataDescription:
                'Sie müssen hier die Grunddaten des Bewerbers angeben.',
            onboardingTargetFormCompanyDataHeading: 'Unternehmensdaten',
            onboardingTargetFormCompanyDataDescription:
                'Sie müssen auch die Unternehmensdaten des Bewerbers hier angeben.',
            onboardingTargetFormNextHeading: 'Kandidaten anfordern',
            onboardingTargetFormNextDescription:
                'Klicken Sie auf die Schaltfläche, um Bewerber anzufordern, die mit den bereitgestellten Daten übereinstimmen. Je mehr Informationen Sie bereitstellen, desto besser werden die Ergebnisse sein.',
            onboardingTargetCandidatesunselectedCandidatesHeading:
                'Nicht ausgewählt',
            onboardingTargetCandidatesunselectedCandidatesDescription:
                'In diesem Tab sehen Sie alle gefundenen Ergebnisse, die nicht automatisch zugeordnet oder verworfen werden konnten. Sie können diese manuell einbeziehen oder ausschließen.',
            onboardingTargetCandidatesdecidedCandidatesHeading: 'Entschieden',
            onboardingTargetCandidatesdecidedCandidatesDescription:
                'In diesem Tab sehen Sie alle Ergebnisse, die zugeordnet oder verworfen wurden, eine automatische Auswahl wurde bereits für Sie basierend auf den bereitgestellten Daten getroffen, aber Sie können die Liste manuell bearbeiten.',
            onboardingTargetCandidatesExcludeHeading: 'Ausschließen',
            onboardingTargetCandidatesExcludeDescription:
                'Klicken Sie auf die Schließen-Schaltfläche, um den aktuellen Kandidaten von der Liste auszuschließen.',
            onboardingTargetCandidatesIncludeHeading: 'Einschließen',
            onboardingTargetCandidatesIncludeDescription:
                'Klicken Sie auf die Einschließen-Schaltfläche, um den aktuellen Kandidaten in die Liste aufzunehmen.',
            onboardingTargetCandidatesRequestHeading: 'Bericht anfordern',
            onboardingTargetCandidatesRequestDescription:
                'Klicken Sie schließlich auf die Schaltfläche Bericht anfordern, um den Bericht mit den ausgewählten Kandidaten zu erstellen.',
        },
        doYouNeedHelp: 'Brauchen Sie Hilfe?',
        unknown: 'unbekannt',
        unknownPosition: 'Unbekannte Position',
        unknownCompany: 'Unbekanntes Unternehmen',
        intlNumber: '{{val, number}}',
        intlDateTime: '{{val, datetime}}',
        schemaValidation: {
            invalidDate: 'Ungültiges Datum',
            invalidDateStart: 'Ungültiges Startdatum',
            invalidDateEnd: 'Ungültiges Enddatum',
            invalidDateRange: 'Ungültiger Zeitraum',
            invalidDateRangeEndWithoutStart: 'Enddatum erfordert Startdatum',
            invalidStartDateGraterThanCurrentDate:
                'Startdatum kann nicht nach dem aktuellen Datum liegen',
            invalidEndDateGraterThanCurrentDate:
                'Enddatum kann nicht nach dem aktuellen Datum liegen',
            invalidEmail: 'Ungültige E-Mail-Adresse',
            invalidPhone: 'Ungültige Telefonnummer',
            invalidUrl: 'Ungültige URL',
            invalidSocialMediaUrl: {
                facebook: 'Ungültige Facebook-URL',
                instagram: 'Ungültige Instagram-URL',
                linkedin: 'Ungültige Linkedin-URL',
                xing: 'Ungültige Xing-URL',
                twitter: 'Ungültige X-URL',
            },
            invalidFacebookUrl: 'Ungültige Facebook-URL',
            invalidInstagramUrl: 'Ungültige Instagram-URL',
            invalidLinkedinUrl: 'Ungültige Linkedin-URL',
            invalidXingUrl: 'Ungültige Xing-URL',
            invalidTwitterUrl: 'Ungültige X-URL',
            tooFewCharacters: 'Zu wenige Zeichen',
            invalidNumber: 'Ungültige Zahl',
            invalidInteger: 'Ungültige Ganzzahl',
            invalidPositiveNumber: 'Zahl muss größer als 0 sein',
            invalidCharacter: 'Ungültiges Zeichen',
            required: 'Pflichtfeld (muss angegeben werden)',
            toLessCharacters: 'Zu wenige Zeichen',
            minimumCharacters:
                'Muss mindestens {{minLength}} Zeichen lang sein',
            maxLength:
                'Die maximale Länge des Feldes wurde überschritten ({{maxLength}} Zeichen)',
        },
        passwordValidation: {
            min: 'Mindestens {{minLength}} Zeichen',
            oneNumber: 'Enthält mindestens eine Ziffer',
            oneSpecialChar: 'Enthält mindestens ein Sonderzeichen',
            oneUppercase: 'Enthält mindestens einen Großbuchstaben',
            oneLowercase: 'Enthält mindestens einen Kleinbuchstaben',
            passwordMatch: 'Passwörter sind gleich',
        },
        targetNewForm: {
            targetCreationInProgress: 'Target wird angelegt',
            targetDeletionNotAllowed: 'Target löschen nicht erlaubt',
            dateOfBirthLabel: 'Geburtstag oder -jahr',
            dateOfBirthPlaceholder: 'TT.MM.JJJJ oder JJJJ',
        },
        dropPdfToUpload:
            'Zum Hochladen von PDF ablegen und neues Ziel generieren',
        uploadPdf: 'PDF hochladen',
        educationHeadline: 'Bildung',
        educationTitle: 'Titel',
        educationTitlePlaceholder:
            'z.B. Master in Business Administration (MBA)',
        isCurrentInstitution: 'Aktuelle Bildungseinrichtung',
        educationType: 'Typ',
        educationDate: 'Datum (Datum, Jahr, Datumsbereich, Jahresbereich)',
        educationInstitutionName: 'Bildungseinrichtung',
        educationInstitutionLocation: 'Ort',
        removeEntry: 'Eintrag entfernen',
        addEntry: 'Eintrag hinzufügen',
        professionalExperienceHeadline: 'Berufserfahrung',
        jobTitle: 'Berufsbezeichnung',
        jobDate: 'Datum (Datum, Jahr, Datumsbereich, Jahresbereich)',
        dateOrYear: 'Vollständiges Datum (TT.MM.JJJJ) oder Jahr (JJJJ)',
        dateRange: 'Datum (Zeitraum)',
        dateRangeStart: 'Anfang',
        dateRangeEnd: 'Ende',
        jobCompanyName: 'Firmenname',
        jobCompanyLocation: 'Firmenort',
        jobCompanyIndustry: 'Industry',
        educationLevel: 'Level',
        jobCompanyCity: 'Firmenstadt',
        selfEmployed: 'Selbstständig',
        isCurrentPosition: 'Aktuelle Position',
        jobCompanyWebsite: 'Firmenwebseite',
        jobCompanyVatNumber: 'Umsatzsteuernummer des Unternehmens',
        jobCompanyCommercialRegisterNumber:
            'Handelsregisternummer des Unternehmens',
        others: 'Andere',
        required: 'Pflichtfeld',
        requiredNameError: 'Vor- und Nachname müssen angegeben werden.',
        requiredCompanyError: 'Firma muss angegeben werden',
        emailAlreadyExists:
            'Die eingegebene E-Mail-Adresse ist bereits vergeben.',
        cvParsedWarning:
            'Einige Felder wurden aus einem Lebenslauf geparst. Bitte überprüfen Sie sie sorgfältig',
        cvUploadFailed:
            'Der Lebenslauf konnte nicht hochgeladen werden, Sie können es erneut versuchen',
        autofilled: 'Autofilled',
        recommended: 'Empfohlen',
        preselection: 'Restliche Kandidaten zuweisen',
        preselectionTitle: 'Auswahl',
        preselectionRunning:
            'Automatisierte Auswahl der Kandidat:innen in Bearbeitung',
        preselectionFailed: 'Re-sorting failed',
        preselectionFinished: 'Neusortierung fehlgeschlagen ',
        preselectionFinished2: ' neue Kandidaten wurden hinzugefügt',
        back: 'Zurück',
        continue: 'Weiter',
        getStarted: 'Loslegen',
        save: 'Speichern',
        show: 'Anzeigen',
        hide: 'Verbergen',
        showMore: 'Mehr anzeigen',
        showLess: 'Weniger anzeigen',
        download: 'Herunterladen',
        archive: 'Archivieren',
        delete: 'Löschen',
        open: 'Öffnen',
        duplicate: 'Duplizieren',
        edit: 'Bearbeiten',
        admin: 'Admin',
        cases: 'Positionen',
        user: 'Nutzer',
        users: 'Nutzer',
        profileImage: 'Profilbild',
        editProfile: 'Profil bearbeiten',
        editUserData: 'Nutzerdaten anpassen',
        changePassword: 'Passwort ändern',
        emailPreferences: {
            groupLabel: 'DSGVO-Email-Einstellungen',
            groupLabelTooltip:
                'Um an die bevorstehende Löschung nach DGSVO zu erinnern, werden für jeden Report eine Woche und nochmals einen Tag zuvor jeweils eine Erinnerungs-Email verschickt. Sie haben die Wahl für welche Reports Sie diese Erinnerungen erhalten möchten.',
            allInOrg: 'Erinnerung für alle Reports',
            allInOrgTooltip:
                'Alle Reports in den Organizationen in denen ich Adminrechte besitzen + alle Reports, die ich selbst erstellt habe',
            ownReports: 'Erinnerung nur für meine Reports',
            ownReportsTooltip: 'Nur Reports, die ich selbst erstellt habe',
            none: 'Keine Erinnerungen',
            noneTooltip:
                'Ich bin mir der Löschung der Rohdaten nach DSGVO von Reports 30 Tage nach Erstellung bewusst und möchte keine Erinnerungen erhalten.',
        },
        fallbackProfileImage: 'Fallback Profilbild',
        fallbackImageLabel: 'Keine Profilbilder gefunden',
        noResult: 'Kein Ergebnis',
        loading: {
            searchingCandidates:
                'Datenquellen werden durchsucht und mögliche Profile werden zusammengestellt.\nDies kann einige Minuten dauern.',
            collectingData:
                'Auswahl erfolgreich übermittelt.\nAlle Quellen werden überprüft und der endgültige Report wird zusammengestellt.\nDies dauert in der Regel etwa eine Stunde. Sie werden per E-Mail benachrichtigt, sobald der Bericht fertig ist.',
        },
        moreInfo: 'Weitere Angaben',

        riskAnalysis: {
            title: 'Risikoanalyse',
            profileInfo: {
                createdOn: 'Erstellt am',
            },
            suspicionRisk: {
                total: 'Insgesamt',
                totalRedFlags: 'Warnsignale',
                totalConfirmed: 'bestätigt',
                totalPending: 'ausstehend',
                totalRemoved: 'entfernt',
                loadingMessage: 'Berechnung der Gesamtanzahl der Ergebnisse',
                high: {
                    title: 'Hohes Risiko',
                    tooltip:
                        'Inhalte mit hohem Risiko deuten darauf hin, dass ein konkreter Bezug zu einer oder mehreren Straftaten besteht bzw. die Person mit hoher Wahrscheinlichkeit direkt verwickelt war oder sogar verurteilt wurde. Außerdem gelten alle Red Flags, die über den Compliance Catalyst gefunden wurden, als hohes Risiko.',
                },
                medium: {
                    title: 'Mittleres Risiko',
                    tooltip:
                        'Inhalte mit mittlerem Risiko deuten darauf hin, dass die Person im Verdacht steht, eine Straftaten begangen zu haben. Außerdem gilt jegliches offensives Verhalten auf Social Media Kanälen als mittleres Risiko.',
                },
                low: {
                    title: 'Niedriges Risiko',
                    tooltip:
                        'Inhalte mit niedrigem Risiko deuten darauf hin, dass die Person Opfer einer Straftaten wurde. Außerdem tauchen hier die Eigenen Stichworte auf, sowie generelle Auffälligkeiten.',
                },
                none: {
                    title: 'Unbestimmtes Risiko',
                    tooltip:
                        'Das System war nicht in der Lage, das Risiko zu bestimmen.',
                },
            },
            filters: {
                suspicion: 'Verdacht',
                participation: 'Mitwirkung',
                suspicionSource: 'Verdachtsquelle',
                contentType: 'Inhaltstyp',
                reviewStatus: 'Prüfungsstatus',
                resetAll: 'Alles zurücksetzen',
            },
            notFound: {
                headline: 'Keine Verdächte gefunden',
                message:
                    'Versuchen Sie, Ihre Filterauswahl zu ändern, um Verdächte zu finden.',
            },
            articleMeta: {
                suspicions: 'Verdächtigungen',
                suspicion: 'Verdacht',
                relatesTo: 'Inhalt bezieht sich auf',
                systemSuspicions: 'Vom System zugeschriebene Verdächtigungen',
                userCreatedSuspicions:
                    'Von Benutzern zugeschriebene Verdächtigungen',
                editSuspicions: 'Verdächtigungen bearbeiten',
                suspicionStatus: 'Verdachtsstatus',
                notConfirmed: 'Verdacht nicht bestätigt',
            },
            employmentRole: {
                manager: 'Manager',
                employee: 'Employee',
            },
            articleDetails: {
                company: 'Unternehmen',
                role: 'Rolle',
                websites: 'Websites',
                phone: 'Telefon',
                prevNames: 'Vorherige Namen',
                address: 'Adresse',
                companyActivity: 'Firmenaktivität',
                source: 'Quelle',
                pubDate: 'Veröffentlichungsdatum',
                author: 'Autorin',
                retrievalDate: 'Abrufdatum',
                likes: 'Likes',
            },
            editSuspicions: {
                title: 'Verdachtsliste bearbeiten',
                confirm: 'Bestätigen',
                reset: 'Zurücksetzen',
                addToList: 'Zur Liste hinzufügen',
                selectSuspicion:
                    'Wählen Sie einen oder mehrere Verdächte zum Bearbeiten aus.',
                suspicionStatus: 'Verdachtsstatus',
                suspicionDeleted: 'Verdacht gelöscht',
                suspicionPending: 'Verdacht nicht bestätigt',
                suspicionConfirmed: 'Verdacht bestätigt',
                selectAll: 'Alles auswählen',
                unselectAll: 'Alles wiederufen',
                successMessage: {
                    plural: '{{count}} Verdachtsstatus erfolgreich geändert',
                    singular: '{{count}} Verdachtsstatus erfolgreich geändert',
                },
                searchSuspicion: 'Suche nach Verdächten',
                addSuspicion: 'Fügen Sie Verdächte hinzu',
            },
        },

        // Network Graph
        networkGraph: {
            searchPlaceholder: 'Suche nach Namen...',
            title: 'Netzwerk Graph',
            graphDepth: 'Netzwerktiefe',
            visibleDepth: 'Maximal sichtbare Netzwerktiefe:',
            onlyRedFlags: 'Netzknoten ohne pot. kritische Themen ausblenden:',
            graphLevels: 'Ebene',
            unknownRole: 'Unbekannte Rolle',
            candidateRole: 'Rolle der Bewerber:in',
            executiveRole: 'Führungskraft',
            employeeRole: 'Angestellte:r',
            companyStatus: 'Status der Firma',
            companyStatusActive: 'Aktiv',
            companyStatusInactive: 'Inaktiv',
            companyStatusUnknown: 'Unbekannt',
            companyStatusLiquidation: 'Liquidation',
            companyStatusDissolved: 'Aufgelöst',
            companyStatusAll: 'Alle anzeigen',
            hideControls: 'Diese Steuerelemente automatisch ausblenden',
            allFlags: 'Alle Firmen anzeigen',
            noRedFlags: 'Firmen ohne Warnsignale ausblenden',
            redFlags: 'Nur Firmen mit Warnsignalen anzeigen',
            emptySidebar:
                'Klicken Sie auf einen Netzknoten, um mehr Informationen zu sehen',
            showInGraph: 'Im Graphen anzeigen (wenn aktuell sichtbar)',
            entityDetailLabels: {
                place_of_birth: 'Geburtsort',
                home_city: 'Heimatstadt',
                home_country: 'Heimatland',
                home_email: 'E-Mail',
                multiple_nationalities_label: 'Nationalitäten',
                birth_date: 'Geburtstag',
                gender: 'Geschlecht',
            },
            entityDetailValues: {
                birth_date: '{{formattedDate}} ({{age}} Jahre alt)',
                gender_male: 'Männlich',
                gender_female: 'Weiblich',
                gender_other: 'Divers',
            },
            notFound: {
                headline: 'No graph possible',
                message:
                    'The target is very old and you need to recreate it to be able to generate the new graph.',
            },
        },

        networkGraphLegend: {
            title: 'Legende',
            showLegend: 'Legende zeigen',
            closeButton: 'Schließen',
            candidate: 'Kandidat',
            company: 'Firma',
            companyFlagged: 'Firma Datenbanktreffer (SOE, Sanctioned, etc.)',
            employeePosition: 'Angestellenposition',
            person: 'natürliche Person',
            personFlagged:
                'natürliche Person Datenbanktreffer (PEP, Sanctioned, etc.)',
        },

        cvData: {
            title: 'Lebenslaufdaten',
        },

        cVAnalysis: {
            title: 'Lebenslauf-Statistiken',
            timeline: 'Timeline',
            noTimelineHeadline:
                'Für die genutzten Filter wurden keine Einträge mit validen Zeiträumen gefunden',
            noTimeframe: 'Kein Zeitraum',
            noTimeframeTooltip:
                'Für diese Einträge wurde entweder kein Zeitraum, oder kein valider Zeitraum gefunden.',
            legend: 'Legende:',
            education: 'Bildung',
            work: 'Berufserfahrung',
            infoMessage:
                'Alle laufenden Positionen (ohne Enddatum) werden so behandelt, als hätten sie am Tag der Berichterstellung geendet.\n' +
                'Dieses Vorgehen gewährleistet die Konsistenz des Berichts, kann jedoch die Genauigkeit der Ergebnisse beeinflussen.',
            filters: {
                sourceGroupOptions: {
                    osint: 'Indicium-Analyse',
                    cv: 'Lebenslauf des Bewerbers',
                },
                search: 'Suche',
                selectSourceGroup: 'Auswählen',
                searchPlaceholder: 'Suche nach Bildung/Berufserfahrung...',
                startDate: 'Startdatum',
                endDate: 'Enddatum',
            },
            otherEllipses: 'Andere...',
            chartUnit: {
                months: 'Monate',
                years: 'Jahre',
                typos: 'typos',
                typo: 'typo',
            },
            chartTitle: {
                educationTimespan: 'Bildungszeitraum',
                educationLevel: 'Höchster Bildungsabschluss',
                jobTimespan: 'Berufszeitraum',
                uniqueCompanies: 'Einmalige Unternehmen',
                gaps: 'Anzahl an Lebenslauflücken',
                typos: 'Rechtschreibfehler',
                timespanOfPositions: 'Zeit pro Rolle',
                industriesTimespan: 'Zeit pro Industrie',
            },
            chartWarning: {
                educationTimespan:
                    'Einige Bildungseinträge werden wegen fehlenden Start- und Enddaten nicht beachtet',
                jobTimespan:
                    'Einige Berufseinträge werden wegen fehlenden Start- und Enddaten nicht beachtet',
                gaps: 'Einige Bildungs- und Berufseinträge werden wegen fehlenden Start- und Enddaten nicht beachtet',
                typos: 'Rechtschreibfehler werden nur für den bereit gestellten CV analysiert, nicht für andere Quellen.',
            },
            chartEmpty: {
                educationTimespan:
                    'Es gibt nicht genügend Daten über die Bildung für die ausgewählte Quelle.',
                educationLevel: 'Kein Bildungsniveau gefunden.',
                jobTimespan:
                    'Es gibt nicht genügend Daten über die Berufserfahrung für die ausgewählte Quelle.',
                gaps: 'Es gibt nicht genügend Daten über die Bildung und Berufserfahrung für die ausgewählte Quelle.',
            },
            chartNotFound: {
                educationTimespan: 'Keine Bildungsinformationen gefunden.',
                educationLevel: 'Kein Bildungsniveau gefunden.',
                jobTimespan: 'Keine Berufserfahrungen gefunden.',
                uniqueCompanies: 'Keine Unternehmen gefunden.',
                gaps: 'Keine Lebenslauflücken gefunden.',
                typos: 'Keine Schreibfehler gefunden.',
                timespanOfPositions: 'Keine Berufspositionsdaten gefunden.',
                industriesTimespan: 'Keine Industrie-Daten gefunden.',
            },
        },

        cVTimeline: {
            title: 'Lebenslauf-Timeline',
        },

        geoLocations: {
            title: 'Hinweise auf Standorte',
        },

        persons: {
            title: 'Personen',
        },

        landingPage: {
            welcome: 'Willkommen bei der Indicium Insights Beta, {{name}}',
            title: 'Willkommen {{name}}!',
            recentCases: 'Meine neuesten Positionen',
            recentTargets: 'Meine neuesten Bewerber:innen',
            noCasesFound: 'Sie haben noch keine Positionen angelegt',
            noTargetsFound: 'Sie haben noch keine Bewerber:innen angelegt',
        },

        // Navigation
        summary: 'Zusammenfassung',
        overview: 'Übersicht',
        personalData: 'Persönliche Daten',
        socialMediaSection: 'Soziale Medien',
        socialMediaConnectionSection: 'Soziale Medien Verbindungen',
        socialMediaPostSection: 'Soziale Medien Beiträge',
        pressSection: 'Presse',
        companySection: 'Verbundene Firmen',
        searchEngineSection: 'Suchmaschinen',
        pictureSection: 'Bildmaterial',
        noData: 'Keine Daten',
        noDataFound: 'Keine Daten vorhanden',
        network: 'Netzwerk',
        downloads: 'Herunterladen',
        indicium: 'Indicium',
        signOut: 'Ausloggen',
        searchAndFilter: 'Suche & Filter',
        inputDataAndSources: 'Eingabedaten & Quellen',
        inputData: 'Eingabedaten',
        targetPersonSelection: 'Auswahl Bewerber:in',
        sourceOverview: 'Quellenübersicht',
        caseData: 'Positionsdaten',
        backToOverview: 'Zurück zur Übersicht',

        rawData: {
            title: 'Rohdaten',
            search: 'Freitextsuche',
            socialMedia: 'Soziale Medien',
            press: 'Presse',
            registerData: 'Verbundene Firmen',
            searchEngines: 'Suchmaschinen',
            imageSlashVideos: 'Bildmaterial/Videos',
            dataNotAvailableTooltip:
                'Dieser Abschnitt wird noch erstellt und ist daher noch nicht verfügbar.',
            disabledTooltip: 'Die Rohdaten wurden aufgrund der DSGVO gelöscht',
        },

        // newTarget: data input
        targetPersonDataHeadline: 'Daten zur Bewerber:in',
        targetPersonDataSubHeadline: 'Welche Daten zur Person sind bekannt?',
        basisData: 'Basisdaten',
        title: 'Titel',
        titles: {
            dr: 'Dr.',
            prof: 'Prof.',
            profDr: 'Prof. Dr.',
        },
        gender: 'Geschlecht',
        genders: {
            male: 'Männlich',
            female: 'Weiblich',
            // TODO add support for other
            // other: 'Divers',
        },
        name: 'Name',
        firstname: 'Vorname',
        middlename: 'Zweiter Vorname',
        lastname: 'Nachname',
        nickname: 'Spitzname',
        nicknameAlternative: 'Spitzname / Künstlername',
        dateOfBirth: 'Geburtstag',
        nationality: 'Nationalität',
        countryOfResidence: 'Land des Wohnsitzes',
        placeOfBirth: 'Geburtsort',
        countryOfBirth: 'Geburtsland',
        occupation: 'Position',
        placesLived: 'Aufenthaltsorte',
        contactData: 'Kontaktdaten',
        phone: 'Telefon',
        phonePlaceholder: 'z.B. +49 1234 567890',
        mobile: 'Mobil',
        email: 'E-Mail',
        emailPlaceholder:
            'z.B. max.mustermann@example.com (personenbezogene E-Mail Adresse (kein info@, kontakt@, o.Ä)',
        address: 'Adresse',
        companyWebsite: 'Unternehmenswebseite',
        websites: 'Webseiten',
        roles: 'Rollen von',
        shareholders: 'Aktionäre',
        bankDetails: 'Bankverbindung',
        company: 'Unternehmen',
        companyRole: {
            label: 'Rolle im Untenehmen',
            optionLabels: {
                Unknown: 'Nicht bekannt',
                Employee: 'Mitarbeiter:in',
                ManagingDirector: 'Geschäftsführer:in',
                Shareholder: 'Anteilseigner:in',
                AuthorizedSignatory: 'Unterschriftsberechtigt (Prokurist:in)',
                Other: 'Sonstiges',
            },
        },
        unused: 'Dieses Feld steht in künftigen Releases zur Verfügung',
        employee: 'Angestellter',
        companyOwner: 'Firma der Bewerber:in',
        companyName: 'Firmenname',
        companyEducation: 'Firmenname / Ausbildung',
        companyLocation: 'Firmensitz',
        commercialRegisterNumber: 'Handelsregisternummer',
        commercialRegisterNumberPlaceholder:
            'Die Handelsregisternummer muss aus mindestens 5 Zeichen bestehen',
        companyVatNumber: 'Umsatzsteuernummer',
        companyVatNumberPlaceholder:
            'Die Umsatzsteuernummer muss aus mindestens 5 Zeichen bestehen',
        website: 'Webseite',
        websitePlaceholder: 'z.B. https://www.example.com',
        socialMediaProfiles: 'Profile in sozialen Medien',
        socialMediaPrivacyHint:
            'Hinweis zum Datenschutz: Soziale Medien Profile dürfen nur bei berechtigtem Interesse in der Analayse einbezogen werden.',
        socialMediaDisabledHint:
            'Private soziale Medien sind in diesem Berichtstyp deaktiviert',
        socialMediaFormatHint: 'Link + Handle / Handle',
        targetPersonDetails: 'Bewerber:in eingrenzen',
        targetPersonDetailsHint:
            'Sie können die Qualität der Ergebnisse erhöhen, indem sie Stichworte zur Bewerber:in eingeben.',
        targetPersonDetailsKeywords:
            'Stichworte zur Identifizierung der Bewerber:in',
        targetPersonAmbiguityKeywords:
            'Stichworte um namensgleiche Personen auszuschließen',
        targetPersonAmbiguityKeywordsHint:
            'Gibt es prominente Personen mit dem Namen der Bewerber:in, oder Personen in der gleichen Branche?',
        invalidNationalityMessage:
            'Die Eingabe konnte keiner Nationalität zugeordnet werden.',
        invalidCountryMessage:
            'Die Eingabe konnte keine Land zugeordnet werden.',
        invalidServerResponse:
            'Es ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Eingabe und probieren Sie es erneut.',
        //newTarget: resolve ambiguity
        age: 'Alter',
        // TODO: IND-460 Check usage of duplicate keys on global level
        // Some of the keys inside `candidateInfo` like `age` already existed at the
        // root level before the `candidateInfo` nesting group was added. I haven't
        // removed them from the root level as it is currently unclear whether some
        // other component of the application has been reusing them.
        // Once IND-460 has been completed and other translation usage has been
        // migrated to use context / feature specific nesting, they keys from
        // candidateInfo that also exist at the root level should be checked for
        // obsolescence.
        candidateInfo: {
            placeOfResidency: 'Wohnort',
            workPlaceOfResidency: 'Arbeitsorte',
            age: 'Alter',
            company: 'Unternehmen',
            nationality: 'Nationalität',
            phone: 'Telefon',
            email: 'E-Mail',
            website: 'Webseite',
            url: 'URLs',
            placeOfBirth: 'Geburtsort',
            dateOfBirth: 'Geburtstag',
            username: 'Benutzernamen',
            gender: 'Geschlecht',
            language: 'Sprache',
            originCountry: 'Herkunftsland',
            education: 'Ausbildung',
            city: 'Stadt',
            state: 'Bundesland',
            country: 'Land',
            scientificTitle: 'Beruf',
        },
        seeXMore: '{{ x }} weitere ansehen',
        placeOfResidency: 'Wohnort',
        resolveTargetCandidatesHeadline: 'Kandidatenauswahl',
        // TODO 'resolveTargetCandidatesSubHeadline' needs German approval
        resolveTargetCandidatesSubHeadline:
            'Bitte wählen Sie alle Kandidat:innen aus, die zu der Bewerber:in passen, bitte exkludieren Sie alle, die nicht passen. Wenn Sie sich unsicher sind, wählen Sie nichts aus.',
        candidateSelectionHelp:
            'In diesem Abschnitt finden Sie alle Ergebnisse, die durch die Suche in allen verfügbaren Datenquellen gefunden wurden. Bitte <bold>entscheiden Sie bei jedem möglichen Treffer</bold>, ob er zu Ihrer Bewerber:in passt oder nicht, oder <bold>überspringen Sie ihn, wenn Sie unsicher sind</bold>.' +
            '<p><span>1</span>Die wahrscheinlichsten Übereinstimmungen werden Ihnen zuerst angezeigt.</p>' +
            '<p><span>2</span>Jedes Mal, wenn Sie eine Auswahl treffen, versucht das das System, basierend auf Ihrer Auswahl, automatisch andere mögliche Übereinstimmungen ein- oder auszuschliessen und aktualisiert die Liste der Kandidaten, um Ihnen die nächsten wahrscheinlichsten Übereinstimmungen anzuzeigen.</p>' +
            '<p><span>3</span>Je mehr Übereinstimmungen Sie einbeziehen oder ausschließen, desto besser werden die Ergebnisse in der Regel sein.</p>',
        targetCandidatesIntro: `Im folgenden Abschnitt können Sie alle Ergebnisse sehen, die durch die Suche in allen verfügbaren Datenquellen gefunden wurden.\n
            Es muss mindestens ein Kandidat ausgewählt werden, um einen Bericht erstellen zu können, aber es führt in der Regel zu besseren Ergebnissen, wenn mehr ausgewählt werden.\n
            <bold>Das System hat festgestellt, dass von den <highlight>{{ totalCount }}</highlight> gefundenen potenziellen Kandidaten, <highlight>{{ includedCount }}</highlight> mit der Zielperson übereinstimmen.</bold>\n
            Sie können nun entweder die Auswahl überprüfen bzw. ändern, oder direkt zur Berichterstellung übergehen.`,
        reachedEndOfPendingCandidatesHelp:
            'Sie haben das Ende der Liste erreicht. Sie können jetzt:' +
            '<p><span>1</span>Ihre Auswahl überprüfen, indem Sie oben auf "<bold>Entschieden</bold>" klicken,</p>' +
            '<p><span>2</span>durch einen Klick auf "<bold>Report anfordern</bold>" den Report anfordern oder</p>' +
            '<p><span>3</span><loop>zurück zum Anfang</loop>, um die "<bold>Entscheidung ausstehend</bold>"-Liste noch einmal durchzugehen.</p>',
        selectCandidate: 'Kandidat:in wählen',
        excludeCandidate: 'Kandidat:in ausschließen',
        selectAllCandidates: 'Alle Kandidat:innen wählen',
        excludeAllCandidates: 'Alle Kandidat:innen ausschließen',
        skipCandidate: 'Kandidat:in überspringen',
        addMoreCandidates: 'Weitere hinzufügen',
        reviewCandidates: 'Auswahl überprüfen',
        startAnalysis: 'Report anfordern',
        likelyCandidates: 'Wahrscheinlich korrekt',
        possibleCandidates: 'Mögliche Übereinstimmung',
        wrongCandidates: 'Wahrscheinlich falsch',
        includedCandidates: 'Ausgewählte Kandidat:innen',
        excludedCandidates: 'Ausgeschlossene Kandidat:innen',
        unselectedCandidates: 'Entscheidung ausstehend',
        skippedCandidates: 'Unschlüssige/gemischte Kandidat:innen',
        totalCandidates: 'Kandidat:innen insgesamt',
        decidedCandidates: 'Entschieden',
        finishedCandidateSelection:
            'Fertig! Sie können jetzt Ihre Auswahl überprüfen, oder auf "Report anfordern" klicken, um mit der Analyse fortzufahren.',

        // Dashboard: Overview
        overviewAnalysis: 'Analyse',
        globalSearch: {
            title: 'Suche nach Positionen und Bewerber:innen',
            hint: 'Namen bzw. Titel eingeben, um Positionen und Bewerber:innen zu finden',
            noResults: 'Keine passenden Ergebnisse gefunden',
            tooltip: {
                type: 'Typ',
                status: 'Status',
                reportType: 'Reportart',
                creator: 'Erstellt durch',
            },
            type: {
                case: 'Position',
                target: 'Bewerber:in',
            },
            resultsCount: '{{count}} Treffer gefunden',
        },
        // Red Flags
        redFlags: {
            heading: 'Prüfung erforderlich',
            cardHeadline: 'Kritische Themen',
            cardSubHeadline: 'Ergebnisse gefunden',
            headers: {
                name: 'Name',
                role: 'Firmenposition(en)',
                detail: 'Einzelheiten zur Roten Flagge',
                land: 'Land',
                source: 'Quelle',
                description: 'Beschreibung',
            },
            types: {
                pep: {
                    detail: 'Politisch exponierte Person',
                    description:
                        'Der/Die Bewerber:in findet sich auf einer Liste für hochrangig öffentliche tätige Personen wieder (Politically Exposed Persons)',
                },
                sanction: {
                    detail: 'Sanktionsliste',
                    description:
                        'Der/Die Bewerber:in findet sich in einer weltweiten Sanktionsliste wieder',
                },
                enforcement: {
                    detail: 'Strafverfolgung',
                    description:
                        'Der/Die Bewerber:in findet sich in einer weltweiten Strafverfolgungsliste wieder',
                },
                soe: {
                    detail: 'SOE',
                    description:
                        'Der/Die Bewerber:in weist Verbindungen zu staatlichen Besitz oder Regierungen sowie über zu deren Mitarbeitern auf (State Owned Enterprises)',
                },
                government_link: {
                    detail: 'Government Linked',
                    description:
                        'Der/Die Bewerber:in weist Verbindungen zu staatlichen Besitz sowie zu deren Mitarbeitern auf',
                },
                adverse_media: {
                    detail: 'Adverse Media',
                    description:
                        'Der/Die Bewerber:in steht in möglicher Verbindung mit illegalen Aktivitäten im Bereich Geldwäsche und Terrorismusfinanzierung, basierend auf Nachrichten, die von glaubwürdigen Medien veröffentlicht wurden',
                },
                associated_entity: {
                    detail: 'Associated Entity',
                    description:
                        'Der/Die Bewerber:in steht mit mindestens einer sanktionierten Einrichtung in Verbindung',
                },
                registrations: {
                    detail: 'Registration',
                    description:
                        'Der/Die Bewerber:in steht im Zusammenhang mit Überwachungsmaterial, das von offiziellen Regierungsbehörden, internationalen Organisationen, Branchenregulierungsbehörden und Disziplinarausschüssen für Ereignisse veröffentlicht wird, die qualifizierte Standards und Definitionen erfüllen.',
                },
                generic_red_flag: {
                    detail: 'Allgemeine Auffälligkeit',
                    description:
                        'Der/Die Bewerber:in ist auf einer weltweiten Strafverfolgungsliste vermerkt',
                    sources: {
                        orbis: 'Globale Sanktionslisten, Watchlisten und Listen politisch exponierter Personen (via "BvD Compliance Catalyst")',
                    },
                },
            },
            rawTypes: {
                'PEP/Former PEP':
                    'Current or former Politically Exposed Person (PEP)',
            },
            reportsHeading: 'Detailreport',
            reportsItem: 'Report',
            roleNotAvailable: '-',
            positionIsCurrent: 'bis heute',
            unknownTimeframe: 'Unbekannter Zeitraum',
            unknownRole: 'Unbekannte Rolle',
        },

        highlight: {
            source: 'Quelle:',
            inferedFromTextAs: 'Erkannt als:',
        },

        // Profile: Overview
        source: 'Quelle',
        sources: 'Quellen',
        profileHeadline: 'Profil',
        downloadPDF: 'PDF',
        profileLoading: 'Daten werden geladen.',
        profileDataNotFinal:
            'Es kann bis zu 1 Stunde dauern, bis die angezeigten Daten vollständig sind.',
        profileErrorHeadline: 'Ein Fehler ist aufgetreten.',
        profileErrorMessage: 'Ihre Anfrage konnte nicht verarbeitet werden.',
        profileErrorRetryHeadline: 'Noch sind keine Daten verfügbar.',
        profileErrorRetryMessage:
            'Bitte versuchen Sie es in einigen Minuten erneut',
        workflowRunningMessage:
            'Der Prozess läuft noch, bitte kommen Sie später wieder. (Dies kann bis zu einer halben Stunde dauern).',
        complianceIndication: 'Hinweise auf Complianceverstöße',
        searchTerms: 'Eigene Suchbegriffe',
        profileSize: 'Profilgröße',
        networkSize: 'Netzwerkgröße',
        from: 'von',
        to: 'bis',
        role: 'Tätigkeit',
        cv: 'Lebenslauf',

        // Profile: raw data
        data: 'Daten',
        include: 'Einbeziehen',
        included: 'Einbezogen',
        exclude: 'Ausschließen',
        cancelSelection: 'Auswahl aufheben',
        excluded: 'Ausgeschlossen',
        includeCandidate: 'Kandidat:in einbeziehen',
        backToDataInput: 'Zur Dateneingabe',
        cancelCreateTarget: 'Analyse beenden',
        noPersonHeadline: 'Keine Personen gefunden',
        noPersonText:
            'Leider haben wir auf Basis Ihrer Angaben keine mögliche Bewerber:in identifiziert. Bitte überprüfen Sie Ihre Eingabe.',
        summaryBetaTooltip: `Dies ist eine Beta-Funktion, die Große Sprachmodelle (LLMs) zur Zusammenfassung verwendet.\n
      Bei Fragen wenden Sie sich bitte an Ihren Kundenbetreuer.`,

        // Admin: User Admin
        overviewOfAllUsers: 'Übersicht aller Nutzer',
        createNewUser: 'Neuen Nutzer anlegen',
        addButton: 'Hinzufügen',
        closeButton: 'Schließen',
        addMoreUsers: 'Weiteren Nutzer anlegen',
        assignedCases: 'Aktive Positionen',
        activeReports: 'Aktive Reports',
        totalReports: 'Reports Gesamt',
        includingDeleted: 'inkl. Gelöschter',
        userTableFooter: 'Gesamt',
        createdOn: 'Erstellt am',
        createdAt: 'Erstellt am',
        changedOn: 'Geändert am',
        updatedAt: 'Geändert am',
        notAvailable: '-',

        // Admin: Customer Admin
        overviewOfAllCustomers: 'Übersicht aller Kunden',
        createNewCustomer: 'Neuen Kunden anlegen',
        customerName: 'Kundenname',
        numberOfReports: 'Reports',
        limitOfReports: 'Report-Limit',
        expiresAt: 'Vertrag endet',
        updateCustomer: {
            action: 'Kunden bearbeiten',
            formHeadline: 'Verfügbare Felder',
        },
        lastReportCreatedAt: 'Letzter Report erstellt',

        // Admin: Cases Admin
        overviewOfAllCases: 'Übersicht aller Positionen',
        createNewCase: 'Neue Position anlegen',
        createNewTarget: 'Neue Bewerber:in anlegen',
        openCases: 'Offene Positionen',
        archivedCases: 'Archivierte Positionen',
        caseName: 'Position',
        caseType: 'Reportart',
        caseTypes: {
            option1: 'Insight Essential',
            option2: 'Insight Plus',
            option3: 'Insight Complete',
        },
        mustBeUnique: 'Muss einzigartig sein',
        caseNameMustBeUnique: 'Positionsname muss einzigartig sein.',

        subject: 'Betreff',
        targetPerson: 'Bewerber:in',
        targetPersonPress: '{{name}} (Bewerber:in)',
        targetNetworkPress: '{{name}} (Netzwerkebene {{networkLevel}})',
        editor: 'Bearbeiter:in',
        creator: 'Ersteller:in',
        dateChanged: 'Geändert',
        dateCreated: 'Erstellt',
        titleOfCase: 'Titel des Falles',
        exampleTitle: 'Beispieltitel',
        assignUserToCase: 'Nutzer der Position zuweisen',
        assignUserToCaseSubtext:
            'Wählen Sie hier aus welchen Mitarbeitern Sie Zugriffsrechte für die Bearbeitung dieses Falls erteilen möchten. Administratoren Ihrer Organisation haben Zugriff auf alle Position.',
        currentResults: 'Aktuelle Ergebnisse',
        archivedResults: 'Archivierte Ergebnisse',
        noCaseFoundHeadline: 'Keine Position gefunden',
        noCaseFoundText:
            'Leider haben wir auf Basis Ihrer Angaben keine mögliche Position identifiziert. Bitte überprüfen Sie Ihre Eingabe.',
        deleteCase: {
            action: 'Position löschen',
            modalTitle:
                'Sind Sie sicher, dass Sie die ausgewählte Position löschen möchten?',
            modalText:
                'Sind Sie sicher, dass Sie die ausgewählte Position löschen möchten? Bitte achten Sie darauf, das sie vorab alle für Sie relevanten Reports exportiert und abgespeichert haben, da diese nach der Löschung nicht mehr zum Herunterladen zur Verfügung gestellt werden können.',
            radioOptions: [
                'Ja, ich habe die relevanten Bewerber:innen Reports vorab heruntergeladen und möchte die ausgewählte Position löschen',
                'Ja, ich möchte die Position und alle Daten unwiderruflich löschen, aber habe die Reports nicht heruntergeladen, da ich sie nicht benötige',
            ],
            cancelButton: 'Löschen abbrechen',
            confirmButton: 'Löschen bestätigen',
        },
        editCaseAction: 'Position bearbeiten',
        editCaseFormHeadline: 'Verfügbare Felder',
        editCase: {
            titleHasNotChanged: 'Der Titel hat sich nicht geändert',
        },
        addAdminPermissionsAction: 'Administratorrechte hinzufügen',
        removeAdminPermissionsAction: 'Administratorrechte widerrufen',
        deleteTarget: {
            action: 'Bewerber:in löschen',
            modalTitle:
                'Sind Sie sich sicher, dass Sie diese Bewerber:in unwiderruflich löschen möchten?',
            modalText:
                'Sind Sie sicher, dass Sie die ausgewählte Bewerber:in unwiderruflich löschen wollen? Bitte achten Sie darauf, dass Sie vorab den Report der Bewerber:in exportiert und abgespeichert haben, da dieser nach der Löschung nicht mehr zum Herunterladen zur Verfügung gestellt werden kann.',
            radioOptions: [
                'Ja, ich habe den Report vorab heruntergeladen und möchte die ausgewählte Bewerber:in löschen',
                'Ja, ich möchte die Bewerber:in und ihre Daten unwiderruflich löschen, aber habe deren Report nicht heruntergeladen, da ich sie nicht benötige',
            ],
            cancelButton: 'Löschen abbrechen',
            confirmButton: 'Löschen bestätigen',
        },
        targetStatus: {
            Created: 'Erstellt',
            Completed: 'Fertig gestellt',
            FetchingCandidates: 'Bewerber:innen werden abgerufen',
            CandidateSelectionPending: 'Auswahl der Bewerber:in ausstehend',
            CandidateSelectionCompleted:
                'Auswahl der Bewerber:in abgeschlossen',
            FetchingDetails: 'Details werden abgerufen',
            HasInitialProfile: 'Initiales Profil erstellt',
            CompletedWithIrregularities:
                'Fertig gestellt mit Unregelmäßigkeiten',
        },
        deleteUser: {
            action: 'Nutzer löschen',
            modalTitle:
                'Sind Sie sich sicher, dass Sie diesen Nutzer löschen möchten?',
            modalText:
                'Sind Sie sicher, dass Sie diesen Nutzer unwiderruflich löschen möchten?',
            radioOptions: [
                'Ja, ich möchte den ausgewählten Nutzer unwiderruflich löschen',
            ],
            cancelButton: 'Löschen abbrechen',
            confirmButton: 'Löschen bestätigen',
        },
        deleteCustomer: {
            action: 'Kunden löschen',
            modalTitle:
                'Sind Sie sich sicher, dass Sie diesen Kunden-Account löschen möchten?',
            modalText:
                'Sind Sie wirklich sicher, dass Sie den ausgewählten Kunden-Account löschen wollen? Bitte achten Sie darauf, das vorab alle relevanten Bewerber-Reports exportiert und abgespeichert wurden, da diese zusammen mit dem Kunden-Account gelöscht werden und nach der Löschung nicht mehr zum Herunterladen zur Verfügung gestellt werden können.',
            radioOptions: [
                'Ja, ich habe die relevanten Bewerber:innen Reports vorab heruntergeladen und möchte den ausgewählten Kunden löschen',
                'Ja, ich möchte den Kunden und alle dazugehörigen Daten unwiderruflich löschen, aber habe die Reports nicht heruntergeladen, da ich sie nicht benötige',
            ],
            cancelButton: 'Löschen abbrechen',
            confirmButton: 'Löschen bestätigen',
        },
        editCustomerAction: 'Kunden bearbeiten',
        editCustomerFormHeadline: 'Verfügbare Felder',
        quotaExceeded: 'Ihr erlaubtes Volumen an Reports ist erreicht.',
        quota: 'Kontingent',

        // Press
        press: 'Presse',
        position: 'Position',
        date: 'Datum',
        publicationDate: 'Erscheinungsdatum',
        retrievalDate: 'Abrufdatum',
        preview: 'Vorschau',
        author: 'Autor/In',
        copyright: 'Urheberrechte ©',
        previous: 'Vorherige',
        next: 'Weiter',
        managers: 'Manager',

        // SocialMedia
        socialMedia: {
            contactsHeadline: 'Soziale Medien Kontakte',
            contactsNoAccounts:
                'Es wurden keine Soziale Medien Konto gefunden.',
            postsHeadline: 'Soziale Medien Beiträge',
            media: 'Medien',
            url: 'Quelle',
            itemType: 'Artikeltyp',
            commentCount: 'Kommentare',
            likeCount: 'Likes',
            date: 'Datum',
            datePublished: 'Datum',
            tags: 'Tags',
            tagged: 'Getaggt',
            links: 'Links',
            mentions: 'Erwähnungen',
            profileName: 'Profilname',
            profileId: 'Profil ID',
            profileImageUrl: 'Profilbild-URL',
            profileImageWebAddress: 'Profilbild Webadresse',
            profileLocation: 'Profilstandort',
            inLanguage: 'Sprache',
            postingPlatform: 'Gepostet via',
            profileFollowers: 'Profil Abonnenten',
            profileFollowing: 'Profil abonniert',
            source: 'Plattform',
            favoriteCount: 'Favorisiert',
            retweetCount: 'Retweeted',
            userName: 'Username',
            contentId: 'Content ID',
            dateRecorded: 'Erfassungsdatum',
            fullName: 'Vollständiger Name',
            mediaWebAddress: 'Media URL',
            isSharedContent: 'Geteilte Inhalte',
            connectionType: 'Verbindungstyp',
            username: 'Nutzername',
            mediaType: 'Medientyp',
            body: 'Inhalt',
            inReplyToUsername: 'Als Antwort auf Username',
            inReplyToContentId: 'Als Antwort auf Content ID',
            inReplyToProfileId: 'Als Antwort auf Profile ID',
            friend: 'Freunde',
            noFriends: 'Keine Freunde',
            follower: 'Followers',
            noFollowers: 'Folgt keinem Profil',
            followed: 'Following',
            nofollowed: 'Keine Follower',
            noContacts: 'Keine Kontakte',
            connections: 'Verbindungen',
            profileUrl: 'Profil-URL',
            posts: 'Beiträge',
            profile: 'Profile',
            friendsAndInteractants: 'Freunde & Interakteure',
            attribution: 'Bereitgestellt durch',
        },

        // Socials
        facebook: 'Facebook',
        facebookPlaceholder: 'z.B. https://facebook.com/maxmustermann',
        instagram: 'Instagram',
        instagramPlaceholder: 'z.B. https://instagram.com/maxmustermann',
        linkedin: 'LinkedIn',
        linkedinPlaceholder: 'z.B. https://linkedin.com/in/maxmustermann',
        xing: 'Xing',
        xingPlaceholder: 'z.B. https://xing.com/profile/maxmustermann',
        twitter: 'X',
        twitterPlaceholder: 'z.B. https://x.com/maxmustermann',
        vkontakte: 'VKontakte',
        pinterest: 'Pinterest',
        flickr: 'Flickr',
        skype: 'Skype',
        whatsapp: 'WhatsApp',

        // commercial register
        commercialRegister: {
            filterTitle: 'Rolle',
            relevantCheckbox:
                'Nur Firmen anzeigen mit Rolle in hoher Verantwortung',
            relevantTooltip:
                'Unternehmen, in denen die Bewerber:in eine Führungsposition oder Entscheidungsbefugnis hatte – z.B. CEO, Vorstandsmitglied, Geschäftsführer, etc. – was auf eine direkte Verwicklung hindeuten würde, falls das Unternehmen in einem Zusammenhang mit einer Straftat stehen sollte',
            relevantLabel: 'Führungsposition',
            nonRelevantLabel: 'Angestellte/r',
            nonRelevantTooltip:
                'Unternehmen, mit denen die Bewerber:in verbunden war, in denen sie aber keine Führungsposition oder Entscheidungsbefugnis innehatte',
        },

        // Signin
        signIn: {
            headline: 'Melden Sie sich an',
            passwordForgotHeadline: 'Passwort zurücksetzen',
            passwordResetRequiredHeadline: 'Passwort neu setzen',
            emailAddressLabel: 'Email Adresse',
            emailAddressInputPlaceholder: 'Email Adresse',
            passwordLabel: 'Passwort',
            passwordInputPlaceholder: 'Passwort',
            signInButton: 'Anmelden',
            rememberMe: 'Angemeldet bleiben',
            forgotPassword: 'Passwort vergessen?',
            imprint: 'Impressum',
        },
        authenticationSection: {
            headline: 'Authentifizierung',
            enableMfa: 'Mehrfaktorauthentifizierung aktivieren',
            disableMfa: 'Mehrfaktorauthentifizierung deaktivieren',
            enableMfaTooltop:
                'Das Aktivieren der Mehrfaktorauthentifizierung (MFA) wird Sie aus Ihrer aktuellen Sitzung ausloggen',
            disableMfaTooltip:
                'Das Deaktivieren der Mehrfaktorauthentifizierung (MFA) wird Sie aus Ihrer aktuellen Sitzung ausloggen',
        },
        authentication: {
            headline: 'Multi-Faktor-Authentifizierung',
            description:
                'Bitte geben Sie den Code aus Ihrer Authentifizierungs-App ein, um den Anmeldevorgang abzuschließen.',
            instructions: `
        1. Installieren Sie eine Authentifizierungs-App auf Ihrem Mobilgerät: Wenn Sie dies noch nicht getan haben, laden Sie eine Authentifizierungs-App auf Ihr Mobilgerät herunter und installieren Sie sie. Beliebte Optionen sind Google Authenticator (verfügbar für Android und iOS) und Authy (verfügbar für Android und iOS).\n\n
        2. Scannen Sie den QR-Code: Öffnen Sie die Authentifizierungs-App und scannen Sie den untenstehenden QR-Code. Wenn Sie den QR-Code nicht scannen können, können Sie den Code und den Schlüssel unter dem QR-Code manuell eingeben.\n
        3. Geben Sie den Authentifizierungscode ein: Geben Sie den von der Authentifizierungs-App generierten Authentifizierungscode ein und klicken Sie auf "Absenden".\n
      `,
            codeInputPlaceholder: 'Authentifizierungscode',
            submitButton: 'Absenden',
            oneTimePassword: 'Einmalpasswort',
        },

        passwordReset: {
            password: 'Passwort',
            newPassword: 'Neues Passwort',
            oldPassword: 'Altes Passwort',
            newPasswordRepeat: 'Passwort wiederholen',
            newPasswordConfirm: 'Neues Passwort bestätigen',
            verifyAccount: 'Email Adresse bestätigen',
            updatePassword: 'Passwort aktualisieren',
        },

        // Error
        NotAuthorizedException:
            'Sie sind nicht angemeldet. E-Mail Adresse oder Passwort sind nicht korrekt.',
        UserNotFoundException:
            'Sie sind nicht angemeldet. E-Mail Adresse oder Passwort sind nicht korrekt.',
        PasswordEmptyOrMismatchException:
            'Die eingegebenen Passwörter sind leer oder stimmen nicht überein.',
        InvalidPasswordException:
            'Das gewählte Passwort muss folgende Anforderungen erfüllen:',
        passwordRequirements: [
            'Min. 8 Zeichen',
            'Groß- und Kleinbuchstaben',
            'Ziffern und Sonderzeichen',
        ],
        PasswordResetRequiredException:
            'Das ist das erste Mal, dass Sie sich einloggen. Bitte legen Sie ein neues Passwort fest.',
        PasswordResetExpiredCodeException:
            'Der eingegebene Code ist abgelaufen.',
        InvalidParameterException: 'Der eingegebene Code ist nicht korrekt.',
        CodeMismatchException: 'Der eingegebene Code ist nicht korrekt.',
        LimitExceededException:
            'Sie haben zu oft versucht, ihr Passwort zu ändern. Warten sie einige Minuten bevor Sie es erneut versuchen.',
        PasswordChallengeException:
            'Aktuell kann ihr Passwort nicht zurückgesetzt werden. Bitte verwenden sie das Passwort, welches sie per Email zugeschickt bekommen haben.',
        PasswordExpiredException:
            'Ihr vorläufiges Passwort ist nicht mehr gültig. Bitte wenden sie sich an den Support, um ein neues Passwort zu erhalten.',
        WrongPasswordException: 'Das eingegebene Passwort ist nicht korrekt.',
        uhOh: 'Uh-oh!',
        noPageFound: 'Wir können die Seite, die Sie suchen, nicht finden.',
        goBack: 'Bitte gehen Sie zurück.',
        noUsers: 'Keine Benutzer',
        noResultHeadline: 'Keine Ergebnisse gefunden',
        noResultText:
            'Leider haben wir anhand Ihrer Angaben kein mögliches Ergebnis ermittelt. Bitte überprüfen Sie Ihre Eingabe.',
        noTradeRegisterDataText:
            'Wir haben über 400 Millionen Unternehmen global geprüft: eine Person mit dem angegebenen Namen konnte nicht mit registerrelevanter, unternehmerischer Tätigkeit identifiziert werden.',
        caseAgentsUpdated:
            'Der/Die Nutzer wurden der Position erfolgreich zugewiesen',
        caseAgentsAssignError:
            'Der/Die Nutzer konnten der Position nicht zugewiesen werden.',
        firstPage: '<< erste Seite',
        previousPage: '< vorherige Seite',
        nextPage: 'nächste Seite >',
        lastPage: 'letzte Seite >>',
        pageNotFound: {
            title: 'Hier ist etwas schief gelaufen',
            subtitle:
                'Diese Seite existiert nicht oder Ihnen fehlt die Berechtigung auf diese Seite zuzugreifen.',
            description:
                'Bitte überprüfen Sie die URL auf Korrektheit oder versuchen Sie es zu einem späteren Zeitpunkt erneut. Sollte das Problem weiterhin auftreten, kontaktieren Sie den Support.',
            button: 'Zurück zur Übersicht',
        },
        organization: 'Organisation',
        privilegeLevel: 'Rolle',
        CustomerOwner: 'Eigentümer',
        CustomerAdmin: 'Administrator',
        CustomerUser: 'Mitarbeiter',
        administrators: 'Administratoren',

        companyList: {
            roleStatus: {
                current: 'aktuell',
                previous: 'ehemalig',
            },
        },
        caseNew: {
            caseTitle: 'Neue Position anlegen',
            stepOne: 'Position anlegen',
            stepTwo: 'Fallparameter bestimmen',
            topicsTitle: 'Themenfelder der Analyse',
            topicsParagraph:
                'Folgende Analysen werden durch das System unterstützt',
            redFlagsTitle: 'Pot. kritische Themen',
            redFlagsParagraph:
                'Abgleich des Namens der Bewerber:in, der mit Ihr verbundenen Unternehmen sowie der mit diesen Unternehmen verbundenen Personen mit nationalen und internationalen Sanktionslisten und Listen politisch exponierter Personen.',
            complianceTitle:
                'Hinweise im Zusammenhang mit Compliance - oder Kriminellen Thematiken',
            complianceParagraph:
                'Schlüsselwort-basierte Analyse und Identifikation von Auffälligkeiten in Textmaterial zur Bewerber:in. Wir prüfen Begriffe aus dem Bereich klassischer Kriminalität (Organisierte Kriminalität, Terrorismus, Extremismus) sowie insbesondere auch aus dem Bereich Wirtschaftskriminalität.',
            keywordsLink: 'Liste der Stichworte und Themen Felder',
            businessRegistryTitle:
                'Markierung auffälliger Merkmale in den Unternehmensregisterdaten',
            sources: {
                title: 'Quellen',
                searchEngines: 'Suchmaschinen',
                commercialRegisters: 'Handelsregister',
                sanctionsList:
                    'Listen politisch exponierte Person/Sanktionslisten',
                webSites: 'Webseiten',
                pressArchive: 'Pressearchive',
                businessSocialMedia: 'Geschäft Soziale Medien',
                privatePublicSocialMedia: 'PRIVATE, ÖFFENTLICHE Soziale Medien',
            },
            selectOption: 'Bitte wählen Sie den Report-Typ aus',
            title: {
                option1: 'Insight Essential',
                option2: 'Insight Plus',
                option3: 'Insight Complete',
            },
            stepSubtitle: {
                step1: 'Bitte wählen Sie den Report-Typ aus',
                step2: 'Bitte geben Sie weitere Details für den gewählten Report-Typ an',
                step3: 'Bitte wählen Sie einen Namen, und konfigurieren Sie den Zugang',
            },
            optionsInfo: {
                option1: [
                    'Durchführung möglich ohne Einverständnis',
                    'Empfohlen für jede:n Bewerber:in',
                    'Exklusion von rein privaten Soziale Medien Portalen',
                ],
                option2: [
                    'Durchführung möglich ohne Einverständnis basierend auf den selektierten Quellen',
                    'Empfohlen für spezielle Positionen',
                    'Individuelle Auswahl von Quellen',
                    'Individuelle Möglichkeit für Stichwortsuche',
                ],
                option3: [
                    'Setzt Einverständnis voraus',
                    'Empfohlen für Management-Positionen',
                    'Alle online öffentlich verfügbaren Quellen (Handelsregister, Presse, Soziale Medien und Suchmaschinen)',
                    'Stichworte mit Fallbezug sind anzugeben',
                ],
            },
            sourceUsageInfo: {
                option1: {
                    included: [
                        'Professionelle soziale Medien (LinkedIn, Xing)',
                        'Presse',
                        'Suchmaschinen',
                    ],
                    excluded: [
                        'Private soziale Medien',
                        'Sanktionslisten',
                        'Unternehmensnetzwerke',
                        'Offshore-Leak-Datenbank',
                    ],
                },
                option2: {
                    included: [
                        'Professionelle soziale Medien (LinkedIn, Xing)',
                        'Presse',
                        'Suchmaschinen',
                        'Private soziale Medien',
                        'Sanktionslisten',
                    ],
                    excluded: [
                        'Unternehmensnetzwerke',
                        'Offshore-Leak-Datenbank',
                    ],
                },
                option3: {
                    included: [
                        'Professionelle soziale Medien (LinkedIn, Xing)',
                        'Presse',
                        'Suchmaschinen',
                        'Private soziale Medien',
                        'Sanktionslisten',
                        'Unternehmensnetzwerke',
                        'Offshore-Leak-Datenbank',
                    ],
                    excluded: [],
                },
            },
            backgroundCheckTitle:
                'Hintergrundprüfung - Geschäftsanbahnung ohne konkreten Verdachtsmoment',
            backgroundCheckDescription1:
                'Compliance-Recherchen werden aus wirtschaftlichem Interesse basierend auf geplanter Geschäftsanbahnung, jedoch ohne konkrete Verdachtsmomente durchgeführt. Indicium verarbeitet nur online öffentlich verfügbare Informationen.',
            targetPersonInformationTitle: 'Information der betroffenen Person',
            targetPersonInformation: {
                yes: 'Die betreffenden Personen wurden über die geplante Verarbeitung informiert.',
                no: 'Die betreffenden Personen wurden nicht über die geplante Verarbeitung informiert, weil dies den Zweck der Verarbeitung gefährden würde.',
            },
            osintCheckWithConsent:
                'OSINT-Check mit Einverständnis aller Bewerber:innen',
            publicInfoDesc:
                'Alle öffentlich im Internet verfügbaren Informationen (Texte, Bilder)',
            privateSocialMedia:
                'Private Social Media Informationen (z.B. Facebook, Instagram, X) - benötigt Einverständnis',
            icijLeaks:
                'Öffentlich verfügbare Leak-Datenbanken dürfen durchsucht werden',
            option3IcijLeaks:
                'Öffentlich verfügbare Leak-Datenbanken in Suche integrieren',
            customSearchTerms: 'Spezifische Suchbegriffe ',
            suspCompInvestigation: {
                optionDesc: 'Verdachtsbezogen Compliance Recherche',
                section2MainTitle:
                    'Analyse um positionsbezogene Stichworte ergänzen',
                section3Title: 'Individuelle Suchbegriffe',
                section2Description1:
                    'Mit eigenen, positionsbezogenen Stichworten können Sie der Analyse eine zusätzliche Vertiefung geben. Wir identifizieren Ihre oder ähnliche Stichwort in den gefundenen Daten.',
            },
            addNewKeyword: 'Weitere Stichwort ergänzen',
            dataPrivacy: {
                title: 'Datenschutzhinweise',
                bullets: [
                    'Der Auftraggeber hat sicherzustellen, dass die Datenschutzgrundlage sichergestellt ist (Anbahnung Geschäftsverhältnis Art. 6 Abs. 1 lit. b oder Berechtigtes Interesse Art. 6 Abs. 1 lit. f)',
                    'Indicium verarbeitet grundsätzlich nur zugelassene Daten (Beispiel: Suchmaschinen werden im Einklang mit dem gültigen Fragerecht abgefragt)',
                    'Bitte kontaktieren Sie support@indicium.ag für das angefertigte Datenschutzgutachten',
                ],
            },
            leftFooter: {
                title: 'Umfassende Analysen auf Ihrem Datensatz',
                bullet0:
                    'Prüfung auf Kritische Themen: politisch exponierte Personen-Prüfung, Sanktionslistenprüfung und weitere Überwachungslisten von verbundenen Unternehmen und Unternehmern bis zum 2. Grad',
                bullet1:
                    'Abgleich sämtlicher gefundener mit der Person verbundenen Texte mit kritischen Stichworten oder negativen Inhalten (Basierend auf Analysen mit künstlicher Intelligenz)',
                bullet2:
                    'Automatische Prüfung von verdächtigen Sachzusammenhängen aus Perspektive eines professionellen OSINT Analysten',
                bullet3:
                    'Bei Stichworten mit Fallbezug: Analyse auf vorkommen der Stichworte und Stichworterweiterungen (über Maschinelles Lernen).',
            },
            rightFooter: {
                title: ' Inkludierte Quellen für Ihre Analyse',
                bullet0:
                    'Zugriff auf Daten von über 400 Millionen Unternehmen global über > 270 verschiedene Anbieter',
                bullet1:
                    'Besondere Auflösung mit weiteren ~ 10 Mio. Datensätze in Deutschland, Österreich und der Schweiz durch Anbindung Spezialanbieter',
                bullet2:
                    'Tagesaktuelle, weltweite Echtzeit-Informationen über Nutzung LexisNexis, Zugang zu mehr als 100.000 Quellen mit nationalen und internationalen Nachrichten und Firmeninformationen aus über 100 Ländern in 90 Sprachen.',
                bullet3:
                    'Maschineller Zugriff auf Suchmaschinenergebnisse zu Ihrer Bewerber:in (Texte; Artikel; Dokumente, Bildmaterial)',
                bullet4:
                    'Abdeckung der öffentlichen Teile der wichtigsten Soziale Medien Kanäle wie LinkedIn, Xing, Facebook, X, Instagram',
            },
            reasonForTheAnalysisPopup: {
                title: 'Fallparameter aufsetzen',
                content:
                    'Zur DSGVO-gerechten Auswahl der Analysewerkzeuge und Quellen müssen im Folgenden die Rahmenbedingungen des vorliegenden Falls definiert werden. Die jeweiligen Konsequenzen der Einstellungen werden in jedem Punkt nochmal detailliert erläutert. Indicium ist dazu verpflichtet, die Richtigkeit der Angaben Stichprobenhaft zu überprüfen. Wenn Sie weitere Fragen haben, wenden Sie sich gerne an das Indicium Team unter ',
                contentEmail: 'fallanlage@indicium.ag.',
            },
            keywordsTitle: 'Geben Sie ein Stichwort an',
            keywordsTitleEnglish: 'Englisch',
            noTranslationsFound: 'Kein Übersetzung gefunden',
            suggestSynonyms: 'Synonyme vorschlagen',
            clearSynonyms: 'Synonyme entfernen',
            noSynonymsFound: 'Kein Synonym gefunden',
            keywordType: {
                topic: 'Thema',
            },
            gdprPopover: {
                title: '100% DSGVO-Konform',
                infoText1: 'Alle Analysen sind Konform mit der DSGVO',
                infoText2: 'Sichere Verbindung über SSL-Server',
                infoText3: 'Verschlüsselte Daten',
            },
        },
        yes: 'Ja',
        no: 'Nein',

        consolidatedView: 'Konsolidierte Ansicht',
        present: 'Heute',
        seeMore: 'Zeig mehr',
        seeLess: 'Zeige weniger',
        moreDetails: 'Mehr Details',

        image: 'Bild',
        imageUpload: 'Bild hochladen',
        uploadTheImage: 'Lade das Bild hoch',
        browse: 'Durchsuche',
        documentType: 'Dokumentenart',
        mdCeo: 'Geschäftsführer/Vorstand',
        country: 'Land',
        zipCode: 'Postleitzahl',
        street: 'Straße',

        registrationDate: 'Registrierungsdatum',
        registrationNumber: 'Registrationsnummer',

        keyword: 'Stichwort',
        keywords: 'Stichworte',
        /**
         * @todo: Clean-up and organize the keys better
         * Some of these translations exist on root level
         * Also there are duplicated because of naming inconsistencies between candidate tiles and target profile
         */
        infoBlockItem: {
            placeOfResidency: 'Wohnorte',
            age: 'Alter',
            company: 'Unternehmen',
            companies: 'Unternehmen',
            nationality: 'Nationalitäten',
            phone: 'Telefon',
            phones: 'Telefon',
            email: 'E-Mail',
            emails: 'E-Mail',
            website: 'Webseiten',
            websites: 'Webseiten',
            url: 'URLs',
            placeOfBirth: 'Geburtsort',
            birthPlace: 'Geburtsort',
            dateRangeOfBirth: 'Geburtstag',
            dateOfBirth: 'Geburtstag',
            birthday: 'Geburtstag',
            username: 'Benutzernamen',
            gender: 'Geschlecht',
            language: 'Sprachen',
            originCountry: 'Herkunftsland',
            education: 'Ausbildung',
            city: 'Stadt',
            state: 'Bundesland',
            country: 'Land',
            countries: 'Länder',
            scientificTitle: 'Profession',
            firstname: 'Vorname',
            lastname: 'Nachname',
            placesLived: 'Aufenthaltsorte',
            jobRoles: 'Positionen',
            jobTitles: 'Job-Titel',
            more: 'weitere',
            addresses: 'Adressen',
            leakSource: 'Leak',
            description: 'Beschreibung',
            skills: 'Fähigkeiten',
            hobbies: 'Hobbies',
        },
        validUntil: 'Gültig bis: {{validUntil}}',
        icijLeakSource: {
            'Panama Papers': 'Panama Papers',
            'Offshore Leaks': 'Offshore Leaks',
            'Bahamas Leaks': 'Bahamas Leaks',
            'Paradise Papers - Malta corporate registry':
                'Paradise Papers - Unternehmensregister Malta',
            'Paradise Papers - Barbados corporate registry':
                'Paradise Papers - Unternehmensregister Barbados',
            'Paradise Papers - Appleby': 'Paradise Papers - Appleby',
            'Paradise Papers - Bahamas corporate registry':
                'Paradise Papers - Unternehmensregister Bahamas',
            'Paradise Papers - Lebanon corporate registry':
                'Paradise Papers - Unternehmensregister Libanon',
            'Paradise Papers - Aruba corporate registry':
                'Paradise Papers - Unternehmensregister Aruba',
            'Paradise Papers - Nevis corporate registry':
                'Paradise Papers - Unternehmensregister Nevis',
            'Pandora Papers - Fidelity Corporate Services':
                'Pandora Papers - Fidelity Corporate Services',
            'Pandora Papers - Alemán, Cordero, Galindo & Lee (Alcogal)':
                'Pandora Papers - Alemán, Cordero, Galindo & Lee (Alcogal)',
            'Paradise Papers - Cook Islands corporate registry':
                'Paradise Papers - Unternehmensregister Cookinseln',
            'Paradise Papers - Samoa corporate registry':
                'Paradise Papers - Unternehmensregister Samoa',
            'Pandora Papers - Alpha Consulting':
                'Pandora Papers - Alpha Consulting',
            'Pandora Papers - Trident Trust': 'Pandora Papers - Trident Trust',
            'Pandora Papers - CILTrust International':
                'Pandora Papers - CILTrust International',
            'Pandora Papers - Asiaciti Trust':
                'Pandora Papers - Asiaciti Trust',
            'Pandora Papers - Commence Overseas':
                'Pandora Papers - Commence Overseas',
            'Pandora Papers - SFM Corporate Services':
                'Pandora Papers - SFM Unternehmensdienstleistungen',
            'Pandora Papers - Il Shin Corporate Consulting Limited':
                'Pandora Papers - Il Shin Corporate Consulting Limited',
            'Pandora Papers - Overseas Management Company (OMC)':
                'Pandora Papers - Overseas Management Company (OMC)',
        },
        yearsOld: '{{ age }} Jahre alt',
        pagination: {
            previous: 'Vorherige',
            next: 'Nächste',
        },
        filters: {
            dateFrom: 'Datum von',
            dateTo: 'Datum bis',

            neutral: 'Neutral',
            positive: 'Positiv',
            mixed: 'Gemischt',
            negative: 'Negativ',
            source: 'Quelle',
            type: 'Typ',
            socialMedia: 'Soziale Medien',
            mainSocialMediaProfiles: 'Profile',
            potentialSocialMediaProfiles: 'Potentielle Profile',
            connectionType: 'Verbindungstyp',
            status: 'Status',
            involvement: 'Mitwirkung',
            post: 'Beitrag',
            connection: 'Verbindung',
            press: 'Presse',
            registerData: 'Verbundene Firmen',
            searchEngines: 'Suchmaschinen',
            image: 'Bild',
            sources: 'Quellen',
            active: 'Aktiv',
            inactive: 'Inaktiv',
            Orbis: 'Handelsregister (via BvD Orbis)',
            Pipl: 'Internetrecherche (via Pipl)',
            CompanyHouse: 'Handelsregister (via Companyhouse)',
            userInput: 'Nutzereingabe',
            Instagram: 'Instagram',
            Facebook: 'Facebook',
            Twitter: 'X',
            LinkedIn: 'LinkedIn',
            Xing: 'Xing',
            Flickr: 'Flickr',
            Google: 'Google',
            Bing: 'Bing',
            confidence: 'Konfidenzniveau',
            highConfidence: 'höchstwahrscheinlich Bewerber:in',
            indiferrent: 'eventuell Bewerber:in, keine klaren Anhaltspunkte',
            highNegativeConfidence:
                'Namensgleich, aber höchstwahrscheinlich andere Person',
            selected: 'Selected',
            search: 'Suche',
            clearFilters: 'Filter zurücksetzen',
            selectAll: 'Alle auswählen',
        },
        exactSearch: 'Genaue Suche',
        hitsCount: '{{hits}} Treffer',
        sortBy: 'Sortieren nach',
        suspicionCount: '{{hits}} Funde',
        confirmedSuspicionCount: '{{hits}} Bestätigt',
        reports: {
            redFlags: 'Red Flags: Sanktionslisten',
            anomaliesCount: '{{count}} Auffälligkeiten',
            sourcesCount: '{{count}} Quellen überprüft',
            resultsCount: '{{count}} Ergebnisse',
            sections: {
                compliance_violations: 'Hinweise auf Complianceverstöße',
                general_crime: 'Hinweise auf Kriminalität',
                own_keywords: 'Eigene Stichworte',
                general_anomalies: 'Auffälligkeiten',
                problematic_content_detected: 'Prüfenswerte Inhalte',
                extreme_opinions: 'Extreme Meinungen',
                moderation: 'Anstößige Inhalte (Soziale Medien)',
            },
            showAll: 'Alle anzeigen',
            showLess: 'Weniger anzeigen',
        },
        relevanceScore: 'Relevanz Score',
        suspicion: 'Verdacht',
        relatedSuspicion: 'Textinhalt mit Bezug zu',
        entity: 'Entität',
        suspicionItemSource: {
            User: 'Nutzereingabe',
            ML: 'Machine Learning Classifier',
            Cortical: 'AI',
            LogosLLM: 'AI',
            OwnKeyword: 'Eigene Stichworte',
            KeywordList: 'Regeln / Heuristiken',
        },
        suspicionItemInvolvement: {
            perpetrator: 'Täter:in',
            victim: 'Opfer',
            suspect: 'Verdächtige:r',
        },
        suspicionItemInvolvementDecision: {
            inferred: 'Abgeleitet', // Involvement inferred from context
            explicit: 'Explizit', // Explicitly stated involvement
            assumed: 'Angenommen', // Assumed involvement based on available information
            unknown: 'Unbekannt', // Involvement is unknown
        },
        googleCandidates: {
            groupTitle: 'Erweitete Suchmaschinen suche',
            selectGroup: 'Auswahl',
            excludeGroup: 'Ausschließen',
            results: 'Resultate',
            showGroupTopics: 'Gruppenthemen anzeigen',
            hideGroupTopics: 'Gruppenthemen verbergen',
            groupTopics: 'Gruppenthemen',
            moreDetails: 'Weitere Details',
            lessDetails: 'Weniger Details',
            imagesSelected: '{{ selected }}/{{ total }} Bildern ausgewählt',
            editImageSelection: 'Bilderauswahl bearbeiten',
            selectProfileImages: 'Wählen Sie Profilbilder aus',
            noCandidatesNoImages:
                'Wählen Sie Kandidat:innen mit Bildern aus, um Profilbilder auswählen zu können',
        },
        saveSelection: 'Auswahl speichern',
        addToReport: 'Zum Report hinzufügen',
        changeSuspicion: 'Ändern auf anderen Verdacht',
        changeSuspicionButton: 'Ändern',
        selectSuspicion: 'Wählen sie einen Verdacht',
        linkedSuspicions: 'Verknüpfte Verdachte',
        suspicionItemEntityType: {
            SocialAccountConnection:
                'öffentliche Verbindungen auf Soziale Medien',
            SocialAccountPost: 'öffentliche Beiträge auf Soziale Medien',
            press: 'Presse',
            TargetCompany: 'Registerdaten',
            WebPage: 'Suchmaschinen',
            Image: 'Bildmaterial',
        },
        suspicionItemStatus: {
            problem: 'Problem',
            no_problem: 'Unbedenklich',
            pending: 'Ausstehend',
            wrong_target: 'Falsche Person',
        },
        setAsProfilePhoto: 'als Profilbild verwenden',
        apiErrors: {
            403: 'Vereinbartes Volumen an Reports erreicht',
            default:
                'Es ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Eingabe und probieren Sie es erneut.',
        },
        companyStatus: {
            active: 'Aktiv',
            'active (default of payment)': 'Aktiv (Zahlungsausfall)',
            'active (insolvency proceedings)':
                'Aktiv (Insolvenzverfahren läuft)',
            'active (dormant)': 'Aktiv (ruhend)',
            insolvency: 'Insolvent',
            dissolved: 'Aufgelöst',
            'status unknown': 'Status unbekannt',
            inactive: 'Inaktiv',
            'in liquidation': 'In Liquidation',
            ak: 'Aktiv',
            ia: 'Inaktiv',
            'active (reorganization)': 'Aktiv (Reorganisation)',
            bankruptcy: 'Bankrott',
            'dissolved (bankruptcy)': 'Aufgelöst (Bankrott)',
            'dissolved (liquidation)': 'Aufgelöst (Liquidation)',
            'dissolved (merger or take-over)': 'Aufgelöst (nach Übernahme)',
            'inactive (no precision)': 'Inaktiv',
            'administratively suspended': 'Verwaltungsseitig inaktiv gesetzt',
            default: 'Status unbekannt',
        },
        companyCardTabs: {
            basicData: 'Basisdaten',
            subsidiaryCompany: 'Tochterunternehmen',
            ownersAndManagers: 'Eigentümer und Manager',
            financialInformation: 'Finanzinformationen',
            publications: 'Publikationen',
        },
        companyMutations: {
            kapitalaenderung: 'Kapitaländerung',
            kapitalherabsetzung: 'Kapitalherabsetzung',
            kapitalerhoehung: 'Kapitalerhöhung',
            aenderungorgane: 'Änderung der Organe',
            adressaenderung: 'Adressänderung',
            status: 'Status',
            firmenaenderung: 'Firmenänderung',
        },
        sourceName: {
            socialMedia: 'Soziale Medien (via Pipl / ShadowDragon)',
            orbis: 'Handelsregister (via BvD Orbis)',
            pipl: 'Internetrecherche (via Pipl)',
            companyhouse: 'Handelsregister (via Companyhouse)',
            complianceCatalyst:
                'Globale Sanktionslisten, Watchlisten und Listen politisch exponierter Personen (via "BvD Compliance Catalyst")',
            firmenabc: 'Handelsregister (via FirmenABC)',
            lexisnexis: 'Pressearchive (via Lexis Nexis)',
            google: 'Internetrecherche (via Google)',
            facebook: 'Soziale Medien (via ShadowDragon)',
            twitter: 'Soziale Medien (via ShadowDragon)',
            instagram: 'Soziale Medien (via ShadowDragon)',
            linkedin: 'Soziale Medien (via ShadowDragon)',
            xing: 'Soziale Medien (via ShadowDragon)',
            flickr: 'Soziale Medien (via ShadowDragon)',
            digitalclues: 'Soziale Medien (via ShadowDragon)',
            zefix: 'Handelsregister (via Zefix)',
            userinput: 'Nutzereingabe',
            userInput: 'Nutzereingabe',
            shadowDragon: 'Soziale Medien (via ShadowDragon)', // this is deprecated, but just here for backwards compatibility
            shadowdragon: 'Soziale Medien (via ShadowDragon)',
            icijleaks: 'Offshore-Leak-Archive (via ICIJ)',
        },
        inputDataDisclamer:
            'Hier können Sie Ihre ursprüngliche Eingabe-Daten einsehen. Ebenso können Sie die Daten verändern und so einen weiteren Report anlegen (kostenpflichtig).',
        somethingWentWrong: {
            title: 'Hoppla! Etwas ist schief gegangen.',
            subtitle:
                'Die gewünschte Seite kann aktuell nicht dargestellt werden. Wir entschuldigen uns für die Unannehmlichkeiten.',
            description:
                'Wenn das Problem weiterhin besteht oder falls Sie Hilfe erhalten möchten, kontaktieren Sie bitte den Support.',
            sendEmail: 'E-Mail senden',
            startChat: 'Live Chat starten',
            email: {
                subject: 'IDA - etwas hat nicht funktioniert',
                body: 'Lieber Indicium Support, auf folgender Seite ist ein Fehler passiert: {{currentUrl}}.',
            },
            backHomeButton: 'Zurück zur Startseite',
            tooltip: 'Support kontaktieren',
        },
        maintenance: {
            title: 'Die Seite wird derzeit gewartet',
            subtitle: 'Bitte versuchen Sie es später noch einmal',
            reload: 'Seite erneut laden',
        },
        companyCard: {
            dateOfIncorporation: 'Gründungsdatum',
            companyActivity: 'Unternehmensfokus',
            previousNames: 'vorige Firmierungen',
            identifiers: 'Identifikationsnummern',
            tradeRegisterNumber: 'Registernummer',
            vatTaxNumber: 'Umsatzsteuer ID',
            leiCompanyId: 'LEI Firmen ID',
            europeanVatNumber: 'Europäische Umsatzsteuernummer',
            subjectText: 'Unternehmensgegenstand',
            subsidiaryName: 'Firmierung',
            informationDate: 'Gründung/Kauf',
            country: 'Land',
            shareholder: 'Shareholder',
            manager: 'Manager',
            actuality: 'Status',
            name: 'Name',
            dob: 'Geburtsdatum',
            pob: 'Geburtsort',
            directlyOwned: '% Beteiligung',
            ultimatelyOwned: '% durchgerechnete Beteiligung',
            address: 'Address',
            financials: 'Eckdaten/Finanzinformationen',
            turnover: 'Umsatz',
            profit: 'Gewinn',
            employees: 'Anzahl Mitarbeiter',
            previous: 'Previous',
            current: 'Current',
        },
        dynamicForm: {
            title: 'Weitere Stichworte',
            selectField: 'Feld',
            fields: {
                occupations: 'Position',
                relatedPersons: 'Person',
                websites: 'Webseite',
                organizations: 'Organisation',
                topics: 'Thema',
                locations: 'Ort',
                nicknames: 'Spitznamen',
                default: 'Wort',
            },
            buttons: {
                include: 'Einbeziehen',
                exclude: 'Ausschließen',
            },
            description:
                'Bitte geben Sie weitere Stichworte ein, die die Bewerber:in beschreiben (auf Deutsch und Englisch): Orte (Land oder Stadt von Aufenthaltsorten, Geburtsorten, Arbeitsstätten, ...), Beschäftigungen (Titel, Jobbezeichnung, ...), Organisationen (außer Unternehmen), verbundene Webseiten, Personen (Vor- und Zuname) oder Themen (Hobbies, Events, etc.) die als mit der/die Bewerber:in zusammenhängend gesichert bekannt sind',
            totalKeywords: 'Anzahl Stichworte:',
            maxKeywords: '(Maximum: {{maxLength}})',
        },
        confidenceScore: {
            score10: 'Mit hoher Wahrscheinlichkeit FALSCHE Person',
            score20: 'wenig Anhaltspunkte, ob es der/die Bewerber:in ist',
            score30: 'Mit hoher Wahrscheinlichkeit der/die Bewerber:in',
            matchesLabel: 'Score Zusammensetzung',
            matches: {
                birthPlace: 'Geburtsort',
                company: {
                    name: 'Firmenname',
                    addresses: 'Firmenadresse',
                    countries: 'Firmenland',
                    managers: 'Manager',
                    shareholders: 'Anteilseigner',
                },
                educations: 'Bildungsinstitution',
                emails: 'E-Mail',
                locations: 'Ort',
                name: 'Name',
                nationality: 'Nationalität',
                nicknames: 'Spitznamen',
                occupations: 'Position',
                organizations: 'Organisation',
                phones: 'Telefon',
                relatedPersons: 'Person',
                residence: {
                    street: 'Straße des Wohnsitzes',
                    city: 'Stadt des Wohnsitzes',
                    country: 'Land des Wohnsitzes',
                },
                topics: 'Thema',
                username: 'Benutzernamen',
                websites: 'Webseite',
            },
        },
        suspicionKeywords: {
            arson: 'Brandstiftung',
            assault: 'Körperverletzung',
            asset_misuse_misdemeanors: 'Vermögensmissbrauch',
            assets_misuse_offenses: 'Vermögensmissbrauchsdelikte',
            banking_payment_offenses:
                'Vergehen aus Banken- oder Zahlungsdiensterecht',
            black_market: 'Schwarzhandel',
            bmf_fiu_risk_country: 'BMF/FIU Risikoland',
            capital_market_offenses: 'Kapitalmarktrechtsvergehen',
            capital_offenses: 'Kapitalverbrechen',
            child_pornography: 'Kinderpornographie',
            clans: 'Clans',
            climate_change_denier: 'Klimawandel-Leugner:in',
            company_blacklist: 'Firma auf Blacklist',
            conspiracy_theorist: 'Verschwörungstheoretiker:in',
            copyright_infringement: 'Urheberrrechtsverletzung',
            corona_denier: 'Corona-Leugner:in',
            corporate_governance_code: 'Corporate Governance Vergehen',
            corporate_insurance_offenses: 'Versicherungsbetrug',
            corporate_law_violations: 'Gesellschaftsrechtsvergehen',
            corporate_logistics_offenses:
                'Vergehen in der betrieblichen Logistik',
            corporate_obligations: 'Verstoß gegen Unternehmerpflichten',
            corporate_operations:
                'Problematiken in Zusammenhang mit Unternehmensabläufen',
            corruption: 'Korruption',
            cybercrime: 'Cyberkriminalität',
            data_breach: 'Datenschutzverletzung',
            data_security_breaches: 'Datenschutzvergehen',
            debt_law_offenses: 'Schuldrechtsvergehen',
            digital_black_markets: 'Digitale Schwarzmärkte',
            employment_relationships: 'Beschäftigungsrechtliche Problematiken',
            eu_third_country_with_increased_:
                'EU Drittland mit erhöhtem Risiko',
            exhibitionism: 'Exhibitionismus',
            exploiting_leading_position: 'Ausnutzen führender Postion',
            explosives: 'Sprengstoffe',
            extortion: 'Erpressung',
            extremism_terror: 'Extremismus / Terror',
            fair_trading_offenses: 'Lauterkeitsrechtsvergehen',
            fatf_increased_monitoring: 'Erhöhte FATF-Überwachung',
            financial_reporting: 'Fehlerhafte Finanzberichterstattung',
            firearms: 'Schusswaffen',
            foreign_trade_law: 'Verstöße im Außenwirtschaftsrecht',
            fraud_consumer: 'Betrugsdelikte / Verbraucher',
            hacking_attacks: 'Hacking Attacken',
            harassment: 'Belästigung',
            harassment_threatening: 'Belästigung / Bedrohung',
            hate: 'Hass',
            hate_speech: 'Hassrede',
            hate_threatening: 'Hass / Bedrohung',
            health_safety_offenses:
                'Arbeitssicherheits / Arbeitsschutzvergehen',
            human_rights_violations: 'Menschenrechtsverletzung',
            identity_theft: 'Identitätsdiebstahl',
            illicit: 'Gesetzwidrigkeit',
            illicit_violent: 'Gesetzwidrigkeit mit Hinweis auf Gewalttätigkeit',
            indications_for_crime: 'Hinweise auf Kriminalität',
            information_protection: 'Mangelnder Schutz von Informationen',
            insolvency_law_offenses: 'Insolvenzvergehen',
            intolerance_bigotry_discrimination: 'Diskriminierung',
            islamist_terror: 'Islamistischer Terror',
            labor_law_offenses: 'Arbeitsrechtsvergehen',
            left_wing_extremism: 'Linksextremismus',
            malware: 'Schadprogramme (Erstellung / Nutzung)',
            money_laundering: 'Geldwäsche',
            money_laundering_country_list: 'Auf Liste Geldwäscheländer',
            murder_manslaughter: 'Mord / Totschlag',
            negative_content: 'Negativer Inhalt',
            negative_sentiment: 'Negative Ausdrucksweise',
            no_internet_presence: 'Keine Internetpräsenz',
            offenses: 'Beleidigung',
            business_operations_and_risk_management_offenses:
                'Verstöße in Geschäftsbetrieb und Risikomanagement',
            financial_and_corporate_compliance_offenses:
                'Verstöße in Finanz- und Unternehmenscompliance',
            offshore_leak: 'Genannt in Offshore Leak Datenbanken',
            organized_crime: 'Organisierte Kriminalität',
            other_criminal_activities: 'Sonstige kriminelle Aktivitäten',
            potential_compliance_violation: 'Hinweise auf Complianceverstöße',
            procurement_law_offenses: 'Vergaberechtsverstöße',
            product_safety: 'Vergehen in der Produktsicherheit und -haftung',
            property_damage: 'Sachbeschädigung',
            property_offenses:
                'Potentielle Vergehen aus dem Bereich betrieblicher Grundstücke und Immobilien',
            racism: 'Rassismus',
            ransomware: 'Ransomware',
            rape: 'Vergewaltigung',
            red_light_milieu: 'Rotlichtmilieu',
            right_wing_extremism: 'Rechtsextremismus',
            robbery_kidnapping: 'Raub / Entführung / Menschenhandel',
            rockers: 'Rocker / MCs',
            russian_war_supporter: 'Russland-Kriegsbefürworter:in',
            scam_fraud: 'Scam / Betrug',
            self_harm: 'Selbstverletzung',
            self_harm_instructions: 'Anleitung zur Selbstverletzung',
            self_harm_intent: 'Absicht auf Selbstverletzung',
            sexual: 'Sexualität',
            sex_offenses: 'Sexualdelikte',
            sexual_assault: 'Sexuelle Nötigung',
            sexual_minors: 'Sexualität in Zusammenhang mit Minderjährigen',
            social_security_fraud: 'Sozialversicherungsbetrug',
            substance_abuse: 'Drogendelikte',
            sunsidies_funding_offenses: 'Subventionsbetrug',
            sustainability_environment: 'Nachhaltigkeits- und Umweltvergehen',
            tax_offenses: 'Steuervergehen',
            theft: 'Diebstahl / Unterschlagung / Hehlerei',
            trade_secrets: 'Geheimnisverrat',
            transactions: 'Problematiken in Zusammenhang mit Transaktionen',
            violent_crimes: 'Gewaltdelikte',
            violence: 'Gewalt',
            violence_graphic: 'Grafische Gewalt',
            weapon_offenses: 'Waffendelikte',
            white_collar_crime: 'Wirtschaftskriminalität',
            wrong_notices: 'Fehlerhafte Kündigungen',
        },
        errorEntityNames: {
            title: 'Es gab Probleme mit externen Diensten.',
            subTitle:
                'Folgende Daten könnten eventuell fehlen oder unvollständig sein: ',
            entity: {
                company: 'Unternehmen',
                facebookFollower: 'Facebook-Follower',
                facebookFollowing: 'Facebook-Gefolgt',
                facebookFriend: 'Facebook-Freunde',
                facebookPhoto: 'Facebook-Fotos',
                facebookPost: 'Facebook-Beiträge',
                facebookUser: 'Facebook-Nutzer',
                facebookVideo: 'Facebook-Videos',
                googleNews: 'Google News',
                image: 'Bilder',
                instagramFollower: 'Instagram-Follower',
                instagramFollowing: 'Instagram-Gefolgt',
                instagramPost: 'Instagram-Beiträge',
                instagramReel: 'Instagram-Reels',
                instagramStory: 'Instagram-Storys',
                instagramUser: 'Instagram-Nutzer',
                linkedInEducation: 'LinkedIn-Ausbildungen',
                linkedInPosition: 'LinkedIn-Positionen',
                linkedInPost: 'LinkedIn-Beiträge',
                linkedInUser: 'LinkedIn-Nutzer',
                person: 'Personen',
                pressArticle: 'Presseartikel',
                searchEngineImage: 'Suchmaschinenbilder',
                searchEnginePage: 'Suchmaschinenseiten',
                xFollower: 'X-Follower',
                xFollowing: 'X-Gefolgt',
                xTweet: 'X-Tweets',
                xUser: 'X-Nutzer',
                xingConnections: 'Xing-Verbindungen',
                xingUser: 'Xing-Nutzer',
            },
        },
        socialMediaErrors: {
            externalError:
                'Aufgrund eines Problems bei einem Dienstleister, sind die folgenden Daten potenziell unvollständig:',
            warning:
                'Aufgrund eines Problems bei einem Dienstleister, könnten folgende Daten unvollständig sein:',
            internalError:
                'Aufgrund eines Systemfehlers fehlen folgende Daten. Bitte kontaktieren Sie support@indicium.ag',
            path: {
                user: 'Benutzer',
                posts: 'Beiträge',
                friends: 'Freunde',
                following: 'Gefolgt',
                followers: 'Follower',
                tweets: 'Tweets',
                post: 'Beitrag',
                video: 'Video',
                photo: 'Foto',
                reel: 'Reel',
                story: 'Story',
                tweet: 'Tweet',
                contacts: 'Kontakte',
                educations: 'Ausbildungen',
                positions: 'Positionen',
            },
        },
    },
};
