import React from 'react';
import classNames from 'classnames';

interface FormLabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
    children: React.ReactNode;
}

export const FormLabel = React.forwardRef<HTMLLabelElement, FormLabelProps>(
    ({ className, children, ...rest }, ref) => {
        return (
            <label
                ref={ref}
                className={classNames(
                    'text-sm font-bold font-jost text-neutral-500',
                    className,
                )}
                {...rest}
            >
                {children}
            </label>
        );
    },
);

FormLabel.displayName = 'FormLabel';
