import { ElementType, FC } from 'react';
import { Paragraph } from '_atoms';
import { HeaderComponentProps } from '../form.interface';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

export const TARGET_SUMMARY_SECTION_HEADER_ICON_SIZE = 15;
export const TARGET_SUMMARY_ICON_SIZE = 18;

export const SimpleHeader: FC<HeaderComponentProps> = ({ sectionKey }) => {
    const { t } = useTranslation();
    return (
        <Paragraph weight="bold" color="white" size="small" className="px-1">
            {t(`targetWizard.fields.${sectionKey}.title`)}
        </Paragraph>
    );
};

export const LeftSideSectionTitle: FC<HeaderComponentProps> = ({
    icon: Icon,
    sectionKey,
}) => {
    const { t } = useTranslation();
    return (
        <div className="flex items-center justify-center bg-neutral-300 p-2 rounded-md text-font-dark">
            {!!Icon && (
                <Icon
                    size={TARGET_SUMMARY_SECTION_HEADER_ICON_SIZE}
                    width={TARGET_SUMMARY_SECTION_HEADER_ICON_SIZE}
                    height={TARGET_SUMMARY_SECTION_HEADER_ICON_SIZE}
                />
            )}
            <Paragraph size="small" className="px-2 uppercase text-center">
                {t(`targetWizard.fields.${sectionKey}.title`)}
            </Paragraph>
        </div>
    );
};

export const RightSideSectionTitle: FC<HeaderComponentProps> = ({
    icon: Icon,
    sectionKey,
    className,
}) => {
    const { t } = useTranslation();
    return (
        <div
            className={classnames(
                className,
                'flex items-center justify-center gap-2 bg-neutral-300 p-2 rounded-md text-font-dark mb-2',
            )}
        >
            {!!Icon && (
                <Icon
                    size={TARGET_SUMMARY_SECTION_HEADER_ICON_SIZE}
                    width={TARGET_SUMMARY_SECTION_HEADER_ICON_SIZE}
                    height={TARGET_SUMMARY_SECTION_HEADER_ICON_SIZE}
                />
            )}
            <Paragraph size="small" className="uppercase text-center">
                {t(`targetWizard.fields.${sectionKey}.title`)}
            </Paragraph>
        </div>
    );
};

export const LeftSideText: FC = ({ children }) => (
    <Paragraph color="white" size="small" className="text-left">
        {children}
    </Paragraph>
);

export const LeftSideBigText: FC = ({ children }) => (
    <Paragraph color="white" size="big">
        {children}
    </Paragraph>
);

export const RightSideText: FC = ({ children }) => (
    <Paragraph size="small" HtmlTag="div">
        {children}
    </Paragraph>
);

export const RightSideBigText: FC = ({ children }) => (
    <Paragraph
        color="dark"
        weight="semibold"
        size="default"
        className="fontFamily-sans:Helvetica capitalize"
    >
        {children}
    </Paragraph>
);

export const RightSideLightText: FC<{ className?: string }> = ({
    children,
    className,
}) => (
    <Paragraph color="light" weight="normal" size="small" className={className}>
        {children}
    </Paragraph>
);

export const RightSideIconAndText: FC<{
    Icon?: ElementType;
    text: string;
    label?: string;
}> = ({ Icon, text, label }) => (
    <div className="flex items-center gap-2 text-font-dark">
        {Icon && <IconWrapper icon={Icon} size={TARGET_SUMMARY_ICON_SIZE} />}
        <RightSideText>
            {label && (
                <Paragraph size="sLabel" color="light">
                    {label + ':'}
                </Paragraph>
            )}
            {text}
        </RightSideText>
    </div>
);

export const IconWrapper: FC<{
    icon: ElementType;
    size: number;
    className?: string;
}> = ({ icon: Icon, size, className }) => (
    <Icon
        size={size}
        width={size}
        height={size}
        className={classnames(className, 'shrink-0 my-0.25')}
    />
);
