import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { baseURL } from '../../services/nestApiService';
import { ChecklistData } from '../../types/ChecklistData';

export const getChecklists = async (
    targetId = '',
): Promise<ChecklistData[]> => {
    return (await axios.get(`${baseURL}/checklists/targets/${targetId}`)).data
        .data;
};

export const useChecklistsQuery = ({
    targetId,
    isEnabled,
    onSuccess,
    onError,
}: {
    targetId?: string;
    isEnabled?: boolean;
    onSuccess?: (data: ChecklistData[]) => void;
    onError?: () => void;
}): UseQueryResult<ChecklistData[], string> => {
    return useQuery(['checklists', targetId], () => getChecklists(targetId), {
        enabled: isEnabled,
        onSuccess,
        onError,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
    });
};
