export const industryOptions = [
    'agriculture_and_farming',
    'aerospace_and_defense',
    'artificial_intelligence_and_machine_learning',
    'architecture_and_urban_planning',
    'automotive',
    'biotechnology',
    'chemicals_and_petrochemicals',
    'consumer_electronics',
    'consulting',
    'construction',
    'cybersecurity',
    'digital_marketing_and_advertising',
    'e_commerce',
    'education',
    'electronics_and_semiconductors',
    'energy',
    'entertainment_and_media',
    'environmental_services',
    'event_planning_and_management',
    'fashion_and_apparel',
    'finance_and_banking',
    'fitness_and_wellness',
    'food_and_beverage',
    'forestry_and_timber',
    'gaming_and_esports',
    'healthcare',
    'hospitality',
    'human_resources_and_recruitment',
    'information_technology',
    'interior_design_and_home_improvement',
    'insurance',
    'legal_services',
    'logistics_and_supply_chain',
    'marine_and_maritime',
    'mining_and_metals',
    'music_and_arts',
    'nonprofit',
    'personal_care_and_beauty',
    'pharmaceuticals',
    'printing_and_publishing',
    'public_administration_and_government',
    'real_estate',
    'research_and_development',
    'retail',
    'space_exploration_and_technology',
    'sports_and_recreation',
    'telecommunications',
    'textile_manufacturing',
    'transportation',
    'travel_and_tourism',
    'venture_capital_and_private_equity',
];
