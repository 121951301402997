import axios from 'axios';
import { useMutation, UseMutationResult } from 'react-query';
import { baseURL } from '../../services/nestApiService';
import { ChecklistTemplate } from '../../types/ChecklistTemplate';

export const updateChecklistTemplate = async ({
    id,
    ...data
}: ChecklistTemplate): Promise<ChecklistTemplate> => {
    return (await axios.put(`${baseURL}/checklists/templates/${id}`, data))
        .data;
};

export const useUpdateChecklistTemplateMutation = ({
    onSuccess,
    onError,
}: {
    onSuccess?: (data: ChecklistTemplate) => void;
    onError?: () => void;
}): UseMutationResult<ChecklistTemplate, string, ChecklistTemplate> => {
    return useMutation(updateChecklistTemplate, {
        onSuccess,
        onError,
    });
};
