export const stripFalsyValues = <T>(input: T): T => {
    if (Array.isArray(input)) {
        const cleanedArray = input
            .map((value) => stripFalsyValues(value as T))
            .filter((v) => v !== undefined) as unknown as T;

        // Re-check if array became empty after cleaning
        return (cleanedArray as unknown as unknown[]).length === 0
            ? (undefined as unknown as T)
            : cleanedArray;
    }

    if (typeof input === 'string') {
        return input.trim() === ''
            ? (undefined as unknown as T)
            : (input.trim() as unknown as T);
    }

    if (typeof input === 'object' && input !== null) {
        const obj: Record<string, unknown> = {};

        for (const [key, oldValue] of Object.entries(input)) {
            const newValue = stripFalsyValues(oldValue);
            if (newValue !== undefined) {
                obj[key] = newValue;
            }
        }

        // If object became empty, return undefined
        return Object.keys(obj).length === 0
            ? (undefined as unknown as T)
            : (obj as unknown as T);
    }

    return input ?? (undefined as unknown as T);
};
