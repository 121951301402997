import {
    CaseKeyword,
    TargetCompanies,
    TargetProfile,
} from '../../../services/dataService';
import { removeLegalSuffix } from '_utils';
import { HighlightChunks, IndicatorsWithTooltip } from './types';
import { escapeRegExp } from 'lodash';

interface HighlightDeps {
    searchQuery?: string;
    indicators?: IndicatorsWithTooltip[];
    caseKeywords?: CaseKeyword[];
    targetProfile?: TargetProfile;
    targetCompanies?: TargetCompanies;
}

export const buildNameRegExps = (
    inputData: TargetProfile,
): ReadonlyArray<RegExp> => {
    const firstname = escapeRegExp(inputData.firstname?.toLowerCase() ?? '');
    const middlename = escapeRegExp(inputData.middlename?.toLowerCase() ?? '');
    const lastname = escapeRegExp(inputData.lastname?.toLowerCase() ?? '');

    const regExps = [
        new RegExp(`\\b${firstname}\\s+${lastname}\\b`, 'im'),
        new RegExp(`\\b${firstname.charAt(0)}\\.\\s+${lastname}\\b`, 'im'),
        new RegExp(`\\b${lastname},\\s+${firstname}\\b`, 'im'),
        new RegExp(`\\b${lastname},\\s+${firstname.charAt(0)}\\.\\b`, 'im'),
    ];

    if (!middlename) {
        return regExps;
    }

    return [
        ...regExps,
        new RegExp(`\\b${firstname}\\s+${middlename}\\s+${lastname}\\b`, 'im'),
        new RegExp(
            `\\b${firstname}\\s+${middlename.charAt(0)}\\s+${lastname}\\b`,
            'im',
        ),
        new RegExp(
            `\\b${firstname}\\s+${middlename.charAt(0)}\\.\\s+${lastname}\\b`,
            'im',
        ),
    ];
};

const getTargetPerson = (target?: TargetProfile): ReadonlyArray<RegExp> =>
    target ? buildNameRegExps(target) : [];

export const getTargetCompanies = (
    target?: TargetCompanies,
): ReadonlyArray<RegExp> => {
    return (target?.companies ?? [])
        .flatMap((company) => company.data.name)
        .flatMap((companyName) => {
            const lowerCasedName = companyName.value.toLowerCase();
            const trimmedCompanyName = removeLegalSuffix(lowerCasedName);
            return trimmedCompanyName.length === lowerCasedName.length
                ? [lowerCasedName]
                : [lowerCasedName, trimmedCompanyName.trim()];
        })
        .map((name) => {
            return new RegExp(`\\b${escapeRegExp(name)}\\b`, 'im');
        });
};

const getCaseKeywords = (caseKeywords?: CaseKeyword[]): string[] =>
    caseKeywords
        ? caseKeywords
              .flatMap((caseKeyword) => caseKeyword.translations)
              .map(({ value }) => value)
              .concat(caseKeywords.map(({ value }) => value))
        : [];

const getSearchQuery = (searchQuery?: string): string[] =>
    searchQuery ? [searchQuery] : [];

const getIndicatorsQuery = (indicators?: IndicatorsWithTooltip[]): string[] =>
    indicators ? indicators.flatMap(({ highlightText }) => highlightText) : [];

export const buildHighlightChunks = (deps: HighlightDeps): HighlightChunks => ({
    searchQuery: getSearchQuery(deps.searchQuery),
    suspicionKeywords: getIndicatorsQuery(deps.indicators),
    caseKeywords: getCaseKeywords(deps.caseKeywords),
    targetPerson: getTargetPerson(deps.targetProfile),
    targetCompanies: getTargetCompanies(deps.targetCompanies),
});
