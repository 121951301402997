import { useQuery, UseQueryResult } from 'react-query';
import { ReportState } from '../../services/dataService';
import { targets } from '../../services/nestApiService';

export const useTargetReportStateQuery = (
    caseId: string,
    targetId: string,
): UseQueryResult<ReportState> =>
    useQuery(
        ['target', caseId, targetId, 'results', 'v2'],
        () => targets.getReportState(caseId, targetId),
        {
            refetchOnWindowFocus: false,
        },
    );
