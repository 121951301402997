import { FC, useCallback, useEffect, useState } from 'react';
import { Form, Formik, FormikErrors, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import {
    DynamicFormValue,
    Gender,
    SocialMediaType,
    Title,
    UserInputTarget,
} from '@indicium/common';
import { Button, Headline, Paragraph, StyledField } from '_atoms';
import { Link, useParams } from 'react-router-dom';
import {
    getCountrySelectionOptions,
    getNationalitySelectionOptions,
} from '../TargetNew/countryCodeOptions';
import { useCaseQuery, useDeleteTargetMutation } from '_queries';
import './TargetForm.css';
import {
    emptyJobEntry,
    getContact,
    getEducationEntriesFromEducationInput,
    getFormDateFromDateRange,
    getJobEntriesFromJobInput,
    getSocialMediaProfiles,
} from './targetFormUtils';
import { ProcessTarget } from '@indicium/common/src/types/Target/TargetTypes';
import { useUserQuota } from '../../../hooks/useUserQuota';
import { nonProdDataTestId } from '_utils';
import { CollapsibleSection, DynamicForm } from '_molecules';
import { TargetFormSchema } from '../../../schemas/targetFormSchema';
import { merge } from 'lodash';
import { CVUploader } from '../../../components/_molecules/CVUploader';
import { SpotlightTarget } from '@atlaskit/onboarding';

interface TargetFormProps {
    targetInput?: ProcessTarget;
    showBackButton?: boolean;
    onSubmit?: (values: unknown) => Promise<void>;
    isSubmitting: boolean;
    apiErrors: string[];
    disabled?: boolean;
    redirect?: (values: unknown) => void;
}

export const targetFormInitialValues = {
    title: '',
    gender: '',
    firstname: '',
    lastname: '',
    middlename: '',
    nicknames: [],
    dateOfBirth: '',
    nationalities: [''],
    countryOfBirth: '',
    countryOfResidence: '',
    contact: getContact(),
    socialMediaProfiles: getSocialMediaProfiles(),
    relatedPersons: [],
    websites: [] as DynamicFormValue[],
    organizations: [] as DynamicFormValue[],
    topics: [] as DynamicFormValue[],
    locations: [] as DynamicFormValue[],
    occupations: [], // retro-compatibility
    education: getEducationEntriesFromEducationInput([]),
    jobs: getJobEntriesFromJobInput([emptyJobEntry]),
    note: { value: '' },
    description: '',
    skills: [],
    hobbies: [],
    typos: [],
} as Partial<Omit<UserInputTarget, 'title' | 'gender'>> & {
    title?: string;
    gender?: string;
}; // we need this type conversion here

export type TargetFormData = typeof targetFormInitialValues;

const isProfessionalPlatform = (type: string): boolean =>
    ['linkedin', 'xing'].includes(type);

function mergeTargetFormData(
    _obj: TargetFormData,
    src?: TargetFormData,
): TargetFormData {
    // eslint-disable-next-line no-undef
    const obj = structuredClone(_obj);
    if (!src) {
        return obj;
    }
    const result = merge(
        {},
        {
            ...obj,
        },
        {
            ...src,
            socialMediaProfiles: undefined,
            contact: [
                {
                    type: 'phone',
                    value:
                        src.contact?.find((c) => c.type === 'phone')?.value ??
                        '',
                },
                ...(src.contact?.filter((c) => c.type !== 'phone') ?? []),
            ],
        },
    );
    if (!src.socialMediaProfiles) {
        return result;
    }
    let socialMediaProfiles: Record<string, string> = {};
    if (obj.socialMediaProfiles && src.socialMediaProfiles) {
        const socialMediaProfilesObj = Object.fromEntries(
            obj.socialMediaProfiles.map(
                (s: { type: SocialMediaType; value: string }) => [
                    s.type,
                    s.value,
                ],
            ),
        );
        const socialMediaProfilesSrc = Object.fromEntries(
            src.socialMediaProfiles.map((s) => [s.type, s.value]),
        );
        socialMediaProfiles = merge(
            {},
            socialMediaProfilesObj,
            socialMediaProfilesSrc,
        );
    }
    const constructedSocialMediaProfiles = Object.entries(
        socialMediaProfiles,
    ).map(([type, value]) => ({ type: type as SocialMediaType, value }));
    return {
        ...result,
        socialMediaProfiles: constructedSocialMediaProfiles,
    };
}

export const TargetForm: FC<TargetFormProps> = ({
    targetInput,
    showBackButton = false,
    onSubmit = async (values) => {
        return Promise.resolve(values);
    },
    isSubmitting,
    apiErrors,
    redirect,
    disabled = false,
}) => {
    const [state, setState] = useState<{
        target?: TargetFormData;
        images?: string[];
    }>({});

    const { t, i18n } = useTranslation();

    useEffect(() => {
        resetForm();
        const listener = (e: KeyboardEvent) => {
            if (e.key === 'Enter') {
                e.preventDefault();
            }
        };
        window.addEventListener('keydown', listener);
        return () => {
            window.removeEventListener('keydown', listener);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [formValues, setFormValues] = useState<TargetFormData>(
        mergeTargetFormData(targetFormInitialValues, state?.target),
    );

    useEffect(() => {
        setFormValues(
            mergeTargetFormData(targetFormInitialValues, state?.target),
        );
    }, [state]);

    const [selectedImage, _setSelectedImage] = useState<any>(null);
    const _handleImageUpload = useCallback(() => {
        // Create an object of formData
        const formData = new FormData();

        // Update the formData object
        formData.append('targetImage', selectedImage, selectedImage?.name);

        // TODO: enable when connecting to the BE
        // Request made to the backend api
        // Send formData object
        // axios.post('api/uploadfile', formData);
        // After successful upload, set the state for the for field "image" to the returned url
    }, [selectedImage]);

    const { isError: isDeleteTargetError } = useDeleteTargetMutation();

    const { caseId } = useParams<{ caseId: string }>();

    const { quota } = useUserQuota();

    const fetchedCase = useCaseQuery(caseId);

    const resetForm = useCallback(() => {
        setFormValues({
            ...targetFormInitialValues,
            title: targetInput?.title || '',
            gender: targetInput?.gender || '',
            firstname: targetInput?.firstname || '',
            lastname: targetInput?.lastname || '',
            middlename: targetInput?.middlename || '',
            nicknames: targetInput?.nicknames || [],
            dateOfBirth:
                typeof targetInput?.dateOfBirth === 'string'
                    ? targetInput?.dateOfBirth
                    : getFormDateFromDateRange(targetInput?.dateOfBirth, ''),
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            nationalities: targetInput?.nationalities
                ? typeof targetInput?.nationalities === 'string'
                    ? [targetInput.nationalities]
                    : targetInput?.nationalities
                : [''],
            countryOfBirth: targetInput?.countryOfBirth || '',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            contact: getContact(targetInput?.contact),
            socialMediaProfiles: getSocialMediaProfiles(
                targetInput?.socialMediaProfiles,
            ),
            relatedPersons: targetInput?.relatedPersons || [],
            websites: targetInput?.websites || [],
            organizations: targetInput?.organizations || [],
            topics: targetInput?.topics || [],
            locations: targetInput?.locations || [],
            countryOfResidence: targetInput?.countryOfResidence || '',
            education: getEducationEntriesFromEducationInput(
                targetInput?.education ?? [],
            ),
            jobs: getJobEntriesFromJobInput(
                targetInput?.jobs?.length ? targetInput.jobs : [emptyJobEntry],
            ),
            note: { value: targetInput?.note?.value || '' },
        });
    }, [targetInput]);

    useEffect(() => {
        resetForm();
    }, [resetForm, targetInput]);

    const nationalitySelectionOptions = getNationalitySelectionOptions(
        i18n.language,
    );

    const countrySelectionOptions = getCountrySelectionOptions(i18n.language);

    const buttons = (
        errors: FormikErrors<FormikValues>,
        values: FormikValues,
    ) => (
        <div>
            <div className="space-x-4">
                <CVUploader
                    customView={{
                        openElement(open) {
                            return (
                                <SpotlightTarget name="onboardingTargetFormUploadPdf">
                                    <Button
                                        level="secondary"
                                        onClick={open}
                                        className="w-52"
                                        type="reset"
                                    >
                                        {t('uploadPdf')}
                                    </Button>
                                </SpotlightTarget>
                            );
                        },
                    }}
                    onUpload={(target, images) => {
                        resetForm();
                        setState({ target, images });
                    }}
                    onError={(error) => {
                        alert(
                            t('cvUploadFailed') +
                                '\n' +
                                (typeof error === 'object' &&
                                error instanceof Error
                                    ? error.message
                                    : String(error)),
                        );
                    }}
                />
                {showBackButton && (
                    <Link className="h-fit-content" to={`/cases/${caseId}`}>
                        <Button
                            type="button"
                            className="w-52"
                            level="primaryGhost"
                        >
                            {t('back')}
                        </Button>
                    </Link>
                )}

                {!disabled && (
                    <SpotlightTarget name="onboardingTargetFormNext">
                        <Button
                            className="w-52"
                            level="primary"
                            disabled={
                                isSubmitting ||
                                !quota?.remaining ||
                                Object.keys(errors).length > 0
                            }
                            type="submit"
                            data-testid={nonProdDataTestId('submit button')}
                        >
                            {t('continue')}
                        </Button>
                    </SpotlightTarget>
                )}

                {disabled && (
                    <Button
                        className="w-52"
                        level="primaryGhost"
                        type="button"
                        onClick={() => (redirect ? redirect(formValues) : {})}
                    >
                        {t('duplicate')}
                    </Button>
                )}
            </div>
            <div className="text-right">
                {isDeleteTargetError ? (
                    <Paragraph
                        size="default"
                        weight="normal"
                        lineHeight="default"
                        color="blue"
                        className="mt-4"
                    >
                        {t('targetNewForm.targetDeletionNotAllowed')}
                    </Paragraph>
                ) : null}
                {isSubmitting ? (
                    <Paragraph
                        size="default"
                        weight="normal"
                        lineHeight="default"
                        color="default"
                        className="mt-4"
                    >
                        {t('targetNewForm.targetCreationInProgress')}
                    </Paragraph>
                ) : null}
                {(values.firstname.length === 0 ||
                    values.lastname.length === 0) && (
                    <Paragraph
                        size="default"
                        weight="normal"
                        lineHeight="default"
                        color="red"
                        className="mt-4"
                    >
                        {t('requiredNameError')}
                    </Paragraph>
                )}
                {/* TODO: Replace error list down here with an active Next button that jumps to
                  any invalid fields */}
                {errors.dateOfBirth && (
                    <Paragraph
                        size="default"
                        weight="normal"
                        lineHeight="default"
                        color="default"
                        className="mt-4 w-52"
                    >
                        {t('schemaValidation.invalidDate')}
                    </Paragraph>
                )}
                {errors.jobs && (
                    <Paragraph
                        size="default"
                        weight="normal"
                        lineHeight="default"
                        color="red"
                        className="mt-4"
                    >
                        {t('requiredCompanyError')}
                    </Paragraph>
                )}
                {apiErrors.length > 0
                    ? apiErrors.map((error, index) => (
                          <Paragraph
                              key={[error, index].join(':')}
                              size="default"
                              weight="normal"
                              lineHeight="default"
                              className="mt-4 w-52 text-red-700"
                          >
                              {error}
                          </Paragraph>
                      ))
                    : null}
            </div>
        </div>
    );

    return (
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={formValues}
                onSubmit={onSubmit}
                validationSchema={TargetFormSchema}
                validateOnMount
            >
                {({ errors, values, setFieldValue, touched }) => (
                    <Form className="space-y-6 pb-9" noValidate>
                        <div className="z-9 sticky top-0 bg-neutral-250">
                            <div className="flex justify-between space-x-6 xl:max-w-max-content mx-auto p-9 relative after:w-full after:absolute after:h-0.5 after:bg-neutral-300 after:left-0 after:bottom-0">
                                <div className="space-y-5">
                                    <Headline Level="h3" color="dark">
                                        {t('targetPersonDataHeadline')}
                                    </Headline>
                                    <Headline Level="h5" color="dark">
                                        {t('targetPersonDataSubHeadline')}
                                    </Headline>
                                </div>
                                <div className="flex justify-end items-center space-x-4">
                                    {buttons(errors, values)}
                                </div>
                            </div>
                        </div>
                        <div className="bg-neutral-100 rounded-md py-5 px-6 relative space-y-9 xl:max-w-max-content mx-auto p-9">
                            <SpotlightTarget name="onboardingTargetFormBasicData">
                                <section className="row space-y-6">
                                    <div className="col-12 flex justify-between items-center">
                                        <Headline
                                            Level="h4"
                                            color="dark"
                                            className="my-3.5"
                                        >
                                            {t('basisData')}
                                        </Headline>
                                        {state?.target && (
                                            <div className="text-yellow-400 text-sm">
                                                {t('cvParsedWarning')}
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-6 md:col-3">
                                        <StyledField
                                            as="select"
                                            name="title"
                                            label={t('title')}
                                            autofilled={
                                                state?.target &&
                                                values.title ===
                                                    state?.target?.title &&
                                                !touched.title
                                            }
                                            data-testid={nonProdDataTestId(
                                                'title',
                                            )}
                                            disabled={disabled}
                                        >
                                            <option value="">-</option>
                                            {Object.values(Title).map(
                                                (title) => (
                                                    <option
                                                        value={title}
                                                        key={title}
                                                    >
                                                        {t(`titles.${title}`)}
                                                    </option>
                                                ),
                                            )}
                                        </StyledField>
                                    </div>
                                    <div className="col-6 md:col-3 mr-0 md:mr-10">
                                        <StyledField
                                            as="select"
                                            name="gender"
                                            label={t('gender')}
                                            autofilled={
                                                state?.target &&
                                                values.gender ===
                                                    state?.target?.gender &&
                                                !touched.gender
                                            }
                                            data-testid={nonProdDataTestId(
                                                'gender',
                                            )}
                                            disabled={disabled}
                                        >
                                            <option value="">-</option>
                                            {Object.values(Gender).map(
                                                (title) => (
                                                    <option
                                                        value={title}
                                                        key={title}
                                                    >
                                                        {t(`genders.${title}`)}
                                                    </option>
                                                ),
                                            )}
                                        </StyledField>
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <StyledField
                                            type="text"
                                            name="firstname"
                                            label={t('firstname')}
                                            required
                                            autoFocus
                                            autofilled={
                                                state?.target &&
                                                values.firstname ===
                                                    state?.target?.firstname &&
                                                !touched.firstname
                                            }
                                            data-testid={nonProdDataTestId(
                                                'firstname',
                                            )}
                                            disabled={disabled}
                                        />
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <StyledField
                                            type="text"
                                            name="lastname"
                                            label={t('lastname')}
                                            autofilled={
                                                state?.target &&
                                                values.lastname ===
                                                    state?.target?.lastname &&
                                                !touched.lastname
                                            }
                                            required
                                            data-testid={nonProdDataTestId(
                                                'lastname',
                                            )}
                                            disabled={disabled}
                                        />
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <StyledField
                                            type="text"
                                            name="middlename"
                                            label={t('middlename')}
                                            autofilled={
                                                state?.target &&
                                                values.middlename ===
                                                    state?.target?.middlename &&
                                                !touched.middlename
                                            }
                                            data-testid={nonProdDataTestId(
                                                'middlename',
                                            )}
                                            disabled={disabled}
                                        />
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <StyledField
                                            type="text"
                                            name="dateOfBirth"
                                            label={t(
                                                'targetNewForm.dateOfBirthLabel',
                                            )}
                                            placeholder={t(
                                                'targetNewForm.dateOfBirthPlaceholder',
                                            )}
                                            autofilled={
                                                state?.target &&
                                                values.dateOfBirth ===
                                                    state?.target
                                                        ?.dateOfBirth &&
                                                !touched.dateOfBirth
                                            }
                                            recommended
                                            data-testid={nonProdDataTestId(
                                                'dateOfBirth',
                                            )}
                                            disabled={disabled}
                                        />
                                    </div>
                                    <div className="col-12 md:col-6">
                                        {values.nationalities?.map(
                                            (value, index) => (
                                                <StyledField
                                                    as="select"
                                                    name={`nationalities.${index}`}
                                                    label={t('nationality')}
                                                    key={index}
                                                    autofilled={
                                                        state?.target &&
                                                        Array.isArray(
                                                            state?.target
                                                                .nationalities,
                                                        ) &&
                                                        value ===
                                                            state?.target
                                                                ?.nationalities[
                                                                index
                                                            ] &&
                                                        !touched.nationalities
                                                    }
                                                    data-testid={nonProdDataTestId(
                                                        'nationality',
                                                    )}
                                                    disabled={disabled}
                                                >
                                                    <option value="">-</option>
                                                    {nationalitySelectionOptions.map(
                                                        ({ code, name }) => (
                                                            <option
                                                                value={code}
                                                                key={code}
                                                            >
                                                                {name}
                                                            </option>
                                                        ),
                                                    )}
                                                </StyledField>
                                            ),
                                        )}
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <StyledField
                                            as="select"
                                            name="countryOfResidence"
                                            label={t('countryOfResidence')}
                                            autofilled={
                                                state?.target &&
                                                values.countryOfResidence ===
                                                    state?.target
                                                        ?.countryOfResidence &&
                                                !touched.countryOfResidence
                                            }
                                            data-testid={nonProdDataTestId(
                                                'countryOfResidence',
                                            )}
                                            disabled={disabled}
                                        >
                                            <option value="">-</option>
                                            {countrySelectionOptions.map(
                                                ({ code, name }) => (
                                                    <option
                                                        value={code}
                                                        key={code}
                                                    >
                                                        {name}
                                                    </option>
                                                ),
                                            )}
                                        </StyledField>
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <StyledField
                                            as="select"
                                            name="countryOfBirth"
                                            label={t('countryOfBirth')}
                                            autofilled={
                                                state?.target &&
                                                values.countryOfBirth ===
                                                    state?.target
                                                        ?.countryOfBirth &&
                                                !touched.countryOfBirth
                                            }
                                            data-testid={nonProdDataTestId(
                                                'countryOfBirth',
                                            )}
                                            disabled={disabled}
                                        >
                                            <option value="">-</option>
                                            {countrySelectionOptions.map(
                                                ({ code, name }) => (
                                                    <option
                                                        value={code}
                                                        key={`country-of-birth-${code}`}
                                                    >
                                                        {name}
                                                    </option>
                                                ),
                                            )}
                                        </StyledField>
                                    </div>
                                </section>
                            </SpotlightTarget>
                            <SpotlightTarget name="onboardinUnused">
                                <section>
                                    <DynamicForm
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        disabled={disabled}
                                    />
                                </section>
                            </SpotlightTarget>
                            <SpotlightTarget name="onboardingUnused">
                                <section className="row">
                                    <div className="col-12 my-3.5">
                                        <Headline Level="h4" color="dark">
                                            {t('contactData')}
                                        </Headline>
                                    </div>
                                    <div className="col-12 md:col-6 my-3.5">
                                        <StyledField
                                            name="contact.0.value"
                                            label={t('phone')}
                                            type="tel"
                                            autofilled={
                                                state?.target &&
                                                state?.target?.contact?.[0] &&
                                                values?.contact?.[0] &&
                                                values.contact[0].value ===
                                                    state?.target.contact[0]
                                                        .value &&
                                                !touched.contact
                                            }
                                            placeholder={t('phonePlaceholder')}
                                            data-testid={nonProdDataTestId(
                                                'phone',
                                            )}
                                            disabled={disabled}
                                        />
                                    </div>

                                    {[1, 2, 3].map((fieldNumber) => (
                                        <div
                                            key={fieldNumber}
                                            className="col-12 md:col-6 my-3.5"
                                        >
                                            <StyledField
                                                name={`contact.${fieldNumber}.value`}
                                                label={`${t(
                                                    'email',
                                                )} ${fieldNumber}`}
                                                placeholder={t(
                                                    'emailPlaceholder',
                                                )}
                                                autofilled={
                                                    state?.target?.contact?.[
                                                        fieldNumber
                                                    ]?.value !== undefined &&
                                                    values?.contact?.[
                                                        fieldNumber
                                                    ]?.value !== undefined &&
                                                    values.contact[fieldNumber]
                                                        .value ===
                                                        state.target.contact[
                                                            fieldNumber
                                                        ].value &&
                                                    !touched.contact
                                                }
                                                type="email"
                                                data-testid={nonProdDataTestId(
                                                    `email-${fieldNumber}`,
                                                )}
                                                disabled={disabled}
                                            />
                                        </div>
                                    ))}
                                </section>

                                <section className="row space-y-6">
                                    <CollapsibleSection
                                        initialState={
                                            (values.jobs?.length ?? 0) > 0
                                        }
                                        title={
                                            <Headline Level="h4">
                                                {t(
                                                    'professionalExperienceHeadline',
                                                )}
                                            </Headline>
                                        }
                                    >
                                        {!values.jobs?.length && (
                                            <Button
                                                type="button"
                                                level="primary"
                                                className="text-sm"
                                                onClick={() => {
                                                    setFieldValue('jobs', [
                                                        ...(values.jobs ?? []),
                                                        {},
                                                    ]);
                                                }}
                                                disabled={
                                                    disabled ||
                                                    ((values.jobs?.length ??
                                                        0) > 0 &&
                                                        Object.values(
                                                            values.jobs?.[
                                                                values.jobs
                                                                    ?.length ??
                                                                    1 - 1 ??
                                                                    0
                                                            ] ?? {},
                                                        ).filter(Boolean)
                                                            .length === 0)
                                                }
                                            >
                                                {t('addEntry')}
                                            </Button>
                                        )}
                                        {values.jobs?.map((_job, jobIndex) => {
                                            const hasCompanyName = Boolean(
                                                _job.company?.name?.length,
                                            );
                                            const hasCompanyLocation = Boolean(
                                                _job.company?.country?.length,
                                            );
                                            return (
                                                <div
                                                    className="row mb-4"
                                                    key={jobIndex}
                                                >
                                                    <div className="col-12 md:col-6 mb-7">
                                                        <StyledField
                                                            type="text"
                                                            name={`jobs.${jobIndex}.company.name`}
                                                            label={t(
                                                                'jobCompanyName',
                                                            )}
                                                            required
                                                            placeholder="e.g Company LTD"
                                                            autofilled={
                                                                state?.target
                                                                    ?.jobs?.[
                                                                    jobIndex
                                                                ]?.company
                                                                    ?.name !==
                                                                    undefined &&
                                                                _job?.company
                                                                    ?.name ===
                                                                    state
                                                                        ?.target
                                                                        ?.jobs?.[
                                                                        jobIndex
                                                                    ]?.company
                                                                        ?.name &&
                                                                !touched.jobs
                                                            }
                                                            data-testid={nonProdDataTestId(
                                                                `jobCompanyName-${
                                                                    jobIndex + 1
                                                                }`,
                                                            )}
                                                            disabled={disabled}
                                                        />
                                                    </div>
                                                    <div className="col-12 md:col-6 mb-7">
                                                        <div className="flex flex-row flex-nowrap items-stretch justify-start space-x-4">
                                                            <div className="flex-grow self-auto">
                                                                <StyledField
                                                                    type="text"
                                                                    name={`jobs.${jobIndex}.title`}
                                                                    label={t(
                                                                        'jobTitle',
                                                                    )}
                                                                    placeholder="e.g CEO"
                                                                    autofilled={
                                                                        state
                                                                            ?.target
                                                                            ?.jobs?.[
                                                                            jobIndex
                                                                        ]
                                                                            ?.title !==
                                                                            undefined &&
                                                                        _job.title ===
                                                                            state
                                                                                ?.target
                                                                                ?.jobs?.[
                                                                                jobIndex
                                                                            ]
                                                                                ?.title &&
                                                                        !touched.jobs
                                                                    }
                                                                    data-testid={nonProdDataTestId(
                                                                        `jobTitle-${jobIndex}`,
                                                                    )}
                                                                    disabled={
                                                                        disabled
                                                                    }
                                                                />
                                                            </div>
                                                            <StyledField
                                                                type="checkbox"
                                                                name={`jobs.${jobIndex}.selfEmployed`}
                                                                label={t(
                                                                    'selfEmployed',
                                                                )}
                                                                disabled={
                                                                    disabled
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 md:col-6 mb-7">
                                                        <StyledField
                                                            as="select"
                                                            name={`jobs.${jobIndex}.company.country`}
                                                            label={t(
                                                                'jobCompanyLocation',
                                                            )}
                                                            placeholder="e.g Berlin"
                                                            autofilled={
                                                                state?.target
                                                                    ?.jobs?.[
                                                                    jobIndex
                                                                ]?.company
                                                                    ?.country !==
                                                                    undefined &&
                                                                _job?.company
                                                                    ?.country ===
                                                                    state
                                                                        ?.target
                                                                        ?.jobs?.[
                                                                        jobIndex
                                                                    ]?.company
                                                                        ?.country &&
                                                                !touched.jobs
                                                            }
                                                            data-testid={nonProdDataTestId(
                                                                `jobCompanyName-${jobIndex}`,
                                                            )}
                                                            disabled={disabled}
                                                        >
                                                            <option value="">
                                                                -
                                                            </option>
                                                            {countrySelectionOptions.map(
                                                                ({
                                                                    code,
                                                                    name,
                                                                }) => (
                                                                    <option
                                                                        value={
                                                                            code
                                                                        }
                                                                        key={
                                                                            code
                                                                        }
                                                                    >
                                                                        {name}
                                                                    </option>
                                                                ),
                                                            )}
                                                        </StyledField>
                                                    </div>
                                                    <div className="col-12 md:col-6 mb-7">
                                                        <StyledField
                                                            type="text"
                                                            name={`jobs.${jobIndex}.date`}
                                                            label={t('jobDate')}
                                                            placeholder="e.g DD.MM.YYYY, YYYY"
                                                            autofilled={
                                                                state?.target
                                                                    ?.jobs?.[
                                                                    jobIndex
                                                                ]?.date !==
                                                                    undefined &&
                                                                _job.date ===
                                                                    state
                                                                        ?.target
                                                                        ?.jobs?.[
                                                                        jobIndex
                                                                    ]?.date &&
                                                                !touched.jobs
                                                            }
                                                            data-testid={nonProdDataTestId(
                                                                `jobCompany-${jobIndex}`,
                                                            )}
                                                            disabled={disabled}
                                                        />
                                                    </div>
                                                    <div className="col-12 md:col-6 mb-7">
                                                        <StyledField
                                                            type="text"
                                                            name={`jobs.${jobIndex}.company.city`}
                                                            label={t(
                                                                'jobCompanyCity',
                                                            )}
                                                            placeholder={''}
                                                            disabled={
                                                                disabled ||
                                                                !hasCompanyLocation
                                                            }
                                                            data-testid={nonProdDataTestId(
                                                                `jobs-company-city-${
                                                                    jobIndex + 1
                                                                }`,
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="col-12 md:col-6 mb-7">
                                                        <StyledField
                                                            type="text"
                                                            name={`jobs.${jobIndex}.company.website`}
                                                            label={t(
                                                                'jobCompanyWebsite',
                                                            )}
                                                            placeholder={t(
                                                                'websitePlaceholder',
                                                            )}
                                                            disabled={
                                                                disabled ||
                                                                !hasCompanyName
                                                            }
                                                            data-testid={nonProdDataTestId(
                                                                `jobs-company-website-${
                                                                    jobIndex + 1
                                                                }`,
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="col-12 md:col-6 mb-7">
                                                        <StyledField
                                                            type="text"
                                                            name={`jobs.${jobIndex}.company.commercialRegisterNumber`}
                                                            label={t(
                                                                'jobCompanyCommercialRegisterNumber',
                                                            )}
                                                            placeholder={t(
                                                                'commercialRegisterNumberPlaceholder',
                                                            )}
                                                            disabled={
                                                                disabled ||
                                                                !hasCompanyName
                                                            }
                                                            data-testid={nonProdDataTestId(
                                                                `jobCompanyCommercialRegisterNumber-${
                                                                    jobIndex + 1
                                                                }`,
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="col-12 md:col-6 mb-7">
                                                        <StyledField
                                                            type="text"
                                                            name={`jobs.${jobIndex}.company.vatNumber`}
                                                            label={t(
                                                                'jobCompanyVatNumber',
                                                            )}
                                                            placeholder={t(
                                                                'companyVatNumberPlaceholder',
                                                            )}
                                                            disabled={
                                                                disabled ||
                                                                !hasCompanyName
                                                            }
                                                            data-testid={nonProdDataTestId(
                                                                `jobCompanyVatNumber-${
                                                                    jobIndex + 1
                                                                }`,
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="space-x-6">
                                                        {jobIndex ===
                                                            (values.jobs
                                                                ?.length ??
                                                                -1) -
                                                                1 && (
                                                            <Button
                                                                type="button"
                                                                level="primary"
                                                                className="text-sm"
                                                                onClick={() => {
                                                                    setFieldValue(
                                                                        'jobs',
                                                                        [
                                                                            ...(values.jobs ?? [
                                                                                emptyJobEntry,
                                                                            ]),
                                                                            {},
                                                                        ],
                                                                    );
                                                                }}
                                                                disabled={
                                                                    disabled ||
                                                                    ((values
                                                                        .jobs
                                                                        ?.length ??
                                                                        0) >
                                                                        0 &&
                                                                        Object.values(
                                                                            values
                                                                                .jobs?.[
                                                                                values
                                                                                    .jobs
                                                                                    ?.length ??
                                                                                    1 -
                                                                                        1 ??
                                                                                    0
                                                                            ] ??
                                                                                {},
                                                                        ).filter(
                                                                            Boolean,
                                                                        )
                                                                            .length ===
                                                                            0)
                                                                }
                                                            >
                                                                {t('addEntry')}
                                                            </Button>
                                                        )}
                                                        <Button
                                                            type="button"
                                                            level="primaryGhost"
                                                            className="text-sm"
                                                            onClick={() => {
                                                                setFieldValue(
                                                                    'jobs',
                                                                    values.jobs?.filter(
                                                                        (
                                                                            _value,
                                                                            index,
                                                                        ) =>
                                                                            index !==
                                                                            jobIndex,
                                                                    ),
                                                                );
                                                            }}
                                                            disabled={disabled}
                                                        >
                                                            {t('removeEntry')}
                                                        </Button>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </CollapsibleSection>
                                </section>

                                <section className="row space-y-6">
                                    <CollapsibleSection
                                        initialState={
                                            (values.education?.length ?? 0) > 0
                                        }
                                        title={
                                            <Headline Level="h4">
                                                {t('educationHeadline')}
                                            </Headline>
                                        }
                                    >
                                        {!values.education?.length && (
                                            <Button
                                                type="button"
                                                level="primary"
                                                className="text-sm"
                                                onClick={() => {
                                                    setFieldValue('education', [
                                                        ...(values.education ??
                                                            []),
                                                        {},
                                                    ]);
                                                }}
                                                disabled={
                                                    disabled ||
                                                    ((values.education
                                                        ?.length ?? 0) > 0 &&
                                                        Object.values(
                                                            values.education?.[
                                                                values.education
                                                                    ?.length ??
                                                                    1 - 1 ??
                                                                    0
                                                            ] ?? {},
                                                        ).filter(Boolean)
                                                            .length === 0)
                                                }
                                            >
                                                {t('addEntry')}
                                            </Button>
                                        )}
                                        {values.education?.map(
                                            (_education, educationIndex) => {
                                                return (
                                                    <div
                                                        className="row mb-4"
                                                        key={educationIndex}
                                                    >
                                                        <div className="col-12 md:col-6 mb-7">
                                                            <StyledField
                                                                type="text"
                                                                name={`education.${educationIndex}.title`}
                                                                label={t(
                                                                    'educationTitle',
                                                                )}
                                                                autofilled={
                                                                    state
                                                                        ?.target
                                                                        ?.education?.[
                                                                        educationIndex
                                                                    ]?.title !==
                                                                        undefined &&
                                                                    _education.title ===
                                                                        state
                                                                            ?.target
                                                                            ?.education?.[
                                                                            educationIndex
                                                                        ]
                                                                            ?.title &&
                                                                    !touched.education
                                                                }
                                                                placeholder={t(
                                                                    'educationTitlePlaceholder',
                                                                )}
                                                                data-testid={nonProdDataTestId(
                                                                    `educationTitle-${educationIndex}`,
                                                                )}
                                                                disabled={
                                                                    disabled
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-12 md:col-6 mb-7">
                                                            <StyledField
                                                                type="text"
                                                                name={`education.${educationIndex}.type`}
                                                                label={t(
                                                                    'educationType',
                                                                )}
                                                                autofilled={
                                                                    state
                                                                        ?.target
                                                                        ?.education?.[
                                                                        educationIndex
                                                                    ]?.type !==
                                                                        undefined &&
                                                                    _education.type ===
                                                                        state
                                                                            ?.target
                                                                            ?.education?.[
                                                                            educationIndex
                                                                        ]
                                                                            ?.type &&
                                                                    !touched.education
                                                                }
                                                                placeholder="e.g Master"
                                                                data-testid={nonProdDataTestId(
                                                                    `educationType-${educationIndex}`,
                                                                )}
                                                                disabled={
                                                                    disabled
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-12 md:col-6 mb-7">
                                                            <StyledField
                                                                type="text"
                                                                name={`education.${educationIndex}.date`}
                                                                label={t(
                                                                    'educationDate',
                                                                )}
                                                                autofilled={
                                                                    state
                                                                        ?.target
                                                                        ?.education?.[
                                                                        educationIndex
                                                                    ]?.date !==
                                                                        undefined &&
                                                                    _education.date ===
                                                                        state
                                                                            ?.target
                                                                            ?.education?.[
                                                                            educationIndex
                                                                        ]
                                                                            ?.date &&
                                                                    !touched.education
                                                                }
                                                                placeholder="e.g DD.MM.YYYY, YYYY"
                                                                data-testid={nonProdDataTestId(
                                                                    `educationDate-${educationIndex}`,
                                                                )}
                                                                disabled={
                                                                    disabled
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-12 md:col-6 mb-7">
                                                            <StyledField
                                                                type="text"
                                                                name={`education.${educationIndex}.institution.name`}
                                                                label={t(
                                                                    'educationInstitutionName',
                                                                )}
                                                                autofilled={
                                                                    state
                                                                        ?.target
                                                                        ?.education?.[
                                                                        educationIndex
                                                                    ]
                                                                        ?.institution
                                                                        ?.name !==
                                                                        undefined &&
                                                                    _education
                                                                        .institution
                                                                        ?.name ===
                                                                        state
                                                                            ?.target
                                                                            ?.education?.[
                                                                            educationIndex
                                                                        ]
                                                                            ?.institution
                                                                            ?.name &&
                                                                    !touched.education
                                                                }
                                                                placeholder="e.g Hochschule für Wirtschaft und Recht Berlin"
                                                                data-testid={nonProdDataTestId(
                                                                    `educationInstitutionName-${educationIndex}`,
                                                                )}
                                                                disabled={
                                                                    disabled
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-12 md:col-6 mb-7">
                                                            <StyledField
                                                                type="text"
                                                                name={`education.${educationIndex}.institution.location`}
                                                                label={t(
                                                                    'educationInstitutionLocation',
                                                                )}
                                                                autofilled={
                                                                    state
                                                                        ?.target
                                                                        ?.education?.[
                                                                        educationIndex
                                                                    ]
                                                                        ?.institution
                                                                        ?.location !==
                                                                        undefined &&
                                                                    _education
                                                                        .institution
                                                                        ?.location ===
                                                                        state
                                                                            ?.target
                                                                            ?.education?.[
                                                                            educationIndex
                                                                        ]
                                                                            ?.institution
                                                                            ?.location &&
                                                                    !touched.education
                                                                }
                                                                className="col-12 md:col-6 mb-7"
                                                                placeholder="e.g Berlin"
                                                                data-testid={nonProdDataTestId(
                                                                    `educationInstitutionLocation-${educationIndex}`,
                                                                )}
                                                                disabled={
                                                                    disabled
                                                                }
                                                            />
                                                        </div>
                                                        <div className="space-x-6">
                                                            {educationIndex ===
                                                                (values
                                                                    .education
                                                                    ?.length ??
                                                                    -1) -
                                                                    1 && (
                                                                <Button
                                                                    type="button"
                                                                    level="primary"
                                                                    className="text-sm"
                                                                    onClick={() => {
                                                                        setFieldValue(
                                                                            'education',
                                                                            [
                                                                                ...(values.education ??
                                                                                    []),
                                                                                {},
                                                                            ],
                                                                        );
                                                                    }}
                                                                    disabled={
                                                                        disabled ||
                                                                        ((values
                                                                            .education
                                                                            ?.length ??
                                                                            0) >
                                                                            0 &&
                                                                            Object.values(
                                                                                values
                                                                                    .education?.[
                                                                                    values
                                                                                        .education
                                                                                        ?.length ??
                                                                                        1 -
                                                                                            1 ??
                                                                                        0
                                                                                ] ??
                                                                                    {},
                                                                            ).filter(
                                                                                Boolean,
                                                                            )
                                                                                .length ===
                                                                                0)
                                                                    }
                                                                >
                                                                    {t(
                                                                        'addEntry',
                                                                    )}
                                                                </Button>
                                                            )}
                                                            <Button
                                                                type="button"
                                                                level="primaryGhost"
                                                                className="text-sm"
                                                                onClick={() => {
                                                                    setFieldValue(
                                                                        'education',
                                                                        values.education?.filter(
                                                                            (
                                                                                _value,
                                                                                index,
                                                                            ) =>
                                                                                index !==
                                                                                educationIndex,
                                                                        ),
                                                                    );
                                                                }}
                                                                disabled={
                                                                    disabled
                                                                }
                                                            >
                                                                {t(
                                                                    'removeEntry',
                                                                )}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                );
                                            },
                                        )}
                                    </CollapsibleSection>
                                </section>

                                <section className="row space-y-6">
                                    <div className="col-12">
                                        <Headline Level="h4">
                                            {t('socialMediaProfiles')}
                                        </Headline>
                                        <Paragraph
                                            size="default"
                                            weight="normal"
                                            lineHeight="default"
                                            color="default"
                                            className="mt-1"
                                        >
                                            {t('socialMediaPrivacyHint')}
                                        </Paragraph>
                                    </div>
                                    <div className="row">
                                        {values.socialMediaProfiles?.map(
                                            ({ type, value }, index) => {
                                                const isDisabled =
                                                    disabled ||
                                                    (!fetchedCase.data?.option
                                                        ?.privateSocialMedia &&
                                                        !isProfessionalPlatform(
                                                            type,
                                                        ));
                                                const prefilledSocialMedia =
                                                    state?.target?.socialMediaProfiles?.find(
                                                        (s) => s.type === type,
                                                    );
                                                return (
                                                    <div
                                                        className="col-12 md:col-6 mb-7"
                                                        key={type}
                                                    >
                                                        <StyledField
                                                            disabled={
                                                                isDisabled
                                                            }
                                                            type="text"
                                                            name={`socialMediaProfiles.${index}.value`}
                                                            label={t(type)}
                                                            placeholder={t(
                                                                `${type}Placeholder`,
                                                            )}
                                                            autofilled={
                                                                prefilledSocialMedia !==
                                                                    undefined &&
                                                                value ===
                                                                    prefilledSocialMedia.value &&
                                                                !touched.socialMediaProfiles
                                                            }
                                                            data-testid={nonProdDataTestId(
                                                                `socialMediaProfile-${type}`,
                                                            )}
                                                        />
                                                        <Paragraph
                                                            size="small"
                                                            color={
                                                                isDisabled
                                                                    ? 'red'
                                                                    : 'light'
                                                            }
                                                            className="mt-1"
                                                        >
                                                            {disabled
                                                                ? ''
                                                                : isDisabled
                                                                ? t(
                                                                      'socialMediaDisabledHint',
                                                                  )
                                                                : t(
                                                                      'socialMediaFormatHint',
                                                                  )}
                                                        </Paragraph>
                                                    </div>
                                                );
                                            },
                                        )}
                                    </div>
                                </section>
                                <section>
                                    <Headline Level="h4">
                                        {t('targetWizard.fields.note.title')}
                                    </Headline>
                                    <StyledField
                                        as="textarea"
                                        name="note.value"
                                        label={t(
                                            'targetWizard.fields.note.helpText',
                                        )}
                                        placeholder={t(
                                            'targetWizard.fields.note.description',
                                        )}
                                        data-testid={nonProdDataTestId('note')}
                                        disabled={disabled}
                                    />
                                </section>
                            </SpotlightTarget>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
