import React, { FC, useEffect, useMemo, useState } from 'react';
import { ResultsProps } from '../../../Results';
import { ProfileInfo } from '../../../RiskAnalysis/ProfileInfo';
import { DefaultPageLayout } from '../../../../../components/Layout/DefaultPageLayout';
import { useTranslation } from 'react-i18next';
import {
    Checkbox,
    Paragraph,
    TooltipContentV2,
    TooltipTriggerV2,
    TooltipV2,
} from '_atoms';
import { TimelineFilters } from '../types';
import { DatePickerV2 } from '../../../../../components/_atoms/DatePicker/DatePickerV2';
import { SearchBar } from '../../../../../components/_molecules/SearchBar/SearchBar';
import { ALL_SOURCES, handleSourceChange } from '../helpers';
import { TargetReportSourceType } from '@indicium/common';
import { HorizontalTimeline } from './Components/HorizontalTimeline';
import { NoTimeframeExperience } from './Components/NoTimeframeExperience';
import { HeaderMessage } from '../CvStatistics/Components/HelperComponents';
import { HiMiniQuestionMarkCircle } from 'react-icons/hi2';
import type { CvGroup, CvValidation } from '../schemata';
import {
    getTimeAwareGroups,
    PlainDateRange,
    TimeAwareCvGroup,
} from '../CvStatistics/helpers';
import { Temporal } from 'temporal-polyfill';
import { fromDate, toDate } from '../../../../../utils/temporal';
import { useCvGroups } from '../../../../../hooks/queries/useCvGroups';
import { useCvValidation } from '../../../../../hooks/queries/useCvValidation';
import { VscCopy } from 'react-icons/vsc';

const compareTimeAwareCvGroups = (
    a: TimeAwareCvGroup,
    b: TimeAwareCvGroup,
): number => {
    const startComparisonResult = Temporal.PlainDate.compare(
        a.plainDateRange.start,
        b.plainDateRange.start,
    );

    if (startComparisonResult !== 0) {
        return startComparisonResult;
    }

    const endComparisonResult = Temporal.PlainDate.compare(
        a.plainDateRange.end,
        b.plainDateRange.end,
    );

    if (endComparisonResult !== 0) {
        return endComparisonResult;
    }

    // TODO why locale compare?
    const companyComparisonResult = a.company.localeCompare(b.company);

    if (companyComparisonResult !== 0) {
        return companyComparisonResult;
    }

    return a.position.localeCompare(b.position);
};

const calculateRows = (groups: ReadonlyArray<TimeAwareCvGroup>) => {
    const rows: TimeAwareCvGroup[][] = [];

    groups.forEach((experience) => {
        let placed = false;

        for (const row of rows) {
            const end = row.at(row.length - 1)?.plainDateRange.end ?? null;

            if (end === null) {
                continue;
            }

            if (
                Temporal.PlainDate.compare(
                    experience.plainDateRange.start,
                    end,
                ) >= 0
            ) {
                row.push(experience);
                placed = true;
                break;
            }
        }

        if (!placed) {
            rows.push([experience]);
        }
    });

    return rows;
};

const SectionHeader: FC<{ title: string }> = ({ title }) => (
    <div className="text-font-dark font-bold">{title}</div>
);

const getTimeNaiveGroups = (
    groups: ReadonlyArray<CvGroup>,
): ReadonlyArray<CvGroup> => groups.filter((group) => group.dateRange === null);

const getDateRange = (
    ranges: ReadonlyArray<PlainDateRange>,
): PlainDateRange => {
    if (ranges.length === 0) {
        const plainDate = Temporal.Now.plainDateISO();

        return {
            start: plainDate,
            end: plainDate,
        };
    }

    const { start } = ranges.reduce((earliest, current) =>
        Temporal.PlainDate.compare(current.start, earliest.start) < 0
            ? current
            : earliest,
    );

    const { end } = ranges.reduce((latest, current) =>
        Temporal.PlainDate.compare(current.end, latest.end) > 0
            ? current
            : latest,
    );

    return {
        start,
        end,
    };
};

export const CvTimelinePage: FC<ResultsProps> = ({
    caseData,
    targetProfile,
    targetImages,
}) => {
    const { t } = useTranslation();

    const isNotProduction = process.env.REACT_APP_STAGE !== 'production';

    const groupResult = useCvGroups(caseData.id ?? '', targetProfile?.id ?? '');
    const groups = useMemo(() => groupResult.data ?? [], [groupResult]);

    const cvValidationResult = useCvValidation(
        caseData.id ?? '',
        targetProfile?.id ?? '',
    );
    const cvValidation: CvValidation = useMemo(
        () =>
            cvValidationResult.data ?? {
                userControlledComparison: {
                    similarCvEntries: [],
                    discrepancyCvEntries: [],
                },
                userUncontrolledComparison: {
                    similarCvEntries: [],
                    discrepancyCvEntries: [],
                },
            },
        [cvValidationResult],
    );

    const timeAwareGroups = useMemo(() => getTimeAwareGroups(groups), [groups]);
    const timeNaiveGroups = useMemo(() => getTimeNaiveGroups(groups), [groups]);
    const originalRange = useMemo(
        () =>
            getDateRange(
                timeAwareGroups.map(({ plainDateRange }) => plainDateRange),
            ),
        [timeAwareGroups],
    );

    const [filters, setFilters] = useState<TimelineFilters>({
        start: originalRange.start,
        end: originalRange.end,
        company: '',
        sources: ALL_SOURCES,
    });

    useEffect(() => {
        setFilters((prev) => ({
            ...prev,
            start: originalRange.start,
            end: originalRange.end,
        }));
    }, [originalRange]);

    const handleFilterChange = <K extends keyof TimelineFilters>(
        key: K,
        value: TimelineFilters[K],
    ) => setFilters((prev) => ({ ...prev, [key]: value }));

    const changeSource = (value: ReadonlyArray<TargetReportSourceType>) =>
        setFilters((prev) => ({ ...prev, sources: value }));

    const { range, rows } = useMemo(() => {
        const filteredTimeAwareGroups = timeAwareGroups
            .filter((group) => {
                const matchesHeader = group.company
                    .toLowerCase()
                    .includes(filters.company.toLowerCase());

                const matchesDate =
                    Temporal.PlainDate.compare(
                        group.plainDateRange.start,
                        filters.start,
                    ) >= 0 &&
                    Temporal.PlainDate.compare(
                        group.plainDateRange.start,
                        filters.end,
                    ) <= 0;

                const matchesSource = group.sources.some((s) =>
                    filters.sources.includes(s),
                );

                return matchesDate && matchesHeader && matchesSource;
            })
            .sort(compareTimeAwareCvGroups);

        const range = getDateRange(
            filteredTimeAwareGroups.map(({ plainDateRange }) => plainDateRange),
        );
        const rows = calculateRows(filteredTimeAwareGroups);

        return {
            range,
            rows,
        };
    }, [filters, timeAwareGroups]);

    const targetFullName = [
        targetProfile?.firstname,
        targetProfile?.middlename,
        targetProfile?.lastname,
    ]
        .filter(Boolean)
        .join(' ');

    const handleCopy = () => {
        navigator.clipboard.writeText(JSON.stringify(cvValidation, null, 2));
    };

    return (
        <DefaultPageLayout
            title={t('cVTimeline.title')}
            headerActions={
                <ProfileInfo
                    name={targetFullName}
                    createdAt={targetProfile?.profile?.createdAt}
                    imageUrl={targetImages[0]}
                />
            }
        >
            <div className="flex flex-col gap-6">
                <HeaderMessage />
                <div className="flex flex-col gap-4 pt-1">
                    <SectionHeader title={t('cVAnalysis.timeline')} />
                    <div className="flex flex-row gap-4 justify-between">
                        <div className="justify-start">
                            <Paragraph
                                color="light"
                                weight="bold"
                                size="small"
                                className="font-jost"
                            >
                                {t('cVAnalysis.filters.selectSourceGroup')}
                            </Paragraph>
                            <div className="flex gap-4 py-3.5">
                                <Checkbox
                                    onChange={(isSelected) =>
                                        handleSourceChange(
                                            filters.sources,
                                            'userInput',
                                            isSelected,
                                            changeSource,
                                        )
                                    }
                                    initialIsSelected={filters.sources.includes(
                                        'userInput',
                                    )}
                                    centered={true}
                                    className="font-normal"
                                    size={5}
                                    label={t(
                                        `cVAnalysis.filters.sourceGroupOptions.cv`,
                                    )}
                                    labelPosition="append"
                                    labelClassName="text-base"
                                />
                                <Checkbox
                                    onChange={(isSelected) =>
                                        handleSourceChange(
                                            filters.sources,
                                            'osint',
                                            isSelected,
                                            changeSource,
                                        )
                                    }
                                    initialIsSelected={filters.sources.includes(
                                        'orbis',
                                    )}
                                    centered={true}
                                    className="text-neutral-500 font-normal"
                                    size={5}
                                    label={t(
                                        `cVAnalysis.filters.sourceGroupOptions.osint`,
                                    )}
                                    labelPosition="append"
                                    labelClassName="text-base"
                                />
                            </div>
                        </div>
                        <div className="flex gap-1">
                            <DatePickerV2
                                dateFormat="dd MMM yyyy"
                                selected={toDate(filters.start)}
                                onChange={(from) => {
                                    handleFilterChange(
                                        'start',
                                        from !== null
                                            ? fromDate(from)
                                            : originalRange.start,
                                    );
                                }}
                                label={t('cVAnalysis.filters.startDate')}
                                minDate={toDate(originalRange.start)}
                                maxDate={toDate(originalRange.end)}
                                isClearable
                            />
                            <DatePickerV2
                                dateFormat="dd MMM yyyy"
                                selected={toDate(filters.end)}
                                onChange={(to) => {
                                    handleFilterChange(
                                        'end',
                                        to !== null
                                            ? fromDate(to)
                                            : originalRange.end,
                                    );
                                }}
                                label={t('cVAnalysis.filters.endDate')}
                                minDate={toDate(originalRange.start)}
                                maxDate={toDate(originalRange.end)}
                                isClearable
                            />
                        </div>
                        <div className="flex-grow">
                            <Paragraph
                                color="light"
                                weight="bold"
                                size="small"
                                className="font-jost"
                            >
                                {t('cVAnalysis.filters.search')}
                            </Paragraph>
                            <SearchBar
                                query={filters.company}
                                onSearchChange={({ query }) => {
                                    handleFilterChange('company', query);
                                }}
                                placeholder={t(
                                    'cVAnalysis.filters.searchPlaceholder',
                                )}
                                className="w-80"
                            />
                        </div>
                    </div>
                    <HorizontalTimeline
                        filters={filters}
                        rows={rows}
                        range={range}
                    />
                </div>
                {timeNaiveGroups.length > 0 && (
                    <div className="flex flex-col gap-4">
                        <div className="flex items-center gap-2">
                            <SectionHeader
                                title={t('cVAnalysis.noTimeframe')}
                            />
                            <TooltipV2 placement="right" withArrow={true}>
                                <TooltipTriggerV2 className="text-neutral-400 hover:text-font-dark">
                                    <HiMiniQuestionMarkCircle className="w-5 h-5" />
                                </TooltipTriggerV2>
                                <TooltipContentV2>
                                    {t('cVAnalysis.noTimeframeTooltip')}
                                </TooltipContentV2>
                            </TooltipV2>
                        </div>

                        <NoTimeframeExperience groups={timeNaiveGroups} />
                    </div>
                )}
                {/*TODO: remove this code once we finish with CV Validation design implementation */}
                {isNotProduction && (
                    <div>
                        <div className="flex items-center gap-2 mb-3">
                            <SectionHeader title={'CV Validation'} />
                            <button
                                onClick={handleCopy}
                                title={'Copy to clipboard'}
                                className="px-3 py-1 bg-primary-1 text-white rounded hover:bg-primary-4 transition"
                            >
                                <VscCopy />
                            </button>
                        </div>
                        <div className="flex justify-between mb-3">
                            <Paragraph>
                                {'User Non-controlled discrepancies found: '}{' '}
                                {
                                    cvValidation.userUncontrolledComparison
                                        .discrepancyCvEntries.length
                                }
                            </Paragraph>

                            <Paragraph>
                                {'User Controlled discrepancies found: '}{' '}
                                {
                                    cvValidation.userControlledComparison
                                        .discrepancyCvEntries.length
                                }
                            </Paragraph>
                        </div>
                        <pre className="whitespace-pre-wrap border p-2 bg-gray-100 text-sm text-primary-2 rounded">
                            {JSON.stringify(cvValidation, null, 2)}
                        </pre>
                    </div>
                )}
            </div>
        </DefaultPageLayout>
    );
};
