import React, { FC } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import type { CvGroup } from '../../schemata';

const Experience: FC<{ group: CvGroup }> = ({ group }) => {
    return (
        <div className={classnames('flex flex-row py-1')}>
            <div className="flex flex-col justify-start w-full">
                <span className="text-font-dark font-bold">
                    {group.company}
                </span>
                <span className="text-font-dark">{group.position}</span>
            </div>
        </div>
    );
};

const Header: FC<{ title: string }> = ({ title }) => (
    <div className="text-neutral-400 font-semibold pb-2 uppercase">{title}</div>
);

const columnClassName = 'flex flex-col gap-3 basis-0 flex-grow';

export const NoTimeframeExperience: FC<{ groups: ReadonlyArray<CvGroup> }> = ({
    groups,
}) => {
    const { t } = useTranslation();

    const educations = groups.filter(({ kind }) => kind === 'education');
    const occupations = groups.filter(({ kind }) => kind === 'occupation');

    return (
        <div className="flex gap-3 justify-between rounded-lg bg-neutral-50 p-6">
            {occupations.length > 0 && (
                <div className={columnClassName}>
                    <Header title={t('cVAnalysis.work')} />
                    {occupations.map((group) => (
                        <Experience group={group} key={group.id} />
                    ))}
                </div>
            )}
            {educations.length > 0 && (
                <div className={columnClassName}>
                    <Header title={t('cVAnalysis.education')} />
                    {educations.map((group) => (
                        <Experience group={group} key={group.id} />
                    ))}
                </div>
            )}
        </div>
    );
};
