import classNames from 'classnames';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

export const TextArea = forwardRef<
    HTMLTextAreaElement,
    React.ComponentProps<'textarea'> & {
        label?: string;
        required?: boolean;
        recommended?: boolean;
        wrapperClassName?: string;
    }
>(({ className, label, required, recommended, ...props }, ref) => {
    const { t } = useTranslation();
    return (
        <div className={props.wrapperClassName}>
            {(label || required || recommended) && (
                <div className="flex justify-between mb-1">
                    {label && (
                        <label
                            htmlFor={label}
                            className="block text-sm font-bold font-jost text-neutral-500"
                        >
                            {label}
                        </label>
                    )}
                    {(required || recommended) && (
                        <span className="text-sm text-primary-4">
                            {required ? t('required') : t('recommended')}
                        </span>
                    )}
                </div>
            )}
            <textarea
                className={classNames(
                    `text-base border-1 border-neutral-400 p-2 rounded-lg w-full`,
                    'active:outline-0 active:ring-0 focus:outline-0 focus:ring-0',
                    'focus:border-primary-4 hover:border-primary-4 transition-all',
                    'disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-neutral-200',
                    className,
                )}
                {...props}
                ref={ref}
            />
        </div>
    );
});

TextArea.displayName = 'TextArea';
